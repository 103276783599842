/* ------------------------------------*\
    #CLASSIC TEASER
\*------------------------------------ */

$classic-teaser-button-size: $inuit-base-font-size;
$classic-teaser-button-color: $color-classic-primary;

//CLASSIC ITEM WRAPPER

$classic-teaser-item-wrapper-width: 100%;
$classic-teaser-item-wrapper-max-width: $max-page-width-with-padding;
$classic-teaser-item-wrapper-padding: 0 $inuit-base-spacing-unit;

//CLASSIC ITEM
$classic-teaser-item-width: auto;

$classic-teaser-item-headline-font-size: $inuit-heading-size-4;
$classic-teaser-item-headline-font-size-xl: 2vw;
$classic-teaser-item-headline-font-size-l: 2.5vw;
$classic-teaser-item-headline-font-size-m: $inuit-heading-size-4;

$classic-teaser-item-text-font-size: 13px;
$classic-teaser-item-text-font-size-xl: 13px;

$classic-teaser-item-headline-f-weight: $font-weight-medium;
$classic-teaser-item-headline-color: $color-classic-primary;
$classic-teaser-item-ml: $inuit-base-spacing-unit;
$classic-teaser-item-p: 30px;
$classic-teaser-item-p-tab: $inuit-base-spacing-unit;
$classic-teaser-item-p-mobile: $inuit-base-spacing-unit ($inuit-base-spacing-unit) / 2;
$classic-teaser-item-button-m: ($inuit-base-spacing-unit) / 2 0;
$classic-teaser-item-wrapper-min-height: 190px;
$classic-teaser-item-color-shop: $color-brand-primary;
$classic-teaser-item-f-color: $color-white;

$classic-teaser-item-right: $inuit-base-spacing-unit;

//BLACK INFOBOX
$classic-teaser-item-background: rgba($color-black, 0.8);
$classic-teaser-item-f-color-white: $color-black;

//WHITE INFOBOX
$classic-teaser-item-background-white: rgba($color-white, 0.8);

//SHOP INFOBOX
$classic-teaser-item-headline-color-shop: $color-brand-primary;

// todo:Klären: Was ist die eigentliche Komponente? c-classic-teaser oder c-textbox(__slider)? Ist meiner Ansicht nach zusammengemixt, bzw. ist einfach eine Komponente, oder sollte sauber getrennt sein!?
// classic Teaser kann theoretisch auch ohne textbox auskommen. Im Fall der ClassicSliderComponent bräuchte man die Textbox-Klassen nicht, wenn keine Textbox gepflegt ist.
// is-slider ist hier eine Q&D Lösung

.c-classic-teaser {
	position: relative;
	margin: 0 auto;
	max-width: 2000px;

	&.is-slider {
		display: none;

		&.slick-initialized {
			display: block;
		}
	}

	&:hover {
		.c-slider-control {
			display: block !important;
		}
	}
}

.c-classic-teaser__slides {
	display: flex !important;
	justify-content: center;
	align-items: center;
	position: relative;
	//border-bottom: 1px solid rgba($color-classic-primary, 0.4);

	@include media-query(xl-down) {
		display: block !important;
	}

	img {
		width: 100%;
		max-width: 100%;
		margin: 0;
		position: relative;
	}
}

.c-classic-teaser__video {
	max-width: 100%;
	margin: 0;
	width: 100%;
	line-height: 0;

	video {
		width: 100%;
	}
}

.c-classic-teaser__link {
	width: 100%;
}

.c-classic-teaser__item-wrapper {
	width: $classic-teaser-item-wrapper-width;
	max-width: $classic-teaser-item-wrapper-max-width;
	padding: $classic-teaser-item-wrapper-padding;
	height: auto;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: z("slider-item");

	@include media-query(m-down) {
		left: 0;
		top: 0;
		transform: translate(0, 0);
		height: auto;
	}

	@include media-query(l-down) {
		width: 100%;
		display: block;
		position: relative !important;
		padding: 0 !important;
		left: 0;
		top: 0;
		transform: translate(0, 0);
		min-height: $classic-teaser-item-wrapper-min-height;
	}

	@include media-query(xl-down) {
		width: 100%;
		display: block;
		position: absolute;
		height: 100%;
		padding: $classic-teaser-item-wrapper-padding;
		min-height: $classic-teaser-item-wrapper-min-height;
	}
}

.c-classic-teaser__item {
	min-width: $classic-teaser-item-width;
	padding: $classic-teaser-item-p;
	position: absolute;
	display: block;
	background: $classic-teaser-item-background;
	border: 0 solid $classic-teaser-item-background;
	top: 50%;
	transform: translateY(-50%);

	@include media-query(l-down) {
		min-width: auto;
		width: 100% !important;
		height: 100%;
		top: 0;
		transform: translateY(0);
		text-align: center !important;
		list-style-position: inside;
		padding: $classic-teaser-item-p-mobile;

		&.c-classic-teaser__item--shop {
			background: $classic-teaser-item-background-white;

			button {
				color: $color-black;

				&:hover {
					color: $color-white;
				}

				&:focus {
					color: $color-black;
				}
			}
		}

		&.c-classic-teaser__item--mobile-black {
			background: $classic-teaser-item-background !important;
		}

		&.c-classic-teaser__item--mobile-white {
			background: $classic-teaser-item-background-white !important;
		}
	}

	@include media-query(l) {
		padding: $classic-teaser-item-p-tab;
	}

	@include media-query(xl-down) {
		min-width: auto;
		width: $classic-teaser-item-width;
		top: 50%;
		transform: translateY(-50%);
		text-align: left;
		list-style-position: inside;
	}

	button {
		margin: $classic-teaser-item-button-m;
		float: right;

		@include media-query(l-down) {
			float: none;
		}
	}

	&.c-classic-teaser__item--white {
		background: $classic-teaser-item-background-white;
	}

	&.c-classic-teaser__item--right {
		right: $classic-teaser-item-right;

		@include media-query(l-down) {
			right: 0;
		}
	}
}

.c-classic-teaser__headline {
	font-size: $classic-teaser-item-headline-font-size;
	font-weight: $classic-teaser-item-headline-f-weight;
	margin-bottom: ($inuit-base-spacing-unit) / 2;
	white-space: nowrap;
	color: $classic-teaser-item-headline-color;

	@include media-query(m-down) {
		font-size: $classic-teaser-item-headline-font-size-m;
	}

	@include media-query(l) {
		font-size: $classic-teaser-item-headline-font-size-l;
	}

	@include media-query(xl-up) {
		font-size: $classic-teaser-item-headline-font-size-xl;
	}

	.c-classic-teaser__item--shop & {
		color: $classic-teaser-item-headline-color-shop;
	}
}

.c-classic-teaser__text {
	font-size: $classic-teaser-item-text-font-size;
	color: $classic-teaser-item-f-color;
	margin-left: $classic-teaser-item-ml;

	.language-ar & {
		margin-right: $classic-teaser-item-ml;
		margin-left: 0;
	}

	@include media-query(l-down) {
		font-size: $u-fontsize;
		margin-bottom: ($inuit-base-spacing-unit) / 2;

		.c-classic-teaser__item--shop & {
			color: $classic-teaser-item-f-color-white;
		}

		.c-classic-teaser__item--black & {
			color: $color-white;
		}

		.c-classic-teaser__item--mobile-black & {
			color: $color-white !important;
		}

		.c-classic-teaser__item--mobile-white & {
			color: $color-black !important;
		}
	}

	@include media-query(l) {
		font-size: $u-fontsize;
	}

	@include media-query(xl-up) {
		font-size: $classic-teaser-item-text-font-size-xl;
	}

	li {
		margin-bottom: 4px;
	}

	.c-classic-teaser__item--white & {
		color: $classic-teaser-item-f-color-white;
	}
}
