/* ------------------------------------*\
    #COLUMN TEXT
\*------------------------------------ */

.u-col-2 {
	@include media-query(m-up) {
		column-count: 2;
		column-gap: $lib-space-m;
	}
}

.u-col-3 {
	@include media-query(m-up) {
		column-count: 3;
		column-gap: $lib-space-m;
	}
}

// change column gap
.u-col-gap-l {
	column-gap: $lib-space-l;
}

.u-col-gap-2l {
	column-gap: 2 * $lib-space-l;
}

//revert columns
.u-col-span {
	-webkit-column-span: all;
	column-span: all;
}
