/* ------------------------------------*\
    #ACCORDION
\*------------------------------------ */

.c-accordion {
	border-bottom: 1px solid var(--color-gray-60);

	&__icon {
		display: flex;
		align-items: center;
		align-self: flex-start;
	}
}

.c-accordion__headline-wrapper {
	background-color: $color-gray-light;
	margin-bottom: $spacing-unit;

	&.is-open {
		.c-accordion__header::after {
			content: $icon-font--arrow-up-big;
			transform: rotate(0deg);
		}
	}
}

.c-accordion__header {
	position: relative;
	padding: 8px 16px 8px 0;
	margin-bottom: 0;
	border-top: 1px solid var(--color-gray-60);
	font-weight: 500;
	cursor: pointer;

	&--flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 400;
		line-height: 1.2;

		&::after {
			display: none;
		}
	}

	&::after {
		content: $icon-font--plus;
		position: absolute;
		right: 2px;
		font-weight: 700;

		@include icon-font(12px);

		line-height: 24px;

		.t-classic & {
			content: $icon-font--arrow-up-big;
			transform: rotate(180deg);
			transition: transform 0.4s ease;
		}
	}

	&.is-open {
		&::after {
			content: $icon-font--minus;
		}
	}
}

.c-accordion__body {
	position: relative;
	display: none;
	padding: 8px 0;

	&--transition {
		display: block;
		transition: height 0.25s;
	}

	&.is-active {
		display: block;
	}

	&.is-hidden {
		display: none;
	}
}

.c-accordion__headerContent {
	transition: height 0.25s;
}

.c-accordion-light {
	@include media-query(m-up) {
		padding-bottom: $spacing-unit;
	}
}

.c-accordion-light__header {
	margin-bottom: $spacing-unit--small;
	cursor: pointer;
	font-weight: 700;

	@include media-query(m-down) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $spacing-unit;
		padding-inline-start: $spacing-unit--small;
		margin-bottom: 0;
		font-weight: 700;
		border-bottom: 1px solid $color-gray-dark;
		background-color: $color-gray-lighter;
	}

	.language-ar & {
		direction: rtl;
		text-align: right;
	}

	&::after {
		display: inline-block;
		content: $icon-font--arrow-down-big;
		margin-inline-start: $spacing-unit--small;

		@include icon-font(11px);

		font-weight: 700;
		line-height: 12px;

		@include ui-transition();

		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	&.is-open::after {
		@include ui-transition();

		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

.c-accordion-light__body {
	display: none;

	@include media-query(m-down) {
		padding: $spacing-unit--small;
		border-bottom: 1px solid $color-gray-dark;

		p {
			margin-bottom: 0;
		}
	}
}

.c-accordion-mobile__header {
	@include media-query(m-down) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		margin-bottom: 0;
		padding: 0 $spacing-unit--small;
		cursor: pointer;
		border-bottom: 1px solid $color-gray-dark;
		font-size: $inuit-heading-size-6;
		font-weight: 500;

		&::after {
			content: $icon-font--arrow-down-big;

			@include icon-font(11px);

			font-weight: 700;
			line-height: 50px;

			@include ui-transition();

			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		&.is-open::after {
			@include ui-transition();

			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}

	.language-ar & {
		direction: rtl;
	}
}

.c-accordion-mobile__body {
	@include media-query(m-down) {
		display: none;
	}

	.language-ar & {
		direction: rtl;
	}
}
