/* ------------------------------------*\
	#Form
\*------------------------------------ */

$formColLeftWidth: 35%;
$formColRightWidth: 65%;

$formInputStreetWidth: 50%;
$formInputStreetWidthMobile: 80%;
$formInputStreetNoWidth: 13%;
$formInputStreetNo-pl: 2%;
$formInputStreetNoWidthMobile: 18%;

$formInputZipcodeWidth: 15%;
$formInputZipcodeWidthMobile: 30%;
$formInputCityWidth: 48%;
$formInputCity-pl: 2%;
$formInputCityWidthMobile: 68%;

$formInputCVVWidth: 80px;

$formInputPackstationWidth: 40%;
$formInputPackstationWidthMobile: 75%;
$formInputPackstationInfoWidth: 7%;

$formAccount: 60%;
$formBirthday: 20%;
$formBirthdaySmall: 28%;
$formtitle: 23%;

$formPlHeight: 16px; // = $checkbox-size;

//use this class only for forms with labels on the left side and to show placeholder only for mobile
.c-form {
	max-width: 600px;

	&.u-mxw-none {
		max-width: none;
	}

	@include media-query(m-down) {
		max-width: none;
	}
}

.c-form-input__btn-cross {
	position: absolute;
	top: 0;
	right: 0;
	width: $spacing-unit--large;
	height: $spacing-unit--large;
	line-height: $spacing-unit--large !important;
	font-size: $inuit-heading-size-micro !important;
	color: $color-gray-dark;
	text-align: center;
}

.c-form-row {
	margin-bottom: $inuit-base-spacing-unit;

	&--inline {
		display: flex;
	}

	&--flip {
		display: flex;
		align-items: flex-start;

		@include media-query("l-down") {
			flex-wrap: wrap;
		}
	}
}

.c-form-row--newsletter {
	width: 65%;
	left: 35%;

	@include media-query(l-down) {
		width: 100%;
		left: 0;
	}
}

.c-form-row__newsletter {
	overflow: hidden;
	padding-left: $spacing-unit--small;
}

.c-form-row__newsletter-subtext {
	font-size: 10px;
	color: $color-gray-dark;
	margin: 0;
}

.c-form-row__wrapper {
	width: $formColRightWidth;
	display: inline-block;

	input + input {
		margin-left: 5%;
	}

	@include media-query(l-down) {
		width: 100%;
	}
}

.c-form-row__small {
	width: 20% !important;
}

.c-form-row__big {
	width: 75% !important;
}

.c-form-label {
	display: inline-block;
	width: $formColLeftWidth;

	@include media-query("l-down") {
		display: none;
	}

	.c-form-row--flip & {
		@include media-query("l-down") {
			display: block;
			margin-bottom: $spacing-unit/3;
			width: 100%;
		}

		@include media-query("l-up") {
			padding-top: $spacing-unit--small - 1;
		}
	}
}

.c-form-label-top {
	display: block;
	margin-bottom: $spacing-unit/3;
	font-weight: $font-weight-bold;
}

.c-form-input {
	display: inline-block;
	width: $formColRightWidth;

	@include media-query("l-down") {
		width: 100%;
	}
}

.c-form-input--street {
	width: $formInputStreetWidth;

	@include media-query("l-down") {
		width: $formInputStreetWidthMobile;
	}
}

.c-form-input--streetnumber {
	width: $formInputStreetNoWidth;
	margin-left: $formInputStreetNo-pl;

	@include media-query("l-down") {
		width: $formInputStreetNoWidthMobile;
	}
}

.c-form-input--zipcode {
	width: $formInputZipcodeWidth;

	@include media-query("l-down") {
		width: $formInputZipcodeWidthMobile;
	}
}

.c-form-input--city {
	width: $formInputCityWidth;
	margin-left: $formInputCity-pl;

	@include media-query("l-down") {
		width: $formInputCityWidthMobile;
	}
}

.c-form-input--postnr,
.c-form-input--packstationnr {
	width: $formInputPackstationWidth;

	@include media-query("l-down") {
		width: $formInputPackstationWidthMobile;
	}
}

.c-form-postnr--info,
.c-form-packstation--info {
	display: inline-block;
	width: $formInputPackstationInfoWidth;
	text-align: center;
}

.c-form-input--cvv {
	width: $formInputCVVWidth;

	@include media-query(m-up) {
		margin-right: $spacing-unit;
	}
}

.c-form-pl {
	display: inline-flex;
	height: $formPlHeight;

	@include media-query("l-up") {
		padding-left: $formColLeftWidth;
	}
}

.c-form-error {
	margin-left: $formColLeftWidth;

	@include media-query("l-down") {
		margin-left: 0;
	}
}

.error-IBAN.c-form-error,
.error-accountHolder.c-form-error {
	margin-left: 0;
}

.c-form:not(.c-form--placeholder) {
	// Placeholder
	@include media-query("l-up") {
		/* Chrome/Opera/Safari */
		input::-webkit-input-placeholder,
		textarea::-webkit-input-placeholder {
			color: transparent;
		}

		/* FF 18- */
		input:-moz-placeholder,
		textarea:-moz-placeholder {
			color: transparent;
		}

		/* FF 19+ */
		input::-moz-placeholder,
		textarea::-moz-placeholder {
			color: transparent;
		}
	}

	@include media-query("l-down") {
		/* Chrome/Opera/Safari */
		input::-webkit-input-placeholder,
		textarea::-webkit-input-placeholder {
			color: $color-gray-dark;
		}

		/* FF 18- */
		input:-moz-placeholder,
		textarea:-moz-placeholder {
			color: $color-gray-dark;
			opacity: 1;
		}

		/* FF 19+ */
		input::-moz-placeholder,
		textarea::-moz-placeholder {
			color: $color-gray-dark;
			opacity: 1;
		}
	}
}

.c-form-input--account {
	display: inline-block;
	width: $formAccount;

	@include media-query(m-down) {
		width: 100%;
	}
}

.c-form-input--birthday {
	display: inline-block;
	width: 60%;

	@include media-query(m-down) {
		width: calc(100% - 22px);
	}
}

.c-form-professionalTitle,
.c-form-title {
	display: inline-block;
	width: $formtitle;

	+ .c-dropdown {
		display: inline-block;
		width: $formtitle;

		@include media-query(m-down) {
			width: 100%;
		}
	}

	@include media-query(m-down) {
		width: 100%;
	}
}

.g-recaptcha {
	text-align: right;

	& > div {
		display: inline-block !important;
	}
}

// Prevents iOS Safari from zooming in, to make the input larger.
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="week"] {
	@supports (-webkit-touch-callout: none) {
		font-size: max(1.1428571rem, 16px);
	}
}
