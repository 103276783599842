/* ------------------------------------*\
	#CL-DROPDOWN-FILTER
\*------------------------------------ */

$dropdown-filter-width: 250px;
$dropdown-filter-border: 1px solid $color-classic-primary;
$dropdown-filter-height: 40px;
$dropdown-filter-arrow-width: 30px;
$dropdown-filter-arrow-pl: 10px;

.c-cl-dropdown-filter {
	position: relative;
	width: $dropdown-filter-width;
	cursor: pointer;
	background-color: $color-white;
	z-index: 10;

	@include media-query(m-down) {
		width: 100%;
	}
}

.c-cl-dropdown-filter-wrapper {
	@include media-query(m-down) {
		padding: $inuit-base-spacing-unit--small 0;
	}
}

.c-cl-dropdown-filter__category-wrapper {
	width: 100%;
	position: relative;

	@include media-query(m-down) {
		border: $dropdown-filter-border;
		padding: 0 $spacing-unit--small;
	}

	.is-open & {
		&::after {
			content: $icon-font--arrow-down;

			@include icon-font();

			position: absolute;
			top: 100%;
			left: $spacing-unit;
			z-index: 1;
			font-size: $u-fontsize-s;
			margin-top: -3px;
			color: $color-classic-primary;
			background: $color-white;

			@include media-query(m-down) {
				display: none;
			}
		}
	}
}

.c-cl-dropdown-filter__category {
	display: flex;
	align-items: center;
	position: relative;
	height: $dropdown-filter-height;
	line-height: $dropdown-filter-height;
	font-size: $u-fontsize;

	@include media-query(m-down) {
		display: block;
	}

	.svg-icon {
		transform: rotate(0deg);
		transition: transform 0.4s ease-in-out;
		margin-left: $dropdown-filter-arrow-pl;
	}

	.is-open & {
		.svg-icon {
			transform: rotate(180deg);
		}
	}
}

.c-cl-dropdown-filter__inner {
	display: none;
	position: absolute;
	left: 0;
	top: $dropdown-filter-height;
	padding: $spacing-unit;
	background: $color-white;
	border: $dropdown-filter-border;

	&.is-open {
		display: block;

		@include media-query(m-down) {
			padding: $inuit-base-spacing-unit/2 0;
			width: 100%;
		}
	}
}

.c-cl-dropdown-filter__list {
	max-height: 400px;

	li + li {
		margin-top: ($spacing-unit / 2);
	}
}

.c-cl-dropdown-filter__item {
	display: block;
	width: 100%;

	@include media-query(m-down) {
		padding: $inuit-base-spacing-unit/2 $inuit-base-spacing-unit;
	}
}
