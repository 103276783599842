/* ------------------------------------*\
    #FLEX
\*------------------------------------ */

/**
 * Flex helper classes.
 */

.u-flex {
	display: flex;
}

.u-flex-right {
	display: flex;
	justify-content: flex-end;
}

.u-flex-between {
	display: flex;
	justify-content: space-between;
}

.u-flex-column-between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.u-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.u-flex-v-center {
	display: flex;
	align-items: center;
}

.u-flex-h-center {
	display: flex;
	justify-content: center;
}

.u-flex-column-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.u-flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.u-flex-full-height {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.u-flex-full-width {
	flex: 1 1 auto;
}

.u-flex-same-length {
	flex: 1;
}

.u-flex-row-reverse {
	flex-direction: row-reverse;
}

.u-flex-row-reverse-m-down {
	@include media-query(m-down) {
		flex-direction: row-reverse;
	}
}

.u-flex-column-reverse {
	flex-direction: column-reverse;
}

.u-flex-column-reverse-m-down {
	@include media-query(m-down) {
		flex-direction: column-reverse;
	}
}

.u-flex-column-m-down {
	@include media-query(m-down) {
		flex-direction: column;
	}
}

.u-flex-inline {
	display: inline-flex;
	align-items: center;
}

.u-flex-responsive {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@include media-query(m-down) {
		flex-direction: column;
	}
}
