/* ------------------------------------*\
    #FOOTER BOTTOM
\*------------------------------------ */

$footer-small-color: var(--color-white);
$separator-height: 0.7857rem;

.c-footer-small {
	background-color: var(--color-gray-90);
	padding: $space-m 0;
}

.c-footer-small__container {
	display: flex;
	flex-wrap: wrap;
}

.c-footer-small__column__classic,
.c-footer-small__column {
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	color: $footer-small-color;
	@extend .ocm-subtext1;

	@include media-query(l-up) {
		flex-basis: 33%;
		margin-bottom: 0;
		&__classic {
			flex-basis: 50%;
		}
		&:first-child {
			justify-content: flex-start;
		}
		&:last-child {
			justify-content: flex-end;
		}
	}

	@include media-query(l-down) {
		padding-bottom: 0;
		&:not(:last-child) {
			margin-bottom: $space-xs;
		}
	}
}

.c-footer-small__list {
	display: flex;
	list-style: none;
	margin: 0;
	li {
		display: inline-flex;
		align-items: center;
	}
	li:not(:last-child) {
		&::after {
			content: "";
			height: $separator-height;
			margin: 0 $space-xs;
			border-right: 1px solid $footer-small-color;
		}
	}
}

.c-footer-small__link {
	color: $footer-small-color;
	@extend .ocm-subtext1;

	&:hover {
		color: $footer-small-color;
	}

	&:focus-visible {
		outline-color: $footer-small-color;
	}
}
