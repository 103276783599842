/* ------------------------------------*\
    #MAPS
\*------------------------------------ */

$position-marker-size: 12px;
$marker-distance: 13px;

.c-maps {
	width: 100%;
}

.c-maps__container {
	position: fixed;
	height: 100%;
	width: 100%;
}

.c-maps--city-map {
	height: 100%;
}

.c-maps--current-pos-btn {
	display: none;
	position: fixed;
	z-index: 1000;
	bottom: $space-m + $space-s;
	left: $space-m;
	line-height: 0;
	width: 46px;

	&.is-visible {
		display: block;
	}
}

.c-maps__message-container {
	$message-spacing-left: 46px + $space-m + $space-s; // button width + space

	display: none;
	position: absolute;
	bottom: $space-m + $space-s;
	left: $message-spacing-left;
	max-width: calc(100% - #{$message-spacing-left + $space-m});
	padding: $space-m / 2;
	background: $color-gray-light;
	z-index: 15;
	text-align: center;

	@include media-query(m-up) {
		left: $space-m;
		max-width: calc(100% - #{$space-m + 60px}); // 60px ~ maps control-buttons rechts
	}

	&.is-visible {
		display: block;
	}
}

.c-maps__position-marker {
	width: $position-marker-size;
	height: $position-marker-size;
	border-radius: 100%;
	position: relative;
}

.c-maps__position-point {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: $color-classic-primary;
	border-radius: 100%;
	border: 1px solid $color-white;
	z-index: 5;
}

.c-maps__position-accuracy {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid $color-white;
	background: $color-gold-transparent;
	border-radius: 100%;
	z-index: 0;
}

.c-maps__marker-container {
	position: absolute;

	&:hover {
		cursor: pointer;
	}

	&--icon {
		transform: translateX(-50%);
	}

	img {
		border: 2px solid transparent;
	}

	&.c-maps__marker-container--admin-active {
		border-color: $color-classic-primary;

		img {
			border-color: $color-classic-primary;
		}
	}
}

.c-maps__marker-container--right {
	transform: translateX(-100%);
}

.c-maps__marker {
	display: inline-block;
	background: $color-white;
	border: 1px solid transparent;
	padding: $spacing-unit--tiny $spacing-unit--small;
	white-space: nowrap;
	overflow: hidden;
	margin-left: $marker-distance;

	.c-maps__marker-container--right & {
		margin-right: $marker-distance;
		margin-left: 0;
	}

	.c-maps__marker-container--active & {
		border: 1px solid $color-black;
	}

	.c-maps__marker-container--admin-active & {
		background: $color-classic-primary;
		color: white;
	}

	.is-unlocked & {
		border-color: #00870a;
	}
}

.c-maps__marker-point {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background: $color-black;
	position: absolute;
	top: 7px;
	left: -5px;
	right: auto;

	&--right {
		right: -5px;
		left: auto;
	}
}

.c-maps__marker-point-active {
	display: none;
	float: left;
	width: 18px;
	height: 19px;
	border-radius: 100%;
	border: 1px solid $color-black;
	position: absolute;
	top: 2px;
	left: -22px;

	.c-maps__marker-container--active & {
		display: inline-block;
	}
}

.flag-new {
	padding-right: 0;

	&::after {
		content: "NEW";
		padding: $spacing-unit--tiny $spacing-unit--small;
		background: $color-black;
		color: $color-white;
		margin-left: $spacing-unit--small;
	}
}

.flag-soon {
	padding-right: 0;

	&::after {
		content: "SOON";
		padding: $spacing-unit--tiny $spacing-unit--small;
		background: $color-black;
		color: $color-white;
		margin-left: $spacing-unit--small;
	}
}

.c-maps__marker-overlay {
	width: 250px;
	padding: $spacing-unit;
	background: $color-white;
	font-size: $inuit-base-font-size;
	box-shadow: 2px 0 5px $color-gray-dark;

	&::after {
		content: "";
		width: 16px;
		height: 16px;
		left: 50%;
		background: $color-white;
		position: absolute;
		transform: rotate(45deg);
		bottom: -8px;
		margin-left: -8px;
	}
}

// Back-Button

$maps-back-p: 10px;
$maps-back-iconfontsize: 14px;
$maps-back-icon-p: 9px;
$maps-back-icon-ml: 10px;

.c-maps__back {
	position: fixed;
	top: 0;
	right: 0;
	z-index: z("classic-map-close");
	padding: $maps-back-p;
	cursor: pointer;
	background-color: $color-white-transparent-dark;

	@include media-query(m-down) {
		.c-maps__back-text {
			display: none !important;
		}
	}
}

.c-maps__back-text {
	display: inline-block;
	vertical-align: middle;

	@include font-size($inuit-base-font-size, 1);
}

.c-maps__back-icon {
	@include icon-font();
	@include font-size($maps-back-iconfontsize, 1);

	padding: $maps-back-icon-p;
	border-radius: 50%;
	color: $color-white;
	background-color: $color-black;
	border: none;
	margin-left: $maps-back-icon-ml;
	display: inline-block;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	cursor: pointer;

	@include media-query(m-down) {
		display: block !important;
		margin-left: 0;
	}

	&::before {
		content: $icon-font--close;
	}
}

.c-maps__marker-inline-flag {
	font-weight: $font-weight-bold;
	padding-left: $spacing-unit--tiny;
	display: inline-block;
}
