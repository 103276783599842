/* ------------------------------------*\
    #CLASSIC 404
\*------------------------------------ */

$classic-404-height: 80vh;
$classic-404-height-mobile: 80vh;
$classic-404-headline-size: 80px;

.c-classic-404 {
	position: relative;
	height: $classic-404-height;

	@include media-query(l-down) {
		height: $classic-404-height-mobile;
	}
}

.c-classic-404__img {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: $classic-404-height;
	width: 100%;

	@include media-query(l-down) {
		height: $classic-404-height-mobile;
	}
}

.c-classic-404__content {
	text-align: center;
	position: absolute;
	bottom: $spacing-unit * 2;
	left: 50%;
	transform: translateX(-50%);

	h1 {
		font-size: $classic-404-headline-size;
		color: $color-white;
	}
}

.c-classic-404__info {
	font-weight: $font-weight-bold;
	font-size: $inuit-heading-size-2;
	color: $color-white;
	margin: 0;
}

.c-classic-404__sub-info {
	color: $color-white;
}
