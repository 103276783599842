/* ------------------------------------*\
    #COLORS
\*------------------------------------ */

.u-link-brand {
	color: $color-brand-primary;
	font-weight: inherit;

	&:hover {
		color: $color-brand-hover;
	}
}

/* ------------------------------------*\
   #COLORS
\*------------------------------------ */

// Colors map.
// Insert colors here, you wish to have a background-color
// and text-color utility-class for.
$colors: (
	brand: $color-brand-primary,
	classic: $color-classic-primary,
	gray-lighter: $color-gray-lighter,
	gray-light: $color-gray-light,
	gray-dark: $color-gray-dark,
	gray-darker: $color-gray-darker,
	black: $color-black,
	black-light: $color-black-lighter,
	black-transparent: $color-black-transparent,
	black-transparent-dark: $color-black-transparent-dark,
	white: $color-white,
	white-transparent: $color-white-transparent,
	white-transparent-dark: $color-white-transparent-dark,
	ui: $color-white,
	error: $color-error,
	ok: $color-ok,
);

// Don't need to generate a bg-utility for this case
.u-color-text {
	&-primary {
		color: $color-black-light;
	}

	&-secondary {
		color: $color-black;
	}
}

/**
 * Background colors.
 */
@each $color in $colors {
	$color-name: nth($color, 1);
	$color-value: nth($color, 2);

	.u-color-bg-#{"" + $color-name} {
		background-color: #{$color-value};
	}
}

/**
 * Text colors.
 */
@each $color in $colors {
	$color-name: nth($color, 1);
	$color-value: nth($color, 2);

	.u-color-text-#{"" + $color-name} {
		color: #{$color-value};
	}
}

/**
 * Legacy Color definition - only integrated for fallback in case there are still existing variables named in the old way
 */

@each $color in $colors {
	$color-name: nth($color, 1);
	$color-value: nth($color, 2);

	.u-bg-#{"" + $color-name} {
		background-color: #{$color-value};
	}
}

@each $color in $colors {
	$color-name: nth($color, 1);
	$color-value: nth($color, 2);

	.u-text-#{"" + $color-name} {
		color: #{$color-value};
	}
}
