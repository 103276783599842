/* ------------------------------------*\
    #TEXT-ALIGN
\*------------------------------------ */

/**
 * Text align left.
 */
.u-text-left {
	text-align: left !important;
}

/**
 * Text align right.
 */
.u-text-right {
	text-align: right !important;
}

/**
 * Text align center.
 */
.u-text-center {
	text-align: center !important;
}
