/* ------------------------------------*\
    #OFFCANVAS LEFT , RIGHT CLASSIC
\*------------------------------------ */
$top-item-pt: 16px; //$spacing-unit- $border-width

.c-offcanvas-right {
	@include media-query(m-down) {
		.c-accordion-nav__link--top-item {
			border-bottom: 2px solid var(--color-black);
			background-color: $color-white;
			font-weight: bold;
			padding-top: $top-item-pt;
		}

		.c-accordion-nav__item--classic {
			border-bottom: 1px solid $color-gray-light;
		}
	}
}
