/* ------------------------------------*\
   #FILTER COLORS
\*------------------------------------ */

// Colors map.
// Insert colors here, you wish to have a background-color
// and text-color utility-class for.
$filter-colors: (
	beige: #c8ac99,
	black: #000,
	blue: #0b4086,
	brown: #865d35,
	cyan: #1cd9b3,
	deeppink: #ff1493,
	gold: #fad649,
	gray: #9e9e9e,
	green: #1b9715,
	orange: #f80,
	pink: #ff6ea8,
	purple: #9012a4,
	red: #ff001e,
	redgold: #d796a0,
	rosegold: #e6c7c2,
	silver: #e0e0e0,
	white: #fff,
	whitegold: #fdeba4,
	yellow: #fffc54,
	yellowgold: #fde94f,
);

/**
 * Background colors.
 */
@each $filter-color in $filter-colors {
	$color-name: nth($filter-color, 1);
	$color-value: nth($filter-color, 2);

	.#{"" + $color-name} {
		background-color: #{$color-value};
	}
}

.white {
	background-color: #fff;
	outline: 2px solid var(--color-black-primary);
}

.multicolor {
	background: conic-gradient(#194281 90deg, #eb332f 90deg 180deg, #489330 180deg 270deg, #fad649 270deg);
}

$filter-colors-img: (
	striped: url("/_ui/responsive/common/images/colors/gestreift.png"),
	plaid: url("/_ui/responsive/common/images/colors/kariert.png"),
);

@each $filter-color-img in $filter-colors-img {
	$color-name: nth($filter-color-img, 1);
	$color-value: nth($filter-color-img, 2);

	.#{"" + $color-name} {
		background-image: #{$color-value};
		background-size: 100% 100%;
	}
}
