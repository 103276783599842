/* ------------------------------------*\
    #CURRENT INFOS SCSS
\*------------------------------------ */

$business-hours-closed-icon-color: $color-error;
$business-hours-open-icon-color: $color-ok;

$times-font-sizes: $inuit-heading-size-5;
$times-font-sizes--mobile: $inuit-base-font-size;

$current-info-ml: ($spacing-unit * 2);
$current-info-height: 36px;

.c-current-info {
	align-items: center;
	bottom: 0;
	display: none;
	flex-direction: column;
	font-weight: 600;
	height: 100%;
	justify-content: center;
	left: 100%;
	margin-left: 2.285714285714286rem;
	position: absolute;
	white-space: nowrap;

	.language-ar & {
		direction: rtl;
	}

	// newheader
	.c-header.is-fixed & {
		display: none;
	}
}

@media (min-width: 112em) {
	.c-current-info {
		display: flex;
	}
}

.c-current-info--mobile {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	background: $color-black;
	color: $color-white;
}

.c-current-info__business-hours {
	display: inline-block;
}

.c-current-info__times {
	font-size: $times-font-sizes;
}

.c-current-info__times--mobile {
	font-size: $times-font-sizes--mobile;
}

.c-current-info__opening-status {
	&.is-open {
		i {
			color: $business-hours-open-icon-color;
		}
	}

	&.is-closed {
		i {
			color: $business-hours-closed-icon-color;
		}
	}
}
