/* ------------------------------------*\
    #BRAND SLIDER
\*------------------------------------ */

.c-brand-slider {
	margin-left: auto;
	margin-right: auto;
	max-width: 1408px;
	margin-bottom: $lib-space-xl;

	@include media-query(m-up) {
		margin-bottom: $lib-space-xxl;
	}

	&__slider {
		height: 100px;
		overflow: hidden;
		display: flex;
		margin: 0 0 $lib-space-m 0 !important;

		@include media-query(l-up) {
			padding-left: $lib-space-m;
			padding-right: $lib-space-m;
		}
	}

	&__headline {
		margin: 0 $lib-space-m $lib-space-m;
	}

	&__link {
		text-decoration: underline;
		margin: 0 $lib-space-m;
	}

	& &__item {
		padding: $lib-space-m 22px;
		display: block;
		flex-shrink: 0;
		color: var(--color-gray-30);
		box-shadow: var(--borderInner);
		margin-right: 8px;
		width: 150px;
		height: 100px;

		// prevent hover style to leave tile gray after scrolling in carousel on mobile devices
		@media (pointer: fine) {
			&:hover {
				background-color: var(--color-gray-30);
			}
		}

		// focus outline if slick slider does not fully initialize, because all content fits on viewport
		position: relative;
		&:focus-visible {
			outline: transparent;
			&::after {
				content: "";
				position: absolute;
				inset: 0;
				border: 2px solid var(--color-black-primary);
			}
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__container {
		&:first-child {
			@include media-query(l-down) {
				padding-left: 1.142rem;
			}
		}
	}

	&__arrow {
		@extend .ocm-button__filled-black;
		@extend .ocm-button--small;
	}

	& &__arrow {
		position: absolute;
		top: calc((50% - (3.285rem / 2)));
		width: 3.285rem;
		height: 3.285rem;
		background-color: $color-black-light;
		color: white;
		z-index: 2;

		svg {
			width: 1.4285rem;
			height: 1.4285rem;
		}

		&--left {
			left: $lib-space-m;
			svg {
				margin-left: -0.2rem;
			}
		}

		&--right {
			right: $lib-space-m;
			svg {
				margin-left: 0.2rem;
			}
		}

		&.slick-disabled {
			visibility: hidden;
		}

		&:hover {
			color: white;
		}

		.no-touchevents &:focus::after {
			border: none;
		}
	}
}
