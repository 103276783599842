/* ------------------------------------*\
     #GRADIENT
\*------------------------------------ */

.u-img-gradient {
	display: inline-block;

	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: inline-block;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%); /* W3C */

		@include media-query(l-down) {
			background: none;
		}
	}
}

$gradient-height: 100px;

.u-gradient {
	position: absolute !important;
	bottom: 0;
	z-index: 1;
	height: $gradient-height;
}
