/* ------------------------------------*\
    das ist der RAHMEN für die Flyouts
    der Content sollte hier nicht definiert werden.
\*------------------------------------ */
@import "../../settings/header-settings";

.c-header-meta-link__flyout-outerframe {
	height: 100%;
	margin-left: 1.714285714285714rem;
	position: relative;

	.c-header-meta-nav__user-bottom &,
	.is-header-sticky & {
		margin-left: 0;
	}

	.is-header-sticky &.hide-on-fixed {
		display: none;
	}
}

.c-header-meta-link__flyout {
	background-color: $color-white;
	box-shadow: var(--shadow-4);
	display: none;
	position: absolute;
	right: 0;
	text-align: left;
	top: 100%;
	z-index: 502;
	border-radius: 8px;

	.c-header-meta-nav__user-bottom &,
	.is-header-sticky & {
		border-top: 2px solid var(--color-gray-40);
		clip-path: inset(0 -10px -10px -10px);
	}

	.is-open & {
		display: block;
	}
}

.c-header-meta-link__flydown_button {
	position: absolute;
	bottom: -10px;
	left: calc(50% - 0.475rem);
	z-index: 20;
}
