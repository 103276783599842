/* ------------------------------------*\
    #INLINE
\*------------------------------------ */

$inline-text-link-color: $color-classic-primary;
$inline-text-line-height--mobile: 26px;
$inline-text-line-height: 20px;
$inline-text-label-padding: 6px;

.c-inline {
	@include media-query(xl-up) {
		display: inline-flex;
	}

	@include media-query(xl-down) {
		display: inline-block;
	}
}

.c-inline__label {
	padding-right: $inline-text-label-padding;
	line-height: $inline-text-line-height;

	@include media-query(m-down) {
		line-height: $inline-text-line-height--mobile;
	}
}

.c-inline__link {
	color: $inline-text-link-color;
	line-height: $inline-text-line-height;
	text-decoration: underline;
	text-underline-position: under;
}
