/* ------------------------------------*\
    #SEO IMAGE
\*------------------------------------ */

$container-height: 400px;

.c-seo-image--flipped {
	.c-seo-image__item {
		&:first-child {
			transform: translateX(100%);

			@include media-query(m-down) {
				transform: translateX(0);
			}
		}

		&:last-child {
			transform: translateX(-100%);

			@include media-query(m-down) {
				transform: translateX(0);
			}
		}
	}
}

.c-seo-image__item {
	.slick-slider {
		margin-bottom: 0;
	}

	&--border-bottom {
		@include media-query(m-down) {
			border-bottom: 1px solid $color-classic-primary;
		}
	}
}

.c-seo-image__image {
	// height: $container-height;
	background-size: cover;

	&.slick-slide {
		// height:$container-height;
	}

	img {
		@include media-query(m-up) {
			@include aspect-ratio(690, 400);
		}
	}
}

.c-seo-image__body {
	max-height: 470px; // max image height
	overflow: auto;
	overflow-x: hidden;

	@include media-query(m-down) {
		max-height: $container-height/2;
		overflow: hidden;

		@include ui-transition();

		&.is-active {
			max-height: 3000px;
			padding-right: 0;

			@include ui-transition();
		}
	}

	@include media-query(m-up) {
		.c-seo-image__body {
			padding-right: $spacing-unit;
		}
	}
}

.c-seo-image__content-wrapper {
	@include media-query(m-up) {
		@include aspect-ratio(630, 400);
	}

	.language-ar & {
		direction: rtl;
	}
}
