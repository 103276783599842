/* ------------------------------------*\
    #INFO BAR
\*------------------------------------ */

$info-bar-background: $color-black;
$info-bar-text-align: center;
$info-bar-transition: height 0.2s ease, padding 0.2s ease;
$info-bar-height: auto;
$info-bar-p: $spacing-unit ($spacing-unit * 2);

$info-bar-text-color: $color-white;

$info-bar-font-size: $heading-secondary-font-size-zeta;
$info-bar-font-size-m: 13px;

$info-bar-close-icon-color: $color-white;
$info-bar-close-icon-pos-t: 50%;
$info-bar-close-icon-pos-r: $spacing-unit;

.c-info-bar {
	position: relative;
	display: block;
	background-color: $info-bar-background;
	text-align: $info-bar-text-align;
	height: $info-bar-height;
	padding: $info-bar-p;
	transition: $info-bar-transition;

	&.is-closed {
		height: 0;
		padding: 0;
	}

	&--secondary {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid #ebeaea;
		background-color: $color-gray-lighter;
		text-align: unset;
		height: auto;
		padding: 7px 14px;
		transition: none;
		font-size: $u-fontsize;
		line-height: 1.142857;

		svg {
			flex: 0 0 24px;
			width: 24px;
			height: 24px;
			margin-right: 9px;
		}
	}
}

.c-info-bar__text {
	color: $info-bar-text-color;
	font-size: $info-bar-font-size;

	@include media-query(m-down) {
		font-size: $info-bar-font-size-m;
	}
}

.c-info-bar__close {
	position: absolute;
	right: $info-bar-close-icon-pos-r;
	top: $info-bar-close-icon-pos-t;
	transform: translateY(-50%);
	cursor: pointer;

	i {
		color: $info-bar-close-icon-color;
	}
}
