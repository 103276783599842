///*------------------------------------*\
//    #Header and offcanvas
//\*------------------------------------*/
// Dimensions used in different scss files
@import "colors";

// Header Logo
$gl_header-logo-l-width: 230px;
$gl_header-logo-l-height: 53px;

$gl_header-logo-m-width: 180px;
$gl_header-logo-m-height: 53px;

// Header Bars
$gl_header-logo-bar-height: 71px;
$gl_header-logo-bar-margin: -22px;
$gl_header-top-links-height: 52px;
$gl_header-top-links-height-l-down: 30px;
$gl_header-navigation-bar-height: 50px;

$gl_header-fixed-height: $gl_header-navigation-bar-height;

$gl_header-border-width: 2px;
//$gl_header-border-width-hight-resolution: 1.5px; mit Media-Query nur für Hochauflösende Monitore ?
$gl_header-border-color: $color-gray-light;
$gl_header-flyout-box-shadow: 0 0 6px var(--color-gray-40);

// Header Primary and Secondary Navigation
$gl_second-nav-spacing: 16px; // sollte durch 2 Teilbar sein.
