/* ------------------------------------*\
    #INSPIRATION TEASER
    #INSPIRATION
\*------------------------------------ */

.c-inspiration-teaser {
	display: none;

	&.slick-initialized {
		display: block;
	}
}

.c-inspiration-teaser__slides {
	position: relative;
}
