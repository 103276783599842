/* ------------------------------------*\
    #CAREER SPLITPAGE
\*------------------------------------ */

.c-career-split__mobile-title {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	background: $color-black;
	padding: $spacing-unit--tiny 0;
	color: $color-white;
}

.c-career-split__item {
	display: inline-block;
}
