/* ------------------------------------*\
    #ICON-TEXT
\*------------------------------------ */

$icon-text-spacing: $inuit-base-spacing-unit--tiny !default;

/**
 * Styling for an object which contains an icon and a text.
 */

.o-icon-text {
	line-height: 1;
	white-space: nowrap;
	overflow: visible;
}

.o-icon-text__icon {
	padding-right: $icon-text-spacing;

	.language-ar & {
		padding-right: 0;
		padding-left: $icon-text-spacing;
	}
}

.o-icon-text__icon-right {
	padding-left: $icon-text-spacing;
}

.o-icon-text__icon,
.o-icon-text__icon-right,
.o-icon-text__text {
	display: inline-block;
	vertical-align: middle;
}
