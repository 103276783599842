/* ------------------------------------*\
    #TEXTBOXES
\*------------------------------------ */

$days-font-size: $heading-classic-secondary-font-size-alpha;
$span-font-size: $heading-classic-secondary-font-size-epsilon;

.c-cl-counter__span {
	font-size: $span-font-size;
}

.c-cl-counter__days {
	font-size: $days-font-size;
	margin: 0;
	line-height: 0.8;
	color: $color-classic-primary;
}
