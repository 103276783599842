/* ------------------------------------*\
    #LANGUAGE STYLES CONFIG
\*------------------------------------ */

.u-direction-ltr {
	direction: ltr !important;
}

.u-direction-rtl {
	direction: rtl;
}
