// Signin-Page and Landingpages
.cms-login-benefits {
	margin-top: 0 !important;
	margin-bottom: 1.4285714rem !important; // 20px

	.cms-login-benefits__headline {
		font-size: 1.7142857rem !important; // 24px
		line-height: 2.2857143rem !important; // 28px
		font-style: normal !important;
		font-weight: 600 !important;
		padding-bottom: 0 !important;
		margin-bottom: 0.2857143rem !important; // 4px
	}

	.cms-login-benefits__subheadline {
		font-size: 1.1428571rem !important; // 16px
		line-height: 1.4285714rem !important; // 20px
		font-style: normal !important;
		font-weight: 400 !important;
		margin-bottom: 1.1428571rem !important; // 16px
	}

	.cms-login-benefits__list {
		margin-left: 0 !important;
		list-style: none !important;
	}

	.cms-login-benefits__list-text {
		font-size: 1rem !important; // 14px
		line-height: 1.4285714rem !important; // 20px
		font-style: normal !important;
		font-weight: 400 !important;
		margin-bottom: 0.4285714rem !important; // 6px
		position: relative;
		padding-left: 2rem;

		svg {
			position: absolute;
			top: 0;
			left: 0;
			height: 1.4285714rem !important; // 20px
			width: 1.4285714rem !important; // 20px
			fill: currentColor !important;
		}
	}
}

// OVERWRITE STYLES FOR CLASSES USED IN CMS-COCKPIT
// Overwrite old styles in signInPages (cms-cockpit)
%login-benefits-hook {
	&::before {
		border-right: 2px solid var(--color-black);
		border-bottom: 2px solid var(--color-black);
		content: "";
		display: block;
		height: 24px;
		left: 0;
		top: -3px;
		position: absolute;
		transform: rotate(45deg) scale(0.75) translate(4px, -4px);
		width: 12px;
	}
}

.c-cms-login-content > div:first-child {
	@extend .cms-login-benefits;

	h3.u-heading-large-secondary {
		@extend .cms-login-benefits__headline;
	}

	div.u-heading-small {
		@extend .cms-login-benefits__subheadline;
	}

	ul.c-login-headline-list {
		@extend .cms-login-benefits__list;
	}

	li.c-login-headline-list__item {
		@extend .cms-login-benefits__list-text;
		@extend %login-benefits-hook;
	}
}

// Overwrite old styles in LandingPages (cms-cockpit)
#LandingPageLayerWrapper > div:first-child > div:first-child {
	@extend .cms-login-benefits;

	h3.c-login-layer__headline {
		@extend .cms-login-benefits__headline;

		span {
			display: block;
		}
	}

	p.Text {
		@extend .cms-login-benefits__subheadline;
	}

	.o-layout__item .c-login-layer__icon-text {
		@extend .cms-login-benefits__list-text;
		@extend %login-benefits-hook;

		text-transform: none !important;
		padding-bottom: 0 !important;
	}

	.o-layout__item .c-login-layer__icon-text .c-login-layer__icon {
		display: none;
	}
}

// handle displayed image
.c-cms-login-wrapper .c-cms-login-image img {
	object-fit: cover;
	width: 100%;

	@include media-query(l-up) {
		position: relative;
		min-width: 100%;
		min-height: 100%;
		max-width: none;
		margin-top: 0;
	}
}

.line-on-side {
	color: $color-gray-darker;
	text-align: center;
	padding: $lib-space-xs 0;
	display: flex;
	justify-content: center;
	align-items: center;

	&::before,
	&::after {
		content: "";
		border-top: 1px solid $color-gray-light;
		margin: 0 4px 0 0;
		flex: 1 0 20px;
	}

	&::after {
		margin: 0 0 0 4px;
	}
}
