// makes sure the long string will wrap and not bust out of the container

@mixin word-wrap() {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;

	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */

	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

// truncating the text and adding ellipses where the string of text hits the container

@mixin ellipsis() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
