/* ------------------------------------*\
    #BUTTONS
\*------------------------------------ */
.c-btn--normal {
	border: none;
	background-color: $color-black-lighter;
	color: $color-white;
	transition: background-color 0.15s ease;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-black;
		color: $color-white;
	}

	&:focus {
		color: $color-white;
	}
}

.c-btn--brand {
	background-color: $color-brand-primary;
	border-color: $color-brand-primary;
	color: $color-white;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-brand-hover;
		border-color: $color-brand-hover;
		color: $color-white;
	}

	&:focus {
		color: $color-white;
	}
}

.c-btn--cart {
	border: none;
	background-color: $color-button-red;
	color: $color-white;
	transition: background-color 0.15s ease;

	&:hover {
		background-color: $color-button-red-hover;
		color: $color-white;
	}

	&:focus {
		color: $color-white;
	}
}

.c-btn--transparent {
	background-color: $color-white;
	border: 1px solid currentColor;
	color: $color-black;
	box-shadow: 0 0 0 0 currentColor inset;
	transition: box-shadow 0.15s ease;

	.no-touchevents &:hover,
	.no-touchevents &:focus,
	.no-touchevents a:hover &.c-btn--textbox {
		background-color: $color-white;
		color: $color-black;
		box-shadow: 0 0 0 1px currentColor inset;
	}
}

.c-btn--abort {
	background-color: $color-gray-dark;
	color: $color-white;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-gray-darker;
		color: $color-white;
	}
}

.c-btn--normal:disabled {
	pointer-events: none;
	background-color: $color-gray-light;
	color: $color-gray-dark;
}

/* ButtonLink.vue */
.c-btn--transparent:disabled,
.c-btn--disabled-light:disabled {
	pointer-events: none;
	background-color: $color-white-shaded;
	border-color: currentColor;
	color: $color-gray-dark;
}

.c-btn--download {
	background-color: $color-white;
	border: 1px solid $color-black;
	color: $color-black;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-white;
		color: $color-black;
	}
}

.c-btn--classic {
	border: none;
	background-color: $color-black-lighter;
	color: $color-white;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-black;
		color: $color-white;
	}

	&:focus {
		color: $color-white;
	}
}

.c-btn--classic-o {
	background-color: transparent;
	color: $color-classic-primary;
	border: 1px solid $color-classic-primary;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-classic-primary;
		color: $color-white;
	}

	&:focus {
		background-color: transparent;
		color: $color-classic-primary;
		border: 1px solid $color-classic-primary;
	}

	&:active {
		color: $color-classic-primary;
	}
}

.c-btn--brand-classic {
	background-color: transparent;
	color: $color-black;
	border: 1px solid $color-brand-primary;

	&:hover,
	a:hover &.c-btn--textbox {
		background-color: $color-brand-primary;
		border-color: $color-brand-primary;
		color: $color-white;
	}

	&:focus {
		background-color: transparent;
		color: $color-black;
		border: 1px solid $color-brand-primary;
	}

	&:active {
		color: $color-white;
	}
}
