///*------------------------------------*\
//    #COLORS
//\*------------------------------------*/

$color-brand-primary: var(--color-red-primary);
$color-classic-primary: var(--color-black);
$color-brand-hover: var(--color-red-hover);
$color-classic-hover: $color-classic-primary;

$color-badge-gray: var(--color-gray-40);

$color-black: var(--color-black);
$color-black-light: var(--color-black-primary);
$color-black-lighter: var(--color-black-primary);
$color-white: var(--color-white);
$color-white-shaded: var(--color-gray-20);

$color-gray-lighter: var(--color-gray-20);
$color-gray-light: var(--color-gray-40);
$color-gray-dark: var(--color-gray-60);
$color-gray-darker: var(--color-gray-80);

$color-gold-dark: #c4a46c;
$color-silver-dark: #bebec3;

$color-button-red: var(--color-red-primary);
$color-button-red-hover: var(--color-red-hover);

// Feedback-Colors
$color-ok: var(--color-success);
$color-error: var(--color-error);
$color-error-bg: #f5ebec; // not needed as soon as the elements that use this color are overhauled accornding to the styleguide

//Social Icon Colors
$color-facebook: #3b5998;
$color-instagram: #e95950;
$color-pinterest: #cb2027;
$color-twitter: #55acee;
$color-snapchat: #fffc00;

// Transparent color
$color-white-transparent: rgba(255, 255, 255, 0.2);
$color-white-transparent-dark: rgba(255, 255, 255, 0.7);

$color-black-shadow: rgba(0, 0, 0, 0.3);
$color-black-transparent: rgba(0, 0, 0, 0.6);
$color-black-transparent-dark: rgba(0, 0, 0, 0.8);

$color-gold-transparent: rgba(196, 163, 108, 0.4);
