.c-themeWorldItem {
	&__headline {
		@extend .ocm-headline2;

		margin-bottom: 0;
	}

	&__text {
		font-size: 1rem;
		font-weight: $font-weight-normal;
		line-height: 20px;
		margin-top: $lib-space-s;
		margin-bottom: 0;
	}

	&__button {
		margin-top: $lib-space-m;
		width: 100%;
		@include media-query(m-up) {
			width: auto;
		}
	}

	&__teaserImage {
		position: relative;
		display: block;
		overflow: hidden;
		padding-top: 120%; // aspect-ratio 5/6 (6 div by 5)

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		@include media-query(m-up) {
			padding-top: 0;
			margin-bottom: 0;

			img {
				position: static;
				top: unset;
				left: unset;
				transition: transform 250ms ease-in-out;
				transform-origin: center;
				object-fit: cover;
			}

			&:hover {
				img {
					transform: scale(1.075);
				}
			}
		}
	}
}
