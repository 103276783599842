/* ------------------------------------*\
    #HEADER Main-Navigation
    1) Primary Navigation (Department)
    2) Secondary Navigation (Bekleidung, u.a.)
\*------------------------------------ */
@import "../../settings/header-settings";

// Main Navigation Container
$header-nav-container-top: $gl_header-logo-bar-height + $gl_header-top-links-height + $gl_header-logo-bar-margin;
$header-nav-container-top-l-down: $gl_header-logo-bar-height + $gl_header-top-links-height-l-down;
$header-nav-left-offset: -$gl_second-nav-spacing;
$secondary-nav-item-mr: 118px;
$secondary-nav-item-mr-tablet: 120px;
$secondary-nav-item-mr--classic: 152px;

//-----------------------------------
// Main-Navigation Container
//-----------------------------------
.c-nav-container {
	display: inline-flex;
	height: 100%;
}

.c-header__nav {
	border-bottom: 2px solid var(--color-gray-40);
	box-sizing: content-box;
	height: 3.142857142857143rem;
	left: 0;
	position: absolute;
	top: 7.142857142857143rem;
	width: 100%;

	@media (min-width: 64em) {
		background-color: white;
		height: 4rem;
		top: 6.285714285714286rem;
	}

	&.c-header__nav--checkout {
		@media (min-width: 64em) {
			margin-bottom: $spacing-unit--large;
		}

		@include media-query(m) {
			display: block;
			margin-bottom: -$spacing-unit--huge;
		}

		@include media-query(m-down) {
			display: none;
		}
	}

	.search-list {
		@media (min-width: 80em) {
			.is-hidden & {
				display: none;
			}
		}
	}

	.search__icon--line {
		margin-top: 10px;
	}
}

.c-header__nav-wrapper {
	height: 4rem;
	@media not all and (min-width: 64em) {
		display: none;
	}
}

.c-shop-search-container,
.c-classic-search-container {
	align-items: center;
	position: relative;
}
