/* ------------------------------------*\
    # Points History #
\*------------------------------------ */

.c-account-points {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.c-account-points-entry-locked {
	color: $color-gray-dark;
}
