/* ------------------------------------*\
    #SLICK DOTS CLASSIC
\*------------------------------------ */

.slick-dots {
	.t-classic & {
		li:first-child {
			margin-left: 0;
		}

		li:last-child {
			margin-right: 0;
		}

		li button::before {
			height: $classic-teaser-button-size;
			width: $classic-teaser-button-size;
			border-radius: $classic-teaser-button-size;
			border: 1px solid $classic-teaser-button-color;
			background: transparent;
		}

		li.slick-active button::before {
			color: $classic-teaser-button-color;
			background: $classic-teaser-button-color;
		}
	}
}
