/* ------------------------------------*\
    #IMAGE TEASER
\*------------------------------------ */

$image-teaser-overlay-spacing: ($spacing-unit / 2);

$image-teaser-color: $color-white;
$image-teaser-color-hover: $color-gray-lighter;

$image-teaser-color--luxus: $color-classic-primary;
$image-teaser-color--luxus-hover: $color-classic-hover;

.c-image-teaser {
	position: relative;
	display: block;

	@include media-query(m-down) {
		margin-bottom: $spacing-unit;
	}

	.language-ar & {
		direction: rtl;
	}
}

.c-image-teaser__img {
	width: 100%;
}

.c-image-teaser__overlay {
	color: $image-teaser-color;
	position: absolute;
	bottom: -1px;
	width: 100%;
	background: var(--color-dimmer);
	padding: $image-teaser-overlay-spacing;

	.c-image-teaser:hover & {
		background: $color-black;
	}
}

.c-image-teaser__overlay--luxus {
	color: $image-teaser-color--luxus;

	&:hover {
		background: $color-black;
	}
}

.c-image-teaser__text {
	font-size: $heading-secondary-font-size-gamma !important;

	@include media-query(l-down) {
		font-size: $heading-secondary-font-size-delta !important;
	}
}

.c-image-teaser__icon {
	position: absolute;
	right: $image-teaser-overlay-spacing;
	font-size: 20px;
	top: 50%;
	transform: translateY(-50%);

	.language-ar & {
		display: none;
	}
}
