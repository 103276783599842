/* ------------------------------------*\
    #OFFER ITEM
\*------------------------------------ */

// Variables Start
$offer-saving-font-size: $heading-secondary-font-size-alpha;

$offer-description-height: 115px;

$offer-card-animation-time: 0.3s;
$offer-card-animation-add-time: 0.1s;

$offer-container-height: 464px;
$offer-fallback-height: 370px;

$offerheadline-height: 62px;
$offerheadline-mobile-height: 33px;
$offerheadline-font-size: $heading-secondary-font-size-gamma;

$offer-overlay-label-font-size: 20px;
$offer-overlay-label-font-size-m-down: 15px;
$offer-overlay-label-pl: 15px;
$offer-overlay-padding: 0 0 20px 40px;
$offer-overlay-padding-m: 0 0 20px 20px;

$offer-c-offer-item__content-container-height: 75%;
$offer-c-offer-item__saving-top: 21px;

// Variables End

.c-offer-item-container {
	position: relative;
	margin-bottom: 32px;
	background-color: #fff;
	border: none;
	color: #000;

	&::before {
		padding-top: 115%;
	}
}

//TODO RESERVES SPACE FOR .c-offer-item -->RENAME
.c-offer-slider__inner {
	.c-offer-item-container {
		height: $offer-fallback-height !important;
	}
}

// TODO FIX HEIGTH ON branddetailpagesaleitemcomponent.jsp

.c-offer-item {
	background: $color-white;
	position: relative;
	overflow: hidden;
	height: 100%;
	border: none;
	margin: 0;
	//padding: 14px;
	font-size: 13px;

	@include media-query("m-down") {
		margin-right: 0;
	}

	// Telling the user what happens on click by highlighting the Call to Action
	.no-touchevents &:hover {
		.c-offer-item__link--primary {
			color: $color-classic-primary;

			&.is-deactivated {
				color: $color-black-light;
			}
		}
	}

	// FRONT ELEMENTS
	&__image-container {
		display: block;
		cursor: pointer;

		&__image {
			width: 100%;
			height: auto;
		}
	}

	&__headline {
		@include ellipsis();

		font-size: 21px;
		line-height: 21px;
		font-weight: 500;
		height: 25px;

		.no-touchevents &:hover ~ .c-offer-item__link-container .c-offer-item__link--primary {
			color: $color-black-light;
		}

		&.c-offer-item__headline--arrowed {
			&::after {
				content: $icon-font--arrow-right;

				@include icon-font(14px);

				font-weight: $font-weight-bold;
			}
		}
	}

	&__saving {
		@include font-size(22px, none);

		// centering the content
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		position: absolute;
		top: -1 * $offer-c-offer-item__saving-top;
		height: 42px;
		right: 0;
		padding-left: 12px;
		padding-right: 12px;
		background-color: $color-black;
		color: $color-white;
	}

	&__link {
		display: inline-block;
		font-weight: $font-weight-bold;
		text-decoration: underline;

		.no-touchevents &:hover {
			color: $color-classic-primary;
		}

		&--secondary {
			.no-touchevents &:hover + .c-offer-item__link--primary {
				color: $color-black-light;
			}
		}
	}

	&__text {
		@include ellipsis();

		font-size: 13px;
		line-height: 13px;
		font-weight: 500;
	}

	&__description {
		height: $offer-description-height;

		@include media-query("m") {
			height: $offer-description-height - 10px;
		}

		padding-top: 70px;
		line-height: 15px;
		font-size: 13px;
		opacity: 0;
	}

	//TODO: Rename __fallback to  moreOffers
	&__fallback {
		text-align: center;
		overflow: visible;
		height: $offer-fallback-height !important;

		&__img {
			margin: 0 auto;
			max-width: none;
			height: 100%;
		}
	}

	&__overlay {
		color: $color-white;
		position: absolute;
		bottom: 0;
		text-align: left;
		padding: $offer-overlay-padding;

		@include media-query("m") {
			padding: $offer-overlay-padding-m;
		}
		//COMPONENT ELEMENTS
		&__label {
			font-weight: $font-weight-medium;
			font-size: $inuit-heading-size-2;
			overflow: hidden;
			white-space: nowrap;
			margin-bottom: 0;

			@include media-query("m") {
				font-size: $inuit-heading-size-3;
			}

			@include media-query("m-down") {
				font-size: $inuit-heading-size-4;
			}

			&::after {
				@include icon-font($offer-overlay-label-font-size);

				font-weight: $font-weight-bold;
				content: $icon-font--arrow-right-big;
				padding-left: $offer-overlay-label-pl;
				vertical-align: baseline;
				line-height: inherit;

				@include media-query("m-down") {
					@include icon-font($offer-overlay-label-font-size-m-down);
				}
			}

			//ELEMENT VARIANTS
			&--small {
				@include media-query("m-up") {
					font-size: $inuit-heading-size-4;

					&::after {
						@include icon-font($offer-overlay-label-font-size-m-down);
					}
				}
			}
		}

		&__text {
			font-size: $inuit-heading-size-5;
			font-weight: $font-weight-normal;
			line-height: $inuit-heading-size-5;
		}
	}

	&__content-container {
		background: $color-white;
		position: absolute;
		top: 72.8%;
		left: 0;
		width: 100%;
		height: $offer-c-offer-item__content-container-height;
		padding-top: 20px;

		@include ui-transition($offer-card-animation-time, ease-out);
	}

	&__link-container {
		position: absolute;
		bottom: 18px;
		width: 100%;
	}

	//COMPONENT STATES
	&.is-open {
		.c-offer-item__content-container {
			@include media-query("m-down") {
				top: 100% - ($offer-c-offer-item__content-container-height);
				height: $offer-c-offer-item__content-container-height;
			}

			@include media-query("m") {
				top: 100% - ($offer-c-offer-item__content-container-height);
				height: $offer-c-offer-item__content-container-height;
			}

			@include media-query("l") {
				top: 100% - ($offer-c-offer-item__content-container-height);
				height: $offer-c-offer-item__content-container-height;
			}

			@include media-query("xl-up") {
				top: 100% - ($offer-c-offer-item__content-container-height + 10%);
				height: $offer-c-offer-item__content-container-height + 10%;
			}

			@include ui-transition($offer-card-animation-time, ease-out);
		}

		.c-offer-item__description {
			padding-top: 10px;
			opacity: 100;
		}
	}

	//COMPONENT VARIANTS
	&--large {
		@include media-query(xl-up) {
			// definition von brand title / offer title
			.c-offer-item__headline {
				height: 60px;
				font-size: 40px;
				line-height: 40px;
			}

			// definitions for flags
			.c-offer-item__saving {
				height: 68px;
				font-size: 32px;
				padding-left: 36px;
				padding-right: 36px;
				top: -34px;
			}

			.c-offer-item__text,
			.c-offer-item__description,
			.c-offer-item__link-container {
				line-height: 18px;
				font-size: 20px;
				font-weight: 400;
			}
		}
	}
}

// definition for first card when less then 7 Cards + 1 Card more
//@media screen and (min-width: 1024px) {
@include media-query("l-up") {
	.homeOffers {
		.o-layout__item {
			float: left;
		}
	}
}

// Page: all offers
.offers-all {
	.o-layout__item {
		//TODO:
		@include media-query("m-down") {
			width: 100%;
		}

		@include media-query("m") {
			width: 50%;
		}

		@include media-query("l") {
			width: 33%;
		}

		@include media-query("xl-up") {
			width: 25%;
		}
	}
} //TODO: RENAME IN c-offer-items-list

.c-offer-all-links-deactivated {
	.c-offer-item__link-container .is-hidden-on-branddetail {
		display: none;
	}

	.c-offer-item__title {
		pointer-events: none;
	}
}
