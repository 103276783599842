/* ------------------------------------*\
    #BRANDS BANNER
\*------------------------------------ */

$brands-teaser-height: 60px;
$brands-teaser-height-small: 50px;
$brands-teaser-pr: 30px;

.c-brands-teaser {
	position: relative;
	height: $brands-teaser-height;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;

	@include ui-transition(0.8s, ease-out);

	&.is-visible {
		visibility: visible;
		opacity: 1;

		@include ui-transition(0.8s, ease-in);
	}

	@include media-query("m-down") {
		height: 2 * $brands-teaser-height-small;
		text-align: center;
	}
}

.c-brands-teaser--item {
	padding-right: $brands-teaser-pr;

	img {
		height: $brands-teaser-height;

		@include media-query("m-down") {
			height: $brands-teaser-height-small;
		}
	}

	&.is-not-visible {
		visibility: hidden;
		// opacity: 0.3;
	}
}

.c-brands-teaser--more {
	background: var(--color-white);
	position: absolute;
	right: 0;
	bottom: 0;
	height: $brands-teaser-height;
	line-height: $brands-teaser-height;
	padding-left: 20px;

	@include media-query("m-down") {
		height: $brands-teaser-height-small;
		line-height: $brands-teaser-height-small;
	}
}
