.toast-container {
	@include media-query(xxl-up) {
		transform: translateX(calc((100vw - 1408px) / -2));
	}
}

// Shared Toast
@keyframes toast-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.toast-enter-active {
	animation: toast-fade-in 360ms ease-out forwards;
}

.toast-leave-active {
	animation: toast-fade-in 360ms ease-in reverse forwards;
}
