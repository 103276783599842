/* ------------------------------------*\
    #css for legacy html (Content importet from old shop)
\*------------------------------------ */

.textilebadges {
	margin-top: 36px;
	margin-bottom: 10px;
}

.textilebadge {
	display: inline-block;
	margin-right: 5px;
}
