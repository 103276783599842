// themesinglecolumncomponent.jsp
.c-themeSingleColumn {
	margin-bottom: $lib-space-xl;
	padding-left: $lib-space-m;
	padding-right: $lib-space-m;

	@include media-query(m-up) {
		//desktop page-wrap
		max-width: 1408px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $lib-space-xxl;
	}

	.c-themeWorldItem {
		@include media-query(m-up) {
			display: flex;
		}

		&__teaserImage,
		&__content {
			@include media-query(m-up) {
				width: 50%;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: $lib-spacing-base-unit * 6 $lib-space-m;
		}
	}
}
