/* ------------------------------------*\
    #ALL BRANDS PAGE / BRAND BOTTOM
\*------------------------------------ */
$brandlist-bottom-item-width: 420px;

.c-brandlist-bottom__item {
	width: $brandlist-bottom-item-width;
}

.c-brandlist-bottom__text {
	font-size: $inuit-heading-size-6;
	margin-top: -$inuit-base-line-height/2;
}
