/* ------------------------------------*\
    #TOOLTIPS

    siehe auch _jquery.powertip.scss
\*------------------------------------ */

$tooltip-small-width: 250px !default;
$tooltip-medium-width: 320px !default;
$tooltip-large-width: 550px !default;
$tooltip-headline-border: 1px solid #000;
$tooltip-headline-fontsize: 13px !default;
$tooltip-headline-mb: 0.5em !default;
$tooltip-copy-fontsize: 12px !default;
$tooltip-close-size: 30px !default;
$tooltip-close-offset: 2px !default;

.c-tooltip {
	display: none;
}

.c-tooltip__inner {
	background: $color-white;
	padding: $spacing-unit;
	max-width: 100%;
	border: 1px solid $color-black;
	white-space: normal;
	text-indent: 0;

	@include media-query("m-down") {
		.mobile-fullsize & {
			width: 100%;
			max-width: 100%;
			border: none;
		}
	}
}

.c-tooltip__inner--small {
	max-width: $tooltip-small-width;
}

.c-tooltip__inner--medium {
	max-width: $tooltip-medium-width;
}

.c-tooltip__inner--large {
	max-width: $tooltip-large-width;
}

.c-tooltip__copy {
	font-size: $tooltip-copy-fontsize;
	margin-bottom: 0;

	.mobile-fullsize & {
		font-size: $u-fontsize;
	}
}

.c-tooltip__header {
	font-size: $tooltip-headline-fontsize;
	font-weight: 700;
	border-bottom: $tooltip-headline-border;
	margin-bottom: $tooltip-headline-mb;

	.mobile-fullsize & {
		font-size: 18px;
	}
}

.c-tooltip__close {
	position: absolute;
	right: $tooltip-close-offset;
	top: $tooltip-close-offset;
	width: $tooltip-close-size;
	height: $tooltip-close-size;
	text-align: center;
	line-height: $tooltip-close-size;

	.no-touchevents & {
		display: none;
	}

	@include media-query("m-down") {
		.mobile-fullsize & {
			position: absolute;
			width: 36px;
			height: 36px;
			padding: 7px;
			background-color: #000;
			border: medium none;
			border-radius: 50%;
			color: #fff;
			font-size: 13px;
			line-height: 26px;
			text-align: justify;
		}
	}
}
