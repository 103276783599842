/* ------------------------------------*\
    #SOCIAL-ICONS
\*------------------------------------ */

$social-icon-size: 40px;
$social-icon-font-size: 16px;
$social-icon-text-font-size: 12px;
$social-icon-text-mr: 6px;
$social-icon-color: $color-gray-dark;

.c-social-icon {
	display: block;
	font-size: $social-icon-font-size;
	line-height: $social-icon-size;
	color: $social-icon-color;
	cursor: pointer;
}

.c-social-icon__text {
	margin-bottom: 0;
	margin-right: $social-icon-text-mr;
	color: $social-icon-color;
	font-size: $social-icon-text-font-size;
}
