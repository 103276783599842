/* ------------------------------------*\
    #CLASSIC-TABLES
\*------------------------------------ */

.c-classic-table {
	width: 100%;
	margin-bottom: $spacing-unit--small;
	border-collapse: collapse;
	border-spacing: 0;
}

.c-classic-table--2col-small {
	width: auto;

	td {
		padding: 0 $spacing-unit / 2;
	}

	td:first-child {
		text-align: left;
		padding-left: 0;
	}

	td:last-child {
		text-align: right;
		padding-right: 0;
	}

	@include media-query(l-down) {
		margin-left: auto;
		margin-right: auto;
	}
}

.c-classic-table--half {
	td {
		width: 50%;

		&:last-child {
			text-align: right;
		}
	}
}
