/* ------------------------------------*\
    #TEASER ONLINE SHOP
\*------------------------------------ */

$os-teaser-height: 400px;
$os-teaser-mobile-height: 180px;

$os-teaser-background: url(http://dummyimage.com/1920x1080/999/FFF) center center;
$os-teaser-background-repeat: no-repeat;

$os-teaser-content-wrapper-background: rgba($color-white, 0.8);
$os-teaser-content-wrapper-padding: ($inuit-base-spacing-unit) * 2;

$os-teaser-headline-font-size: $heading-secondary-font-size-alpha !default;
$os-teaser-headline-font-weight: $font-weight-medium !default;
$os-teaser-headline-font-color: $color-brand-primary;

$os-teaser-text-heading-font-size: $inuit-heading-size-5 !default;
$os-teaser-text-mt: $inuit-base-spacing-unit;
$os-teaser-text-mb: ($inuit-base-spacing-unit) * 1.5;

$os-teaser-link-color: $color-black;

/* ------------------------------------*\
    #TEASER ONLINE SHOP {{ MOBILE }}
\*------------------------------------ */

$os-teaser-logo-size: 45px;
$os-teaser-logo-position: ($inuit-base-spacing-unit) / 2;

$os-teaser-mobile-headline-font-size: $heading-secondary-font-size-gamma !default;
$os-teaser-mobile-headline-font-color: $color-brand-primary;

$os-teaser-link-font-weight: $font-weight-medium;

/* ------------------------------------*\
    #TEASER ONLINE SHOP // BLACK THEME
\*------------------------------------ */

$os-teaser-content-wrapper-background--black: rgba($color-black, 0.8);
$os-teaser-text--black: $color-white;
$os-teaser-link-color--black: $color-white;

$os-teaser-mobile-headline-font-color--black: $color-brand-primary;
$os-teaser-link-color--black: $color-white;

.c-online-shop-teaser {
	background: $os-teaser-background;
	background-size: cover;
	background-repeat: $os-teaser-background-repeat;
	height: $os-teaser-height;
	width: 100%;
	position: relative;

	@include media-query(m-down) {
		height: $os-teaser-mobile-height;
	}
}

.c-online-shop-teaser__content-wrapper {
	background: $os-teaser-content-wrapper-background;
	height: 100%;
	padding: $os-teaser-content-wrapper-padding;

	@include media-query(l-down) {
		padding-top: $inuit-base-spacing-unit !important;
	}

	.c-online-shop-teaser--black & {
		background: $os-teaser-content-wrapper-background--black !important;
	}
}

.c-online-shop-teaser__headline {
	font-size: $os-teaser-headline-font-size;
	font-weight: $os-teaser-headline-font-weight;
	color: $os-teaser-headline-font-color;
}

.c-online-shop-teaser__text {
	font-size: $u-fontsize;
	margin-top: $os-teaser-text-mt;
	margin-bottom: $os-teaser-text-mb;

	h5 {
		font-size: $os-teaser-text-heading-font-size;
	}

	.c-online-shop-teaser--black & {
		color: $os-teaser-text--black !important;
	}
}

.c-online-shop-teaser__link {
	color: $os-teaser-link-color;

	.c-online-shop-teaser--black & {
		color: $os-teaser-link-color--black !important;
	}
}

.c-online-shop-teaser__mobile-wrapper {
	text-align: center !important;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.c-online-shop-teaser__mobile-headline {
	font-size: $os-teaser-mobile-headline-font-size;
	color: $os-teaser-mobile-headline-font-color;
	font-weight: $os-teaser-headline-font-weight;

	.c-online-shop-teaser--black & {
		color: $os-teaser-mobile-headline-font-color--black !important;
	}
}

.c-online-shop-teaser__mobile-link {
	color: $os-teaser-link-color;
	font-weight: $os-teaser-link-font-weight;

	.c-online-shop-teaser--black & {
		color: $os-teaser-link-color--black !important;
	}
}

.c-online-shop-teaser__logo {
	height: $os-teaser-logo-size;
	width: $os-teaser-logo-size;
	position: absolute;
	top: $os-teaser-logo-position;
	left: $os-teaser-logo-position;
}
