///*------------------------------------*\
//    #FIX-WHITE SPACE AFTER FOOTER
//\*------------------------------------*/
body > img {
	position: absolute;
	left: -9999px;
	height: 1px;
	width: 1px;
}

body > iframe {
	position: absolute;
	left: -9999px;
	height: 1px;
	width: 1px;
}
