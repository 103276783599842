/* ------------------------------------*\
    #CL-FILTER-BAR
    - Filter Headlines
    - Selected Filter
\*------------------------------------ */

$arrow-font-size: 16px;
$arrow-font-size-small: 10px;
$arrow-margin-top: 24px;

$filter-height: 40px;
$filter-height-m: 50px;
$filter-line-height: $filter-height;
$filter-line-height-m: $filter-height-m;

$filter-headline-icon-pl: 10px;
$filter-headline-icon-fonts-size: 10px;

$filter-headline-icon-width-m: 30px;

.c-cl-filter-bar {
	position: relative;
	background: $color-white;
	z-index: z("classic-filter-bar");
}

.c-cl-filter-bar__list {
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;

	@include media-query(m-down) {
		position: absolute;
		background: $color-white;
		width: 100%;
		border: 1px solid $color-gray-darker;
		display: none;
		overflow: scroll;
		overflow-x: hidden;
		max-height: 73vh;
		transition: max-height 0.5s;

		.scroll-to-fixed-fixed & {
			max-height: 70vh;
		}

		&.is-open {
			display: block;
			margin-top: -1px;
		}
	}
}

.c-cl-filter-bar__item {
	position: relative;
	margin-right: $spacing-unit--small;
	display: inline-block;

	a {
		color: $color-black;
	}

	&:hover::after {
		content: $icon-font--arrow-down;

		@include icon-font();

		position: absolute;
		top: 100%;
		left: $spacing-unit;
		font-size: $arrow-font-size;
		margin-top: -3px;
		color: $color-classic-primary;
		background: $color-white;

		@include media-query(m-down) {
			display: none;
		}
	}

	// responsive step

	@include media-query(m-down) {
		display: block;
		width: 100%;
		border-bottom: 1px solid $color-gray-darker;
		padding: 0;

		&:last-child {
			border-bottom: none;
		}
	}

	&.c-cl-filter--category {
		@include media-query(m-up) {
			&:hover {
				.c-cl-filter__box {
					display: block;
				}
			}
		}
	}

	@include media-query(m-up) {
		&:hover {
			.c-cl-filter__box {
				display: block;
			}
		}
	}
}

.c-cl-sort-bar__item {
	position: inherit;
	display: inline-block;
	float: right;

	a {
		color: $color-black;
	}

	.c-filter__box-list-item {
		//todo: diese Klasse gehört hier doch nicht her
		a.is-selected {
			font-weight: $font-weight-bold;
		}
	}

	@include media-query(m-up) {
		position: relative;

		&:hover {
			.c-cl-filter__box {
				display: block;
			}
		}
	}

	&:hover::after {
		content: $icon-font--arrow-down;

		@include icon-font();

		position: absolute;
		top: 100%;
		left: $spacing-unit;
		font-size: $arrow-font-size;
		margin-top: -3px;
		color: $color-classic-primary;
		background: $color-white;

		@include media-query(m-down) {
			display: none;
		}
	}

	// responsive step

	@include media-query(m-down) {
		display: block;
		width: 100%;
		border-bottom: 1px solid $color-gray-darker;
		padding: 0;

		&:last-child {
			border-bottom: none;
		}
	}
}

.c-cl-filter-bar__item:hover,
.c-cl-sort-bar__item:hover {
	@include media-query(m-up) {
		.c-cl-filter-bar__link::after {
			transform: rotate(180deg);
			transition: transform 0.4s ease-in-out;
		}
	}
}

.c-cl-filter-bar__link {
	display: block;
	position: relative;
	height: $filter-height;

	@include font-size($u-fontsize, $filter-line-height);

	&::after {
		content: $icon-font--arrow-down;
		padding-left: $filter-headline-icon-pl;

		@include icon-font($filter-headline-icon-fonts-size);

		font-weight: $font-weight-bold;
		float: right;
		width: $filter-headline-icon-width-m;
		line-height: $filter-height;

		@include media-query(m-up) {
			transform: rotate(0deg);
			transition: transform 0.4s ease-in-out;
		}
	}

	@include media-query(m-down) {
		padding-left: $filter-headline-icon-pl;
		height: $filter-line-height-m;

		&.is-open {
			&::after {
				transform: rotate(180deg);
				transition: transform 0.4s ease-in-out;
			}
		}
	}

	&--close {
		&::after {
			content: "";
		}

		&::before {
			content: $icon-font--arrow-up;

			@include icon-font();

			font-size: $arrow-font-size-small;
			margin-right: $spacing-unit--small;
		}
	}
}

.c-cl-filter-bar__filter-mobile {
	border: 1px solid $color-gray-darker;
	height: $filter-height;

	&.is-open {
		height: $filter-height + $spacing-unit--small;
		border-bottom: none;
		color: $color-gray-dark;
		background: $color-white;
		z-index: 50;

		&::after {
			transform: rotate(180deg);
			transition: 0.4s ease-in-out;
		}
	}

	&.is-active {
		background: $color-gray-lighter;
	}
}

.c-cl-filter-bar__sort-mobile {
	@include media-query(m-up) {
		display: none;
	}

	border: 1px solid $color-gray-darker;
	height: $filter-height;

	&.is-open {
		height: $filter-height + $spacing-unit--small;
		display: block;
		border-bottom: none;
		color: $color-gray-dark;
		background: $color-white;
		z-index: 50;

		&::after {
			transform: rotate(180deg);
			transition: 0.4s ease-in-out;
		}
	}
}

.c-cl-filter-bar__reset {
	display: inline-block;
	padding: $spacing-unit 0;
	text-align: right;

	a {
		color: $color-gray-dark;
	}
}

.c-cl-filter-bar__wrapper {
	@include media-query(m-up) {
		border-bottom: 1px solid $color-gray-light;
	}

	@include media-query(m-down) {
		background: $color-white;
		padding: ($spacing-unit / 2) 0;
		border-bottom: 1px solid $color-gray-dark;
	}
}

.c-cl-filter-bar__selected {
	background: $color-white;
	padding-top: ($spacing-unit / 2);
	padding-bottom: ($spacing-unit / 4.5);

	.scroll-to-fixed-fixed & {
		border-bottom: 1px solid $color-gray-dark;
	}

	@include media-query(m-down) {
		padding-top: 0;
		border-bottom: none;
	}
}

.c-cl-filter-bar__selected-item {
	display: inline-block;
	padding: 6px $spacing-unit--small;
	border: 1px solid $color-gray-dark;
	font-size: $u-fontsize;
	font-weight: normal;
	margin-right: $spacing-unit--small;
	margin-bottom: $spacing-unit--tiny;
	cursor: pointer;

	&::after {
		@include icon-font();

		content: $icon-font--close;
		font-size: 10px;
		padding-left: $spacing-unit--small;
	}

	i {
		color: $color-classic-primary;
		font-size: $inuit-base-font-size;
		margin-right: $spacing-unit--tiny;
	}

	@include media-query(m-down) {
		display: block;
		width: 100%;
		padding: $spacing-unit;
		border: none;
		border-bottom: 1px solid $color-black !important;
		font-weight: $font-weight-medium;
		margin-right: 0;
		margin-bottom: 0;
		position: relative;
		background: $color-gray-lighter;

		&::after {
			position: absolute;
			right: $spacing-unit;
			top: 50%;
			font-size: $inuit-base-font-size;
			transform: translateY(-50%);
			cursor: pointer;
		}

		&:hover {
			&::after {
				color: $color-black;
			}
		}
	}
}

.c-cl-filter-bar__selected-title {
	font-weight: normal;
	font-size: $u-fontsize;
}

.c-cl-filter-bar__clear-filter {
	float: right;
	padding: $spacing-unit--small;
	font-weight: $font-weight-medium;
	font-size: $u-fontsize;

	@include media-query(m-down) {
		float: none;
	}
}
