/* ------------------------------------*\
    #TEXT IMAGE TEASER  // CLASSIC
\*------------------------------------ */

$text-img-teaser-info-min-width: 300px;

.c-text-img-teaser-classic {
	display: table;
	width: 100%;
	min-height: 350px;

	@include media-query(l-down) {
		display: block;
	}
}

.c-text-img-teaser-classic__text-box {
	padding-left: $spacing-unit;
	display: table-cell;
	position: relative;

	@include media-query(l-down) {
		padding-left: 0;
		display: inline-block;
	}

	ul {
		margin-left: $spacing-unit;

		.language-ar & {
			margin-left: 0;
			margin-right: $spacing-unit;
		}
	}

	.language-ar & {
		direction: rtl;
	}
}

.c-text-img-teaser-classic__img {
	display: table-cell;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@include media-query(l-down) {
		display: inline-block;
		min-height: 250px;
	}
}

.c-text-img-teaser-classic__bottom-text {
	display: table;
	position: absolute;
	bottom: 0;
	padding-right: ($spacing-unit * 2);

	@include media-query(l-down) {
		position: relative;
	}
}

.c-text-img-teaser-classic__info {
	display: block;
	width: 100%;

	@include media-query(l-down) {
		text-align: center;
		display: inline-block;
		width: 100%;
		padding-top: $spacing-unit;
	}
}

.c-text-img-teaser-classic__contact {
	padding-bottom: ($spacing-unit / 2);

	.language-ar & {
		direction: ltr;

		@include media-query(l-up) {
			text-align: right;
		}
	}
}

.c-text-img-teaser-classic__hint {
	font-size: 10px;
	width: 100%;
	display: block;
	color: $color-gray-dark;
	margin-bottom: $spacing-unit;

	@include media-query(l-down) {
		display: inline-block;
	}
}
