/* ------------------------------------*\
    #OVERLAY
\*------------------------------------ */

.o-overlay {
	position: absolute;
	z-index: z("overlay");
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-dimmer);
}

.o-overlay-text {
	position: absolute;
	z-index: z("overlay-text");
	color: $color-white;

	@include font-size($inuit-heading-size-4, 1);

	top: 50%;
	width: 100%;
	display: block;

	@include media-query(m-down) {
		font-size: $inuit-heading-size-6;
	}
}
