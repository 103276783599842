/* ------------------------------------*\
    #General Layer styles
\*------------------------------------ */

$layer-width-big: 720px;
$layer-width-medium: 460px;

.c-layer-default {
	background-color: transparent;

	@include media-query(m-down) {
		max-width: 100%;
		width: 100%;
		padding-bottom: $spacing-unit--small;
	}

	// Sizes

	&--medium {
		width: $layer-width-medium;

		@include media-query(m-down) {
			max-width: 100%;
			width: 100%;
			padding-bottom: $spacing-unit--small;
		}
	}

	&--big {
		width: $layer-width-big;

		@include media-query(m-down) {
			max-width: 100%;
			width: 100%;
			padding-bottom: $spacing-unit--small;
		}
	}

	// Background Colors

	&--white {
		background: $color-white;
	}

	&--grey {
		background: $color-gray-lighter;
	}
}
