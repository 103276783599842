/* ------------------------------------*\
    #BADGE
\*------------------------------------ */

$circle-width: 150px;
$circle-number-width: 100px;
$circle-number-width-mobile: 80px;
$circle-comma-number-width: 105px;
$circle-comma-number-width-mobile: 75px;
$circle-info-width: 180px;
$circle-info-width-mobile: 160px;

.c-circle-progress {
	display: table;
	width: 100%;
}

.c-circle-progress__item {
	width: $circle-width;
	display: table-cell;
	position: relative;
	text-align: center;

	@include media-query(l-down) {
		display: inline-block;
		width: 50%;
		margin-bottom: ($spacing-unit * 3);
	}
}

.c-circle-progress__number,
.c-circle-progress__comma-number {
	position: absolute;
	top: 50%;
	left: 50%;
	overflow: hidden;
	transform: translate(-50%, -50%);
	font-size: 60px;
	text-align: center;

	@include media-query(l-down) {
		width: $circle-comma-number-width-mobile;
		font-size: 42px;
	}
}

.c-circle-progress__number {
	width: $circle-number-width;

	@include media-query(l-down) {
		width: $circle-number-width-mobile;
	}
}

.c-circle-progress__comma-number {
	width: $circle-comma-number-width;

	@include media-query(l-down) {
		width: $circle-comma-number-width-mobile;
	}
}

.c-circle-progress__info {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	width: $circle-info-width;

	@include media-query(l-down) {
		width: $circle-info-width-mobile;
	}
}
