.c-awardBar {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: $lib-space-xl;
	padding: 0 $lib-space-m;

	@include media-query(m-up) {
		margin-bottom: $lib-space-xxl;
		//desktop page-wrap
		max-width: 1408px;
		margin-left: auto;
		margin-right: auto;
	}

	.c-awardBar-header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: $lib-space-m;
	}

	.c-awardBar-headline {
		@extend .ocm-headline2;

		margin-bottom: 0;
	}

	.c-awardBar-link {
		@extend .ocm-button__outline-black;
		@extend .ocm-button--smallOnMobile;

		span {
			display: none;

			@include media-query(m-up) {
				display: inline;
			}
		}
	}

	.c-awardBar-list {
		display: grid;
		gap: $lib-space-s;
		grid-template-columns: minmax(160px, 1fr);
		grid-template-rows: 1fr;
		place-self: center;
		width: 100%;

		@media screen and (min-width: 360px) {
			grid-template-columns: repeat(2, minmax(160px, 1fr));
		}

		@include media-query(m-up) {
			grid-template-columns: repeat(2, minmax(230px, 1fr));
			gap: $lib-space-xxl;
		}

		@media screen and (min-width: 850px) {
			grid-template-columns: repeat(3, minmax(230px, 1fr));
		}

		@media screen and (min-width: 1143px) {
			grid-template-columns: repeat(4, minmax(230px, 1fr));
		}
	}

	.c-awardBar-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: var(--color-gray-10);
		padding: $lib-space-m;
	}

	.c-awardBar-icon {
		height: 75px;
		margin-bottom: $lib-space-s;
	}

	.c-awardBar-text {
		font-size: 0.85rem;
		text-align: center;
		font-weight: $font-weight-normal;
	}
}
