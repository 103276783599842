/* ------------------------------------*\
    #UPSELLING BANNER
\*------------------------------------ */

$upselling-link-wrapper-mt: 20px;

.c-upselling {
	// center products if slick slider is set to false in config
	text-align: center;

	&.c-upselling--list {
		text-align: left;
	}

	// center products if there are not enough
	.slick-track {
		margin-left: auto;
		margin-right: auto;
	}
}

.c-upselling__nav-thumb {
	font-weight: $headline-font-weight;

	@include font-size($headline-font-size);

	text-align: center;
	cursor: pointer;
	color: $color-gray-dark;

	span {
		padding-bottom: 4px;
	}

	&.slick-current {
		color: $color-black;

		span {
			border-bottom: 1px solid $color-black;
		}
	}
}

.c-upselling__link-wrapper {
	@include aspect-ratio(129, 173);

	margin-top: $upselling-link-wrapper-mt;
	padding: 0 $spacing-unit--small;

	@include media-query(m-up) {
		border-left: 1px solid $color-black;
	}
}

.c-upselling__link-inner {
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: $spacing-unit;
}
