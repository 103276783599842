/* ------------------------------------*\
    #POPUP
\*------------------------------------ */
$mini-cart-layer-width: 720px;
$close-icon-radius: 50%;

.c-popup-hidden {
	display: none !important;
}

.c-popup-background {
	transition: all 0.4s ease-in;
}

.c-popup-layer {
	height: auto;
}

.c-popup {
	padding: $spacing-unit;
	max-width: $mini-cart-layer-width;
	background-color: $color-white;

	&--dark {
		background-color: transparent;
		color: #fff;
	}

	// ehemals '.c-layer', wird aber nur in commonPopupLayer.tag verwendet
	&--reset {
		background-color: transparent !important;
		padding: 0 !important;
		max-width: none;

		&.hide-scrollbars {
			overflow: hidden; // to prevent scrollbars when animating the login layer
		}
	}

	@include media-query(m-down) {
		width: 100%;
		padding: $spacing-unit;
	}
}

.c-popup-close {
	position: absolute;
	top: -$spacing-unit;
	right: -$spacing-unit;
	padding: $spacing-unit--small;
	font-size: $inuit-base-font-size;
	border-radius: $close-icon-radius;
	color: $color-white;
	background-color: $color-black;
	border: none;

	@include media-query(m-down) {
		top: $spacing-unit--small;
		right: $spacing-unit--small;
	}
}

/* Animationen ---------- */

.animated {
	animation-duration: 0.4s;
	animation-fill-mode: both;
}

@keyframes slideInLeft {
	from {
		transform: translate(-50%, 0);
		opacity: 0;
	}

	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}

.slideInLeft {
	animation-name: slideInLeft;
}

@keyframes slideInRight {
	from {
		transform: translate(50%, 0);
		opacity: 0;
	}

	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}

.slideInRight {
	animation-name: slideInRight;
}

@keyframes slideInUp {
	from {
		transform: translate(0, 100%) scale(0.2);
		opacity: 0;
	}

	to {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

.slideInUp {
	animation-name: slideInUp;
}

@keyframes slideInUpNoScale {
	from {
		transform: translate(0, 100%);
	}

	to {
		transform: translate(0, 0);
	}
}

@keyframes slideOutDownNoScale {
	from {
		transform: translate(0, 0);
	}

	to {
		transform: translate(0, 100%);
	}
}

.slideInUpNoScale {
	animation-name: slideInUpNoScale;
}

.slideOutDownNoScale {
	animation-name: slideOutDownNoScale;
}
