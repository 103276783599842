/* ------------------------------------*\
    #HEADER Logo on Maps Page
\*------------------------------------ */

.c-header-logo--maps {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background: white;
	width: 200px;
	padding: 9px;
	z-index: 99;
}
