// themedoublecolumncomponent.jsp
.c-themeDoubleColumn {
	margin-bottom: $lib-space-xl;
	padding-left: $lib-space-m;
	padding-right: $lib-space-m;

	@include media-query(m-up) {
		margin-bottom: $lib-space-xxl;
		//desktop page-wrap
		max-width: 1408px;
		margin-left: auto;
		margin-right: auto;
		padding-left: $lib-space-m;
		padding-right: $lib-space-m;
	}

	&__items {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: $lib-space-m;
		grid-row-gap: $lib-space-m;

		@include media-query(m-up) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.c-themeWorldItem {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: max-content auto;

		&__content {
			padding: $lib-spacing-base-unit * 6 $lib-space-m;
			display: flex;
			flex-direction: column;
		}

		&__copy {
			flex-grow: 1;
		}
	}
}
