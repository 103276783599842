/* ------------------------------------*\
    #NEWSLETTER
\*------------------------------------ */

.c-cl-newsletter {
	iframe {
		border: none;
		width: 100%;
		height: 500px;
		overflow: hidden;
	}
}

.c-cl-newsletter__infobox {
	border-right: 1px solid $color-classic-primary;
	border-bottom: 1px solid $color-classic-primary;
	border-left: 1px solid $color-classic-primary;
	text-align: center;
}

.c-cl-newsletter__infoheadline {
	position: relative;
	top: -$spacing-unit;

	&--default {
		top: 0;
	}

	span {
		&::after,
		&::before {
			border-color: $color-classic-primary !important;
		}
	}
}

.c-cl-newsletter__item-wrapper {
	display: table;
	width: 100%;
}

.c-cl-newsletter__item {
	display: table-cell;

	&--block {
		display: block;
	}
}

.c-cl-newsletter__icon {
	display: inline-block;
	font-size: $heading-classic-secondary-font-size-gamma;
	border: 1px solid $color-classic-primary;
	padding: $spacing-unit;
	border-radius: 50%;
	margin-bottom: $spacing-unit--small;

	&:last-child {
		margin-right: 0;
	}

	@include media-query(m) {
		font-size: $heading-classic-secondary-font-size-delta;
	}

	@include media-query(m-down) {
		font-size: $heading-secondary-font-size-delta;
	}
}

.c-cl-newsletter__iframe {
	border: none;
	width: 100%;
	height: 680px;
	overflow: hidden;
}

.c-cl-newsletter__col {
	display: inline-block;
}
