/* ------------------------------------*\
    #hotel ITEM
\*------------------------------------ */

$hotel-icon-height: 24px;

.c-hotel-ul {
	list-style: none;
}

.c-hotel-ul--list {
	list-style: disc;
}

.c-hotel-ul__entry {
	margin-bottom: $spacing-unit--small;
}

.c-hotel-ul__icon {
	width: $hotel-icon-height;
	vertical-align: middle;
	position: relative;
	top: -1px;
}
