// todo: war in shared ?

/* ------------------------------------*\
    #PRODUCT ITEM
\*------------------------------------ */
$product-item-body-min-height: 110px;
$product-item-body-min-height-m: 74px;
$product-size-list-height: 20px;
$tablet-image: 900px;
$noproduct-icon-size: 90px;
$noproduct-icon-size-m-down: 70px;

.c-product-list {
	@include media-query(m-down) {
		width: 50%;
	}

	@include media-query(m) {
		width: 33.33%;
	}

	@include media-query(l-up) {
		width: 25%;
	}
}

.c-product-list__container {
	@include media-query(m-up) {
		margin-top: 80px;
		padding-top: $spacing-unit;
	}
}

.c-product-item {
	position: relative;
	text-align: center;

	.c-product-item__image,
	.c-product-item__body {
		max-width: 100%;

		.c-upselling & {
			width: 100%;
		}
	}

	.c-product-item__body,
	.c-product-item__body--hover {
		background-color: $color-white;
	}

	.c-product-item__image--hover,
	.c-product-item__body--hover {
		position: absolute;
		visibility: hidden;
		z-index: -999999;
		max-width: 100%;
		opacity: 0;
		left: 0;
	}

	.c-product-item__image--hover {
		top: 0;
	}

	.no-touchevents &.has-hover:hover {
		.c-product-item__image {
			position: absolute;
			left: 0;
			top: 0;
		}

		.c-product-item__body {
			position: absolute;
			opacity: 0;
		}

		.c-product-item__image--hover,
		.c-product-item__body--hover {
			visibility: visible;
			position: relative;
			opacity: 1;
			z-index: 2;
			left: 0;
		}

		.c-product-item__body--hover {
			z-index: z("marker-container") + 1;
		}

		.c-product-item__image--hover {
			top: 0;
			-o-transition: opacity 0.25s ease-in-out;
			transition: opacity 0.25s ease-in-out;
		}
	}

	@media screen and (max-height: $tablet-image) {
		max-width: 85%;
		margin-right: auto;
		margin-left: auto;
	}

	.c-product-item__body {
		@include media-query(l-down) {
			position: relative;
			z-index: z("marker-container") + 1;
		}
	}
}

.c-product-item__link {
	@include media-query(m-up) {
		@include aspect-ratio(530, 795);
	}

	@include media-query(m-down) {
		@include aspect-ratio(360, 540);
	}
}

.touchevents .c-product-item__body {
	position: relative;
	z-index: z("marker-container") + 1;
}

.c-product-item__image,
.c-product-item__image--hover {
	height: auto; //scalling product image on IE9/IE10
}

.c-product-item__sub-title {
	margin-bottom: 0;
	font-weight: 700;
	font-size: $inuit-heading-size-6;
}

.c-product-item__text {
	margin-bottom: $spacing-unit--tiny;
}

.c-product-item__price {
	margin-bottom: $spacing-unit--tiny;
	font-weight: bold;
}

.c-product-item__body,
.c-product-item__body--hover {
	min-height: $product-item-body-min-height;

	.c-upselling & {
		min-height: 60px;
	}

	@include media-query(m-down) {
		min-height: $product-item-body-min-height-m;
	}
}

.c-product-item__body--hover {
	@include media-query(l-down) {
		display: none;
	}
}

.c-product-item__body--hover .c-product-item__sizelist {
	display: inline-block;
	white-space: nowrap;

	li {
		padding-left: $spacing-unit--tiny;
		padding-right: $spacing-unit--tiny;

		a {
			display: block !important;
			font-size: $inuit-heading-size-6;
			font-weight: 400;
		}
	}
}

.c-product-item-size__text {
	font-size: $inuit-heading-size-6;
	font-weight: normal;
}

.c-product-item__marker-container {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: z("marker-container");
}

.c-product-item__ip-marker {
	left: 0;
	top: 0;
	width: 100%;
	max-height: 100%;
	overflow: hidden;
}
