/* ------------------------------------*\
    #BENEFIT-ITEM
\*------------------------------------ */

$benefit-bar-item-img-size: 35px;

.c-benefit-bar {
	padding-bottom: $inuit-base-line-height;
}

.c-benefit-item {
	@include media-query(m-down) {
		text-align: center;
	}
}

.c-benefit-item__img {
	display: inline-block;
	width: $benefit-bar-item-img-size;
	margin-right: $spacing-unit--tiny;

	.c-login-layer & {
		margin-right: 0;
	}
}

.c-benefit-item__text {
	display: inline-block;
	vertical-align: middle;
	font-size: $u-fontsize;

	@include media-query(m-down) {
		display: none;
	}
}

/* ------------------------------------*\
    #BENEFIT-BAR CMS Component
\*------------------------------------ */
.c-benefitBar {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
	gap: $lib-space-l;
	margin-bottom: $lib-space-xl;
	padding-left: $lib-space-m;
	padding-right: $lib-space-m;
	@include media-query(m-up) {
		margin-bottom: $lib-space-xxl;
		//desktop page-wrap
		max-width: 1408px;
		margin-left: auto;
		margin-right: auto;
	}

	.c-benefitBar-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.c-benefitBar-icon {
		font-size: $u-fontsize-xl;
		margin-bottom: $lib-space-s;
	}

	.c-benefitBar-text {
		font-size: $u-fontsize-xs;
		text-align: center;
	}
}
