/* ------------------------------------*\
    #HEADER
    Complete Header Container
\*------------------------------------ */

.c-header {
	background-color: white;
	height: 7.142857142857143rem;
	left: 0;
	position: sticky;
	width: 100%;
	top: -7.142857142857143rem;
	transition: left 0.2s;
	z-index: 500;
	@media (min-width: 64em) {
		border-bottom: 2px solid var(--color-gray-40);
		top: -6.285714285714286rem;

		.is-landingpage &,
		.is-checkout &,
		.page-ContactPage &,
		.template-pages-SignInPageTemplate & {
			// landing- and checkout-pages use a distinct header and set identifying classes on the root element
			// the shop sign-in page uses the normal header, so the CMS template class on the body is used to
			// distiguish the login page from other shop pages
			// isSticky logic should coincide with /global/src/js/shared/10-components/stickyHeader.js
			position: static;
		}

		.is-checkout & {
			border-bottom: none;
		}
	}
}

.c-classic-search-container {
	align-items: center;
	display: flex;
	float: right;
	height: 100%;
	margin-right: 8.571428571428571rem;

	@media (min-width: 88em) {
		margin-right: 9.428571428571429rem;
	}

	// classic login page doesn't have menu items on the right (unfixed bug), so the search bar is moved to the right
	.page-signInPage & {
		margin-right: -0.785714285714286rem;

		@media (min-width: 80em) {
			margin-right: 0;
		}
	}

	.is-header-sticky & {
		margin-right: 11.714285714285714rem;

		@media (min-width: 80em) {
			margin-right: 12.571428571428571rem;
		}
	}
}

.c-shop-search-container {
	display: flex;
	float: right;
	height: 100%;

	.is-header-sticky & {
		margin-right: 9.428571428571429rem;

		@media (min-width: 88em) {
			margin-right: 10.285714285714286rem;
		}
	}

	.is-coop-user.is-header-sticky & {
		margin-right: 2.2857142rem;

		@media (min-width: 80em) {
			margin-right: 3.1428571rem;
		}
	}
}

.c-sitelinks-fade-helper {
	background: linear-gradient(180deg, white, white 85%, rgba(255, 255, 255, 0) 100%);
	height: 4.6rem;
	left: 0;
	pointer-events: none;
	position: sticky;
	top: 0;
	width: 100%;
	transform: translateY(0.7rem);
	z-index: 1;

	.is-header-sticky & {
		visibility: hidden;
	}

	@media (min-width: 64em) {
		display: none;
	}
}

$simple-header-height: 5.57142857rem;

.c-simple-header-background {
	height: $simple-header-height;
	border-bottom: 2px solid var(--color-gray-40);
	background-color: white;
}

.c-simple-header {
	top: -2.2rem;
	height: $simple-header-height;
}
