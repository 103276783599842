// /*------------------------------------*\
//     $STICKY-POSITIONING
// \*------------------------------------*/

@mixin sticky-positioning($top, $transPos) {
	position: fixed;
	top: -$top;
	perspective: 1000px;
	transform: translate(0, $transPos);
	transition: transform 0.5s ease 0s;
}
