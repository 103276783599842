/* ------------------------------------*\
    #HEADER LOGO
\*------------------------------------ */

// Logo
// sizes are defined in the global css file
$header-logo-l-height: $gl_header-logo-l-height;

$header-logo-m-width: $gl_header-logo-m-width;
$header-logo-m-height: $gl_header-logo-m-height;

$header-fixed-height: $gl_header-fixed-height;
$header-logo-mobile-fixed-size: $gl_header-fixed-height;

//-----------------------------------
// Logo

.c-logo {
	align-items: flex-start;
	display: flex;
	left: 0;
	justify-content: center;
	position: absolute;
	top: 0;
	width: 100%;

	.is-header-sticky & {
		@media (min-width: 64em) {
			display: none;
		}
	}
}
.is-logo-collapsed .c-logo__o {
	transform: translateX(71.5px);
}
.c-logo__o,
.c-logo__c {
	transition: transform 400ms ease;
	transition-delay: 0s;

	.is-logo-collapsed & {
		transition-delay: 100ms;
		transition-duration: 300ms;
	}
}
.is-logo-collapsed .c-logo__c {
	transform: translateX(-24px);
}
.c-logo__fadegroup {
	transition: opacity 300ms ease;
	transition-delay: 100ms;

	.is-logo-collapsed & {
		transition-delay: 0s;
		transition-duration: 400ms;
		opacity: 0;
	}
}
.is-logo-collapsed .c-logo__metzingen {
	display: none;
}
.c-logo__container {
	padding-top: 1.142857142857143rem;
	left: 50%;
	pointer-events: none;
	position: fixed;
	top: 0;
	transform: translate(-50%, -0.357142857142857rem);
	z-index: 501;

	.is-left-offcanvas &,
	.is-right-offcanvas & {
		@media not all and (min-width: 80em) {
			z-index: 500;
		}
	}

	@media (min-width: 64em) {
		position: static;
		transform: translateY(-0.428571428571429rem);
	}
}
.c-logo__svg {
	height: auto;
	pointer-events: none;
	transition: transform 0.3s ease;
	width: 12.857142857142857rem;

	@media (min-width: 64em) {
		width: 16.428571428571429rem;
	}

	.is-logo-collapsed & {
		@media not all and (min-width: 64em) {
			transform: translateY(-0.285714285714286rem);
		}
	}
}

.c-logo__link {
	display: inline-block;
	height: 3.714285714285714rem;
	left: 50%;
	width: 14rem;
	position: fixed;
	text-indent: -9999px;
	top: 0.571428571428571rem;
	transform: translateX(-50%);
	z-index: 501;

	.is-left-offcanvas &,
	.is-right-offcanvas & {
		@media not all and (min-width: 80em) {
			z-index: 500;
		}
	}

	@media (min-width: 64em) {
		height: 4.428571428571429rem;
		position: absolute;
		width: 17.571428571428571rem;
	}

	.is-logo-collapsed & {
		height: 3.142857142857143rem;
		top: 0;
		width: 3.142857142857143rem;
	}
}

.c-logo__background {
	background-color: white;
	height: 3.285714285714286rem;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 490;

	@media (min-width: 64em) {
		display: none;
	}

	.is-logo-collapsed & {
		border-bottom: 2px solid var(--color-gray-40);
	}
}
