/* ------------------------------------*\
    #TEXT-INPUT
\*------------------------------------ */

/**
 * Unify text-like inputs.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-text-input-namespace: $inuit-namespace !default;

$inuit-text-input-height: double($inuit-base-spacing-unit) !default;
$inuit-text-input-height--small: halve($inuit-text-input-height) !default;
$inuit-text-input-height--large: double($inuit-text-input-height) !default;

$inuit-text-input-padding: halve($inuit-base-spacing-unit) !default;
$inuit-text-input-padding--small: halve($inuit-text-input-padding) !default;
$inuit-text-input-padding--large: double($inuit-text-input-padding) !default;

$inuit-text-input-background: var(--color-white) !default;
$inuit-text-input-text-color: $inuit-base-text-color !default;
$inuit-text-input-border-width: 1px !default;
$inuit-text-input-border-color: var(--color-gray-50) !default;
$inuit-text-input-border-style: solid !default;
$inuit-text-input-border-radius: 0 !default;

$inuit-enable-text-input--small: false !default;
$inuit-enable-text-input--large: false !default;
$inuit-enable-textarea: false !default;

.#{$inuit-text-input-namespace}o-text-input {
	height: $inuit-text-input-height;
	padding-left: $inuit-text-input-padding;
	padding-right: $inuit-text-input-padding;
	line-height: $inuit-text-input-height;
	background-color: $inuit-text-input-background;
	color: $inuit-text-input-text-color;

	@if ($inuit-text-input-border-width != 0) {
		border: $inuit-text-input-border-width $inuit-text-input-border-style $inuit-text-input-border-color;
	} @else {
		border: 0;
	}

	@if ($inuit-text-input-border-radius != 0) {
		border-radius: $inuit-text-input-border-radius;
	}
}

@if ($inuit-enable-text-input--small == true) {
	/**
     * Compressed text inputs.
     */
	.#{$inuit-text-input-namespace}o-text-input--small {
		height: $inuit-text-input-height--small;
		padding-left: $inuit-text-input-padding--small;
		padding-right: $inuit-text-input-padding--small;
		line-height: $inuit-text-input-height--small;
	}
}

@if ($inuit-enable-text-input--large == true) {
	/**
     * Tall text inputs.
     */
	.#{$inuit-text-input-namespace}o-text-input--large {
		height: $inuit-text-input-height--large;
		padding-left: $inuit-text-input-padding--large;
		padding-right: $inuit-text-input-padding--large;
		line-height: $inuit-text-input-height--large;
	}
}

@if ($inuit-enable-textarea == true) {
	/**
     * Adopt the styles from text-inputs for textarea boxes.
     */
	.#{$inuit-text-input-namespace}o-textarea {
		padding-left: $inuit-text-input-padding;
		padding-right: $inuit-text-input-padding;
		background-color: $inuit-text-input-background;
		color: $inuit-text-input-text-color;

		@if ($inuit-text-input-border-width != 0) {
			border: $inuit-text-input-border-width $inuit-text-input-border-style $inuit-text-input-border-color;
		} @else {
			border: 0;
		}

		@if ($inuit-text-input-border-radius != 0) {
			border-radius: $inuit-text-input-border-radius;
		}
	}
}
