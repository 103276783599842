/* ------------------------------------*\
    #CAREER CONTACT
\*------------------------------------ */

$picture-size: 80px;

.c-career-contact__wrapper {
	background: $color-gray-lighter;
}

.c-career-contact__container {
	display: table;
	width: 100%;
}

.c-career-contact__col {
	display: table-cell;
	vertical-align: middle;

	&:first-child() {
		text-align: right;
	}

	@include media-query(m-down) {
		display: inline-block;
		text-align: center;

		&:first-child() {
			text-align: center;
		}
	}
}

.c-career-contact__cell {
	float: left;

	&::before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	@include media-query(m-down) {
		float: none;
		display: inline-block;
		text-align: left;
	}
}

.c-career-contact__picture {
	width: $picture-size;
	height: $picture-size;
	border-radius: 100%;
}
