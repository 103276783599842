/* ------------------------------------*\
    #DISPLAY
\*------------------------------------ */

/**
 * Display helper class.
 */

.u-inline {
	display: inline !important;
}

.u-inline-block {
	display: inline-block !important;
}

.u-block {
	display: block !important;
}

.u-block-m-up {
	@include media-query(m-up) {
		display: block !important;
	}
}

.u-table {
	display: table !important;
}

/**
 * Helper classes.
 */
.is-hidden {
	display: none !important;
}

.u-show-m-down {
	@include media-query(m-down) {
		display: block !important;
	}
}
