/* ------------------------------------*\
    #brandlist-searchbox
\*------------------------------------ */
// for js-brandlist-searchbox

.js-brandlist-searchbox {
	align-items: center;
	background-color: var(--color-gray-20);
	border-radius: 9999px;
	color: var(--color-gray-80);
	cursor: text;
	display: flex;
	font-size: 1.142857142857143rem;
	padding: 0 2.285714285714286rem;
	height: 2.857142857142857rem;

	&:focus-within,
	&:hover {
		background-color: var(--color-gray-40);
	}

	&:focus-within {
		color: black;
	}

	.c-search__icon {
		height: 1.428571428571429rem;
		width: 1.428571428571429rem;
		flex-shrink: 0;
		margin-right: 0.571428571428571rem;
	}

	.c-search__closebutton {
		margin-left: 0.571428571428571rem;

		svg {
			height: 1.428571428571429rem;
			width: 1.428571428571429rem;
		}
	}

	input {
		appearance: none;
		background: transparent;
		border: none;
		flex: 1 1 0%;
		height: 100%;
		line-height: 1.428571428571429rem;
		margin: 0;
		min-width: 0;
		outline: none;
		padding: 0;

		&::placeholder {
			color: var(--color-gray-80);
		}
	}
}
