/* ------------------------------------*\
    #TIME PLAN - SCHEDULE
\*------------------------------------ */

$schedule-item-margin-bottom: ($spacing-unit * 1.5);

$schedule-time-width: 100px;
$schedule-time-font-weight: $font-weight-bold;

/* remove file if not necessary */

.c-schedule {
	//styles
}

.c-schedule__item {
	display: table;
	width: 100%;
	margin-bottom: $schedule-item-margin-bottom;

	@include media-query(m-down) {
		display: block;
	}
}

.c-schedule__time {
	display: table-cell;
	font-weight: $schedule-time-font-weight;
	width: $schedule-time-width;

	@include media-query(m-down) {
		display: block;
	}
}

.c-schedule__text {
	display: table-cell;

	@include media-query(m-down) {
		display: block;
	}
}

.c-schedule__list {
	margin-left: $spacing-unit;
	margin-bottom: 0;
}
