/* ------------------------------------*\
    #TABS FOR MOBILE AND TABLET
\*------------------------------------ */

.c-department-mobile {
	display: flex;
	flex-grow: 1;
}

.c-department-mobile-item {
	margin: 0;
	list-style: none;
	display: flex;

	li {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-grow: 1;
		margin-right: 20px;

		a {
			@extend .ocm-body2;

			color: var(--color-black-primary);
			padding-bottom: 4px;
			position: relative;
		}

		//active bottom Arrow
		&.active {
			position: relative;

			a {
				border-bottom: 2px solid var(--color-black-primary);
			}
		}
	}
}
