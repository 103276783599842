/* ------------------------------------*\
    #TEXT MODUL
\*------------------------------------ */

$square-max-width: 300px;

.c-text-modul {
	position: relative;

	@include media-query(l-down) {
		display: inline-block;
	}

	.language-ar & {
		direction: rtl;
	}
}

.c-text-modul__first {
	padding-right: $spacing-unit;

	@include media-query(l-down) {
		display: inline-block;
		border-bottom: 1px solid $color-gray-light;
		padding-bottom: $spacing-unit--small;
	}
}

.c-text-modul__second {
	border-left: 1px solid rgba($color-classic-primary, 0.4);
	position: absolute;
	height: 100%;

	@include media-query(l-down) {
		border-left: 0;
		display: inline-block;
		position: static !important;
		padding-top: $spacing-unit--large;
	}

	&--square {
		background: $color-black;
		color: $color-white;
		position: static;
		max-width: $square-max-width;

		@include media-query(l-down) {
			max-width: 100%;
			width: 100%;
		}
	}

	&--widget {
		position: static;
	}

	ul {
		margin-left: $spacing-unit;
	}

	.language-ar & {
		border-left: 0;
		border-right: 1px solid rgba($color-classic-primary, 0.4);

		ul {
			margin-left: 0;
			margin-right: $spacing-unit;
		}

		@include media-query(l-down) {
			border-right: 0;
		}
	}
}

.c-text-modul__image {
	margin-right: $spacing-unit--small;

	img {
		max-height: 50px;
		height: 100%;
	}
}
