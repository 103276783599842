/* ------------------------------------*\
    #CLASSIC POPUP
\*------------------------------------ */

$classic-popup-width: 700px;
$classic-popup-width--mobile: 100%;

$classic-popup-max-height: 690px;

$classic-popup-padding: ($spacing-unit * 2);
$classic-popup-padding--mobile: ($spacing-unit / 2);

.c-classic-popup {
	position: relative;
	background: $color-white;
	display: none;
	padding: $classic-popup-padding;
	width: $classic-popup-width;
	max-height: $classic-popup-max-height;

	@include media-query(m-down) {
		width: $classic-popup-width--mobile;
		padding: $classic-popup-padding--mobile;
		padding-top: ($spacing-unit * 2.5);
		position: fixed !important;
		top: 0;
		left: 0;
	}
}

.c-classic-popup__image {
	width: 100%;
	height: auto;
	margin-bottom: $spacing-unit;
}

.c-classic-popup__text {
	text-align: center;
}

.c-classic-popup__close {
	&::after {
		content: $icon-font--close;

		@include icon-font();

		background: $color-black;
		color: $color-white;
		font-size: $secondary-font-size-s;
		border-radius: 100%;
		position: absolute;
		top: -16px;
		right: -16px;
		padding: ($spacing-unit / 2);
		cursor: pointer;
	}

	@include media-query(m-down) {
		&::after {
			top: 5px;
			right: 5px;
		}
	}
}
