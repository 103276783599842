/* ------------------------------------*\
    # My Account  #
\*------------------------------------ */

.c-account-content-row {
	border-bottom: 1px solid $color-gray-dark;

	&--bt {
		border-top: 1px solid $color-gray-dark;
	}
}

.c-account-text-align {
	height: 38px;
	max-width: 400px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: $spacing-unit--small;

	@supports (-webkit-line-clamp: 2) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;

		/* autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
}

.c-my-account__teaserimage {
	@include aspect-ratio(2000, 490);

	@include media-query(m-down) {
		@include aspect-ratio(720, 300);
	}

	img {
		width: 100%;
	}
}

.c-my-account-off-canvas-headline-greeting {
	font-size: $secondary-font-size-xl;
	font-weight: $font-weight-semibold;
	line-height: 24px;
}

.c-my-account-off-canvas-headline-title-name {
	white-space: nowrap;
}

.c-my-account-off-canvas-headline-points {
	font-size: $u-fontsize-s;
	font-weight: $font-weight-bold;
	line-height: 30px;
}
