///*------------------------------------*\
//    #TYPOGRAPHY
//\*------------------------------------*/
$u-fontsize-2xs: 10px;
$u-fontsize-xs: 12px;
$u-fontsize: 14px;
$u-fontsize-s: 16px;
$u-fontsize-m: 20px;
$u-fontsize-l: 24px;
$u-fontsize-xl: 32px;
$u-fontsize-2xl: 40px;
$u-fontsize-3xl: 64px;

// High-level base settings.
$inuit-base-font-size: 14px;

$inuit-base-line-height: 18px;
$inuit-base-text-color: $color-black-light !default;
$inuit-base-background-color: #fff !default;

// These variables are framework variables, sourced from variables defined
// above. Feel free to use these variables throughout your project, but do not
// modify or reassign them.
$inuit-base-spacing-unit: $inuit-base-line-height;
$inuit-base-spacing-unit--tiny: round(calc($inuit-base-spacing-unit / 4));
$inuit-base-spacing-unit--small: round(calc($inuit-base-spacing-unit / 2));
$inuit-base-spacing-unit--large: round(calc($inuit-base-spacing-unit * 2));
$inuit-base-spacing-unit--huge: round(calc($inuit-base-spacing-unit * 4));

// /*------------------------------------*\
//     #HEADINGS
// \*------------------------------------*/

$heading-size-0: 48px;
$inuit-heading-size-1: 40px;
$inuit-heading-size-2: 30px;
$inuit-heading-size-3: 24px;
$inuit-heading-size-4: 20px;
$inuit-heading-size-5: 16px;
$inuit-heading-size-6: 14px;

$inuit-heading-size-milli: 12px;
$inuit-heading-size-micro: 10px;

// /*------------------------------------*\
//     #FONT-SIZE FOR SECONDARY FONT-FAMILY
// \*------------------------------------*/
$heading-secondary-font-size-ultra: 140px;
$headline-ultra-follow-font-size: 175px;
$heading-secondary-font-size-alpha: 42px;
$heading-secondary-font-size-beta: 32px;
$heading-secondary-font-size-gamma: 26px;
$heading-secondary-font-size-delta: 22px;
$heading-secondary-font-size-epsilon: 18px;
$heading-secondary-font-size-zeta: 16px;

$secondary-font-size-xs: 12px;
$secondary-font-size-s: 14px;
$secondary-font-size-m: 15px;
$secondary-font-size-l: 17px;
$secondary-font-size-xl: 20px;

// /*------------------------------------*\
//   CLASSIC #FONT-SIZE FOR SECONDARY FONT-FAMILY
// \*------------------------------------*/
$heading-classic-secondary-font-size-alpha: 70px;
$heading-classic-secondary-font-size-beta: 50px;
$heading-classic-secondary-font-size-gamma: 40px;
$heading-classic-secondary-font-size-delta: 30px;
$heading-classic-secondary-font-size-epsilon: 26px;
$heading-classic-secondary-font-size-zeta: 20px;

// /*------------------------------------*\
//     #BASE-FONT-FAMILY
// \*------------------------------------*/

$base-font-family: "Draft B", Helvetica, Arial, sans-serif;

// /*------------------------------------*\
//     #FONT-WEIGHTS
// \*------------------------------------*/

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;
