/* ------------------------------------*\
    #TEASER AREA
\*------------------------------------ */

.c-teaser-top {
	.c-teaser-top__item {
		display: block;
		position: relative;
		overflow: hidden;
		-webkit-backface-visibility: hidden;

		&:hover {
			.c-teaser-top__image {
				transform: scale(1.03);

				@include ui-transition(0.5s, ease-in);
			}

			.c-teaser-top__image--small {
				transform: scale(1.03);

				@include ui-transition(0.5s, ease-in);
			}
		}
	}

	.c-teaser-top__image {
		width: 100%;

		@include ui-transition(0.5s, ease-out);
	}
}
