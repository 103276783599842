/* ------------------------------------*\
    #CALLOUT
\*------------------------------------ */

$callout-size: 250px;
$callout-wrapper-size: $callout-size + $spacing-unit;

$callout-size-img-teaser: 150px;
$callout-wrapper-size-img-teaser: $callout-size-img-teaser + $spacing-unit;

$callout-border-width: 1px;
$callout-border-style: solid;
$callout-border-color: transparent;
$callout-border-radius: 50%;

$callout-wrapper-border-width: 1px;
$callout-wrapper-border-style: solid;
$callout-wrapper-border-color: $color-black;

$callout-wrapper-pos-r: ($spacing-unit * 4);
$callout-wrapper-pos-t: ($spacing-unit * 13);

$callout-content-max-width: 120px;
$callout-content-mt: $spacing-unit;

$callout-content-main-font-size: $heading-secondary-font-size-alpha;
$callout-content-main-font-color: $color-classic-primary;
$callout-content-main-font-weight: $font-weight-medium;
$callout-content-main-font-line-height: ($inuit-base-line-height * 2);

.c-callout {
	background-color: $color-black;
	height: $callout-size;
	width: $callout-size;
	border-radius: $callout-border-radius;
	border: $callout-border-width $callout-border-style $callout-border-color;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.c-callout--img-teaser {
	height: $callout-size-img-teaser;
	width: $callout-size-img-teaser;
}

.c-callout-wrapper {
	border: $callout-wrapper-border-width $callout-wrapper-border-style $callout-wrapper-border-color;
	border-radius: $callout-border-radius;
	height: $callout-wrapper-size;
	width: $callout-wrapper-size;
	position: absolute;
	right: -$callout-wrapper-pos-r;
	top: $callout-wrapper-pos-t;
}

.c-callout-wrapper--img-teaser {
	height: $callout-wrapper-size-img-teaser;
	width: $callout-wrapper-size-img-teaser;
	left: $spacing-unit * 4;
	top: $spacing-unit;
}

.c-callout__content {
	color: $color-white;
	max-width: $callout-content-max-width;
	margin: 0 auto;
	text-align: center;
	margin-top: $callout-content-mt;

	li {
		&:first-of-type {
			font-size: $callout-content-main-font-size;
			line-height: $callout-content-main-font-line-height;
			font-weight: $callout-content-main-font-weight;
			color: $callout-content-main-font-color;
		}

		&:nth-of-type(2) {
			font-size: $inuit-heading-size-4;
			font-weight: $callout-content-main-font-weight;
			color: $callout-content-main-font-color;
		}

		&:last-of-type {
			font-size: $secondary-font-size-s;
			line-height: 21px;
		}
	}
}

.c-callout__content--img-teaser {
	margin-top: 50px;
	transform: rotate(-20deg);

	li {
		line-height: 20px !important;

		&:first-of-type {
			font-size: 13px;
			color: $color-white;
		}

		&:last-of-type {
			font-size: 18px;
			color: $color-white;
		}
	}
}
