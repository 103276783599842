.progress {
	position: relative;
	height: 2px;
	margin-top: -2px; //height of border navi
	z-index: 503;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		background-color: var(--color-black);
		height: 100%;
		width: 2%;
		transition-property: width;
		animation: progressAnimation 15s;
	}
}

@keyframes progressAnimation {
	0% {
		width: 2%;
	}

	12% {
		width: 85%;
	}

	100% {
		width: 95%;
	}
}
