/* ------------------------------------*\
    #FORM
\*------------------------------------ */

input,
textarea,
select {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
}

fieldset {
	border: none;
}

select.c-formElement {
	height: $spacing-unit--large;
}

input.u-type-date::before {
	content: attr(placeholder) !important;
}

input::-ms-clear {
	display: none;
}
