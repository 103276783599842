/* ------------------------------------*\
	allgmeine Navigationsliste
	im meta-link-Flyout
\*------------------------------------ */

$header-content-navigationlist-border: 1px solid $color-gray-lighter;

.c-header-content-navigationlist {
	margin: 0;
	list-style: none;
	padding: $space-m 0;
}

.c-header-content-navigationlist__linkbutton {
	font-size: 1rem; // 14px
	font-weight: $font-weight-medium; // 500
	line-height: 1.4285714rem; // 20px
	display: inline-block;
	width: 100%;
	margin: 0;
	padding: $space-s $space-l;
	white-space: nowrap;

	&:hover {
		background: $color-gray-lighter;
	}

	&:focus-visible {
		outline-offset: -2px;
	}
}

.c-header-content-navigationlist__borderbottom {
	border-bottom: $header-content-navigationlist-border;
	padding-bottom: $space-s;
	margin-bottom: $space-s;
}

.c-header-content-navigationlist__bordertop {
	border-top: $header-content-navigationlist-border;
	padding-top: $space-s;
	margin-top: $space-s;
}
