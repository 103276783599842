/* ------------------------------------*\
     #FONT-SIZE
\*------------------------------------ */

.u-fontsize-2xs {
	@include font-size($u-fontsize-2xs, 1.2);
}

.u-fontsize-xs {
	@include font-size($u-fontsize-xs, 1.2);
}

.u-fontsize {
	@include font-size($u-fontsize, 1.2);
}

.u-fontsize-s {
	@include font-size($u-fontsize-s, 1.2);
}

.u-fontsize-m {
	@include font-size($u-fontsize-m, 1.2);
}

.u-fontsize-l {
	@include font-size($u-fontsize-l, 1.2);
}

.u-fontsize-xl {
	@include font-size($u-fontsize-xl, 1.2);
}

.u-fontsize-2xl {
	@include font-size($u-fontsize-2xl, 1.2);
}

.u-fontsize-3xl {
	@include font-size($u-fontsize-3xl, 1.2);
}
