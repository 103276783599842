/* ------------------------------------*\
    #TESTIMONIAL
\*------------------------------------ */

$testimonial-pt: 142px;
$testimonial-text-fonts-size: 12px;
$testimonial-box-p: 70px 36px 16px 36px;
$testimonial-img-width: 250px;

.c-testimonial {
	position: relative;
	padding-top: $testimonial-pt;

	.c-testimonial__text {
		font-size: $testimonial-text-fonts-size;
	}
}

.c-testimonial__box {
	padding: $testimonial-box-p;
	background-color: $color-gray-light;
}

.c-testimonial__img {
	position: absolute;
	top: 0;
	width: $testimonial-img-width;
	left: 50%;
	margin-left: -($testimonial-img-width/2);
}
