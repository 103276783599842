/* ------------------------------------*\
    #CLASSIC TEXT IMAGE
\*------------------------------------ */

// Variables Start

// Variables End

.c-cl-txt-img {
	ul {
		margin-left: $spacing-unit;

		.language-ar & {
			margin-left: 0;
			margin-right: $spacing-unit;
		}
	}

	.language-ar & {
		direction: rtl;
	}
}

.c-cl-txt-img__item {
	margin-bottom: $spacing-unit--huge;
}

.c-cl-txt-img__image {
	> img {
		width: 100%;
	}

	@include media-query(l-down) {
		margin-bottom: $spacing-unit--small;
	}
}

.c-cl-txt-img__image--top {
	@include media-query(m-up) {
		margin-bottom: $spacing-unit--small;
	}
}

.c-cl-txt-img__image--flipped {
	@include media-query(l-up) {
		transform: translateX(100%);

		.language-ar & {
			transform: translateX(-100%);
		}
	}
}

.c-cl-txt-img__content--flipped {
	@include media-query(l-up) {
		transform: translateX(-100%);

		.language-ar & {
			transform: translateX(100%);
		}
	}
}

.c-cl-txt-img__image--flipped-wrapper-1\/2 {
	@include media-query(l-up) {
		transform: translateX(0);
	}
}

.c-cl-txt-img__image--flipped-wrapper-1\/3 {
	@include media-query(l-up) {
		transform: translateX(33%);
	}
}

.c-cl-txt-img__image--flipped-wrapper-2\/3 {
	@include media-query(l-up) {
		transform: translateX(-33%);
	}
}

.c-cl-txt-img__headerlink {
	&,
	&:visited,
	&:hover,
	&:active {
		color: inherit;
		font: inherit;
	}
}
