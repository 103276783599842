/* ------------------------------------*\
    #CONTACT NAV Content
\*------------------------------------ */

$contact-nav-box-min-width: 200px;

//CONTACT NAV BOX LINK
$contact-nav-box-link-color: $color-classic-primary;
$contact-nav-icon-arrow-fonts-size-small: ($inuit-base-font-size) / 1.5;
$contact-nav-box-link-pl: ($inuit-base-spacing-unit) / 6;
$contact-nav-box-link-line-height: 21px;

$contact-nav-box-headline: $heading-secondary-font-size-epsilon;
$contact-nav-box-font-size-m: $heading-secondary-font-size-gamma;

.c-contact-nav-box__clock,
.c-contact-nav-box__phone,
.c-contact-nav-box__speechbubble-fat,
.c-contact-nav-box__map {
	padding: $spacing-unit;
	min-width: $contact-nav-box-min-width;
}

.c-contact-nav-box__headline {
	@include font-size($contact-nav-box-headline);

	color: $color-black;
	white-space: nowrap;

	@include media-query(m-down) {
		@include font-size($contact-nav-box-font-size-m);
	}
}

.c-contact-nav-box__link {
	color: $contact-nav-box-link-color;
	font-size: $u-fontsize;
	font-weight: $font-weight-medium;
	text-decoration: underline;
	text-underline-position: under;

	&:where(button) {
		appearance: none;
		background: unset;
		border: none;
		padding: unset;
	}
}

.c-contact-nav-box__address {
	white-space: nowrap;
}
