/* ------------------------------------*\
    #HELPER TYPOGRAPHY
\*------------------------------------ */

%f--italic,
.f--italic {
	font-style: italic !important;
}

%f--bold,
.f--bold {
	font-weight: bold !important;
}

.f-underline {
	text-decoration: underline !important;
}

%u-mega,
.u-mega {
	@include inuit-font-size($heading-size-0);
}

.u-short-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 90%;
}

.u-single-line-height {
	line-height: 1em !important;
}
