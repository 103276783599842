/* ------------------------------------*\
    #BOX-TILE
\*------------------------------------ */
.o-box-tile {
	background-color: $color-gray-lighter;
	padding: 32px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;
	text-align: center;

	@include media-query(l-down) {
		text-align: left;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	&__text {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.2;
		margin-bottom: 18px;

		@include media-query(l-down) {
			margin-left: 9px;
			margin-bottom: 0;
		}
	}

	&__img {
		width: 62px;
		height: 62px;

		@include media-query(l-down) {
			width: 42px;
			height: 42px;
		}
	}
}
