/* ------------------------------------*\
    #OFFER SLIDERS
\*------------------------------------ */

// Variables Start
// $offer-saving-font-size:    $heading-secondary-font-size-alpha;
// Variables End

.c-offer-slider {
	position: relative;

	.o-layout__item {
		padding-left: 9px;
		padding-right: 9px;
	}
}

.c-offer-slider__flexbox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	@include media-query(m-down) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		max-width: 100%;
		height: 100%;
	}
}

.c-offer-slider__inner {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1024px;

	// todo: Reihenfolge sollte getauscht werden, l-down wird immer überschrieben, generell nach solchen Stellen suchen und testen
	@include media-query(m-up) {
		width: 90%;
	}

	@include media-query(xl-up) {
		width: 75%;
	}

	@include media-query(l-up) {
		width: 90%;
	}
}

.c-offer-slider__classic {
	@include media-query(m-down) {
		height: 0;
		overflow: hidden;
		border: none;

		&.is-active {
			height: auto;
		}
	}
}

.c-offer-slider__shop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	display: flex;
	background: $color-white;
	z-index: 2;
	max-height: 0;
	overflow: hidden;

	&.is-active {
		max-height: 3000px;

		@include media-query(m-down) {
			position: static;
		}
	}

	&.is-single {
		position: static;
		max-height: 3000px;
	}

	@include media-query(m-down) {
		position: static;
	}
}

.c-offer-slider__item {
	width: 50%;
	height: 100%;
	padding-right: 5%;
	float: left;
	text-align: center;

	&--full {
		width: 100%;
		padding: 0;
		float: none;
		height: 100%;
		text-align: center;

		&:last-child {
			border: none;
		}

		.slick-dots {
			@include media-query(l-down) {
				bottom: $spacing-unit--small;
			}

			@include media-query(m-down) {
				bottom: -$spacing-unit;
			}
		}
	}

	&:last-child {
		margin-right: 0;
		padding-left: 5%;
		padding-right: 0;
		border-left: 1px solid $color-gray-light;

		@include media-query(m-down) {
			border-left: none;
		}
	}

	@include media-query(m-down) {
		width: 100%;
		padding: 0;
		float: none;
		height: auto;
	}
}
