/* ------------------------------------*\
    #HEADINGS
\*------------------------------------ */

/**
 * Headings 1–6’s corresponding Greek-alphabet abstract classes for double-
 * stranded heading hierarchy: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
 *
 * Use these helper classes to cause other elements to adopt the styling of the
 * respective heading, e.g.:
 *
   <h2 class="alpha">Lorem ipsum</h2>
 *
 */

h1,
.#{$inuit-namespace}u-alpha,
%#{$inuit-namespace}u-alpha {
	@include inuit-font-size($inuit-heading-size-1);
}

h2,
.#{$inuit-namespace}u-beta,
%#{$inuit-namespace}u-beta {
	@include inuit-font-size($inuit-heading-size-2);
}

h3,
.#{$inuit-namespace}u-gamma,
%#{$inuit-namespace}u-gamma {
	@include inuit-font-size($inuit-heading-size-3);
}

h4,
.#{$inuit-namespace}u-delta,
%#{$inuit-namespace}u-delta {
	@include inuit-font-size($inuit-heading-size-4);
}

h5,
.#{$inuit-namespace}u-epsilon,
%#{$inuit-namespace}u-epsilon {
	@include inuit-font-size($inuit-heading-size-5);
}

h6,
.#{$inuit-namespace}u-zeta,
%#{$inuit-namespace}u-zeta {
	@include inuit-font-size($inuit-heading-size-6);
}

.u-heading-2xlarge {
	@include font-size($u-fontsize-2xl, 1.2);

	font-weight: $font-weight-semibold;

	@include media-query(m-down) {
		@include font-size($u-fontsize-xl, 1.2);
	}
}

.u-heading-xlarge {
	@include font-size($u-fontsize-xl, 1.2);

	font-weight: $font-weight-semibold;

	@include media-query(m-down) {
		@include font-size($u-fontsize-l, 1.2);
	}
}

.u-heading-large {
	@include font-size($u-fontsize-l, 1.2);

	font-weight: $font-weight-normal;

	@include media-query(l-down) {
		@include font-size($u-fontsize-m, 1.2);
	}
}

.u-heading-large-secondary {
	@include font-size($u-fontsize-l, 1.2);

	font-weight: $font-weight-semibold;

	@include media-query(l-down) {
		@include font-size($u-fontsize-m, 1.2);
	}
}

.u-heading-medium {
	@include font-size($u-fontsize-m, 1.2);

	font-weight: $font-weight-semibold;
}

.u-heading-small {
	@include font-size($u-fontsize-s, 1.2);

	font-weight: $font-weight-semibold;
}
