/* ------------------------------------*\
    #MAPS-ADMIN
\*------------------------------------ */

.c-maps-admin__info-panel {
	width: 30%;
	height: 100%;
	right: 0;
	top: 0;
	position: fixed;
	transform: translateX(150%);
	background: $color-gray-light;
	box-shadow: -4px 0 20px 0 rgba(0, 0, 0, 0.18);
	transition: all 0.3s;
	z-index: 50;

	&.is-active {
		transform: translateX(0);
	}
}

.c-maps-admin__container {
	position: relative;
	height: 100%;
	width: 100%;
	padding: $spacing-unit;
	overflow: auto;

	input,
	textarea {
		width: 80%;
	}

	textarea {
		resize: none;
		height: 80px;
	}
}

.c-maps-admin__close {
	position: absolute;
	left: -56px;
	top: 0;
	z-index: -1;
	padding: $spacing-unit;
	background: $color-gray-light;
}

.c-maps-admin__label {
	display: table-cell;
	vertical-align: middle;
	height: 39px;
}

.c-maps-admin__zoom-level {
	position: fixed;
	top: $spacing-unit;
	left: $spacing-unit;
}

.c-maps-admin__zoom-text {
	font-size: 4em;
	color: $color-classic-primary;
	font-weight: bold;
}

.c-maps-admin__lock-button {
	&.is-unlocked {
		background: $color-ok;
		color: $color-white;
	}
}

.c-maps-admin__create-button {
	position: absolute;
	bottom: 6rem;
	left: 2rem;

	&::after {
		content: $icon-font--plus-circle;
		display: block;

		@include icon-font();
	}

	&.active {
		background: var(--color-success);
		border: 1px solid var(--color-success);

		&::after {
			content: $icon-font--trust-correct;
		}
	}
}

.c-maps-admin__save-button {
	position: absolute;
	bottom: 2rem;
	left: 2rem;
}

.c-maps-admin__admin-panel {
	position: fixed;
	height: 95vh;
	width: 50vw;
	left: 25vw;
	top: 2.5vh;
	background: white;
	padding: 2rem;
	display: none;

	&.show {
		display: block;
	}

	pre {
		height: 100%;
		overflow: auto;
	}
}

.c-maps-admin__close-button {
	position: absolute;
	top: -20px;
	right: -20px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	background: $color-classic-primary;
	color: white;
	text-align: center;
	display: inline-block;
}

.c-maps-admin__select-button {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: $spacing-unit--small;
	background: $color-classic-primary;
	color: white;
	display: inline-block;
}

.c-maps-admin__save-marker {
	&.saved {
		&::after {
			content: $icon-font--trust-correct;
			display: inline-block;
			padding-left: $spacing-unit--small;

			@include icon-font();

			font-size: 12px;
		}
	}
}

.c-maps-admin__field-description {
	font-size: 11px;
	color: $color-gray-dark;
}
