.c-flydown_button {
	width: 0.95rem;
	height: 0.95rem;
	opacity: 0; // don’t hide the button for assistive technologies
	pointer-events: none;
	@extend .ocm-button__text;
	@extend .ocm-button--small;
	@extend .ocm-button--icon;
	svg {
		transform: rotate(90deg);
	}

	&:focus-visible {
		pointer-events: auto;
		opacity: 1;
	}
}
