/* ------------------------------------*\
    #SHAME
\*------------------------------------ */

/**
 * Hacky, nasty code. These bits have to be repaired!
 */
.c-popup .o-flag__img > img {
	max-width: 100px;
}

//zoom btn hidden
.o-overlay + .c-zoom-btn > .c-product-slider__zoom {
	display: none;
}
