.ocm-headline1 {
	font-size: 2rem !important;
	line-height: 2.71428571rem !important;
	font-weight: 600 !important;
}
.ocm-headline2 {
	font-size: 1.57142857rem !important;
	line-height: 2.285714291rem !important;
	font-weight: 600 !important;
}
.ocm-headline3 {
	font-size: 1.28571429rem !important;
	line-height: 1.85714286rem !important;
	font-weight: 600 !important;
}
.ocm-headline4 {
	font-size: 1rem !important;
	line-height: 1.57142857rem !important;
	font-weight: 600 !important;
}
.ocm-body1 {
	font-size: 1rem !important;
	line-height: 1.42857143rem !important;
	font-weight: 400 !important;
}
.ocm-body2 {
	font-size: 1rem !important;
	line-height: 1.42857143rem !important;
	font-weight: 500 !important;
}
.ocm-body3 {
	font-size: 1rem !important;
	line-height: 1.42857143rem !important;
	font-weight: 600 !important;
}
.ocm-body4 {
	font-size: 1rem !important;
	line-height: 1.42857143rem !important;
	font-weight: 700 !important;
}
.ocm-brandtitle1 {
	font-size: 1.28571429rem !important;
	line-height: 1.85714286rem !important;
	font-weight: 600 !important;
}
.ocm-brandtitle2 {
	font-size: 1rem !important;
	line-height: 1.57142857rem !important;
	font-weight: 700 !important;
}
.ocm-brandtitle3 {
	font-size: 1rem !important;
	line-height: 1.57142857rem !important;
	font-weight: 500 !important;
}
.ocm-subtext1 {
	font-size: 0.85714286rem !important;
	line-height: 1.28571429rem !important;
	font-weight: 400 !important;
}
.ocm-subtext2 {
	font-size: 0.85714286rem !important;
	line-height: 1.28571429rem !important;
	font-weight: 500 !important;
}
.ocm-subtext3 {
	font-size: 0.85714286rem !important;
	line-height: 1.28571429rem !important;
	font-weight: 600 !important;
}
.ocm-subtext4 {
	font-size: 0.85714286rem !important;
	line-height: 1.28571429rem !important;
	font-weight: 700 !important;
}
.ocm-title1 {
	font-size: 2.57142857rem !important;
	line-height: 3rem !important;
	font-weight: 600 !important;
}
.ocm-title2 {
	font-size: 1.57142857rem !important;
	line-height: 2rem !important;
	font-weight: 400 !important;
}
.ocm-footnote1 {
	font-size: 0.85714286rem;
	line-height: 1.28571429rem;
	font-weight: 400;
	color: var(--color-gray-70);
}
