/* ------------------------------------*\
    #ACCORDION IN NAVIGATION
\*------------------------------------ */

$icon-size: 50px;

.c-accordion-nav,
.c-accordion-nav ul {
	margin: 0;
	list-style: none;
}

.c-accordion-nav ul {
	background-color: $color-gray-lighter;

	.c-accordion-nav__link {
		margin-left: $spacing-unit;
	}

	ul .c-accordion-nav__link {
		margin-left: $spacing-unit--large;
	}
}

.c-accordion-nav.level-1 {
	padding: 10px 0;

	.c-accordion-nav__item {
		padding: 0;
	}

	.c-accordion-nav__item.has-teaser-image {
		border: none;
		padding: 0 10px;
		margin-bottom: 16px;
	}

	.c-accordion-nav__link {
		line-height: 1;
		padding: 16px 0 16px 16px;
		background: $color-white;
	}

	.has-teaser-image .c-accordion-nav__link {
		@extend .ocm-body2;

		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		overflow: hidden;
		padding: 0;
		background: $color-white;
		outline-offset: 2px;
	}
}

.c-offcanvas-right .c-accordion-nav__link {
	min-height: 68px;
} 

.c-accordion-nav__link--right {
	display: flex !important;
	align-items: center;
	padding-top: 9px !important;
	padding-bottom: 9px !important;
	padding-left: 9px !important;
	padding-right: 0;

	> span {
		line-height: $inuit-base-spacing-unit;
	}
}

.c-accordion-nav__link--top-item {
	padding: 18px 0 18px 18px;
}

.c-accordion-nav__item {
	position: relative;
	border-top: 1px solid var(--color-gray-30);

	.c-icon--arrow-down-big::before {
		@include ui-transition();

		-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
		transform: rotate(0deg);
		display: inline-block;
	}
}

.c-accordion-nav__header {
	padding: $spacing-unit;
	margin-bottom: 0;
}

.c-accordion-nav__link {
	@extend .ocm-body1;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: none;
	width: 100%;
	outline-offset: -2px;
	background-color: white;
	padding: $spacing-unit--small $spacing-unit {
		right: 0;
	}
	min-height: 50px;

	.c-accordion-nav__item.current & {
		font-weight: $font-weight-medium;
	}

	> span {
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: $icon-size;
		padding-top: $spacing-unit;
		text-align: center;

		.c-icon {
			color: $color-black-lighter;

			@include font-size(12px, 1);
		}
	}
}

.c-accordion-nav__item:last-child {
	border-bottom: 1px solid var(--color-gray-30);
}

.c-accordion-nav__item--classic {
	@include media-query(m-down) {
		border-bottom: none !important;
	}
}

.c-accordion-nav__item--center {
	max-width: 300px;
	margin: 0 auto;
}

.c-accordion-nav__image {
	margin-right: $space-m;
	width: 140px;
	height: 85px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-accordion-nav__text {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
}

.c-accordion-nav__icon-container {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 100%;
}

.c-accordion-nav__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 26px;
}

div.c-accordion-nav__submenu-arrow {
	padding: 0 $space-m 0 $space-s;
	font-size: $fontsize-m;

	.has-teaser-image & {
		padding: 0 $space-s;
	}
}
