///*------------------------------------*\
//    #SEARCHRESULT
//\*------------------------------------*/

$search-result-headline-size: $heading-secondary-font-size-zeta;
$search-result__result-headline: 36px;
$search-result__result-headline-small: 30px;

.c-search-result-headline {
	text-align: center;
	font-family: $base-font-family;
	font-weight: $font-weight-medium;
	font-size: 28px;

	@include media-query(l-down) {
		font-size: 24px;
	}
}

.c-search-result-headline-shop-brand {
	font-size: $u-fontsize-m;
	font-weight: $font-weight-bold;
	text-align: center;
	line-height: 32px;

	@include media-query(m-down) {
		font-size: $u-fontsize-s;
		line-height: 24px;

		br {
			display: none;
		}
	}
}

.c-search-result__label {
	background: $color-white;
	width: 100%;
	position: relative;
	display: flex;
	border-bottom: 1px solid $color-gray-light;
	padding: $spacing-unit;
}

.c-search-result__amount {
	text-align: right;
	color: $color-gray-dark;
}

.js-search-result {
	display: none;
}

.js-search-result--active {
	display: block;
}

.c-search-result__entry {
	background: $color-white;
	width: 100%;
	position: relative;
	display: inline-block;
	border-bottom: 1px solid $color-gray-light;
	padding: $spacing-unit;
	padding-right: $spacing-unit--large;

	&::after {
		@include icon-font();

		content: $icon-font--arrow-right;
		position: absolute;
		top: 50%;
		right: $spacing-unit;
		margin-top: -10px;
		font-size: 15px;
	}
}

.c-search-result__headline {
	font-size: $search-result-headline-size;
}

.c-search-result__headline--bold {
	font-weight: $font-weight-medium;
}

.c-search-result__copy {
	font-weight: $font-weight-normal;
	overflow: hidden;
	text-overflow: ellipsis;

	@supports (-webkit-line-clamp: 5) {
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 5;

		/* autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
}

.c-search-result__show-content {
	width: 100%;
	overflow: hidden;
	margin-bottom: 18px;
	transition: all 850ms ease;
	max-height: 1px;

	&.is-active {
		max-height: 400px;
		padding-right: 0;
		transition: all 850ms ease;
	}
}
