/* ------------------------------------*\
    #CAPS
\*------------------------------------ */

/**
 * Text-transform uppercase helper-class.
 */

.u-caps {
	text-transform: uppercase !important;
}

.u-line-through {
	text-decoration: line-through;
}
