/* ------------------------------------*\
    #INHERITH WIDTH
\*------------------------------------ */

/**
 *  helper-class.
 */

.u-inherit-width {
	width: inherit !important;
}
