/* ------------------------------------*\
    # Infobox #
\*------------------------------------ */

.c-account-infobox {
	background-color: $color-gray-lighter;
	padding: $spacing-unit $spacing-unit--small;
	display: flex;
	justify-content: space-between;

	@include media-query(l-down) {
		flex-wrap: wrap;
	}
}

.c-account-infobox__link {
	font-size: $u-fontsize;

	&::after {
		font-size: $inuit-heading-size-milli;
	}
}
