/* ------------------------------------*\
    #TEXT-ALIGN-RESPONSIVE
\*------------------------------------ */

$inuit-enable-text-align--responsive--left: false !default;
$inuit-enable-text-align--responsive--right: false !default;
$inuit-enable-text-align--responsive--center: false !default;

@each $breakpoint in $breakpoints {
	$alias: nth($breakpoint, 1);

	// We don’t want to generate responsive text-align for retina devices.
	@if ($alias != "retina") {
		@include media-query($alias) {
			@if ($inuit-enable-text-align--responsive--left == true) {
				/**
                 * Text-align left responsive #{$alias}.
                 */
				.u-text-left-#{$alias} {
					text-align: left !important;
				}
			}

			@if ($inuit-enable-text-align--responsive--right == true) {
				/**
                 * Text-align right responsive #{$alias}.
                 */
				.u-text-right-#{$alias} {
					text-align: right !important;
				}
			}

			@if ($inuit-enable-text-align--responsive--center == true) {
				/**
                 * Text-align center responsive #{$alias}.
                 */
				.u-text-center-#{$alias} {
					text-align: center !important;
				}
			}
		} // Close retina check.
	} // Close media query.
} // Close breakpoints loop.
