// overwrite Inuit's internal layout styles
$page-wrap-padding: 4 * $lib-spacing-base-unit;
$o-layout-huge-padding: 18 * $lib-spacing-base-unit;

.o-layout {
	margin-left: -$page-wrap-padding;
	&--huge {
		margin-left: -$o-layout-huge-padding;
	}
}

.o-layout__item {
	padding-left: $page-wrap-padding;
}

// overwrite classes from inuit with same classes to enforce priority when used with classes above
.o-list-inline {
	margin: 0;
	padding: 0;
	list-style: none;
}

.o-layout--flush {
	margin-left: 0;
}
