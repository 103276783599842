/* ------------------------------------*\
    #SLIDER-CONTROLS
\*------------------------------------ */

$slider-control-size: 60px;
$slider-control-size-small: 40px;
$slider-control-line-height: $slider-control-size;
$slider-control-line-height-small: $slider-control-size-small;
$slider-control-font-size: 28px;
$slider-control-font-size-small: 24px;
$slider-control-font-size-x-small: 18px;
$slider-control-font-size-xx-small: 16px;

$slider-control-pos-top: 45%;
$slider-control-p: ($slider-control-font-size/2);
$slider-control-nav-pv: 15px;

$slider-control-bg-color: $color-white-transparent;
$slider-control-bg-color-hover: $color-white-transparent-dark;
$slider-control-bg-color-dark: rgba(255, 255, 255, 0.6);
$slider-control-bg-color-dark-hover: rgba(255, 255, 255, 0.8);
$slider-control-widescreen-width: 1025px;

.c-slider-control {
	position: absolute;
	z-index: 1;

	&::before {
		@include icon-font($slider-control-font-size-small);

		.c-rotation-teaser & {
			@include icon-font($slider-control-font-size, $slider-control-size);
		}

		.c-classic-teaser & {
			@include icon-font($slider-control-font-size-x-small, $slider-control-size-small);

			font-weight: $font-weight-bold;
		}

		.c-inspiration-teaser & {
			@include icon-font($slider-control-font-size, $slider-control-size);
		}

		.c-slider-category &,
		.c-slider-centermode & {
			@include icon-font($slider-control-font-size-x-small, $slider-control-size-small);
		}
	}

	&.c-slider-control--left,
	&.c-slider-control--right {
		top: $slider-control-pos-top;
		text-align: center;
		font-weight: 600;

		&.slick-disabled {
			.c-slider-category &,
			.c-upselling & {
				opacity: 0;
				pointer-events: none;
			}
		}

		.c-rotation-teaser &,
		.c-classic-teaser &,
		.c-inspiration-teaser & {
			width: $slider-control-size;
			height: $slider-control-size;
			border-radius: 50%;
			background-color: $slider-control-bg-color;
			line-height: $slider-control-line-height;
			text-align: center;
			font-weight: 600;
		}

		.c-slider-centermode & {
			top: 40%;
			width: $slider-control-size-small;
			height: $slider-control-size-small;
			border-radius: 50%;
			background-color: $slider-control-bg-color-dark;
			line-height: $slider-control-line-height-small;
			text-align: center;
			font-weight: 600;
		}

		.c-slider-category & {
			top: 40%;
			width: $slider-control-size-small;
			height: $slider-control-size-small;
			border-radius: 50%;
			color: white;
			background-color: $color-black;
			line-height: $slider-control-line-height-small;
			text-align: center;
			font-weight: 600;
		}

		// hide inital state if classic teaser
		.c-classic-teaser & {
			width: $slider-control-size-small;
			height: $slider-control-size-small;
			background-color: $color-white;
			line-height: $slider-control-line-height-small;
			opacity: 0.6;
			display: none !important;
		}

		.c-product-slider__preview & {
			z-index: 100;
			visibility: hidden;
		}

		@media screen and (min-device-width: $slider-control-widescreen-width) {
			&:hover {
				.c-rotation-teaser &,
				.c-inspiration-teaser & {
					background-color: $slider-control-bg-color-hover;
					color: $color-black;
				}

				.c-slider-centermode & {
					background-color: $slider-control-bg-color-dark-hover;
					color: $color-black;
				}

				.c-classic-teaser & {
					opacity: 1;
				}
			}

			.c-product-slider__preview:hover & {
				visibility: visible;
				color: $color-gray-dark;

				&:hover {
					color: $color-black-lighter;
				}
			}
		}
	}

	&.c-slider-control--left {
		left: 10px;

		.c-slider-category &,
		.c-slider-centermode & {
			left: 15px;
		}

		.c-upselling & {
			left: 0;
		}

		.c-rotation-teaser &,
		.c-classic-teaser & {
			left: 10%;
		}

		.c-inspiration-teaser & {
			left: 5%;
		}

		.c-offer-slider__classic & {
			left: -18px;
		}

		.c-offer-slider__shop & {
			left: 0;
		}

		.c-classic-teaser--arrows & {
			left: $spacing-unit;
			font-size: 10px;
			font-weight: bold;
		}

		&::before {
			content: $icon-font--arrow-left;
			padding-right: 5px;
		}
	}

	&.c-slider-control--right {
		right: 10px;

		.c-slider-category &,
		.c-slider-centermode & {
			right: 15px;
		}

		.c-upselling & {
			right: 0;
		}

		.c-rotation-teaser &,
		.c-classic-teaser & {
			right: 10%;
		}

		.c-inspiration-teaser & {
			right: 5%;
		}

		.c-offer-slider__classic & {
			right: -26px;
		}

		.c-offer-slider__shop & {
			right: -10px;
		}

		.c-classic-teaser--arrows & {
			right: $spacing-unit;
		}

		.c-textbox__slider--fixed.c-classic-teaser--arrows & {
			right: 25%;
			margin-right: ($spacing-unit + $spacing-unit--small);
		}

		&::before {
			content: $icon-font--arrow-right;
			padding-left: 5px;
		}
	}

	&.c-slider-control--up,
	&.c-slider-control--down {
		width: 100%;
		text-align: center;
		padding-top: $slider-control-nav-pv;
		padding-bottom: $slider-control-nav-pv;
	}

	&.c-slider-control--up {
		top: 0;

		&::before {
			content: $icon-font--arrow-up;
		}
	}

	&.c-slider-control--down {
		//bottom: 0;

		&::before {
			content: $icon-font--arrow-down;
		}
	}
}

//centermode-slider
.c-slider-centermode__image {
	@include media-query("m-down") {
		width: 100%;
	}
}

.c-slider-centermode {
	.slick-slide.has-opacity {
		opacity: 0.4;

		/*  @include ui-transition(opacity 0.5s, ease-in-out); */

		-o-transition: opacity 0.6s ease-in-out;
		transition: opacity 0.6s ease-in-out;
	}

	&:not(.slick-initialized) > :nth-child(n + 2) {
		display: none;
	}

	&:not(.slick-initialized) > :first-child {
		padding: 0 20px;
	}
}

.slick-slider.slick-initialized.c-slider-centermode {
	.slick-slide:not(:first-child) {
		display: block;
	}
}

.c-slider-control__dots {
	list-style: none;
	margin: $spacing-unit 0 0 0;
	padding: 0;
	width: 100%;
	text-align: center;

	li {
		display: inline-block;

		&:nth-last-child(n + 2) {
			margin-right: 7px;
		}

		&.slick-active {
			button::before {
				background: $color-gray-darker;
			}
		}
	}

	button {
		background: transparent;
		color: transparent;
		border: none;
		cursor: pointer;
		padding: 0;
		width: 13px;
		height: 13px;

		&::before {
			content: "";
			display: inline-block;
			height: inherit;
			width: inherit;
			border-radius: 100%;
			border: 1px solid $color-gray-darker;
			background: transparent;
			color: transparent;
			cursor: pointer;
		}
	}
}

// Custom Dots for Classic
.c-slider-control__dots--seo {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: $spacing-unit--small;

	li {
		display: inline-block;

		&.slick-active {
			button::before {
				background: $color-classic-primary;
			}
		}
	}

	button {
		background: transparent;
		color: transparent;
		border: none;
		cursor: pointer;
		width: 20px;
		height: 20px;

		&::before {
			content: "";
			display: inline-block;
			height: 13px;
			width: 13px;
			border-radius: 100%;
			border: 1px solid $color-classic-primary;
			background: transparent;
			color: transparent;
			cursor: pointer;
		}
	}
}
