/* ------------------------------------*\
    #FLEX-ELEMENTS
\*------------------------------------ */

.o-flex {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	margin-left: -$spacing-unit;

	&--no-wrap {
		flex-wrap: nowrap;
	}
}

.o-flex__item {
	padding-left: $spacing-unit;
	width: 100%;
}
