/* ------------------------------------*\
    #BORDER
\*------------------------------------ */

.c-border {
	border: 1px solid $color-black;
}

.c-border__bottom--gray {
	border-bottom: 1px solid $color-gray-dark;
}

.c-border-hide-m-up {
	@include media-query(m-up) {
		border: none;
	}
}

.c-border-hide-l-up {
	@include media-query(l-up) {
		border: none;
	}
}
