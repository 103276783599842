/* ------------------------------------*\
	Header Meta Link Buttons
	Icon + Label / small / big
\*------------------------------------ */
@import "../../settings/header-settings";

$meta-link-button-big-height: ($inuit-base-spacing-unit) * 2;

$meta-link-button-icon-right-size: 18px;
$meta-link-button-big-icon-size: 20px;
$meta-link-button-sitetoggle-size: $meta-link-button-icon-right-size;
$meta-link-button-icon-padding: 12px;

$meta-link-button-cart-count-size: 10px;

//-----------------------------------
// Header links mit Icon und Label
.c-header-meta-link-button {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	line-height: 0.8571428;
	padding: 0 0.857142857142857rem;
	position: relative;
	white-space: nowrap;
	z-index: 10;

	&.link-button-reversed {
		flex-direction: column-reverse;
	}

	@media (min-width: 64em) {
		flex-grow: 0;
		padding: 0 0 0.571428571428571rem;

		.c-header-meta-nav__site-toggle & {
			margin-right: 1.714285714285714rem;
		}

		.c-header-meta-nav__user-bottom &,
		.is-header-sticky & {
			padding: 0 0.857142857142857rem;
		}
	}
}

:not(.is-site-classic).is-header-sticky .c-header-meta-link-button-login {
	transform: translateX(0.857142857142857rem);
}

.c-header-meta-link__icon {
	flex-shrink: 0;
	height: 1.428571428571429rem;
	pointer-events: none;
	width: 1.428571428571429rem;
}

.c-header-meta-link__icon--bigicon {
	font-size: $meta-link-button-big-icon-size;
	pointer-events: none;
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ICONS Variations
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++

// Site-Toggle
.c-header-meta-link--site-toggle {
	border-bottom: 2px solid var(--color-gray-40);
	color: var(--color-black-primary);
	flex-direction: row;
	padding: 0 1.142857142857143rem 0.285714285714286rem;

	&:last-child {
		padding-left: 0.928571428571429rem;
	}

	@media (min-width: 64em) {
		border-bottom-color: transparent;
		padding-left: 0;
		padding-right: 0;

		&:last-child {
			padding-left: 0;
		}
	}

	&.is-active {
		border-bottom-color: var(--color-black-primary);
	}

	.c-header-meta-link__label {
		padding-top: 0;
	}

	.no-touchevents & {
		@include animate_underline_on_hover($line-height: 2px, $duration: 300ms);
	}
}

.c-header-meta-link__label {
	padding-left: $spacing-unit--tiny;
	padding-top: 0.571428571428571rem;

	.is-header-sticky & {
		// sr-only
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
}

.c-header-meta-nav__service__counter-badge {
	color: white;
	background: var(--color-black-primary);
	position: absolute;
	left: 0.857142857142857rem;
	top: -0.285714285714286rem;
	border-radius: 1000px;
	min-width: 1.285714285714286rem;
	height: 1.285714285714286rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 0.714285714285714rem;
	line-height: 1;
	padding: 0 0.285714285714286rem;
	user-select: none;
}
