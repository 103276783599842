/* ------------------------------------*\
    #TEXTBOXES ROUND
\*------------------------------------ */

// Variables Start
$offer-saving-font-size: $heading-secondary-font-size-alpha;

$textbox-wrapper-width: 100%;
$textbox-wrapper-max-width: $max-page-width-with-padding;

// Variables End

.c-textbox-round {
	display: flex !important;
	position: relative;
	// height:520px;
	overflow: hidden;

	&--content-width {
		margin: 0 auto;
		max-width: $textbox-wrapper-max-width;
	}

	@include media-query(m-down) {
		display: block !important;
	}

	&.c-textbox-round--full-height {
		img {
			width: 100%;
			height: 100%;
		}
	}

	img {
		width: 100%;
		height: auto !important;
	}
}

// Textbox variants

.c-textbox-round--top {
	align-items: flex-start;
}

.c-textbox-round--middle {
	align-items: center;
}

.c-textbox-round--bottom {
	align-items: flex-end;
}

.c-textbox-round__wrapper {
	width: $textbox-wrapper-width;
	max-width: $textbox-wrapper-max-width;
	position: absolute;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.c-textbox-round-hide-m-down & {
		@include media-query(m-down) {
			display: none;
		}
	}
}

.c-textbox-round__container {
	width: 100%;
	display: block;

	/*****************************   */

	/****** DEFAULT TEXTBOX *******/

	/*****************************   */

	// horizontal

	.c-textbox-round--left & {
		text-align: left;
	}

	.c-textbox-round--center & {
		text-align: center;
	}

	.c-textbox-round--right & {
		text-align: right;
	}

	// vertical

	// .c-textbox-round--top &{
	//     position: absolute;
	//     top:0%;
	// }

	// .c-textbox-round--bottom &{
	//     position: absolute;
	//     bottom: 0%;
	// }

	@include media-query(m-down) {
		position: static !important;
		transform: translateY(0%) !important;
	}
}

.c-textbox-round__border {
	border-radius: 100%;
	height: 0;
	background: transparent;
	border: 1px solid $color-black;
	width: 26%;
	padding-bottom: 26%;
	position: relative;

	// TOP AND BOTTOM

	.c-textbox-round--bottom & {
		transform: translateY(50%);
	}

	.c-textbox-round--top & {
		transform: translateY(-50%);
	}

	// LEFT AND RIGHT

	.c-textbox-round--left & {
		margin-left: 36px;
	}

	.c-textbox-round--right & {
		margin-right: 36px;
	}

	@include media-query(l-down) {
		width: 30%;
		padding-bottom: 30%;

		.c-textbox-round--bottom & {
			transform: translateY(40%);
		}

		.c-textbox-round--top & {
			transform: translateY(-40%);
		}
	}
}

.c-textbox-round__item {
	width: 96%;
	padding-bottom: 96%;
	height: 0;
	background: $color-black;
	border-radius: 100%;
	position: absolute;
	left: 2%;
	top: 2%;
	text-align: center;
	overflow: hidden;
	color: white;
}

.c-textbox-round__content-container {
	position: relative;
	height: 100%;
}

.c-textbox-round__content {
	position: absolute;
	max-width: 60%;
	margin-left: auto;
	margin-right: auto;
	left: 20%;

	.c-textbox-round--top & {
		bottom: 10%;
	}

	.c-textbox-round--bottom & {
		top: 10%;
	}
}
