/* ------------------------------------*\
    #hotel ITEM
\*------------------------------------ */

// Variables Start
$hotel-saving-font-size: $heading-secondary-font-size-alpha;

$description-height: 218px;

$tablet-image: 900px;

$card-animation-time: 0.25s;

$hotel-container-height: 456px;

$hotel-rating-container-height: 21px;

// Variables End

.c-hotel-item__container {
	margin-left: -$spacing-unit;
}

.c-hotel-list {
	@include media-query(m-down) {
		width: 100%;
	}

	@include media-query(m) {
		width: 49%;
	}

	@include media-query(l-up) {
		width: 33.333%;
	}

	@include media-query(xl-up) {
		width: 33.333%;
	}
}

.c-hotel-item {
	position: relative;
	display: block;
	text-align: left;
	border: 1px solid $color-gray-darker;
	margin-bottom: $spacing-unit;

	// Telling the user what happens on click by highlighting the Call to Action
	&:hover {
		.c-hotel-item__link {
			color: $color-classic-primary;
		}
	}
}

.c-hotel-item__title {
	display: inline-block;
	width: 100%;
	height: $classic-headline-line-height-default * 2;
	overflow: hidden;
	text-overflow: ellipsis;

	&--small {
		height: $classic-headline-line-height-default;
	}
}

.c-hotel-item__rating {
	color: $color-classic-primary;
	min-height: $hotel-rating-container-height;

	.c-icon {
		font-size: 18px;
	}
}

.c-hotel-item__image {
	position: relative;
	display: block;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
	text-align: center;
	padding-bottom: 50%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		padding: $spacing-unit;

		@include media-query(m-down) {
			padding-bottom: 0;
		}
	}
}

.c-hotel-item__body {
	display: block;
	cursor: pointer;
	height: $description-height;

	.language-ar & {
		direction: rtl;
		text-align: right;
	}
}

.c-hotel-item__description {
	height: 3 * $inuit-base-line-height;
	overflow: hidden;
	line-height: 1.4;

	@include media-query(m-down) {
		display: none;
	}
}

.c-hotel-item__link {
	display: inline-block;
	font-weight: $font-weight-bold;
}

.not-inrange {
	display: none !important;
}
