.skip-links {
	margin: unset;
	padding: unset;
	list-style: "";
	position: absolute;
	top: -20em;
	width: 100%;
	z-index: 1000;

	.is-checkout & {
		display: none;
	}
}

.skip-link {
	background-color: #fff;
	position: absolute;
	top: 6px;
	left: 6px;
	right: 6px;
	padding: 0.5714286rem 0.7142857rem;
	text-align: center;

	&:focus {
		top: calc(20em + 6px);
	}
}
