/* ------------------------------------*\
    #TEXTBOXES
\*------------------------------------ */

$offer-saving-font-size: $heading-secondary-font-size-alpha;
$textbox-wrapper-width: 100%;
$textbox-wrapper-max-width: $max-page-width-with-padding;
$map-min-height: 450px;
$mobile-textbox-height: 190px;

.c-textbox {
	position: relative;
	overflow: hidden;

	@include media-query(l-up) {
		display: flex !important;
	}

	&--content-width {
		margin: 0 auto;
		max-width: $textbox-wrapper-max-width;
	}

	&.c-textbox--full-height {
		img {
			width: 100%;
			height: 100%;
		}
	}

	.c-textbox__slider & .c-textbox__image {
		img {
			width: auto; //avoid wrong height in Safari 14.x - TUB-11858
		}
	}

	img {
		width: 100%;
		height: auto !important;
	}

	&--map {
		min-height: $map-min-height;
	}
}

// Textbox variants

.c-textbox--top {
	align-items: flex-start;
}

.c-textbox--middle {
	align-items: center;
}

.c-textbox--bottom {
	align-items: flex-end;
}

.c-textbox__slider {
	&--fixed {
		margin-bottom: $spacing-unit;
	}
}

.c-textbox__wrapper {
	width: $textbox-wrapper-width;
	position: absolute;
	height: 100%;
	top: 50%;
	left: -50%;
	transform: translate(50%, -50%);
	-webkit-font-smoothing: antialiased;
	pointer-events: none;
	overflow: hidden;

	@include media-query(l-down) {
		position: static;
		transform: translate(0, 0);
	}

	// Make the container full with, if there is a full height textbox inside
	&.c-textbox--full-height {
		transform: translate(0, 0);
		top: 0;
		left: 0;

		@include media-query(m-down) {
			position: static;
		}
	}

	// Badge
	&.c-textbox--badge {
		@include media-query(m-down) {
			position: absolute;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.c-textbox__container {
	width: 100%;
	display: block;
	max-width: $max-page-width-with-padding;
	pointer-events: none;
	left: 50%;
	transform: translateX(-50%);

	&--full {
		max-width: 100%;
	}

	/*****************************   */

	/****** DEFAULT TEXTBOX *******/

	/*****************************   */

	// horizontal

	.c-textbox--left & {
		text-align: left;
	}

	.c-textbox--center & {
		text-align: center;
	}

	.c-textbox--right & {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		text-align: right;
	}

	// vertical

	.c-textbox--top & {
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	.c-textbox--middle & {
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.c-textbox--bottom & {
		position: absolute;
		bottom: 0%;
	}

	// Full height
	.c-textbox--full-height & {
		height: 100%;
		margin-left: 0;
		max-width: 100%;
	}

	.c-textbox--full-height.c-textbox--left & {
		.o-page-wrap & {
			margin-left: 0;
		}
	}

	// .c-textbox--full-height.c-textbox--right & {
	//     .o-page-wrap & {
	//         margin-left:0;
	//     }
	// }

	// .c-textbox--full-height.c-textbox--right & {
	//     .o-page-wrap.c-textbox__slider & {
	//         margin-left:-$spacing-unit;
	//     }
	// }

	// Badge
	.c-textbox--badge.c-textbox--right & {
		@include media-query(m-down) {
			position: absolute !important;
			top: 0%;
			right: $spacing-unit;
			left: auto;
			transform: translateY(0%) !important;
		}
	}

	.c-textbox--badge.c-textbox--left & {
		@include media-query(m-down) {
			position: absolute !important;
			top: 0%;
			right: auto;
			left: $spacing-unit;
			transform: translateY(0%) !important;
		}
	}

	@include media-query(l-down) {
		position: static !important;
		transform: translateY(0%) !important;
		margin-left: 0;
	}
}

.c-textbox--full {
	max-width: 100%;
	margin-bottom: 0;
}

.c-textbox--page-wrap {
	max-width: $max-page-width-with-padding;
	margin-bottom: 0;
}

.c-textbox__item {
	text-align: left;
	background: $color-black-transparent-dark;
	-webkit-font-smoothing: antialiased;
	pointer-events: auto;

	@include media-query(l-down) {
		margin-left: 0 !important;
		text-align: center;
		background: $color-black;
		font-size: $u-fontsize;
	}

	.language-ar & {
		direction: rtl;

		@include media-query(l-up) {
			text-align: right;
		}
	}

	// double padding on mobile if textbox is part of a slider for spacing the dots right

	.c-textbox__slider & {
		@include media-query(m-down) {
			padding-bottom: $spacing-unit * 2 !important;
		}
	}

	.c-textbox--width-auto & {
		@include media-query(l-up) {
			width: auto;
			display: inline-block;
		}
	}

	// Override the grid settings to create the margin on the left
	.c-textbox--left & {
		margin-left: $spacing-unit * 2;

		@include media-query(m-down) {
			margin-left: 0;
		}
	}

	// Height Variations
	.c-textbox--full-height & {
		height: 100%;
		margin-left: 0;
		margin-right: 0;
		top: 0;
	}

	// Special treatment for the badge box
	.c-textbox--badge & {
		text-align: center;
	}

	// Color variations

	.c-textbox--black & {
		background: $color-black-transparent-dark;
		color: $color-white;
		border-top: 1px solid $color-black-transparent-dark; // Fix the still existing subpixel? flicker

		@include media-query(l-down) {
			background: $color-black;
		}
	}

	.c-textbox--red & {
		background: $color-brand-primary;
		color: $color-white;
		border-top: 1px solid $color-brand-primary; // Fix the still existing subpixel? flicker
	}

	.c-textbox--white & {
		background: $color-white-transparent-dark;
		color: $color-black;
		border-top: 1px solid $color-white-transparent-dark; // Fix the still existing subpixel? flicker

		@include media-query(l-down) {
			background: $color-white;
		}
	}

	.c-textbox--black-m-down & {
		@include media-query(l-down) {
			background: $color-black;
			color: $color-white;
			border-top: 1px solid $color-black-transparent-dark; // Fix the still existing subpixel? flicker
		}
	}

	.c-textbox--red-m-down & {
		@include media-query(l-down) {
			background: $color-brand-primary;
			color: $color-white;
			border-top: 1px solid $color-brand-primary; // Fix the still existing subpixel? flicker
		}
	}

	.c-textbox--white-m-down & {
		@include media-query(l-down) {
			background: $color-white;
			color: $color-black;
			border-top: 1px solid $color-white-transparent-dark; // Fix the still existing subpixel? flicker
		}
	}

	// Full height, fixed Textbox

	.c-textbox--fixed & {
		position: fixed;
		right: 0;
	}
}

// fixex height for mobile slider

.c-textbox__item--fixed-height {
	@include media-query(l-down) {
		height: $mobile-textbox-height;
		overflow: hidden;
	}
}

.c-textbox__image {
	width: 100%;

	// todo: Bildgröße z.B. über Mediaformat entsprechende Klassen? (statt transparentes Bild)
	//@include aspect-ratio(200, 57);
	//
	//@include media-query(m-down) {
	//    @include aspect-ratio(72, 61);
	//}
	//img {
	//    position: absolute;
	//    left: 0;
	//    top:0;
	//}
}

// Hide the main Container on Mobile (ex. Map Image for arrival teaser)

.c-textbox-hide-main-m-down {
	@include media-query(m-down) {
		.c-textbox__image {
			display: none;
		}
	}
}
