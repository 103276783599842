/* ------------------------------------*\
 #  Login for Omni-Channel
 #  My Account in Classic and Shop
 #  Next Step Page
 #  Register Confirmation Page
\*------------------------------------ */

$login-next-step-icon-size: 80px;
$login-next-step-icon-size-m-down: 60px;
$login-next-step-block-pl: 25px;
$login-next-step-provider-border: 1px solid $color-gray-dark;
$login-next-step-provider-img-height: 100%;
$login-next-step-provider-img-width: auto;

.c-login-next-step__icon {
	display: inline-block;
	vertical-align: middle;
	width: $login-next-step-icon-size;
	height: $login-next-step-icon-size;

	@include media-query("m-down") {
		width: $login-next-step-icon-size-m-down;
		height: $login-next-step-icon-size-m-down;
	}
}

.c-login-next-step__block {
	display: inline-block;
	vertical-align: middle;
	font-weight: $font-weight-bold;
	padding-left: $login-next-step-block-pl;
}

.c-login-next-step__provider {
	display: inline-block;
	padding: $spacing-unit/3;
	vertical-align: middle;
	border: $login-next-step-provider-border;
	width: 100%;
	text-align: center;
}

.c-login-next-step__provider-img {
	height: $login-next-step-provider-img-height;
	width: $login-next-step-provider-img-width;
}

.c-login-next-step__text {
	font-size: $inuit-heading-size-5;

	@include media-query("m-down") {
		font-size: $inuit-heading-size-6;
	}
}

.c-login-next-step__info {
	font-weight: $font-weight-normal;
}
