/* ------------------------------------*\
    #ALL BRANDS PAGE / BRAND LIST A-Z
\*------------------------------------ */

$brandlist-brand-fontsize: $inuit-heading-size-6;
$brandlist-brand-lineheight: 26px;
$brandlist-row-padding-m-up: $spacing-unit 0;
$brandlist-row-padding-m-up: $spacing-unit 0;

$brandlist-row-active-offset-bottom: 0;
$brandlist-row-active-width: 35px;
$brandlist-row-active-offset: -60%;

.c-brandlist {
	@include media-query(m-down) {
		padding: 0;
	}
}

.c-brandlist__row {
	border-bottom: 1px solid $color-gray-light;

	&.is-disabled {
		display: none;
	}

	@include media-query(m-down) {
		border-bottom: none;

		.c-brandlist.is-activeSearch & {
			padding: 0;
			line-height: 0; // if it has no Content on Search
			border-bottom: none;
		}
	}

	@include media-query(m-up) {
		display: table;
		width: 100%;
		padding: $brandlist-row-padding-m-up;
	}
}

.c-brandlist__letter-wrapper {
	@include media-query(m-down) {
		border-bottom: 1px solid $color-gray-light;

		.c-brandlist.is-activeSearch & {
			display: none;
		}
	}

	@include media-query(m-up) {
		display: table-cell;
		vertical-align: middle;
	}
}

.c-brandlist__letter {
	@include font-size($heading-secondary-font-size-alpha);

	padding-right: $inuit-base-spacing-unit--tiny;
	font-weight: $font-weight-bold;
	text-align: center;
	margin-bottom: 0;

	@include media-query(m-down) {
		padding-right: 0;
		text-align: center;
	}

	&.is-active {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			width: $brandlist-row-active-width;
			height: 1px;
			bottom: $brandlist-row-active-offset-bottom;
			left: 50%;
			transform: translateX($brandlist-row-active-offset);
			border-bottom: 2px solid $color-black;
		}
	}

	&.is-filtered {
		color: $color-classic-primary;

		&::before {
			border-bottom-color: $color-classic-primary;
		}
	}
}

.c-brandlist__brand {
	@include font-size($brandlist-brand-fontsize, $brandlist-brand-lineheight);

	a {
		font-weight: normal;
	}

	&.not-active {
		a {
			color: $color-gray-light;
		}
	}

	@include media-query(m-down) {
		@include font-size($u-fontsize, $brandlist-brand-lineheight);

		border-bottom: 1px solid $color-gray-dark;

		a {
			display: block;
			padding: 10px 0;
		}

		&.is-notInResult {
			display: none;
		}
	}
}

.c-brandlist__item {
	@include media-query(m-up) {
		display: table-cell;
		vertical-align: middle;
	}
}

.c-brandlist__brand--luxury {
	a {
		color: var(--color-black);
	}
}

.c-brandlist__new-badge {
	display: inline-block;
	color: $color-white;
	background: var(--color-black-primary);

	@include font-size(11px, 18px);

	padding: 0 8px;
	margin-left: 2px;
}

.c-brandlist__soon-badge {
	display: inline-block;
	color: $color-white;
	background: $color-black;

	@include font-size(11px, 18px);

	padding: 0 5px;
	margin-left: 2px;

	.t-classic & {
		color: $color-black;
		background: $color-classic-primary;
	}
}
