.svg-icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

.flex-icon {
	flex: 0 0 auto;
}

.icon-heart_contour,
.svg-icon-heart_contour {
	color: #fff;

	&.is-active {
		color: #000;
	}
}
