/* ------------------------------------*\
    #GLOBAL STYLES OF ELEMENTS
\*------------------------------------ */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

/* TUB-14518 remove tap highlight color on iOS <= 14 */
@supports not (aspect-ratio: auto) {
	button,
	input,
	a {
		-webkit-tap-highlight-color: transparent;
	}
}

:where(a, button, dialog, [role="dialog"], input[type="checkbox"]):focus-visible {
	outline: 2px solid var(--color-black-primary);
	outline-offset: 2px;
}

/* revert Tailwind outline style for Firefox */
.tw :where(a, button, dialog, [role="dialog"], input[type="checkbox"]):-moz-focusring {
	outline: 2px solid var(--color-black-primary);
}

/* revert inuit generic styles */
:where(a):focus-visible:is(:hover, :active) {
	outline: 2px solid var(--color-black-primary);
}

.tw :where(button):disabled,
.tw :where(button)[aria-disabled="true"] {
	cursor: not-allowed;
}
