/* ------------------------------------*\
    #MAIN FOOTER
\*------------------------------------ */

$fullwidthiconLink_width: 127px;
$fullwidthiconLink_height: 38px;

.page-ContactPage {
	// classic Contact Page with reduced footer (TUB-22262)
	// show only small footer, position always at the bottom
	.c-offcanvas-innerwrapper {
		min-height: 100vh;
		position: relative;
		padding-bottom: 70px;
	}
	.c-main-content {
		min-height: unset;
	}
	.c-main-footer {
		display: none;
	}
	.c-footer-small {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
.c-main-footer {
	background-color: var(--color-gray-20);
	color: var(--color-black);

	@include media-query(l-up) {
		padding-top: $space-l;
	}

	li > a {
		font-weight: normal;
	}
	// nur classic
	.language-ar & {
		direction: rtl;
	}
}

//wrapper für main footer mit Seitenrand (o-page-wrap)
.c-main-footer__inner {
	@include media-query(l-up) {
		width: 100%;
		table-layout: fixed;
		display: flex;
		flex-wrap: wrap;
	}

	@include media-query(l-down) {
		padding: 0;
	}
}

// einzelne Blöcke / columns
.c-main-footer__column {
	position: relative;

	@include media-query(l-up) {
		display: table-cell;
		padding-right: $space-m;
		width: 33.3%;
	}

	@include media-query(xxl-up) {
		width: 25%;
	}
}

// section
.c-main-footer__column .c-main-footer__column-section {
	@include media-query(l-up) {
		margin-bottom: $spacing-base-unit * 10;
	}
}

.c-main-footer__column:not(:last-child) .c-main-footer__column-section,
.c-main-footer__column:last-child .c-main-footer__column-section:not(:last-child) {
	@include media-query(l-down) {
		border-bottom: 1px solid var(--color-gray-60);
	}
}

.c-main-footer__column-content {
	@include media-query(l-down) {
		padding: 0 $space-m $space-m $space-m;
	}

	// hide accordion content (section) / desktop and mobile
	&.c-main-footer__is-accordion {
		display: none;
	}

	// hide Desktop Content on mobile if accordion is active
	&.c-main-footer__desktop-content {
		@include media-query(l-down) {
			display: none;
		}
	}
}

.c-main-footer__headline {
	@extend .ocm-headline4;

	margin-bottom: $space-m;
	// nur in classic
	.language-ar & {
		@include media-query(l-down) {
			padding-right: $space-xl;
		}
	}

	@include media-query(l-down) {
		margin-bottom: 0;
		width: 100%;
		padding: $space-m;
	}
}

.c-main-footer__toggle-button {
	width: 100%;
	position: absolute;
	top: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	height: 22px + 32px;
	padding: 0;
	outline-offset: -2px;

	&::after {
		content: $icon-font--arrow-down-big;
		top: $space-m;
		position: absolute;
		right: $space-m;
		vertical-align: middle;
		@include icon-font($spacing-base-unit * 5, $spacing-base-unit * 5.5);
		@include ui-transition();

		-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
		transform: rotate(0deg);
	}
	&.is-open::after {
		@include ui-transition();

		-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
		transform: rotate(180deg);
	}

	@include media-query(l-up) {
		display: none;
	}
}

.c-main-footer__column ul {
	margin: 0;
	padding: 0;
	list-style: none;

	&.iconList {
		display: flex;
		flex-wrap: wrap;
		gap: $space-s;
	}

	&.textList li {
		margin-bottom: $space-xs;
	}
}

.c-main-footer__column li.iconLink > a,
.c-main-footer__column li.fullwidthiconLink > a {
	display: flex;
	border-radius: $space-s;
	width: $fullwidthiconLink_width;
	height: $fullwidthiconLink_height;
	overflow: hidden;

	img {
		max-height: 100%;
	}
}
.c-main-footer__column li.iconLink > a {
	display: flex;
	width: $space-xxl;
	height: $space-l;
	padding: $space-xs $spacing-base-unit * 1.5;
	text-align: center;
	align-items: center;
	justify-content: center;
	background-color: var(--color-white);

	img {
		max-height: 100%;
	}
}

//legacy classic / Content für Adresse / CMS Text-Content in mehrere Sprachen
h4.c-main-footer__headline.closing {
	@include media-query(l-down) {
		cursor: pointer;

		&::after {
			content: $icon-font--arrow-down-big;
			position: absolute;
			right: $space-m;
			vertical-align: middle;
			@include icon-font($spacing-base-unit * 5, $spacing-base-unit * 5.5);
			@include ui-transition();

			-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
			transform: rotate(0deg);
		}
		&.is-open::after {
			@include ui-transition();

			-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
			transform: rotate(180deg);
		}

		& + .c-main-footer__column-content {
			@include media-query(l-down) {
				display: none;
			}
		}
	}
}
