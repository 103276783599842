/* ------------------------------------*\
    #ERROR
\*------------------------------------ */
$error-icon-size: $inuit-heading-size-6;
$error-size: 13px;

.u-error {
	background-color: $color-error-bg !important;
	border: 1px solid $color-error !important;
}

.u-error-text {
	position: relative;
	display: block;
	margin-top: $spacing-unit--small;
	color: $color-error;
	font-weight: 500;
	padding-left: 28px;

	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		display: block;
		content: " ";
		background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-error-red' viewBox='0 0 134 134'%3E%3Cpath fill='%23cb172c' d='M66.667 0C29.917 0 0 29.917 0 66.667s29.917 66.666 66.667 66.666 66.666-29.916 66.666-66.666S103.417 0 66.667 0Zm0 125C34.5 125 8.333 98.833 8.333 66.667 8.333 34.5 34.5 8.333 66.667 8.333 98.833 8.333 125 34.5 125 66.667 125 98.833 98.833 125 66.667 125Z'/%3E%3Cpath fill='%23cb172c' d='m61.017 29.167 2.3 48.4h7.2l2.3-48.4h-11.8Zm-.6 64.6c0 3.6 2.9 6.4 6.5 6.4 3.5 0 6.4-2.8 6.4-6.4 0-3.5-2.9-6.4-6.4-6.4-3.6 0-6.5 2.9-6.5 6.4Z'/%3E%3C/svg%3E");
		height: 18px;
		width: 18px;
	}

	&--multiline::before {
		top: 2px;
		transform: none;
	}

	&:empty {
		display: none;
	}
}

// .c-dropdown ist select in liste dargestellt
.u-error + .c-dropdown .c-dropdown__label {
	background-color: $color-error-bg;
	border-color: $color-error;
}
