/* ------------------------------------*\
    #FILTER
\*------------------------------------ */

$filter-pos-t: $gl_header-fixed-height;
$filter-translate-pos-t: 2 * $gl_header-fixed-height;

$filter-border-color: $color-gray-dark;

$filter-height: 40px;
$filter-height-m: 50px;
$filter-line-height: $filter-height;
$filter-line-height-m: $filter-height-m;

$filter-headline-pv: 25px;
$filter-headline-pv-m: 12px;
$filter-headline-pl-m: 6px;
$filter-headline-icon-fonts-size: 10px;
$filter-headline-icon-fonts-size-m-down: 8px;
$filter-headline-icon-fonts-size-m: 10px;
$filter-headline-icon-pl: 10px;
$filter-headline-icon-width-m: 30px;
$filter-headline-padding-right: 30px; // needs to be 30px for iPhone SE and co.

$filter-reset-button-font-size: 10px;
$filter-reset-button-pr: 10px;

$filter-box-pos-t: $filter-height;
$filter-box-wrapper-max-height: 164px;
$filter-box-wrapper-category-max-height: 218px;
$filter-box-wrapper-mb: $spacing-unit;
$filter-box-wrapper-category-mb: $spacing-unit;
$filter-box-wrapper-mt-m-down: 20px;
$filter-box-wrapper-pv: 20px;
$filter-box-wrapper-ph: 15px;
$filter-box-width: 264px;
$filter-box-list-item-mb: 12px;
$filter-box-list-item-mb--size: 10px;
$filter-box-list-item-mr--size: 10px;

$filter-bottom-height: 50px;
$filter-bottom-line-height: 28px;
$filter-bottom-pv: 10px;
$filter-bottom-label-min-width: 110px;

$filter-bottom-selection-wrapper-width: 680px;
$filter-bottom-selection-width: $filter-bottom-selection-wrapper-width;

$filter-bottom-selection-height: 32px;
$filter-bottom-reset-min-width: 135px;
$filter-bottom-reset-pl: 4px;
$filter-bottom-selection-arrow-pl: 6px;
$filter-bottom-selection-arrow-pr: 6px;
$filter-selected-item-p: 0 10px;
$filter-filter-reset-p: 0 10px 0 0;
$filter-selected-item-mr: 10px;

$filter-top-mt: 5px;
$filter-top-mb: 5px;

$filter-scrolled-top: -70px;

$filter-icon-arrow-font-size: 16px;
$filter-icon-arrow-ml: $filter-icon-arrow-font-size;
$filter-icon-arrow-pos-b: -13px;
$filter-icon-arrow-pos-l: 50%;

$filter-brand-search-mt: -21px;
$filter-brand-search-ml: -16px;
$filter-brand-search-mr: -16px;

$filter-box-offcanvas-nav-transition-speed: 0.3s;

/**
 * Styling product list filter.
 */

.c-filter {
	position: absolute;
	z-index: z("filter");
	left: 0;
	width: 100%;

	@include media-query(m-up) {
		&.is-fixed {
			width: 100%;
			z-index: z("filter");

			// Header-Animation
			@include sticky-positioning($filter-pos-t, $filter-translate-pos-t);

			background-color: $color-white;
		}
	}

	@include media-query(m-up) {
		&.is-fixed {
			border-bottom: 1px solid $filter-border-color;
		}
	}

	@include media-query(m-down) {
		border-bottom: 0;
		margin-top: $filter-box-wrapper-mt-m-down;
		height: $filter-height;

		&.is-scrollup {
			position: fixed;
			top: $filter-scrolled-top;
			height: $filter-height-m;
			width: 100%;
			z-index: z("filter");
			transform: translate(0, $filter-translate-pos-t);
			transition: transform 0.25s ease-out 0s;
			background-color: $color-white;

			.c-filter__top {
				margin-bottom: $filter-top-mb;
				margin-top: $filter-top-mt;
				left: 0;
				transition: left $filter-box-offcanvas-nav-transition-speed; //for offcanvas
			}
		}
	}
}

.c-filter__top {
	position: relative;
	z-index: z("filter-top");
}

.c-filter__content {
	@include media-query(m-up) {
		display: none;
		position: relative;
		border-bottom: 1px solid $filter-border-color;

		.is-fixed & {
			border-bottom: 0;
		}

		&::after {
			left: 100%;

			.is-fixed & {
				content: "";
				position: absolute;
				width: 3000px;
				background-color: $color-white;
			}
		}
	}
}

/**
* Filter bottom bar. Selected filter items underneath the filter bar.
*/
.c-filter__bottom {
	display: none;

	@include font-size($u-fontsize, $filter-bottom-line-height);

	padding: $filter-bottom-pv 0;
	height: $filter-bottom-height;

	.is-fixed & {
		border-top: 1px solid $color-gray-dark;
	}
}

.c-filter__bottom-label {
	min-width: $filter-bottom-label-min-width;
}

.c-filter__selection-arrow {
	display: inline-block;
	cursor: pointer;

	&::after {
		@include icon-font($u-fontsize);
	}

	&.c-filter__selection-arrow-left::after {
		content: $icon-font--arrow-left-big;
		padding-right: $filter-bottom-selection-arrow-pr;
		padding-left: $filter-bottom-selection-arrow-pl;
	}

	&.c-filter__selection-arrow-right::after {
		content: $icon-font--arrow-right-big;
		padding-right: $filter-bottom-selection-arrow-pr;
		padding-left: $filter-bottom-selection-arrow-pl;
	}
}

.c-filter__selection-content {
	display: inline-block;
	position: relative;
	min-width: $filter-bottom-selection-wrapper-width;
	height: $filter-bottom-selection-height;
	vertical-align: middle;
}

.c-filter__selection-wrapper {
	position: absolute;
	top: 0;
	height: $filter-bottom-selection-height;
	min-width: $filter-bottom-selection-wrapper-width;
	overflow: hidden;
	white-space: nowrap;
}

.c-filter__selection {
	position: absolute;
	width: $filter-bottom-selection-width;
	overflow: hidden;
}

.c-filter__selection-list {
	@include media-query(m-up) {
		position: relative;
		float: left;
	}
}

.c-filter__selected-item {
	position: relative;
	display: inline-block;
	margin-right: $filter-selected-item-mr;
	padding: $filter-selected-item-p;
	padding-right: $spacing-unit;
	border: 1px solid $filter-border-color;
	background-color: $color-white;
	cursor: pointer;

	&::after {
		position: absolute;
		content: $icon-font--close;
		padding-left: 4px;

		@include icon-font($filter-headline-icon-fonts-size-m-down);

		line-height: $filter-bottom-line-height;
		vertical-align: middle;
	}

	// in order to avoid click if last selected item is visible
	&:last-child {
		margin-right: 1px;
	}

	@include media-query(m-down) {
		display: block;
		margin-right: -1px;
		height: $filter-height-m;
		line-height: $filter-height-m;
		border-top: 0;
		border-left: none;
		border-right: none;
		background-color: $color-gray-lighter;

		&::after {
			float: right;

			@include icon-font($filter-headline-icon-fonts-size-m);

			line-height: $filter-height-m;
		}
	}
}

.c-filter__bottom-reset {
	min-width: $filter-bottom-reset-min-width;
	padding: $filter-filter-reset-p;

	&::after {
		content: $icon-font--close;
		padding-left: $filter-bottom-reset-pl;

		@include icon-font($filter-headline-icon-fonts-size);

		line-height: $filter-bottom-line-height;
		vertical-align: middle;
	}
}

/**
* Styling filter headline items in top menu and sub menu
*/
.c-filter__headline {
	display: block;
	position: relative;
	height: $filter-height;

	@include font-size($u-fontsize, $filter-line-height);

	cursor: pointer;
	padding-right: $filter-headline-padding-right;
	white-space: nowrap;

	@include media-query(xl-up) {
		padding: 0 $filter-headline-pv;
	}

	@include media-query(l) {
		padding: 0 $filter-headline-pv 0 $spacing-unit--small;
	}

	&::after {
		content: $icon-font--arrow-down-big;
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		padding: 0 $spacing-unit--small;
		transform: rotate(0deg);

		@include ui-transition(0.5s);
		@include icon-font($filter-headline-icon-fonts-size);

		line-height: $filter-line-height;
		font-weight: $font-weight-bold;
		width: $filter-headline-icon-width-m;

		@include media-query(m-down) {
			width: auto;
		}
	}

	.c-filter__list--dynamic & {
		padding-left: $filter-headline-pl-m;
	}

	.c-filter__sort & {
		display: inline-block;
	}

	@include media-query(l-up) {
		.no-touchevents .c-filter__list > .c-filter__list-item:hover > &,
		.no-touchevents .c-filter__sort:hover &,
		.no-touchevents .c-category__content:hover & {
			&::after {
				@include ui-transition(0.5s);

				transform: rotate(180deg);
			}
		}
	}

	&.is-open {
		.c-filter__list--dynamic & {
			&::after {
				@include ui-transition();

				transform: rotate(180deg);
			}
		}
	}

	@media screen and (min-width: 1024px) and (orientation: landscape), (min-width: 1024px) and (orientation: portrait) {
		> &::after {
			.is-open & {
				@include ui-transition();

				transform: rotate(180deg);
			}
		}
	}

	@include media-query(l-down) {
		&.is-open {
			&::after {
				@include ui-transition();

				transform: rotate(180deg);
			}
		}
	}

	@include media-query(l-up) {
		&.is-open {
			&::after {
				@include ui-transition();

				transform: rotate(180deg);
			}
		}
	}

	@include media-query(m-down) {
		height: $filter-height-m;
		line-height: $filter-height-m;
		font-size: $u-fontsize - 1px;
	}

	@include media-query(l-down) {
		padding-left: $filter-headline-pl-m;

		.is-active & {
			font-weight: $font-weight-bold;
		}
	}

	&.c-filter__headline--sort {
		@include media-query(m) {
			display: inline-block;

			&.is-open::before {
				content: $icon-font--arrow-down-big;
				position: absolute;
				left: $filter-icon-arrow-pos-l;
				z-index: z("filter-arrow-down");
				bottom: $filter-icon-arrow-pos-b;
				margin-left: -$filter-icon-arrow-ml;

				@include icon-font($filter-icon-arrow-font-size);

				color: $filter-border-color;
				background-color: $color-white;
			}
		}
	}

	&.c-filter__headline--mobile {
		display: none;

		@include media-query(m) {
			display: inline-block;

			&.is-open::before {
				content: $icon-font--arrow-down-big;
				position: absolute;
				left: $filter-icon-arrow-pos-l;
				z-index: z("filter-arrow-down");
				bottom: $filter-icon-arrow-pos-b;
				margin-left: -$filter-icon-arrow-ml;

				@include icon-font($filter-icon-arrow-font-size);

				color: $filter-border-color;
				background-color: $color-white;
			}
		}

		@include media-query(m-down) {
			.c-category__content &,
			.c-filter__content & {
				display: block;
				height: $filter-height;
				line-height: $filter-height;
				border: 1px solid $filter-border-color;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	@include media-query(m-down) {
		&.is-open.c-filter__headline--mobile {
			border-bottom-width: 0 !important;
			height: $filter-height + $spacing-unit--small;
			background-color: $color-white;
			z-index: z(filter-box);
		}

		&.is-filter-set,
		&.is-filter-set.is-open.c-filter__headline--mobile {
			background-color: $color-gray-lighter;
		}
	}
}

/**
* Filter menu ul li.
*/
.c-filter__list {
	@extend .o-list-bare;

	display: inline-block;
	min-height: $filter-height; // Fix the case if no filter is available to not break the layout. TUB-3087

	@include media-query(l-down) {
		display: none;
		position: absolute;
		width: 100%;

		&.is-open {
			display: block;
		}
	}

	@include media-query(m-down) {
		left: 0;
		top: ($filter-height + $spacing-unit--small) -1;
		//border-top: 1px solid $color-gray-dark;
	}

	@include media-query(m) {
		top: $filter-height + 1px;
		width: $filter-box-width + 2px;
	}
}

.c-filter__list--dynamic {
	@extend .o-list-bare;

	.c-filter__list-item {
		display: block;
		width: $filter-box-width + 2px;
		border-left: 1px solid $filter-border-color;
		border-right: 1px solid $filter-border-color;
		border-bottom: 1px solid $filter-border-color;
		background-color: $color-white;
	}
}

.c-filter__dynamic-template {
	@include media-query(m-up) {
		display: inline-block;
	}

	@include media-query(xl-up) {
		padding: 0 $filter-headline-pv;

		.c-filter__list--dynamic & {
			padding: 0;
		}
	}

	@include media-query(m-down) {
		display: block;
		border-bottom: 1px solid $filter-border-color;
	}
}

.c-filter__list > .c-filter__dynamic-template > .c-filter__list-item {
	@include media-query(l-up) {
		&:hover > .c-filter__box {
			.no-touchevents & {
				display: block;
			}
		}
	}
}

.c-filter__list > .c-filter__list-item {
	position: relative;

	@include media-query(l) {
		&:nth-child(2) {
			//first element has to have padding-left, otherwise the arrow on hover will not be centered
			margin-left: -$filter-headline-pv-m;
		}
	}

	@include media-query(l-up) {
		display: inline-block;

		&:hover > .c-filter__box {
			.no-touchevents & {
				display: block;
			}
		}

		&:hover::after {
			.no-touchevents & {
				content: $icon-font--arrow-down;
				position: absolute;
				left: $filter-icon-arrow-pos-l;
				z-index: z("filter-arrow-down");
				bottom: $filter-icon-arrow-pos-b;
				margin-left: -$filter-icon-arrow-ml;

				@include icon-font($filter-icon-arrow-font-size);

				color: $filter-border-color;
				background-color: $color-white;
			}
		}
	}

	@media screen and (min-width: 1024px) and (orientation: landscape), (min-width: 1024px) and (orientation: portrait) {
		&.is-open {
			> .c-filter__box {
				display: block;
			}
		}

		&.is-open::after {
			content: $icon-font--arrow-down;
			position: absolute;
			left: $filter-icon-arrow-pos-l;
			z-index: z("filter-arrow-down");
			bottom: $filter-icon-arrow-pos-b;
			margin-left: -$filter-icon-arrow-ml;

			@include icon-font($filter-icon-arrow-font-size);

			color: $filter-border-color;
			background-color: $color-white;
		}
	}
}

.c-filter__list-item {
	@include media-query(l-down) {
		&.c-filter_list-item--dynamic {
			display: none;
		}
	}

	@include media-query(m) {
		display: block;
		width: $filter-box-width + 2px;
		border-left: 1px solid $filter-border-color;
		border-right: 1px solid $filter-border-color;
		border-bottom: 1px solid $filter-border-color;
		background-color: $color-white;
	}

	@include media-query(m-down) {
		display: block;
		border-left: none;
		border-bottom: 1px solid $filter-border-color;
		background-color: $color-white;

		&:last-child {
			border-bottom: 0;
		}
	}
}

/**
* Styling filter sort
*/
.c-filter__sort {
	@include media-query(l-up) {
		display: inline-block;
		position: relative;
		float: right;
	}

	@include media-query(m) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
	}

	@include media-query(m-down) {
		display: none;
	}

	@include media-query(l-up) {
		&:hover .c-filter__box {
			.no-touchevents & {
				display: block;
			}
		}

		&:hover::after {
			.no-touchevents & {
				content: $icon-font--arrow-down-big;
				position: absolute;
				left: $filter-icon-arrow-pos-l;
				z-index: z("filter-arrow-down");
				bottom: $filter-icon-arrow-pos-b;
				margin-left: -$filter-icon-arrow-ml;

				@include icon-font($filter-icon-arrow-font-size);

				color: $filter-border-color;
				background-color: $color-white;
			}
		}
	}

	@media screen and (min-width: 1024px) and (orientation: landscape), (min-width: 1024px) and (orientation: portrait) {
		&.is-open {
			.c-filter__box {
				display: block;
			}
		}

		&.is-open::after {
			content: $icon-font--arrow-down-big;
			position: absolute;
			left: $filter-icon-arrow-pos-l;
			z-index: z("filter-arrow-down");
			bottom: $filter-icon-arrow-pos-b;
			margin-left: -$filter-icon-arrow-ml;

			@include icon-font($filter-icon-arrow-font-size);

			color: $filter-border-color;
			background-color: $color-white;
		}
	}
}

/**
* Styling filter boxes
*/
.c-filter__box-wrapper {
	max-height: $filter-box-wrapper-max-height;
	margin-bottom: $filter-box-wrapper-mb;
	overflow: auto;
}

.c-filter__box--size .c-filter__box-wrapper,
.c-filter__box--color .c-filter__box-wrapper {
	max-height: $filter-box-wrapper-category-max-height;
	margin-bottom: $filter-box-wrapper-category-mb;
	overflow: auto;
}

.c-filter__box {
	display: none;
	width: $filter-box-width;
	padding: $filter-box-wrapper-pv $filter-box-wrapper-ph;
	background-color: $color-white;

	.c-filter__sort & {
		right: 0;

		@include media-query(m) {
			position: absolute;
			top: $filter-height;
			z-index: z("filter-box");
			border: 1px solid $filter-border-color;
		}
	}

	.c-filter__list--dynamic & {
		@include media-query(l-up) {
			position: relative;
			top: 0;
			z-index: z("filter-box");
			border: 0;
		}
	}

	@include media-query(l-up) {
		position: absolute;
		top: $filter-box-pos-t;
		z-index: z("filter-box");
		border: 1px solid $filter-border-color;
	}

	@include media-query(m-down) {
		position: relative;
		width: 100%;
		background-color: $color-gray-lighter;
		border-top: 1px solid $filter-border-color;
	}
}

.c-filter__box--sort {
	&.is-open {
		display: block;
	}
}

.c-filter__box--brand {
	@include media-query(m-down) {
		.c-search.c-search--small {
			height: $filter-height-m;
			margin-left: $filter-brand-search-ml;
			margin-right: $filter-brand-search-mr;
			margin-top: $filter-brand-search-mt;
			border-color: $filter-border-color;
		}

		.c-search.c-search--small::after {
			line-height: $filter-height-m;
		}
	}
}

.c-filter__box--dynamic {
	padding: 0;
	border: 0;
	border-top: 1px solid $filter-border-color;
}

.c-filter__box-list {
	padding-right: $spacing-unit--small;
}

.c-filter__box-list-item {
	display: flex;
	cursor: pointer;
	margin-bottom: $filter-box-list-item-mb;

	> *:not(:first-child) {
		margin-left: 9px;
	}

	& label {
		cursor: pointer;
	}

	&:last-child {
		margin-bottom: 0;
	}

	a {
		display: block;
	}

	.c-filter__box--size & {
		display: inline-block;
		margin-bottom: $filter-box-list-item-mb--size;
		margin-right: $filter-box-list-item-mr--size;
		background-color: $color-white;

		&:nth-child(6n + 1):nth-last-child(-n + 6),
		&:nth-child(6n + 1):nth-last-child(-n + 6) ~ li {
			margin-bottom: 0;
		}
	}

	.c-filter__box--sort & {
		a.is-selected {
			font-weight: $font-weight-bold;
		}
	}
}

/**
* Styling reset button for filter boxes
*/
.c-filter__reset {
	.svg-icon {
		margin-right: 9px;
		width: 18px;
		height: 18px;
	}
}

.c-filter-wrapper-scroll {
	@include media-query(l-down) {
		&.is-open {
			.c-filter__list {
				display: block;
			}
		}
	}

	@include media-query(m-down) {
		position: absolute;
		left: 0;
		display: none;
		width: 100%;
		margin-top: -1px; //magic number
		border: 1px solid $color-gray-dark;

		&.is-open {
			display: block;
			background-color: white;

			.c-filter__list {
				display: block;
				position: static;
			}

			.c-filter-nav-height {
				max-height: 61vh;
				overflow: auto;
				overflow-x: hidden !important;
				z-index: 52;
				height: auto;
				-webkit-overflow-scrolling: touch !important;

				.scrollbar-inner > .scroll-element.scroll-x {
					display: none !important;
				}
			}
		}
	}
}
