/* ------------------------------------*\
    #CLASSIC SLIDER DOTS
\*------------------------------------ */

$classic-teaser-button-size: $inuit-base-font-size;
$classic-teaser-button-color: $color-classic-primary;

.c-classic-slider-dots {
	.slick-dots {
		z-index: 100;
		bottom: 0;
		padding-bottom: $spacing-unit--small;
		width: 100%;
		height: 33px;
		left: -50%;
		transform: translateX(50%);

		li:first-child {
			margin-left: 0;
		}

		li:last-child {
			margin-right: 0;
		}

		li button::before {
			height: $classic-teaser-button-size;
			width: $classic-teaser-button-size;
			border-radius: $classic-teaser-button-size;
			border: 1px solid $classic-teaser-button-color;
			background: transparent;
		}

		li.slick-active button::before {
			color: $classic-teaser-button-color;
			background: $classic-teaser-button-color;
		}
	}
}
