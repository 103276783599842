/* ------------------------------------*\
    #LOADER
\*------------------------------------ */
$loader-box-min-width: 80px;
$loader-background-size: 42px 42px;

.c-loader--product-list-infinite {
	width: 100%;
	min-width: $loader-box-min-width;
	height: 50px;
	opacity: 0;
	transition: opacity 1s;

	&.loading {
		opacity: 1;
		background: url(/_ui/responsive/common/images/loader/loading.gif) center no-repeat;
		background-size: 50px 50px;
	}

	&.loading--small {
		opacity: 1;
		background: url(/_ui/responsive/common/images/loader/loading.gif) center no-repeat;
		background-size: 40px 40px;
	}
}

.c-loader-inline {
	width: 100%;
	min-width: $loader-box-min-width;
	height: 100px;
	opacity: 1;
	background: url(/_ui/responsive/common/images/loader/loading.gif) center no-repeat;
	background-size: 50px 50px;
	margin-bottom: 30%;
}

.c-loader {
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	background: rgba(242, 242, 242, 0.6);
	z-index: z("loader");
}

.c-loader-background {
	z-index: 2;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url(/_ui/responsive/common/images/loader/loading.gif) center center no-repeat;

	&--top {
		background-position: top center;
	}

	@include media-query(m-down) {
		background-size: $loader-background-size;
	}
}
