/* ------------------------------------*\
    #CLASSIC SCROLLBAR
\*------------------------------------ */

.t-classic,
.c-seo-image,
.c-text-modul {
	.scrollbar-inner > .scroll-element .scroll-bar {
		background: $color-classic-primary;
	}
}
