/* ------------------------------------*\
    # Background-image
      with Textcontainer #
\*------------------------------------ */

.c-bg-img {
	height: 100%;
	position: relative;
}

.c-bg-img__container--left {
	top: 50%;
	left: $spacing-unit;
	transform: translateY(-50%);
}

.c-bg-img__container--middle {
	top: 50%;
	left: 50%;
	margin-left: $spacing-unit;
	transform: translate(-50%, -50%);
}

.c-bg-img__container--right {
	top: 50%;
	right: $spacing-unit;
	transform: translateY(-50%);
}

.c-bg-img__container {
	background-color: $color-white;
	max-width: 50%;
	position: absolute;

	@include media-query(l-down) {
		max-width: 100%;
		position: relative;
		transform: translate(0);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
