/* ------------------------------------*\
    #CONTENT-HOTEL
\*------------------------------------ */

$classic-headline-large-responsive: $heading-classic-secondary-font-size-delta;

.c-content-hotel {
	@include media-query(l-down) {
		display: table;
	}

	@include media-query(m-down) {
		display: block;
		margin-left: 0;
	}

	p {
		margin-bottom: $spacing-unit--small;
	}
}

.c-content-hotel__stars {
	color: $color-classic-primary;

	.c-icon {
		font-size: 16px;
		position: static;
	}
}

.c-content-hotel__item {
	border-bottom: 1px solid rgba($color-gray-lighter, 0.2);
	width: 100%;
	margin-bottom: $spacing-unit--small;
	padding-left: 0;

	&:last-child {
		border-bottom: none;
	}

	@include media-query(l-down) {
		display: table-cell;
		border-right: 1px solid rgba($color-gray-lighter, 0.2);
		border-bottom: none;
		padding: 0 $spacing-unit;
		width: 33.333%;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			border-right: none;
		}
	}

	@include media-query(m-down) {
		width: 100%;
		display: block;
		padding: 0;
		border-right: none;
		border-bottom: 1px solid rgba($color-gray-lighter, 0.2);
		margin-bottom: $spacing-unit--small;
		margin-left: 0;
	}

	.c-classic-headline--large {
		@include media-query(xl-down) {
			font-size: $classic-headline-large-responsive;
		}
	}
}
