/* ------------------------------------*\
    #ANIMATION
\*------------------------------------ */
$translate-y: 400px;
$translate-left-x: -100%;
$animate-timing-from: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$animate-timing-to: cubic-bezier(0.175, 0.885, 0.32, 1);

//zoomOutDown
.o-zoomOutDown {
	-webkit-animation-name: zoomOutDown;
	animation-name: zoomOutDown;
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes zoomOutDown {
	40% {
		opacity: 0.4;
		-webkit-transform: scale3d(0.575, 0.575, 0.575) translate3d(0, -80px, 0);
		transform: scale3d(0.575, 0.575, 0.575) translate3d(0, -80px, 0);
		-webkit-animation-timing-function: $animate-timing-from;
		animation-timing-function: $animate-timing-from;
	}

	100% {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, $translate-y, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, $translate-y, 0);
		-webkit-transform-origin: center bottom;
		transform-origin: center bottom;
		-webkit-animation-timing-function: $animate-timing-to;
		animation-timing-function: $animate-timing-to;
	}
}

//ZoomInUp
.o-zoomInUp {
	-webkit-animation-name: zoomInUp;
	animation-name: zoomInUp;
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.o-zoomInUp.o-zoomInUp--Left {
	-webkit-animation-name: zoomInUp-left;
	animation-name: zoomInUp-left;
}

@keyframes zoomInUp {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, $translate-y, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, $translate-y, 0);
		-webkit-animation-timing-function: $animate-timing-from;
		animation-timing-function: $animate-timing-from;
	}

	60% {
		opacity: 0.7;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -50px, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -50px, 0);
		-webkit-animation-timing-function: $animate-timing-to;
		animation-timing-function: $animate-timing-to;
	}
}
