/* ------------------------------------*\
    #BLOGGER TEASER
\*------------------------------------ */

$blogger-teaser-max-height: 400px;

$blogger-teaser-list-max-width: 365px;

$blogger-teaser-quote-wrapper-pv: ($spacing-unit * 8) 0;
$blogger-teaser-quote-wrapper-pv-tab: 50% 0; //TABLET
$blogger-teaser-quote-wrapper-pv-mob: 20% 0; //MOBILE
$blogger-teaser-quote-wrapper-mr: ($spacing-unit / 2);

$blogger-teaser-image-pos-l: ($spacing-unit / 2);
$blogger-teaser-image-pos-r: ($spacing-unit / 2);
$blogger-teaser-image-pos-l-mob: ($spacing-unit * 2); //MOBILE
$blogger-teaser-image-pos-t-mob: ($spacing-unit / 2); //MOBILE

$blogger-border-width: 1px;
$blogger-border-color: rgba($color-classic-primary, 0.4);
$blogger-border-style: solid;

$blogger-author-mt: ($spacing-unit / 2);

$blogger-quote-font-size: $inuit-heading-size-2;
$blogger-quote-font-size-tab: $inuit-heading-size-3; //TABLET
$blogger-quote-font-size-mob: $inuit-heading-size-5; //MOBILE

$blogger-quote-font-weight: $font-weight-medium;

.c-blogger-teaser {
	@include aspect-ratio(673, 400);

	width: 100%;
	border: $blogger-border-width $blogger-border-style $blogger-border-color;
	position: relative;
	max-height: 400px;

	@include media-query(m-down) {
		overflow: hidden;
		border-left: none;
		border-right: none;
	}
}

.c-blogger-teaser__img {
	position: absolute;
	bottom: 0;

	@include media-query(m-down) {
		position: relative;

		img {
			position: absolute;
			bottom: 0;
		}
	}

	@include media-query(xl-down) {
		width: 50%;
	}

	@include media-query(m-down) {
		width: 35%;
		top: $blogger-teaser-image-pos-t-mob;
	}
}

.c-blogger-teaser__img--left {
	left: $blogger-teaser-image-pos-l;

	@include media-query(m-down) {
		left: -$blogger-teaser-image-pos-l-mob;
	}
}

.c-blogger-teaser__img--right {
	right: $blogger-teaser-image-pos-r;

	@include media-query(m-down) {
		right: -$blogger-teaser-image-pos-l-mob;
	}
}

.c-blogger-teaser__quote-wrapper {
	position: absolute;
	width: 50%;
	text-align: center;
	// padding: $blogger-teaser-quote-wrapper-pv;
	top: 50%;
	transform: translateY(-50%);
	margin-right: $blogger-teaser-quote-wrapper-mr;
	display: block;

	@include media-query(xl-down) {
		// padding: $blogger-teaser-quote-wrapper-pv-tab;
		margin-right: 0;
	}

	@include media-query(l-down) {
		// padding: $blogger-teaser-quote-wrapper-pv-mob;
		width: 65% !important;
	}

	@include media-query(m-down) {
		// padding: $blogger-teaser-quote-wrapper-pv-mob;
		width: 65% !important;
	}
}

.c-blogger-teaser__quote-wrapper--left {
	left: 0;

	@include media-query(m-down) {
		position: relative;
		left: 0;
	}
}

.c-blogger-teaser__quote-wrapper--right {
	right: 0;

	@include media-query(m-down) {
		right: 0;
		padding: $spacing-unit--small;
	}
}

.c-blogger-teaser__list {
	max-width: $blogger-teaser-list-max-width;
	margin: 0 auto;
}

.c-blogger-teaser__quote {
	font-size: $blogger-quote-font-size;
	font-weight: $blogger-quote-font-weight;

	@include media-query(xl-down) {
		font-size: $blogger-quote-font-size-tab;
	}

	@include media-query(m-down) {
		font-size: $blogger-quote-font-size-mob;
	}
}

.c-blogger-teaser__author {
	margin-top: $blogger-author-mt;
}
