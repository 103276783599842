/* ------------------------------------*\
    #SEO TEASER
\*------------------------------------ */

$seo-teaser-mt: ($inuit-base-spacing-unit) * 2;
$seo-teaser-mb: ($inuit-base-spacing-unit) * 2;

$seo-quote-font-size: $inuit-heading-size-2 !default;
$seo-quote-line-height: 32px;
$seo-quote-color: $color-classic-primary !default;
$seo-quote-font-weight: $font-weight-medium;
$seo-quote-border: 1px solid rgba($color-classic-primary, 0.4);

$seo-author-font-size: $inuit-base-font-size !default;
$seo-author-color: $color-black !default;
$seo-author-font-weight: normal;
$seo-author-padding: ($inuit-base-spacing-unit) / 2;

$seo-list-padding: ($inuit-base-spacing-unit) * 2;
$seo-list-margin: ($inuit-base-spacing-unit) / 2;
$seo-list-item-margin: $inuit-base-spacing-unit;
$seo-list-width: 340px;

$seo-text-padding: ($inuit-base-spacing-unit) * 3 !default;
$seo-text-margin: $seo-list-margin !default;

$seo-text-mobile-padding: $inuit-base-spacing-unit;

$container-height: 200px;

.c-seo-teaser {
	display: flex;

	h5 {
		margin: 0;
		font-weight: $font-weight-medium;
	}

	//classic arabic
	.language-ar & {
		direction: rtl;
	}
}

.c-seo-teaser--text {
	margin-top: $seo-teaser-mt;
	margin-bottom: $seo-teaser-mb;
	display: block;
	max-height: ($container-height / 3);
	overflow: hidden;

	@include ui-transition();

	&.is-active {
		max-height: 3000px;
		padding-right: 0;

		@include ui-transition();
	}
}

.c-seo-teaser__quote {
	height: 100%;
	padding-right: $spacing-unit;
	font-size: $seo-quote-font-size;
	line-height: $seo-quote-line-height;
	color: $seo-quote-color;
	font-weight: $seo-quote-font-weight;
	border-right: $seo-quote-border;

	@include media-query(xl-down) {
		border: none;
	}

	//classic arabic
	.language-ar & {
		border-right: 0;
		border-left: $seo-quote-border;
		padding-right: 0;
		padding-left: $spacing-unit;
	}
}

.c-seo-teaser__author {
	padding-top: $seo-author-padding;
	font-size: $seo-author-font-size;
	color: $seo-author-color;
	font-weight: $seo-author-font-weight;
}

.c-seo-teaser__list {
	padding-left: $seo-list-padding;
	margin-top: $seo-list-margin;
	// set width for word-wrap
	width: $seo-list-width;
	word-wrap: break-word;

	li + li {
		margin-top: $seo-list-item-margin;
	}
}

.c-seo-teaser__text {
	margin-top: $seo-text-margin;
	max-height: ($container-height / 3);
	overflow: hidden;
	margin-bottom: $seo-teaser-mb;

	@include ui-transition();

	@include media-query(xl-down) {
		padding-left: $seo-text-mobile-padding;
	}

	&.is-active {
		max-height: 3000px;
		padding-right: 0;

		@include ui-transition();
	}
}
