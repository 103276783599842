/* ------------------------------------*\
    #IMAGE TEASER  // TRANSPARENT
\*------------------------------------ */

$img-teaser-transparent-teaser-height: 400px;
$img-teaser-transparent-teaser-mobile-height: 250px;

$img-teaser-transparent-teaser-background: url("../../images/dummy/ocm-couple.jpg") center center;
$img-teaser-transparent-teaser-background-repeat: no-repeat;

.c-img-teaser-transparent {
	width: 100%;
	height: $img-teaser-transparent-teaser-height;
	background: $img-teaser-transparent-teaser-background;
	background-size: cover;
	background-repeat: $img-teaser-transparent-teaser-background-repeat;
	position: relative;

	@include media-query(m-down) {
		height: $img-teaser-transparent-teaser-mobile-height;
	}
}

.c-img-teaser-transparent__text-box {
	padding-top: $spacing-unit * 3;
	padding-left: $spacing-unit;
	padding-right: $spacing-unit;
	width: 100%;
	height: $img-teaser-transparent-teaser-height;
	background: rgba($color-black, 0.8);
	color: $color-white !important;

	@include media-query(m-down) {
		height: auto;
		position: absolute;
		top: 100%;
		left: 0;
		padding-top: $spacing-unit;
		padding-right: $spacing-unit;
		padding-bottom: 18px;
		background: $color-black;
	}
}

.c-img-teaser-transparent__headline {
	font-size: $heading-secondary-font-size-beta;
}

.c-img-teaser-transparent__text {
	margin-bottom: $spacing-unit;
}

.c-img-teaser-transparent__link {
	color: $color-white !important;
}
