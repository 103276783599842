.c-themeWorldItem {
	// Colors
	// Name, Background, Text
	$colors: "" #f2f2f2 dark,
		// use this, if color is missing
		"BLACK" #212121 light,
		// BLACK //LIGHT Schwarz
		"GRAY" #f2f2f2 dark,
		// GRAY //DARK f2-Grau
		"RAUCHQUARZ" #776e64 light,
		// RAUCHQUARZ //LIGHT Rauchquarz
		"CHAMPAGNER" #bf9874 dark,
		// CHAMPAGNER //DARK Champagner
		"PERLMUTT" #e1cab6 dark,
		// PERLMUTT //DARK Perlmutt
		"EBENHOLZ" #423d2e light; // EBENHOLZ //LIGHT

	// Deprecated Colors (still in use in old components)
	// Name, Dark-Background, Light-Background
	$colors-deprecated: "BERNSTEIN" #9b7449 #e6dcd1,
		// BERNSTEIN //Dark Bernstein 1 //Light Bernstein 1
		"SMARAGD" #8fa0a3 #e3e7e8,
		// SMARAGD //Dark Smaragd 3 //Light Smaragd 3
		"MAHAGONI" #4b3d3c #f8f7f7,
		// MAHAGONI //Dark Bronzit 2 //Light Bronzit 2
		"OLIV" #c2b66e #efecdb; // OLIV //Dark Bronzit 3 //Light Bronzit 3

	// Colors and Buttons
	@each $colorName, $colorValue, $colorMode in $colors {
		// Background and Text Color
		&.bgColor-#{$colorName} {
			background-color: $colorValue;
			@if $colorMode == "light" {
				color: white;
			}
		}
		// Button Color
		@if $colorMode == "light" {
			&.bgColor-#{$colorName} &__button {
				@extend .ocm-button__primary-dark;
			}
			// OUTLINE Button
			&.bgColor-#{$colorName}.button-OUTLINE &__button {
				@extend .ocm-button__secondary-dark;
			}
		} @else {
			&.bgColor-#{$colorName} &__button {
				@extend .ocm-button__primary;
			}
			// OUTLINE Button
			&.bgColor-#{$colorName}.button-OUTLINE &__button {
				@extend .ocm-button__secondary;
			}
		}
	}

	// Deprecated Colors and Buttons
	@each $colorName, $colorValueDark, $colorValueLight in $colors-deprecated {
		// Background and Text Color
		&.bgColor-#{$colorName} {
			background-color: $colorValueLight;
		}
		&.bgColor-#{$colorName}.colormode-DARK {
			background-color: $colorValueDark;
			color: white;
		}
		// Button Color
		&.bgColor-#{$colorName} &__button {
			@extend .ocm-button__primary;
		}
		&.bgColor-#{$colorName}.colormode-DARK &__button {
			@extend .ocm-button__primary-dark;
		}
	}
}
