/* ------------------------------------*\
    #CLASSIC TEXT
\*------------------------------------ */

// Variables Start

// Variables End

.c-cl-text--gold {
	color: $color-classic-primary;

	&:hover {
		color: $color-classic-hover;
	}
}
