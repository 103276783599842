/* ------------------------------------*\
    #FLYOUT MENU
    Secondary Navigation Flyout - Bekleidung u.a.
\*------------------------------------ */

$flyout-menu-pos-t: $gl_header-fixed-height;
$flyout-menu-height: 300px;
$flyout-menu-img-wrapper-height: 210px;
$flyout-menu-padding-v: 25px;
$flyout-menu-column-list-pr: $inuit-base-spacing-unit;
$flyout-menu-column-pr: ($inuit-base-spacing-unit) * 2;
$flyout-menu-column-pr-small: ($inuit-base-spacing-unit) / 2;
$flyout-menu-ul-mb: 22px;
$flyout-menu-list-font-size: 13px;
$flyout-menu-list-mb: 7px;

$flyout-profile-menu-min-width: 264px;
$flyout-profile-position: ($inuit-base-spacing-unit) * 4;

$flyout-service-menu-padding: ($inuit-base-spacing-unit) / 2 $inuit-base-spacing-unit;
$flyout-service-menu-item-padding: ($inuit-base-spacing-unit) / 3 0;

$flyout-animate-time: 240ms;

.c-flyout {
	background-color: white;
	border-top: 2px solid var(--color-gray-40);
	box-shadow: var(--shadow-4);
	clip-path: inset(0 -10px -10px -10px);
	left: 0;
	min-height: $flyout-menu-height;
	opacity: 0;
	padding: $flyout-menu-padding-v 0;
	position: absolute;
	top: 4rem;
	visibility: hidden;
	width: 100%;
	z-index: z("flyout");

	.is-open & {
		visibility: visible;
		opacity: 1;
	}

	ul {
		list-style: outside none none;
		margin: 0 0 $flyout-menu-ul-mb 0;
		padding: 0;

		&:last-child {
			margin-bottom: 0;
		}

		li {
			min-width: 100px;
			margin-bottom: $flyout-menu-list-mb;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: block;

				@include font-size($flyout-menu-list-font-size);
			}
		}
	}
}

.c-flyout__headline {
	color: var(--color-black-primary);
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2222222;
	text-transform: none;
	margin: 0 0 16px;
}

.c-flyout__column-wrapper {
	display: flex;
	column-gap: 30px;
}

.c-flyout__column {
	flex: 0 0 auto;
	width: 180px;

	// Safari < 14.1 doesn’t support flexbox gap, but returns true when tested against '@supports (column-gap: normal)'
	&:nth-last-child(n + 2) {
		margin-right: 30px;

		// Selector function is only supported in Safari >= 14.1
		// @supports selector(A > B)
		@supports #{"selector(A > B)"} {
			margin-right: unset;
		}
	}

	&--wide {
		width: 339px;
	}

	.language-ar & {
		text-align: right;
	}
}

.c-flyout__column-img {
	@extend .c-flyout__column;

	flex: 1 1 auto;
	width: auto;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;

	// Teaser ausblenden, wenn kein Platz mehr
	[data-columns="7"] > &:last-child,
	[data-columns="8"] > &:last-child {
		@media not screen and (min-width: 1280px) {
			display: none;
		}
	}
}

.c-flyout__link,
.c-flyout__list-link,
.c-flyout__moodimage {
	.no-touchevents & {
		@include animate_underline_on_hover($duration: $flyout-animate-time, $element: ".animate-underline");
	}
}

/* stylelint-disable-next-line */
.c-flyout {
	.c-flyout__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: grid;
		grid-gap: 10px 18px;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(7, 1fr);

		&--columns {
			grid-template-columns: 1fr 1fr;
			grid-auto-flow: column;
		}

		&-item {
			margin: 0;
		}
	}

	.c-flyout__link,
	.c-flyout__list-link {
		color: var(--color-black-primary);
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5714285;
		display: inline-flex;
		vertical-align: middle;
	}

	.c-flyout__list-link {
		display: flex;
	}

	.c-flyout__link-icon {
		font-size: 20px;
		fill: none;
		margin-top: 1px;
	}
}

.c-flyout__grid {
	display: grid;
	gap: 8px;
	grid-template-columns: repeat(3, 1fr);

	.c-flyout__column--narrow & {
		grid-template-columns: repeat(2, 1fr);
	}

	&-item {
		background: $color-gray-lighter;
		position: relative;
		padding-top: percentage(
			71/107
		); // creates a box with fix aspect-ratio and replaces aspect-ratio: #{75/40}, which is no supported on safari prior V15

		&:hover {
			.c-flyout__grid-image {
				transform: scale(1.21);
			}
		}

		@media (min-width: 64em) {
			padding: 0;
			height: 71px;
		}
	}

	&-image {
		height: 100% !important;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		padding: 12px 16px;
		transition: transform $flyout-animate-time ease-in-out;
	}

	&-row {
		grid-column: 1 / -1;
	}
}

.c-flyout__grid-item + .c-flyout__grid-row {
	margin-top: 10px;
}
