/* ------------------------------------*\
    #TILE FOR INFINITE SCROLLING
\*------------------------------------ */

.c-tile__container {
	margin-left: -$spacing-unit;
}

.c-tile--show {
	display: inline-block;
}
