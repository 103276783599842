/* ------------------------------------*\
    #WHITESPACE NO-WRAP
\*------------------------------------ */

/**
 *  helper-class.
 */

.u-no-wrap {
	white-space: nowrap !important;
}
