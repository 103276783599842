/* ------------------------------------*\
    #CAREER ABOUT
\*------------------------------------ */

.c-career-about__wrapper {
	background: $color-gray-lighter;
}

#classicJobsContainer {
	height: auto !important;
}
