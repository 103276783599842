/* ------------------------------------*\
    # HEADLINE #
\*------------------------------------ */

.c-headline {
	@include media-query(m-up) {
		display: flex;
		position: absolute;
		top: -78px;
		right: 0;
		transform: translateY(-100%);
	}

	&__item {
		background-color: var(--color-gray-40);

		@include media-query(m-up) {
			max-width: 350px;
		}

		&--gold {
			background-color: $color-gold-dark;
		}

		&--silver {
			background-color: $color-silver-dark;
		}
	}
}
