/* ------------------------------------*\
    #STEP IMAGE TEASER // CLASSIC
\*------------------------------------ */

$step-teaser-min-height: 300px;
$step-teaser-content-min-height: 120px;
$step-teaser-content-min-height-mobile: 50%;
$step-teaser-content-mt-mobile: 100px;
$step-teaser-font-size: 13px;
$step-teaser-transition: all 0.4s ease;

$step-teaser-content-background: rgba($color-black, 0.8);

// todo: circle entfernen, wenn dieser nicht mehr im CMS verwendet wird
//$step-teaser-circle-size:				95px;
//$step-teaser-circle-size-mobile:		70px;
//$step-teaser-circle-margin:				($spacing-unit * 1.5) auto $spacing-unit auto;
//$step-teaser-circle-margin-mobile:		($spacing-unit / 2) auto;
//$step-teaser-circle-border:				1px solid $color-classic-primary;

.c-step-teaser {
	position: relative;
	overflow: hidden;

	&:hover .c-step-teaser__img {
		transform: scale(1.05);
	}
}

.c-step-teaser__img {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	transition: $step-teaser-transition;

	@include media-query(l-up) {
		min-height: $step-teaser-min-height;
	}

	@include media-query(l-down) {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.c-step-teaser__content-wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: $spacing-unit / 2;
	background: $step-teaser-content-background;
	text-align: center;
	min-height: $step-teaser-content-min-height;
	width: 100%;

	@include media-query(l) {
		min-height: $step-teaser-content-min-height-mobile;
	}

	@include media-query(l-down) {
		padding: $spacing-unit 0;
		min-height: unset;
		position: relative;
		margin-top: $step-teaser-content-mt-mobile;
	}
}

// todo: circle entfernen, wenn dieser nicht mehr im CMS verwendet wird
//.c-step-teaser__circle {
//	position: relative;
//	border: $step-teaser-circle-border;
//	border-radius: 100%;
//	width: $step-teaser-circle-size;
//	height: $step-teaser-circle-size;
//	margin: $step-teaser-circle-margin;
//
//	@include media-query(l-down) {
//		width: $step-teaser-circle-size-mobile;
//		height: $step-teaser-circle-size-mobile;
//		margin: $step-teaser-circle-margin-mobile;
//	}
//}
//
//.c-step-teaser__circle-count {
//	font-size: $classic-headline-font-size-large;
//	font-weight: $font-weight-medium;
//	color:$color-classic-primary;
//	line-height: 1.1em;
//	position: absolute;
//	top: 50%;
//	left: 50%;
//	transform: translate(-50%,-50%);
//
//	@include media-query(l-down) {
//		font-size: $classic-headline-font-size-default;
//	}
//}

.c-step-teaser__text {
	padding: 0 $spacing-unit;

	@include font-size($secondary-font-size-s);

	color: $color-white;
}
