/* ------------------------------------*\
    #ARROW-LINK
\*------------------------------------ */

$arrow-link-font-size: $inuit-base-font-size;
$arrow-link-icon-padding: 5px;
$arrow-link-icon-font-size: 9px;

$arrow-link-classic-mr: ($inuit-base-spacing-unit) / 1.5;
$arrow-link-classic-color: $color-classic-primary;

.o-arrow-link {
	&::after {
		@include icon-font($arrow-link-icon-font-size);

		font-weight: $font-weight-bold;
		content: $icon-font--arrow-right-big;
		padding-left: $arrow-link-icon-padding;
		vertical-align: baseline;
		line-height: inherit;

		.language-ar & {
			content: "";
			padding: 0;
		}
	}
}

.o-arrow-link--bold {
	font-size: $u-fontsize;
	font-weight: $font-weight-bold;
}

.o-arrow-link--classic {
	color: $arrow-link-classic-color;
	font-weight: $font-weight-bold;

	&:hover {
		color: $color-classic-hover;
	}
}

.o-arrow-back-link {
	font-weight: $font-weight-medium;

	&::before {
		@include icon-font($arrow-link-icon-font-size);

		content: $icon-font--arrow-left-big;
		padding-right: $arrow-link-icon-padding;
		vertical-align: baseline;
		line-height: inherit;
		font-weight: $font-weight-medium;
		color: $color-black;
	}
}

.o-arrow-back-link--icon {
	display: inline-block;
	position: absolute;
	z-index: 1;
	margin-top: 2px;
	vertical-align: top;

	.c-icon {
		@include icon-font($u-fontsize-s);
	}
}

// Arrow down for show more type of links

.o-arrow-link-more {
	&::after {
		@include icon-font($arrow-link-icon-font-size);

		content: $icon-font--arrow-down;
		padding-left: $arrow-link-icon-padding;
		vertical-align: baseline;
		line-height: inherit;

		@include ui-transition();

		-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
		transform: rotate(0deg);
		display: inline-block;

		.language-ar & {
			content: "";
			padding: 0;
		}
	}

	&.is-active {
		&::after {
			@include ui-transition();

			-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
			transform: rotate(180deg);
			display: inline-block;
			padding-right: $arrow-link-icon-padding;
			padding-left: 0;

			.language-ar & {
				padding-right: 0;
				padding-left: $arrow-link-icon-padding;
			}
		}
	}

	&--classic {
		color: $arrow-link-classic-color;
		font-weight: $font-weight-bold;

		&:hover {
			color: $color-classic-hover;
		}
	}
}
