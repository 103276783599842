/* ------------------------------------*\
    #PAGE
\*------------------------------------ */

html {
	font-family: $base-font-family;
	font-size: ($inuit-base-font-size / 16px) * 1em;
	line-height: 1.4285714;
	background-color: $inuit-base-background-color;
	color: $inuit-base-text-color;
	min-height: 100%;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}
