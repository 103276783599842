/* ------------------------------------*\
    #HELPER
\*------------------------------------ */

.u-reset-heading {
	font-size: inherit;
	font-weight: normal;
	font-style: normal;
	line-height: inherit;
	margin: 0;
}

.u-pointer {
	cursor: pointer !important;
}

.u-pointer-events-none {
	pointer-events: none;
}

.u-user-select-none {
	user-select: none;
}

.u-left-auto {
	left: auto !important;
}

.u-full-size {
	height: 100%;
	width: 100%;
}

.u-full-width {
	width: 100%;
}

.o-page-wrap-m-down {
	@include media-query(m-down) {
		max-width: $max-page-width-with-padding;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1.142857142857143rem;
		padding-right: 1.142857142857143rem;
	}
}

.o-page-wrap-m-up {
	@include media-query(m-up) {
		max-width: $max-page-width-with-padding;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1.142857142857143rem;
		padding-right: 1.142857142857143rem;
	}
}
