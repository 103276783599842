/* ------------------------------------*\
    # Badge #
\*------------------------------------ */

$line-height--large: 1.4;

.c-badge-square-wrapper {
	position: absolute;
	left: 1px;
	bottom: 1px;
}

.c-badge-square {
	background-color: $color-black;
	color: $color-white;
	display: inline-flex;
	font-weight: $font-weight-bold;
	padding: $spacing-unit--small;
	line-height: $line-height--large;
}
