/* ------------------------------------*\
    # Modal #
\*------------------------------------ */

$modal-wrapper-mt: 80px;

.c-modal {
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 999;
	overflow: auto;
	transition: opacity 0.25s ease;
	opacity: 0;
	visibility: hidden;

	&--active {
		opacity: unset;
		visibility: unset;
	}

	&__icon {
		width: 100px;

		&-content {
			font-size: $u-fontsize;
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		margin: $modal-wrapper-mt auto $modal-wrapper-mt / 2;
		max-width: 700px;
		background-color: $color-white;
		position: relative;
		min-width: 600px;

		@include media-query(m-down) {
			width: 100%;
			margin-top: 0;
			min-width: auto;
		}
	}

	&__close {
		position: absolute;
		transform: translate(50%, -50%);
		top: 0;
		right: 0;
		padding: $spacing-unit--small;
		font-size: $inuit-base-font-size;
		border-radius: 50%;
		color: $color-white;
		background-color: $color-black;
		border: none;

		@include media-query(m-down) {
			right: $spacing-unit--tiny;
			top: $spacing-unit--tiny;
			transform: translate(0%, 0%);
		}
	}

	&__header {
		text-align: left;
		font-size: 26px;
		font-weight: 500;
		padding: 18px 64px 18px 18px;
		border-bottom: 2px solid var(--color-gray-40);
		margin: 0;
	}

	&__body {
		text-align: center;
		padding: 24px 16px;
		overscroll-behavior: contain;
	}
}

.info-modal {
	width: 100vw;
	height: 100%;
	z-index: 999;
	font-size: $u-fontsize-s;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0);
	visibility: hidden;
	transition-property: background-color, visibility;
	transition-duration: 320ms;
	text-align: left;

	@include media-query(l-up) {
		align-items: center;
	}

	&__container {
		transition-property: transform, opacity;
		transition-duration: 320ms;
		width: 100%;
		max-width: 700px;
		background: #fff;
		position: relative;
		max-height: 100%;
		display: flex;
		flex-direction: column;
		opacity: 0;
		transform: translateY(100%);

		@include media-query(l-up) {
			max-height: 80%;
			transform: translateY(calc(50vh + 50%));
		}

		a {
			text-decoration: underline;
		}
	}

	&.is-visible {
		background-color: var(--color-dimmer);
		visibility: unset;

		.info-modal__container {
			opacity: unset;
			transform: unset;
		}
	}

	&.wide &__container {
		max-width: 900px;
	}

	&.narrow &__container {
		max-width: 500px;
	}

	&.initially-open &__container {
		outline: none;
	}

	&__close {
		.tw & {
			width: 32px;
			height: 32px;
			right: 16px;
			top: 16px;
			position: absolute;
			padding: 0;
			border-radius: 50%;
			color: var(--color-white);
			background-color: var(--color-black-primary);
			border: 1px solid transparent;
			display: grid;
			place-content: center;

			&:hover {
				@media (hover: hover) and (pointer: fine) {
					background-color: var(--color-gray-90);
				}
			}

			&:active {
				background-color: var(--color-gray-80);
			}
		}

		&-icon {
			width: 14px;
			height: 14px;
		}
	}

	&__header {
		padding: 16px 64px 14px 16px;
		border-bottom: 2px solid var(--color-gray-30);

		@media not all and (min-width: 45em) {
			hyphens: auto;
			overflow-wrap: break-word;
		}
	}

	&__body {
		padding: 24px 16px 32px;
		overscroll-behavior: contain;

		@include media-query(l-up) {
			padding-bottom: 24px;
		}
	}
}
