@use "sass:map";

@mixin styleguide-button($theme) {
	$button-color: map.get($theme, "color");
	$button-background: map.get($theme, "background");
	$button-blur: map.get($theme, "blur");
	$button-border: map.get($theme, "border");
	$button-border-focus: map.get($theme, "border-focus");

	$button-color-hover: map.get($theme, "color-hover");
	$button-background-hover: map.get($theme, "background-hover");
	$button-border-hover: map.get($theme, "border-hover");

	$button-color-active: map.get($theme, "color-active");
	$button-background-active: map.get($theme, "background-active");
	$button-border-active: map.get($theme, "border-active");

	$button-color-disabled: map.get($theme, "color-disabled");
	$button-background-disabled: map.get($theme, "background-disabled");
	$button-border-disabled: map.get($theme, "border-disabled");

	@extend %ocm-button;

	@if $button-blur {
		backdrop-filter: blur(4px);
	}
	@if $button-border {
		box-shadow: inset $button-border 0 0 0 1px;
	}

	background-color: $button-background;
	color: $button-color;

	.no-touchevents &:focus-visible::after {
		border-color: $button-border-focus;
	}
	// hover
	.no-touchevents &:hover {
		background-color: $button-background-hover;
		@if $button-border-hover {
			box-shadow: inset $button-border-hover 0 0 0 1px;
		}
		@if $button-color-hover {
			color: $button-color-hover;
		} @else {
			color: $button-color;
		}
	}
	// active
	.no-touchevents &:active,
	body &:active {
		background-color: $button-background-active;
		@if $button-border-active {
			box-shadow: inset $button-border-active 0 0 0 1px;
		}
		@if $button-color-active {
			color: $button-color-active;
		} @else {
			color: $button-color;
		}
	}
	//disabled
	&:disabled,
	&[aria-disabled="”true”"] {
		background-color: $button-background-disabled;
		color: $button-color-disabled;
		@if $button-border-disabled {
			box-shadow: inset $button-border-disabled 0 0 0 1px;
		}
	}
}

// CSS for Buttons
%ocm-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 $lib-space-l;
	height: 46px;
	border: 0;
	border-radius: 9999px;
	position: relative;
	font-weight: 600;

	svg {
		width: 20px;
		height: 20px;
		margin-left: $lib-space-s;
	}
}

// Alteration-CSS for Buttons
.ocm-button--small {
	height: 32px;
	width: 32px;
	padding: 0;
	position: relative;

	svg {
		width: 14px;
		height: 14px;
		margin-left: 0;
	}
	// click-space around button
	&::before {
		content: "";
		width: calc(100% + 10px);
		height: calc(100% + 10px);
		left: -5px;
		top: -5px;
		position: absolute;
	}
}

.ocm-button--icon {
	padding: 0;
	svg {
		margin-left: 0;
	}
}

.ocm-button--smallOnMobile {
	@extend .ocm-button--small;

	@include media-query(m-up) {
		width: auto;
		padding: 0 $lib-space-l;
		height: 46px;

		svg {
			width: 20px;
			height: 20px;
			margin-left: $lib-space-s;
		}

		&::before {
			content: unset;
			width: unset;
			height: unset;
			position: unset;
		}
	}
}
