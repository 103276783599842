/* ------------------------------------*\
    #POPUP-MESSAGE-ERROR
\*------------------------------------ */

.c-popup-message-error {
	font-size: $u-fontsize-s;
	font-weight: 400;
	width: auto;
	display: flex;
	text-align: left;
	margin-top: 0;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	@include media-query(m-down) {
		white-space: normal;
	}

	&::before {
		top: 1px !important;
		margin-top: 0 !important;
		transform: none;
	}
}
