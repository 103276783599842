/* Modern scrollbar */
.o-scrollbar {
	--scrollbar-width: var(--custom-scrollbar-width, 4px);
	--scrollbar-thumb-color: var(--color-gray-50);
	--scrollbar-track-color: var(--color-white);

	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);

	&::-webkit-scrollbar {
		width: var(--scrollbar-width);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: var(--scrollbar-thumb-color);
	}

	&::-webkit-scrollbar-track {
		background: var(--scrollbar-track-color);
	}
}

.scrollbar-hidden {
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}
