/* ------------------------------------*\
    #FULLWIDTH AND PADDING FOR CLICKING COMFORT
\*------------------------------------ */

/**
 *  helper-class.
 */

.u-area-increased {
	width: 100%;
	padding: 10px 0;
	margin: -10px 0;
}
