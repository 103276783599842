/* Brand Page FAQ e.g. https://www.outletcity.com/de/metzingen/marken-outlet-adidas/ */

.faq__answer {
	font-size: $u-fontsize;

	&:not(:first-child) {
		display: none;
	}
}

.faqs__headline-common {
	font-size: $u-fontsize;
	font-weight: 700;
	text-align: left;
	margin-bottom: $lib-space-s;
	margin-left: $lib-space-s;
}

.accordion-headline {
	text-align: left;
	font-size: $u-fontsize;
	font-weight: 700;
	padding: $lib-space-s;
	margin-bottom: 0;
}

.accordion-svg {
	height: 18px;
	width: 18px;
	fill: var(--color-black);
	vertical-align: middle;
}

.accordion {
	&__border {
		border-bottom: 1px solid var(--color-black);
		border-top: 1px solid var(--color-black);
	}

	&__item {
		border-bottom: 1px solid var(--color-gray-20);

		button[aria-expanded="true"] {
			background: var(--color-gray-20);
		}

		&.hidden {
			display: none;
		}
	}

	&__item-first {
		border-top: 1px solid var(--color-gray-20);
	}

	&__button {
		position: relative;
		display: block;
		text-align: left;
		width: 100%;
		padding: $lib-space-m $lib-space-s;
		font-size: $u-fontsize;
		font-weight: 400;
		border: none;
		background: none;
		outline: none;

		&--moreContent {
			font-weight: 500;
		}
	}

	&__title {
		font-size: $u-fontsize;
		padding-right: $lib-space-l;
		cursor: pointer;
		margin-bottom: 0;
	}

	&__icon {
		position: absolute;
		top: $lib-space-m;
		right: 0;
		width: 22px;
		height: 22px;

		&--plus {
			&::before {
				position: absolute;
				content: "";
				top: 9px;
				left: 5px;
				width: 10px;
				height: 2px;
				background: currentColor;
			}

			&::after {
				position: absolute;
				content: "";
				top: 5px;
				left: 9px;
				width: 2px;
				height: 10px;
				background: currentColor;
			}
		}

		&--minus {
			&::before {
				position: absolute;
				content: "";
				top: 9px;
				left: 5px;
				width: 10px;
				height: 2px;
				background: currentColor;
			}
		}
	}

	&__svg-icon {
		position: absolute;
		top: $lib-space-m;
		right: 5px;
		width: 12px;
		height: 12px;
	}

	&__svg-icon--minus {
		display: none;
	}

	&__svg-icon--plus {
		display: block;
	}

	&__content {
		font-size: $u-fontsize;
		opacity: 0;
		max-height: 0;
		overflow: hidden;

		@include ui-transition(0.8s, ease-out);

		p {
			margin-bottom: 0;
			padding: $lib-space-s;
			text-align: left;
		}
	}

	button[aria-expanded="true"] {
		.accordion__svg-icon--minus {
			display: block;
		}
		.accordion__svg-icon--plus {
			display: none;
		}

		.accordion__icon {
			&::after {
				width: 0;
			}
		}

		+ .accordion__content {
			opacity: 1;
			max-height: 90em;

			@include ui-transition(1s, ease-in);
		}
	}
}
