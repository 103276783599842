// TODO TUB-10133 Clean-up

/* ------------------------------------*\
    #BACKGROUND IMAGE Shop Homepage
\*------------------------------------ */
.c-bg-image-wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	min-height: 100%;

	@include media-query(m-up) {
		.c-bg-image {
			position: relative;
			min-width: 100%;
			min-height: 100%;
			max-width: none;
		}
	}

	@media not all and (min-width: 64em) {
		height: 100% !important;
	}
}
