/* ------------------------------------*\
    #SOCIAL-SLIDER
\*------------------------------------ */

.c-social-slider {
	position: relative;

	@include media-query(l-up) {
		.slick-dots {
			left: $spacing-unit;
			width: auto;
		}

		.slick-dots li {
			display: block;
		}
	}

	.slick-dots {
		position: absolute;
		top: $spacing-unit;
		bottom: auto;
	}

	.slick-dots li {
		width: auto;
		height: auto;
	}
}

.c-social-slider__thumb {
	background-color: $color-gray-dark;
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	color: $color-white;
	margin-right: $spacing-unit--small;
	margin-bottom: $spacing-unit--small;
	opacity: 0.5;

	&.c-icon--facebook {
		background-color: $color-facebook;
	}

	&.c-icon--instagram {
		background-color: $color-instagram;
	}

	&.c-icon--pinterest {
		background-color: $color-pinterest;
	}

	&.c-icon--twitter {
		background-color: $color-twitter;
	}

	&:hover {
		color: $color-white;
		opacity: 1;
	}

	.slick-active & {
		opacity: 1;
	}
}

.c-social-slider__slides {
	position: relative;
}

.c-social-slider__slides img {
	width: 100%;
	max-width: 100%;
	margin: 0;
}

.c-social-slider__headline {
	margin-bottom: 0;
}

.c-social-slider__text-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
}

.c-social-slider__text {
	display: table;
	height: 100%;
}

.c-social-slider__text-content {
	display: table-cell;
	vertical-align: middle;
	padding: $spacing-unit--small;
}
