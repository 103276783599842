/* ------------------------------------*\
	#DISPLAY
\*------------------------------------ */

/**
 * Position helper class.
 */

.u-relative {
	position: relative !important;
}

.u-absolute {
	position: absolute !important;
}

.u-static {
	position: static !important;
}

.u-fixed {
	position: fixed !important;
}

//responsive

.u-relative-m-down {
	@include media-query(m-down) {
		position: relative !important;
	}
}

.u-relative-l-down {
	@include media-query(l-down) {
		position: relative !important;
	}
}

.u-overflow-hidden {
	overflow: hidden;
}
