/* ------------------------------------*\
    #BUSINESS CONTACT
\*------------------------------------ */

.c-business-contact__tile {
	text-align: left;

	@include media-query(m-down) {
		text-align: center;
	}
}

.c-business-contact__row {
	@include media-query(m-down) {
		text-align: center;
	}
}

.c-business-contact__list {
	@include media-query(m-down) {
		display: table;
		margin-left: auto !important;
		margin-right: auto !important;
	}
}
