/* -----------------------------------
 TIMELINE FLAT BASE DESIGN
 ---------------------------------- */

.timelineLoader {
	width: 100%;
	text-align: center;
	padding: 150px 0;
}

/* fixed line holder */
.timelineFlat .timeline_line {
	margin-top: 10px;
	margin-bottom: 10px;
	width: 900px;
}

/* full (including months that are not shown) line holder */
.timelineFlat .t_line_holder {
	padding: 10px 0 0 0;
	height: 80px;
	background: url("../../images/dummy/pattern_timeline_black.png") repeat-x 0 49px;
}

/* 2 months are stored in one view */
.timelineFlat .t_line_view {
	height: 20px;
	width: 900px;
}

/* holder for 1 month (constist of nodes and month caption) - we use borders to separate months thats why it has width 2px less then 50% */
.timelineFlat .t_line_m {
	margin-top: 35px;
	height: 10px;
	border-left: 1px solid transparent;
	border-right: 1px solid $color-black;
	width: 448px;
}

/* month on the right side - has left set at 459 so border would overlap border from first element (to evade duplicated borders) */
.timelineFlat .t_line_m.right {
	left: 449px;
	width: 449px;
	border-left: 1px solid $color-black;
	border-right: 1px solid transparent;
}

/* month caption */
.timelineFlat h4.t_line_month {
	font-size: 12px;
	margin: -30px 0 0;
	color: $color-black;
}

/* node on the timeline */
.timelineFlat a.t_line_node {
	text-decoration: none;
	padding: 38px 0 4px;
	font-weight: 500;
	height: 10px;
	font-size: 12px;
	top: -25px;
	position: relative;
	color: $color-black;
}

.timelineFlat a.t_line_node::after {
	//timeline date dot
	content: "";
	border-radius: 22px;
	height: 15px;
	width: 15px;
	position: absolute;
	top: 23px;
	left: 50%;
	margin: 0 0 0 -7px;
	background: $color-classic-primary;
}

.timelineFlat a.t_line_node.active {
	color: $color-classic-primary;
}

.timelineFlat a.t_line_node.active::after {
	//timeline date dot
	border: 10px solid;
	top: 20px;
	left: 3px;
}

/* node description */
.timelineFlat .t_node_desc {
	white-space: normal !important;
	top: -50px;
	opacity: 0;
	filter: alpha(opacity=0);
	left: 50%;
	color: $color-black;
	display: inline-block !important;
	position: relative;
	transition: top 200ms, opacity 500ms;
}

@include media-query(l-up) {
	.timelineFlat a.t_line_node:hover .t_node_desc {
		top: 0;
		opacity: 1;
		filter: alpha(opacity=100);
	}

	.timelineFlat .t_node_desc span::after {
		@include icon-font();

		content: $icon-font--arrow-down;
		display: block;
		position: absolute;
		background: $color-white;
		color: $color-classic-primary;
		font-size: 13px;
		left: 50%;
		transform: translateX(-50%);
		top: 100%;
		margin-top: -2px;
	}
}

.timelineFlat .t_node_desc span {
	white-space: nowrap;
	margin-left: -50%;
	margin-right: 50%;
	margin-top: -20px;
	display: block !important;
	padding: 3px 8px;
	background: $color-white;
	position: relative;
	border: 1px solid $color-classic-primary;
	font-size: 16px;
}

.timelineFlat #t_line_left,
.timelineFlat #t_line_right {
	@include icon-font();

	display: inline-block;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;
	top: 39px;
	width: 14px;
	height: 21px;
	font-size: 21px;
	color: $color-black;
	position: relative;
}

.timelineFlat #t_line_left:hover,
.timelineFlat #t_line_right:hover {
	color: $color-classic-primary;
}

/* line arrow left */
.timelineFlat #t_line_left {
	left: -30px;
}

.timelineFlat #t_line_left::after {
	content: $icon-font--arrow-left;
	position: absolute;
	right: 0;
	top: 0;
}

/* line arrow right */
.timelineFlat #t_line_right {
	right: -30px;
}

.timelineFlat #t_line_right::after {
	content: $icon-font--arrow-right;
	position: absolute;
	left: 0;
	top: 0;
}

.timelineFlat {
	display: none;
}

.timeline_items_wrapper {
	position: relative;
	width: 100%;
	margin: 10px 0;
}

/* items container */
.timelineFlat .timeline_items {
	padding: 0;
}

/* single item (margines set from javascript) */
.timelineFlat .item {
	width: 410px;
	height: auto;
	background: $color-white;
	color: $color-black;
	font-size: $u-fontsize;
	line-height: 1;
	position: relative;
}

.timelineFlat .item.item_node_hover img {
	outline: 4px solid $color-classic-primary;
}

/* ----- content - non-plugin elements ----- */
.timelineFlat .item img {
	margin-top: 0;
	width: 100%;
}

//Timeline Item Headline

.timelineFlat .item h2 {
	color: $color-black;
	height: 66px;
}

.timelineFlat .item span {
	display: block;
	margin: 0 20px 10px;
	height: 75px;
}

/* item details (margines set from javascript) */
.timelineFlat .item_open {
	width: 410px;
	height: 410px;
	background: $color-white;
	position: relative;
	color: #777;
	z-index: 2;
}

/* ----- content - non-plugin elements ----- */

.timelineFlat .timeline_open_content {
	padding: 20px;
}

.timelineFlat .mCSB_container {
	margin-right: 16px;
}

.timelineFlat .mCS-light-thin > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background: $color-classic-primary;
}

.timelineFlat .mCS-light-thin > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	color: #f75f5f;
}

.timelineFlat .mCS-light-thin > .mCSB_scrollTools .mCSB_draggerRail {
	background: #aaa;
}

.timelineFlat .mCustomScrollBox > .mCSB_scrollTools {
	width: 8px;
}

/* ----------------------------------------- */

/* left/right controles */
.timelineFlat .t_controles {
	margin: 0;
}

.timelineTouch.timelineFlat .t_left,
.timelineTouch.timelineFlat .t_right {
	display: none;
}

.timelineFlat:hover .t_left,
.timelineFlat:hover .t_right {
	opacity: 1;
	filter: alpha(opacity=100);
}

.timelineFlat .t_left,
.timelineFlat .t_right {
	@include icon-font();

	position: absolute;
	display: block;
	height: 100%;
	width: 75px;
	margin: 0;
	cursor: pointer;
	top: 0;
	z-index: 10;
	opacity: 0;
	filter: alpha(opacity=0);
	transition: background-color 300ms, opacity 300ms;
}

.timelineFlat .t_left,
.timelineFlat .t_left:hover:active {
	left: 0;
	background: rgba(255, 255, 255, 0.5);

	&::after {
		content: $icon-font--arrow-left;
		position: absolute;
		top: 50%;
		left: $spacing-unit;
		transform: translateY(-50%);
		font-size: $heading-secondary-font-size-beta;
	}
}

.timelineFlat .t_left:hover {
	background: rgba(255, 255, 255, 0.7);
}

.timelineFlat .t_right,
.timelineFlat .t_right:hover:active {
	right: 0;
	background: rgba(255, 255, 255, 0.5);

	&::after {
		content: $icon-font--arrow-right;
		position: absolute;
		top: 50%;
		right: $spacing-unit;
		transform: translateY(-50%);
		font-size: $heading-secondary-font-size-beta;
	}
}

.timelineFlat .t_right:hover {
	background: rgba(255, 255, 255, 0.7);
}

/* ----------------------------------------------------------------------- */

/* ------------------------------ RESPONSIVE ----------------------------- */

/* ----------------------------------------------------------------------- */

@include media-query(l-down) {
	.timelineFlat .timeline_line {
		width: 650px;
	}

	.timelineFlat .t_line_view {
		width: 650px;
	}

	.timelineFlat .t_line_m {
		width: 338px;
	}

	.timelineFlat .t_line_m.right {
		left: 339px;
		width: 339px;
	}
}

@include media-query(m-down) {
	.timelineFlat .timeline_line {
		width: 240px;
	}

	.timelineFlat .t_line_view {
		width: 480px;
	}

	.timelineFlat .t_line_m {
		width: 238px;
	}

	.timelineFlat .t_line_m.right {
		left: 240px;
		width: 238px;
	}

	.timelineFlat .item_open {
		width: 280px;
		height: 390px;
	}

	.timelineFlat .item_open img {
		max-width: 280px;
	}

	.timelineFlat .item_open_cwrapper {
		width: 280px;
	}

	.timelineFlat .item {
		width: 300px;
	}

	.timelineFlat #t_line_left,
	.timelineFlat #t_line_right {
		@include icon-font();

		top: 42px;
		font-size: 16px;
	}
}
