/* ------------------------------------*\
    #CAREER SLIDER
\*------------------------------------ */

.c-career-slider__slide {
	position: relative;
}

.c-career-slider__text {
	position: absolute;
	left: 12.5%;
	top: 50%;
	transform: translateY(-60%);
	max-width: 475px;
	z-index: 5;

	@include media-query(l-down) {
		max-width: 100%;
		position: static;
		transform: translateY(0);
	}
}

.c-career-slider__headline {
	border-bottom: 1px solid $color-gray-dark;
}
