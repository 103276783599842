/* ------------------------------------*\
    #TEXT-LINKS
\*------------------------------------ */

$text-link-color: $inuit-base-text-color !default;
$text-link-color-hover: $color-black !default;

/**
 * Basic text-link styling.
 */

a {
	color: $text-link-color;
	font-weight: 500;
	text-decoration: none;

	&:hover {
		color: $text-link-color-hover;
	}

	&.c-color-luxus {
		color: $color-classic-primary;
	}
}

.c-text-link--white {
	color: $color-white;

	&:hover {
		color: $color-gray-lighter;
	}
}

.c-text-link--gold {
	color: $color-classic-primary;

	&:hover {
		color: $color-classic-hover;
	}
}

[data-goto],
[data-nav] {
	cursor: pointer;
}
