/* ------------------------------------*\
    #BUS TRANSFER
\*------------------------------------ */

$bus-transfer-backround-even: $color-white;
$bus-transfer-backround-odd: $color-gray-lighter;

$bus-transfer-padding: $spacing-unit;
$bus-transfer-padding--extra: ($spacing-unit/2) $spacing-unit;
$bus-transfer-station-position: $bus-transfer-padding;

$bus-transfer-time-backround-gray: $color-gray-light;
$bus-transfer-time-backround-white: $color-gray-lighter;

$bus-transfer-time-cell-width: 70px;
$bus-transfer-second-headline-min-width: 330px;
$bus-transfer-second-headline-min-width-small: 264px;

$bus-transfer-station-max-width: 320px;

.c-bus-transfer {
	position: relative;
}

.c-bus-transfer__headlines {
	display: table;

	@include media-query(m-down) {
		display: none;
	}
}

.c-bus-transfer__first-headline {
	display: table-cell;
	width: 100%;
}

.c-bus-transfer__second-headline {
	display: table-cell;
	text-align: left;
	min-width: $bus-transfer-second-headline-min-width;
}

.c-bus-transfer__second-headline--small {
	min-width: $bus-transfer-second-headline-min-width-small;
}

.c-bus-transfer__item {
	position: relative;
	width: 100%;
	background: $bus-transfer-backround-even;
	text-align: right;
	padding-right: $spacing-unit * 3;

	&:nth-child(even) {
		background: $bus-transfer-backround-even;
	}

	&:nth-child(odd) {
		background: $bus-transfer-backround-odd;
	}

	@include media-query(m-down) {
		text-align: left;
		padding: $bus-transfer-padding;

		&:nth-child(even) {
			background: $bus-transfer-backround-odd;
		}

		&:nth-child(odd) {
			background: $bus-transfer-backround-even;
		}
	}
}

.c-bus-transfer__station {
	position: absolute;
	top: 50%;
	left: $bus-transfer-station-position;
	transform: translateY(-50%);

	@include media-query(l-down) {
		max-width: $bus-transfer-station-max-width;
		text-align: left;
	}

	@include media-query(m-down) {
		max-width: 100%;
		position: relative;
		top: 0;
		left: 0;
		display: block;
		transform: translateY(0);
	}
}

.c-bus-transfer__time-even {
	padding: $bus-transfer-padding;
	display: inline-block;
	font-weight: $font-weight-bold;
	width: $bus-transfer-time-cell-width;

	&:nth-child(even) {
		background: $bus-transfer-time-backround-gray;
	}

	&.extra-time {
		padding: $bus-transfer-padding--extra;
	}

	@include media-query(m-down) {
		background: none !important;
		padding: ($spacing-unit / 2) ($spacing-unit * 1.5) 0 0;
		width: auto;

		&.extra-time {
			padding: ($spacing-unit / 2) ($spacing-unit * 1.5) 0 0;
		}
	}
}

.c-bus-transfer__time-odd {
	text-align: right;
	padding: $bus-transfer-padding;
	display: inline-block;
	font-weight: $font-weight-bold;
	width: $bus-transfer-time-cell-width;

	&:nth-child(even) {
		background: $bus-transfer-time-backround-white;
	}

	&.extra-time {
		padding: $bus-transfer-padding--extra;
	}

	@include media-query(m-down) {
		background: none !important;
		padding: ($spacing-unit / 2) ($spacing-unit * 1.5) 0 0;
		width: auto;

		&.extra-time {
			padding: ($spacing-unit / 2) ($spacing-unit * 1.5) 0 0;
			text-align: left;
		}
	}
}

.only-monday {
	color: $color-gray-dark;
}

.c-bus-transfer__button {
	width: 100%;
	text-align: center;
	margin-top: $spacing-unit;
}
