/* ------------------------------------*\
    #ACCORDION TABLE CLASSIC
\*------------------------------------ */

$accordion-table-classic-width-first: 200px;
$accordion-table-classic-width-time: 100px;

$accordion-table-classic-schedule-margin-bottom: ($spacing-unit * 1.5);

$accordion-table-classic-font-weight: $font-weight-bold;

.c-accordion-table-classic {
	//styles
}

.c-accordion-table-classic__row {
	display: table;

	@include media-query(m-down) {
		display: block;
		margin-bottom: ($spacing-unit / 2);
	}

	&--schedule {
		margin-bottom: $accordion-table-classic-schedule-margin-bottom;
	}
}

.c-accordion-table-classic__first {
	display: table-cell;
	font-weight: $accordion-table-classic-font-weight;
	width: $accordion-table-classic-width-first;

	@include media-query(m-down) {
		display: block;
	}

	&--time {
		width: $accordion-table-classic-width-time;
	}
}

.c-accordion-table-classic__second {
	display: table-cell;

	@include media-query(m-down) {
		display: block;
	}
}

.c-accordion-table-classic__list {
	margin-left: $spacing-unit;
	margin-bottom: 0;
}
