/* ------------------------------------*\
    #CATEGORY BAR
\*------------------------------------ */

$category-bar-height: 40px;
$category-bar-item-mr: 80px;
$category-bar-link-arrow: 6px;
$category-bar-link-arrow-ml: ($category-bar-link-arrow / 2);

$category-bar-content-pv: 40px;
$category-bar-content-height: 320px;
$category-bar-content-height-l: 340px;
$category-bar-content-height-m: 356px;
$category-bar-content-pos-b: $category-bar-height;

$category-bar-content-menu-list-pl: 20px;

$category-bar-content-menu-item-width: 200px;
$category-bar-content-menu-item-mb: 15px;

$category-bar-bg-color: $color-gray-light;
$category-bar-bg-color-light: $color-gray-lighter;
$category-bar-color-black: $color-black-lighter;
$category-bar-content-bg-color: $color-white;

$category-bar-accordion-height-m: 50px;
$category-bar-accordion-p-m: 0 36px 0 18px;
$category-bar-accordion-font-size-m: $heading-secondary-font-size-epsilon;
$category-bar-accordion-icon-font-size-m: $heading-secondary-font-size-epsilon;
$category-bar-header-font-weight: $font-weight-medium;
$category-bar-accordion-icon-pos-r: 18px;

$category-bar-headline-font-size: $inuit-heading-size-6; //Chris said 16px are too big!!!

$category-bar-classic-border: 1px solid $color-classic-primary;

.c-category-bar {
	position: relative;

	@include media-query(m-up) {
		height: $category-bar-height;
		text-align: center;
		background-color: $color-gray-light;
	}

	@include media-query(m-down) {
		display: none;
	}

	.t-classic & {
		border-top: $category-bar-classic-border;
		border-bottom: $category-bar-classic-border;
		background-color: $color-gray-lighter;
	}
}

.c-category-bar__header {
	position: relative;
	width: 100%;
	height: $category-bar-accordion-height-m;
	line-height: $category-bar-accordion-height-m;
	padding: $category-bar-accordion-p-m;
	vertical-align: middle;
	font-size: $category-bar-accordion-font-size-m;
	font-weight: $category-bar-header-font-weight;
	cursor: pointer;
	background-color: $category-bar-bg-color;

	&::after {
		content: $icon-font--arrow-down-big;
		position: absolute;
		right: $category-bar-accordion-icon-pos-r;
		vertical-align: middle;

		@include icon-font($category-bar-accordion-icon-font-size-m, $category-bar-accordion-height-m);

		color: $category-bar-color-black;

		@include ui-transition();

		-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
		transform: rotate(0deg);
	}

	&.is-open::after {
		@include ui-transition();

		-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
		transform: rotate(180deg);
	}
}

/****** Category Bar *********/
.c-category-bar__item {
	@include media-query(m-up) {
		margin-right: $category-bar-item-mr;

		&:last-child {
			margin-right: 0;
		}
	}

	@include media-query(m-down) {
		width: 100%;
	}
}

.c-category-bar__headline {
	position: relative;
	display: block;
	margin-bottom: 0;

	@include font-size($category-bar-headline-font-size, $category-bar-height);

	vertical-align: middle;
	cursor: pointer;

	@include media-query(m-up) {
		padding: 0;

		&::after {
			.is-active & {
				content: "";
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -$category-bar-link-arrow-ml;
				width: 0;
				height: 0;
				border-left: $category-bar-link-arrow solid transparent;
				border-right: $category-bar-link-arrow solid transparent;
				border-top: $category-bar-link-arrow solid $color-white;

				.t-classic & {
					border-top: $category-bar-link-arrow solid $color-classic-primary;
				}
			}
		}
	}

	@include media-query(m-down) {
		width: 100%;
		padding: $category-bar-accordion-p-m;

		@include font-size($category-bar-accordion-font-size-m, $category-bar-accordion-height-m);

		font-weight: $category-bar-header-font-weight;
		background-color: $category-bar-bg-color-light;

		&::after {
			content: $icon-font--arrow-down-big;
			position: absolute;
			right: $category-bar-accordion-icon-pos-r;
			vertical-align: middle;

			@include icon-font($category-bar-accordion-icon-font-size-m, $category-bar-accordion-height-m);

			color: $category-bar-color-black;

			@include ui-transition();

			-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
			transform: rotate(0deg);
		}

		&.is-open::after {
			@include ui-transition();

			-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
			transform: rotate(180deg);
		}
	}
}

/****** Category Bar Content *******/
.c-category-bar__content {
	width: 100%;
	padding: $category-bar-content-pv 0;
	background-color: $category-bar-content-bg-color;

	@include media-query(m-up) {
		position: absolute;
		left: 0;
		right: 0;
		bottom: $category-bar-content-pos-b;
	}

	@include media-query(l-up) {
		height: $category-bar-content-height;
	}

	@include media-query(l) {
		height: $category-bar-content-height-l;
	}

	@include media-query(m) {
		height: $category-bar-content-height-m;
	}

	@include media-query(m-down) {
		display: none;
		padding: ($category-bar-content-pv/2) 0;
	}
}

.c-category-bar__menu-list {
	padding-left: $category-bar-content-menu-list-pl;
	vertical-align: top;
	text-align: left;

	@include media-query(m-up) {
		display: inline-block;
	}
}

.c-category-bar__menu-item {
	width: $category-bar-content-menu-item-width;
	margin-bottom: $category-bar-content-menu-item-mb;

	@include media-query(m-down) {
		width: 100%;
	}
}

.c-category-bar__menu-link {
	font-weight: 400;

	@include media-query(m-down) {
		line-height: $category-bar-height;
		display: block;
	}
}
