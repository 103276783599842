/* ------------------------------------*\
    #SECONDARY-NAV-MOBILE
\*------------------------------------ */

$secondary-nav-mobile-icon-font-size: 9px;
$secondary-nav-mobile-icon-pos-b: $page-wrap-padding;
$secondary-nav-mobile-icon-pv: 4px;

.c-secondary-nav-mobile {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: block;
		border-bottom: 1px solid $color-gray-dark;
	}

	a {
		display: block;
		position: relative;
		padding: $spacing-unit;
		@include font-size($u-fontsize, 1);

		&::after {
			content: $icon-font--arrow-right-big;
			position: absolute;
			right: $secondary-nav-mobile-icon-pos-b;
			padding: $secondary-nav-mobile-icon-pv 0;

			@include icon-font($secondary-nav-mobile-icon-font-size);

			font-weight: $font-weight-semibold;
		}
	}
}
