///*------------------------------------*\
//    #SVG LOADER
//\*------------------------------------*/

@keyframes fadein-loader {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeout-loader {
	0% {
		display: block;
		opacity: 1;
	}

	99% {
		display: block;
		opacity: 0;
	}

	100% {
		opacity: 0;
		display: none;
	}
}

.c-svg-loader__overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(242, 242, 242, 0.6);
	z-index: z("loader");
	display: none;

	&.fade-in {
		animation: fadein-loader 1s ease forwards;
		width: 100%;
		height: 100%;
		display: block;
	}

	&.fade-out {
		animation: fadeout-loader 1s ease forwards;
		display: block;
	}
}

.c-svg-loader__spinner {
	width: 70px;
	height: 70px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
