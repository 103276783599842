/* ------------------------------------*\
    #Header Teaser
\*------------------------------------ */

$cat-teaser-img-width: 2200px;
$cat-teaser-img-scale: ($cat-teaser-img-width / $max-page-width-with-padding) * 100%;

$header-teaser-bg-color: #e0e0e0; // var(--color-gray-40) does not build because of transparentize
$header-teaser-brand-text-pr: 5px;
$header-teaser-brand-icon-width: 20px;
$header-teaser-btn-mobile-height: 30px;
$header-teaser-btn-mobile-width: 40px;
$header-teaser-btn-mobile-pl: 5px;

$header-teaser-copy-fontsize: 11px;
$header-teaser-copy-lineheight: 15px;
$header-teaser-copy-closedheight: 2 * $header-teaser-copy-lineheight;
$header-teaser-copy-gradientwidth: 250px;

$header-teaser-copy-more-fontsize: 11px;
$header-teaser-copy-more-posr: 18px;
$header-teaser-copy-more-icon-pl: 5px;

.c-header-teaser {
	position: relative;
	overflow: hidden;
	background: $header-teaser-bg-color;
}

.c-header-teaser__image {
	width: 100%;
}

// header teaser with max-widht 2200px and special scaling
.c-header-teaser__image--maxwidth,
.c-header-teaser__image--cat {
	display: block;
	position: relative;
	margin-left: 0;
	left: (100 - $cat-teaser-img-scale) / 2;
	max-width: $cat-teaser-img-scale;
	width: $cat-teaser-img-scale;

	@include media-query("xxl-up") {
		margin-left: -($cat-teaser-img-width / 2);
		left: 50%;
		max-width: none;
		width: $cat-teaser-img-width;
	}

	@include media-query("m-down") {
		margin-left: 0;
		left: auto;
		max-width: 100%;
		width: 100%;
	}
}

// Headline
.c-header-teaser__headline {
	@include font-size($inuit-heading-size-4, 1.2);

	font-weight: $font-weight-bold;

	@include media-query("m-down") {
		padding-right: 2 * ($header-teaser-btn-mobile-width + $header-teaser-btn-mobile-pl);
	}
}

// Copy-Text
.c-header-teaser__copy {
	p {
		margin-bottom: 0;
	}

	@include media-query(m-down) {
		font-size: $header-teaser-copy-fontsize;
		line-height: $header-teaser-copy-lineheight;
		height: $header-teaser-copy-closedheight;
		overflow: hidden;
		position: relative;

		&.is-open {
			height: auto;
		}

		// gradient
		&::after {
			content: "";
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
			width: $header-teaser-copy-gradientwidth;
			height: $header-teaser-copy-lineheight;
			// todo: vendor prefix / oder besser ein mixin
			background: linear-gradient(
				to right,
				transparentize($header-teaser-bg-color, 1),
				transparentize($header-teaser-bg-color, 0) 80%
			);
		}

		&.is-open::after {
			display: none;
		}
	}
}

.c-header-teaser__copy--2col {
	@include media-query(m-up) {
		-webkit-column-count: 2; /* Chrome, Safari, Opera */
		-moz-column-count: 2; /* Firefox */
		column-count: 2;
	}
}

.c-header-teaser__copy__more {
	cursor: pointer;
	font-weight: $font-weight-bold;

	@include font-size($header-teaser-copy-more-fontsize, $header-teaser-copy-lineheight);

	position: absolute;
	right: $header-teaser-copy-more-posr;
	bottom: 0;

	&::after {
		@include icon-font(8px);

		content: $icon-font--arrow-down;
		vertical-align: middle;
		padding-left: $header-teaser-copy-more-icon-pl;
	}

	&.is-open::after {
		content: $icon-font--arrow-up;
	}
}

// Add-Brand Button
.c-header-teaser__brand-btn {
	cursor: pointer;
}

.c-header-teaser__brand-btn__text {
	font-weight: $font-weight-bold;
	padding-right: $header-teaser-brand-text-pr;
	vertical-align: middle;

	@include media-query(m-down) {
		display: none;
	}
}

.c-header-teaser__brand-btn__icon {
	@include icon-font();

	width: $header-teaser-brand-icon-width;
	display: inline-block;

	&::before {
		content: $icon-font--heart-outline;
		vertical-align: middle;
	}

	.c-header-teaser__brand-btn.is-active &::before {
		content: $icon-font--heart;
	}

	@include media-query(m-down) {
		position: absolute;
		top: 0;
		right: 0;
		width: $header-teaser-btn-mobile-width;
		height: $header-teaser-btn-mobile-height;
		text-align: center;
	}
}

// Small Video-Button
.c-header-teaser__video {
	cursor: pointer;

	@include media-query(m-down) {
		text-align: center;
		position: absolute;
		top: 0;
		right: $header-teaser-btn-mobile-width + $header-teaser-copy-more-icon-pl;
		width: $header-teaser-btn-mobile-width;
		height: $header-teaser-btn-mobile-height;
	}
}

.c-header-teaser__video-btn {
	@include icon-font();

	&::before {
		content: $icon-font--play;
		vertical-align: middle;
	}
}
