/* ------------------------------------*\
    #PRODUCT-PRICE
\*------------------------------------ */

$product-detail-current-price-font-size: 20px !default;
$product-list-current-price-font-size: 12px !default;
$checkout-header-current-price-font-size: 16px !default;
$cart-mobile-current-price-font-size: 14px !default;
$cart-mobile-item-total-price: 13px !default;

// Price
.c-product__price {
	font-weight: bold;
}

.c-product__old-price {
	font-size: $inuit-heading-size-milli;
	font-weight: normal;
	text-decoration: line-through;
}

.c-product__current-price {
	font-size: $product-detail-current-price-font-size;

	.c-checkout-header__price & {
		font-size: $checkout-header-current-price-font-size;
	}

	.c-cart__item-totalprice & {
		font-size: $cart-mobile-item-total-price;
	}

	//on product-list page set font-size to small
	.c-product-item__price & {
		font-size: $product-list-current-price-font-size;
	}
}
