/* ------------------------------------*\
    #ACCOUNT
\*------------------------------------ */
$account-img-size: 100px;
$account-icon-size: $inuit-heading-size-micro;
$account-tooltip-bottom: 10px;
$account-tooltip-bottom-mobile: 122px;
$account-tooltip-right-mobile: 11px;
$account-email-footer-text-font-size: 11px;
$account-email-image-size: 60px;
$account-email-image-size-m-down: 52px;
$account-button-border-radius: 3px;
$account-button-size-m-down: 100%;
$account-button-size-l-up: 60%;
$account-button-img-size: 15px;
$account-button-img-size-m: 13px;
$account-text-status-right: 20px;

/////////////////////////////////////////////////////////////////
// left Navigation
/////////////////////////////////////////////////////////////////

//body class page-account
.page-account {
	@include media-query(m-down) {
		.c-account-nav-wrapper {
			display: block;
		}

		.c-account {
			display: none;
		}
	}
}

// Return
.c-account-nav-wrapper {
	@include media-query(m-down) {
		display: none;
	}
}

.c-account__nav-header {
	background-color: $color-gray-lighter;
	margin-bottom: 0;
	padding-top: $spacing-unit--small;
	padding-bottom: $spacing-unit--small;

	@include media-query(m-up) {
		display: none;
	}
}

/////////////////////////////////////////////////////////////////
// Content generall classes / + mobile Navigation around Content
/////////////////////////////////////////////////////////////////

.c-account__content {
	background-color: $color-white;
}

// Header (only mobile)
.c-account__content-header {
	background-color: $color-gray-lighter;
	margin: 0;
	padding-top: $spacing-unit--small;
	padding-bottom: $spacing-unit--small;

	@include media-query(m-up) {
		display: none;
	}
}

.c-account__content-header__text {
	display: block;

	@include font-size($inuit-heading-size-3);

	font-weight: $font-weight-normal;
	padding-top: $spacing-unit;
	padding-bottom: $spacing-unit;
	text-align: center;
}

// list containing all "mein konto" topics and the current content
.c-account__content-list {
	margin: 0;
	list-style: none;
	padding: 0;
}

.c-account__content-list__item {
	@include media-query(m-down) {
		border-bottom: 1px solid $color-gray-dark;
	}
}

.c-account__content-list__header {
	margin: 0;
	display: block;

	@include font-size($inuit-heading-size-6, 1.7);

	font-weight: $font-weight-normal;
	padding: $spacing-unit $spacing-unit--small;
	position: relative;

	&::after {
		content: $icon-font--arrow-down-big;
		position: absolute;
		right: $spacing-unit;
		top: 50%;
		margin-top: -2px;

		@include icon-font($inuit-heading-size-micro);

		font-weight: normal;
	}

	&:hover {
		color: $color-black;
	}

	.is-active & {
		color: $color-black-light;
		font-weight: $font-weight-medium;

		&::after {
			content: $icon-font--arrow-up-big;
		}
	}
}

.c-account__content-list__header__text {
	display: block;
	font-weight: $font-weight-normal;

	.is-active & {
		font-weight: $font-weight-medium;
	}
}

/////////////////////////////////////////////////////////////////
// First Page Content / Account
/////////////////////////////////////////////////////////////////

.c-account-item {
	position: relative;
	text-align: center !important;
	padding-top: $spacing-unit;
	padding-bottom: $spacing-unit;
	margin-bottom: $spacing-unit--large;
	cursor: pointer;

	&:hover {
		.c-account-item__image {
			-webkit-transform: scale(1.025);
			transform: scale(1.025);

			@include ui-transition(0.2s, ease-in);
		}
	}
}

.c-account-item__image {
	width: $account-img-size;
	border-radius: 50%;
	border: 1px solid $color-gray-dark;

	@include ui-transition(0.2s, ease-out);
}

.c-account-item__title {
	display: block;
	font-size: $inuit-heading-size-3;
	line-height: 2;
	margin-left: $spacing-unit--large;
	margin-right: $spacing-unit--large;
	border-bottom: 1px solid $color-black-lighter;
}

.c-account-item__description {
	display: block;
}

.c-account-item__link {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	font-weight: $font-weight-bold;

	.c-icon--arrow-right {
		display: inline-block;
		color: $color-black;
		font-size: $account-icon-size;
		font-weight: $font-weight-bold;
		margin-left: $spacing-unit--tiny;
	}
}

/////////////////////////////////////////////////////////////////
// Other Things
/////////////////////////////////////////////////////////////////

//account tooltip
.c-account-tooltip {
	position: absolute;
	top: $account-tooltip-bottom;
	right: -$spacing-unit--tiny;

	@include media-query(m-down) {
		right: 0;
	}

	@include media-query(m) {
		right: 35%;
	}
}

//account error
.c-account--error {
	display: inline-block;
	position: absolute !important;
	width: 100%;
	margin-left: $spacing-unit--large !important;

	@include media-query(l-down) {
		margin-left: $spacing-unit--small !important;
	}

	&.u-error-text {
		@include media-query(l-down) {
			position: relative !important;
		}

		+ .c-dropdown {
			.c-dropdown__label {
				border-color: $color-error;
			}
		}
	}
}
//account info
.c-account-info {
	opacity: 0;
	transition: transform 500ms ease 0s;
	-webkit-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);

	&.show {
		opacity: 1;
		transition: transform 500ms ease 200ms;
		-webkit-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

// todo: this is not the right place
//birthdate desk, mobile
//scroolbar dropdown
.c-form-birthday + .c-dropdown {
	.c-dropdown__list {
		height: 200px;
		overflow: auto;
		padding-right: $spacing-unit--small;
	}
}

.c-select-birthday {
	width: 26%;
	padding-right: 14px;
}

.c-select-birthmonth {
	width: 46%;
	padding-right: 14px;
}

.c-select-birthyear {
	width: 28%;
}

.c-account .c-form-title + .c-dropdown,
.c-account .c-form-professionalTitle + .c-dropdown {
	width: 40%;
}

.u-birthday {
	display: inline;
}

// todo: Why do we need this class? Is only used in the next step Popup for E-Mail Change
// Following classes only (?) für E-Mail Change next step layer
.c-account__headline {
	margin-bottom: 0.5em;
	overflow: hidden;

	@include font-size($headline-font-size);

	text-align: center;

	span {
		display: inline-block;
		position: relative;
		line-height: 1;
	}

	span::after,
	span::before {
		content: "";
		position: absolute;
		border-bottom: 1px solid $color-gray-darker;
		top: 50%;
		width: 1000%;
	}

	span::before {
		right: 100%;
		margin-right: $spacing-unit;
	}

	span::after {
		left: 100%;
		margin-left: $spacing-unit;
	}
}

.c-account__email-image {
	font-size: $account-email-image-size;
	vertical-align: middle;
	margin-bottom: $spacing-unit--small;

	@include media-query(m-down) {
		font-size: $account-email-image-size-m-down;
	}
}

.c-account__email-text {
	font-size: $inuit-heading-size-6;
	font-weight: $font-weight-bold;
}

.c-account__email-footer-text {
	font-size: $account-email-footer-text-font-size;
	text-align: center;
	line-height: 1.2;
} //base ohne icon

// used for order history accordion ONLY (?) todo: Rename class
.c-account-accordion {
	border-color: $color-gray-dark;

	.is-open {
		background-color: $color-gray-lighter;

		+ .c-accordion__body {
			background-color: $color-gray-lighter;
		}
		// todo: c-icon should NOT be changed here, it is a DIFFERENT Component Class
		.c-icon::before {
			@include ui-transition();

			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}

	.c-icon::before {
		@include ui-transition();

		display: inline-block;
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	@include media-query(m-down) {
		background-color: $color-gray-lighter;
		border: none;
	}

	// todo: c-accordion__header should NOT be changed here, it is a DIFFERENT Component Class -> accordion.scss
	.c-accordion__header {
		border-color: $color-gray-dark;
		padding: 0 $spacing-unit 0 0;

		&::after {
			content: "";
		}
	}
}

.c-account-tracking-button {
	border: 2px solid $color-gray-dark;
	background: $color-gray-lighter;
	border-radius: $account-button-border-radius;

	@include media-query(m-down) {
		width: $account-button-size-m-down;
	}

	@include media-query(l-up) {
		width: $account-button-size-l-up;
	}
}

.c-account-tracking-button__img {
	width: $account-button-img-size;
	height: $account-button-img-size;
	vertical-align: text-bottom;

	@include media-query(l-up) {
		margin-left: $spacing-unit--small;
	}

	@include media-query(m) {
		width: $account-button-img-size-m;
		height: $account-button-img-size-m;
	}
}

.c-account-text-status {
	position: absolute;
	right: $account-text-status-right;
	bottom: $spacing-unit;
}

/////////////////////////////////////////////////////////////////
// referral modal
/////////////////////////////////////////////////////////////////
.referral {
	&__row {
		display: flex;
		justify-content: space-between;
		padding: $spacing-unit--small 0;
		margin-top: $spacing-unit;
		border-top: 1px solid $color-gray-lighter;

		&:first-child {
			border-top-width: 0;
		}
	}

	&__label {
		font-weight: 500;
		margin-bottom: $spacing-unit--small;
		margin-top: $spacing-unit;
	}

	&__copy {
		margin: ($spacing-unit + $spacing-unit--small) 0 0 $spacing-unit--small;
	}
}

/////////////////////////////////////////////////////////////////
// styles for ParcelLab generated content
/////////////////////////////////////////////////////////////////
#pl-action-box-container {
	.pl-vote,
	div a[href='//parcellab.com']
	{
		display: none !important;
	}
}
