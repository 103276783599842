/* ------------------------------------*\
    #INSTAGRAM
\*------------------------------------ */

.instagram-image {
	width: 350px;
	padding: 0 ($spacing-unit / 2);
}

.c-instagram__show-more {
	text-align: right;

	@include media-query(m-down) {
		text-align: center;
	}
}
