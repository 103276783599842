/* ------------------------------------*\
    #PRIMARY NAV
\*------------------------------------ */
@import "../../settings/header-settings";

// Secondary Navigation
$second-nav-spacing: $gl_second-nav-spacing;
$second-nav-item-padding: $second-nav-spacing/2;
$secondary-nav-item-mr: 114px;

$second-navi-height: $gl_header-navigation-bar-height;
$second-navi-pt: 10px;
$second-navi-line-height: $second-navi-height - $second-navi-pt;

$flyout-animate-time: 240ms;

//-----------------------------------
// Secondary-Navigation
//-----------------------------------
.c-secondary-nav {
	align-items: center;
	color: var(--color-gray-70);
	font-size: 1rem;
	height: 100%;
	line-height: 1.4285714rem;
	list-style: none;
	margin: 0;
	display: inline-flex;
	vertical-align: top;

	.is-header-sticky & {
		align-items: center;
	}

	// Arabic specific styles
	.language-ar & {
		direction: rtl;
		text-align: left;
	}
}

.c-secondary-nav__item {
	justify-content: center;
	flex-direction: column;
	display: inline-flex;
	height: 100%;
	margin-right: 0.857142857142857rem;

	&--search {
		float: right;
		height: $gl_header-navigation-bar-height;
		display: flex;
		align-items: center;

		.t-classic & {
			margin-right: $secondary-nav-item-mr;
		}
	}

	&.is-active:not(.toggle-is-focused),
	.touchevents &.is-open:not(.toggle-is-focused) {
		.c-secondary-nav__link {
			border-bottom-color: var(--color-black-primary);
		}
	}

	.no-touchevents &:not(.toggle-is-focused) {
		@include animate_underline_on_hover(
			$line-height: 2px,
			$line-color: var(--color-black-primary),
			$duration: 240ms,
			$element: ".c-secondary-nav__link"
		);
	}

	.c-secondary-nav__link {
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;
		display: inline-block;
		position: relative;
		padding: 0.285714285714286rem 0;
		color: var(--color-black-primary);
		font-weight: 500;
	}
}

.c-secondary-nav__flydown_button_container {
	position: relative;
	width: 100%;
	background-color: white;
	top: -0.4rem;
}

.c-secondary-nav__flydown_button {
	position: absolute;
	left: calc(50% - 0.475rem);
}

.c-secondary-nav__teaser-navigation-link {
	overflow: hidden;
	max-width: 100%;
	background: $color-black;
	position: relative;
	color: $color-white;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2222222;

	&-image {
		width: 100%;
		height: auto;
		transition: transform $flyout-animate-time * 1.5 ease-in-out, opacity $flyout-animate-time * 1.5 ease-in-out;
		transform-origin: center;

		@include media-query(l-up) {
			max-height: 263px;
		}
	}

	&-bg {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 78px;
		transition: transform $flyout-animate-time * 1.5 ease-in-out, opacity $flyout-animate-time * 1.5 ease-in-out;
		transform-origin: center;
		background: linear-gradient(to top, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0) 100%);
	}

	&-text-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 12px;
		position: absolute;
		left: 0;
		bottom: 12px;
	}

	&:hover {
		color: $color-white;
		.c-secondary-nav__teaser-navigation-link-image {
			transform: scale(1.075);
		}
	}

	.no-touchevents & {
		@include animate_underline_on_hover($duration: $flyout-animate-time, $element: ".animate-underline");
	}
}
