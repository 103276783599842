/* ------------------------------------*\
    #BOX
\*------------------------------------ */

.c-info-box {
	display: inline-block;
	padding: $spacing-unit--tiny;
	margin-bottom: $spacing-unit--small;

	@include font-size($inuit-heading-size-micro, 1);

	font-weight: 700;

	&:empty {
		display: none;
	}
}

.c-info-box--large {
	padding-left: $spacing-unit--small;
	padding-right: $spacing-unit--small;
}

.c-info-box--huge {
	padding-left: $spacing-unit;
	padding-right: $spacing-unit;
}

.c-info-box--red {
	color: $color-white;
	background-color: $color-brand-primary;
}

.DISCOUNT_GRADE_1 {
	color: $color-black;
	background-color: $color-gray-dark;
}

.DISCOUNT_GRADE_2 {
	color: $color-white;
	background-color: $color-brand-primary;
}

.c-info-box.NO_DISCOUNT {
	display: none;
}

.c-info-box--gray {
	color: $color-black;
	background-color: $color-gray-dark;
}

.c-info-box--black {
	color: $color-white;
	background-color: $color-black;
}
