// /*------------------------------------*\
//     nprogress Plugin not BEM
//     confirm but independent
// \*------------------------------------*/
#nprogress {
	pointer-events: none;
}

#nprogress .bar {
	background: $color-black;
	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
	display: block;
	position: absolute;
	right: 0;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px $color-black, 0 0 5px $color-black;
	opacity: 1;
	-webkit-transform: rotate(3deg) translate(0, -4px);
	transform: rotate(3deg) translate(0, -4px);
}

.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}

.nprogress-custom-parent #nprogress .bar {
	position: absolute;
}
