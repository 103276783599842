/* ------------------------------------*\
    #CLASSIC-HEADLINE
\*------------------------------------ */

$classic-headline-font-size-default: $heading-classic-secondary-font-size-delta;
$classic-headline-line-height-default: $heading-classic-secondary-font-size-delta * 1.2; // *1.2 headline line-height
$classic-headline-font-size-huge: $heading-classic-secondary-font-size-alpha;
$classic-headline-font-size-large: $heading-classic-secondary-font-size-gamma;
$classic-headline-font-size-larger: $heading-classic-secondary-font-size-beta;
$classic-headline-font-size-medium: $heading-classic-secondary-font-size-epsilon;
$classic-headline-font-size-small: $heading-classic-secondary-font-size-zeta;

.c-classic-headline {
	@extend .ocm-headline2;

	&--gold {
		color: $color-classic-primary;
	}

	&--red {
		color: $color-brand-primary;
	}

	&--white {
		color: $color-white;
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&--small {
		font-size: $classic-headline-font-size-small;
	}

	&--medium {
		font-size: $classic-headline-font-size-medium;
		margin-bottom: $spacing-unit--small;
	}

	&--large {
		font-size: $classic-headline-font-size-large;
		line-height: 1.2;

		@include media-query(l-down) {
			font-size: $classic-headline-font-size-default;
		}

		// ------------------------------------------------------------------ //
		// There is a special rule for hotel headlines in content-hotel.scss! //
		// ------------------------------------------------------------------ //
	}

	&--larger {
		font-size: $classic-headline-font-size-larger;
	}

	&--huge {
		font-size: $classic-headline-font-size-huge;
	}

	&--mobile-line-height {
		line-height: $heading-classic-secondary-font-size-epsilon;
		margin-bottom: $spacing-unit--small;
	}
}

.c-classic-headline-primary {
	font-size: $classic-headline-font-size-small;
	font-weight: $font-weight-medium;
	line-height: 1.1;

	@include media-query(m) {
		font-size: $inuit-heading-size-5;
	}

	@include media-query(m-down) {
		font-size: $inuit-heading-size-milli;
	}
}
