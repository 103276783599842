// 1) figma: Filled Button Black // FE: Primary not Dark // vue Shared: .is-primary
// 2) figma: Filled Button White // FE: Primary Dark // vue Shared: .is-primary-dark
// 3) figma: Outline Button Black Blur // FE: Secondary not Dark // vue Shared: .is-secondary
// 4) figma: Outline Button White Blur // FE: Secondary Dark // vue Shared: .is-secondary-dark
// 5) figma: Filled Button Red // FE: Prominent // vue Shared: .is-prominent
// 6) figma: Text Button // FE: Text // vue Shared: .is-text

//Buttons
$buttons: (
	// 1) figma: Filled Button Black
	"primary":
		(
			"color": var(--color-white),
			"background": var(--color-black-primary),
			"blur": false,
			"border": false,
			"border-focus": var(--color-black-primary),
			"background-hover": var(--color-gray-90),
			"background-active": var(--color-gray-80),
			"color-disabled": var(--color-gray-50),
			"background-disabled": var(--color-gray-30),
			"border-disabled": false,
		),
	// 2) figma: Filled Button White
	"primary-dark":
		(
			"color": var(--color-black-primary),
			"background": var(--color-white),
			"blur": false,
			"border": false,
			"border-focus": var(--color-white),
			"background-hover": var(--color-gray-40),
			"background-active": var(--color-gray-60),
			"color-disabled": var(--color-gray-50),
			"background-disabled": var(--color-gray-30),
			"border-disabled": false,
		),
	// 3) figma: Outline Button Black Blur
	"secondary":
		(
			"color": var(--color-black-primary),
			"background": var(--color-white-o-20),
			"blur": true,
			"border": var(--color-black-primary),
			"border-focus": var(--color-black-primary),
			"background-hover": var(--color-gray-40),
			"background-active": var(--color-gray-60),
			"color-disabled": var(--color-gray-30),
			"background-disabled": var(--color-white-o-20),
			"border-disabled": var(--color-gray-30),
		),
	// 4) figma: Outline Button White Blur
	"secondary-dark":
		(
			"color": var(--color-white),
			"background": var(--color-black-primary-o-20),
			"blur": true,
			"border": var(--color-white),
			"border-focus": var(--color-white),
			"color-hover": var(--color-black-primary),
			"background-hover": var(--color-white),
			"border-hover": var(--color-gray-40),
			"color-active": var(--color-black-primary),
			"background-active": var(--color-gray-40),
			"border-active": var(--color-gray-40),
			"color-disabled": var(--color-gray-50),
			"background-disabled": var(--color-black-primary-o-20),
			"border-disabled": var(--color-gray-50),
		),
	// 5) figma: Filled Button Red
	"prominent":
		(
			"color": var(--color-white),
			"background": var(--color-red-primary),
			"blur": false,
			"border": false,
			"border-focus": var(--color-red-primary),
			"background-hover": var(--color-red-hover),
			"background-active": var(--color-red-active),
			"color-disabled": var(--color-gray-50),
			"background-disabled": var(--color-gray-30),
			"border-disabled": false,
		),
	// 6) figma: Text Button
	"text":
		(
			"color": var(--color-black-primary),
			"background": transparent,
			"blur": false,
			"border": false,
			"border-focus": var(--color-black-primary),
			"background-hover": var(--color-gray-40),
			"background-active": var(--color-gray-60),
			"color-disabled": var(--color-gray-50),
			"background-disabled": transparent,
			"border-disabled": false,
		)
);

@each $button, $button-props in $buttons {
	.ocm-button__#{$button} {
		@include styleguide-button($button-props);
	}
}

// deprecated button-class-names
.ocm-button__filled-black {
	@extend .ocm-button__primary;
}
.ocm-button__text {
	@extend .ocm-button__text;
}
.ocm-button__outline-black {
	@extend .ocm-button__secondary;
}
