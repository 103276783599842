///*------------------------------------*\
//
//\*------------------------------------*/

$text-max-width: 70%;

.o-text-note {
	color: $color-gray-dark;

	@include media-query(m-down) {
		font-size: 11px;
	}
}

.o-ocm-text-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: $text-max-width;

	@include media-query(m-down) {
		max-width: 100%;
	}
}
