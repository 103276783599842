/* ------------------------------------*\
    #ROTATION TEASER
\*------------------------------------ */

$rotation-thumbs-color: $color-white;
$rotation-thumbs-font-size: 16px;
$rotation-thumbs-margin-h: 35px;
$rotation-thumbs-margin-h-m: 20px;
$rotation-thumbs-padding-v: 5px;
$rotation-thumbs-pos-b: 10px;

.c-rotation-teaser {
	position: relative;
	margin: 0 auto;
	display: none;
	max-width: 2000px;

	&.slick-initialized {
		display: block;
	}

	.slick-dots li {
		width: auto;
		height: auto;

		@include media-query(m-up) {
			margin: 0 $rotation-thumbs-margin-h;
		}

		@include media-query(m) {
			margin: 0 $rotation-thumbs-margin-h-m;
		}
	}

	.slick-dots li:first-child {
		margin-left: 0;
	}

	.slick-dots li:last-child {
		margin-right: 0;
	}
}

.c-rotation-teaser__slides {
	position: relative;
	//max-height: 750px !important;

	video {
		width: 100%;
		margin: auto;
	}
}

.c-rotation-teaser__full-image {
	width: auto; // avoid wrong height in Safari 14.x - TUB-11858
	max-width: 100%;
	margin: 0;
}

.c-rotation-teaser__video {
	max-width: 100%;
	margin: 0;
}

.c-rotation-teaser__thumbs {
	display: inline-block;
	padding: $rotation-thumbs-padding-v 0;

	@include font-size($rotation-thumbs-font-size);

	font-weight: $font-weight-medium;
	color: $rotation-thumbs-color;
	text-shadow: 0 0 5px rgba($color-black, 0.9);

	.slick-active & {
		&::after {
			display: block;
			content: "";
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 1px;
			border-bottom: 1px solid $rotation-thumbs-color;
			box-shadow: 0 1px 1px 1px rgba($color-black, 0.2);
		}
	}

	&:hover {
		color: $color-white;

		&::after {
			display: block;
			content: "";
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 1px;
			border-bottom: 1px solid $rotation-thumbs-color;
			box-shadow: 0 1px 1px 1px rgba($color-black, 0.1);
		}
	}
}

.c-rotation-teaser .slick-dots {
	@include media-query(m-up) {
		position: absolute;
		bottom: $rotation-thumbs-pos-b;
	}
}
