/* ------------------------------------*\
    #CHECKBOX
\*------------------------------------ */

$checkbox-size: 16px;
$checkbox-size-m: 20px;
$checkbox-m: $spacing-unit--small;

/**
 * Styling for checkboxes.
 */
.c-classic-checkbox__label {
	display: inline-flex;
	line-height: $checkbox-size;
}

.c-classic-checkbox__label--ml {
	// margin left for better alignment
	& .c-classic-checkbox {
		min-width: $spacing-unit--large;
	}
}

.c-classic-checkbox {
	position: relative;
	padding-right: $spacing-unit--tiny;
	display: inline;
	vertical-align: middle;
}

.c-classic-checkbox--brand {
	display: inline-block;
	vertical-align: middle;
	visibility: hidden;
	height: 0;
	width: 0;
	padding: 0;
	overflow: hidden;

	& + label {
		font-weight: $font-weight-medium;
		cursor: pointer;

		/* Todo:  positioning bug. Quick fix. */
		position: relative;
		left: -3px;
	}

	& + label::before {
		content: $icon-font--checkbox-inactive;

		@include icon-font();

		padding-right: $spacing-unit--small;
		height: $checkbox-size;
		width: $checkbox-size;
		font-size: $checkbox-size;
		line-height: $checkbox-size;
		vertical-align: top;

		@include media-query(m-down) {
			height: $checkbox-size-m;
			width: $checkbox-size-m;
			font-size: $checkbox-size-m;
			line-height: $checkbox-size-m;
		}
	}

	&:checked + label::before {
		content: $icon-font--checkbox-active;
	}
}
