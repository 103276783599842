/* ------------------------------------*\
    #DIVIDER
\*------------------------------------ */

hr {
	border-color: rgba($color-gray-lighter, 0.2);
}

.c-hr {
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid $color-gray-light;
	margin: $spacing-unit 0;
}

.c-hr--classic {
	border-bottom: 1px solid rgba($color-classic-primary, 0.4);
}
