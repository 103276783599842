/* ------------------------------------*\
    #TEASER-BADGE
\*------------------------------------ */

$teaser-badge-color: $color-white;
$teaser-badge-p: 10px;
$teaser-badge-border-width: 1px;
$teaser-badge-size: 280px;
$teaser-badge-size-m: 250px;
$teaser-badge-size-inner: $teaser-badge-size - (2 * $teaser-badge-p);
$teaser-badge-size-inner-m: $teaser-badge-size-m - (2 * $teaser-badge-p);

$teaser-badge-headline-pos-b: -4px;

.c-teaser-badge {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding: $spacing-unit--small;
	z-index: 1;

	&.c-teaser-badge--bottom {
		bottom: 0;
		height: 50%;
		width: 100%;
		padding-top: 0;
	}

	&.c-teaser-badge--right {
		right: 0;
		height: 100%;
		width: 50%;
		padding-left: 0;
	}
}

.c-teaser-badge__content {
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background-color: $color-white;
	opacity: 0.9;
}

.c-teaser-badge__headline {
	display: inline-block;
	position: relative;
	margin-bottom: $spacing-unit;

	@include font-size($heading-secondary-font-size-gamma);

	@include media-query(l-down) {
		@include font-size($heading-secondary-font-size-epsilon);

		margin-bottom: $spacing-unit--small;
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		height: 1px;
		bottom: $teaser-badge-headline-pos-b;
		border-bottom: 1px solid $color-gray-darker;
		margin-left: -(20%/2);
		width: 20%;
	}
}

.c-teaser-badge__topic {
	display: block;

	@include font-size($inuit-heading-size-4);

	font-weight: $font-weight-bold;

	@include media-query(l-down) {
		@include font-size($inuit-heading-size-6);
	}
}

.c-teaser-badge__old-price {
	display: block;
	text-decoration: line-through;
}

.c-teaser-badge__current-price {
	display: block;

	@include font-size($inuit-heading-size-4);

	font-weight: $font-weight-bold;

	@include media-query(l-down) {
		line-height: 1;
		font-size: $inuit-heading-size-6;
	}
}

.c-teaser-badge__discount {
	display: block;
	font-size: 11px;
	font-weight: $font-weight-bold;
	color: $color-brand-primary;
}

.c-teaser-text-badge {
	text-align: center;

	@include media-query(l-up) {
		position: absolute;
		right: 0;
		padding: $teaser-badge-p - $teaser-badge-border-width;
		border-radius: 50%;
		border: $teaser-badge-border-width solid $teaser-badge-color;

		&.c-teaser-text-badge--left {
			left: 10px;
			bottom: -80px;
		}

		&.c-teaser-text-badge--right {
			right: 10px;
			bottom: -80px;
		}
	}

	@include media-query(xl-up) {
		width: $teaser-badge-size;
		height: $teaser-badge-size;
	}

	@include media-query(l) {
		width: $teaser-badge-size-m;
		height: $teaser-badge-size-m;
	}
}

.c-teaser-text-badge__inner {
	@include media-query(l-up) {
		border-radius: 50%;
		background-color: $teaser-badge-color;
	}

	@include media-query(xl-up) {
		width: $teaser-badge-size-inner;
		height: $teaser-badge-size-inner;
	}

	@include media-query(l) {
		width: $teaser-badge-size-inner-m;
		height: $teaser-badge-size-inner-m;
	}
}

.c-teaser-text-badge__content {
	display: block;
	padding-top: 30px;
}

.c-teaser-text-badge__bonus {
	@include font-size(80px, 1);
	@include media-query(l) {
		@include font-size(50px, 1);
	}
}

.c-teaser-text-badge__text {
	margin-bottom: $spacing-unit--small;

	@include font-size(14px);

	@include media-query(l) {
		@include font-size(12px);
	}
}

// Imgage Badge auf Hightlight-Teaser / Teaser-Slider //
.c-teaser-img-badge {
	position: absolute;
	width: auto;
	height: auto;
	z-index: 1;
}
