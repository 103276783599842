/* ------------------------------------*\
    #MAIN CONTENT
\*------------------------------------ */

$main-offset-top: $gl_header-logo-bar-height + $gl_header-logo-bar-margin + $gl_header-top-links-height;

$main-offset-top-l-down: $gl_header-logo-bar-height + $gl_header-top-links-height-l-down;

.c-main-content {
	margin-bottom: 20px;
	min-height: 1040px;
	padding-top: 3.2857142rem;
	position: relative;

	.is-landingpage &,
	.is-checkout &,
	.page-ContactPage &,
	.template-pages-SignInPageTemplate & {
		padding-top: 0;
	}

	.is-site-classic .template-pages-SignInPageTemplate:not(.page-registerConfirmationPage) & {
		margin-top: 3.2857142rem;
	}

	// checkout has a separate header-design
	.is-checkout & {
		margin-bottom: unset;
		min-height: unset;
		flex-grow: 1;
	}

	.is-checkout-loading & {
		min-height: 50vh;
	}

	// Classic Map Page has no header
	.template-pages-classic-mapPage & {
		margin-bottom: 0;
		min-height: 0;
		padding-top: 0;
	}

	// My Account Page
	.page-account & {
		min-height: 80vh;
	}
}

.is-checkout .c-offcanvas-innerwrapper {
	min-height: 100vh;
	/* stylelint-disable-next-line */
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
}
