///*------------------------------------*\
//    #GLOBAL
//\*------------------------------------*/

// o-pagewrap
$max-page-width-with-padding: 1408px;
$inuit-page-wrap-collapse-at: 720px;

// /*------------------------------------*\
//     #BREAKPOINTS
// \*------------------------------------*/

@import "../../../../../shared/css/responsive";
@import "../../../../../shared/css/variables";

// If we have included this file, set a variable to tell the rest of the
// framework that we have some responsive settings.
$inuit-responsive-settings: true;

/* spacing base unit defined in style guide 2022 */
//todo: replace spacing with $space-....
$lib-spacing-base-unit: 4px; //$spacing-base-unit

/* Spacing defined in library-guild */
$lib-space-xs: 4px; //$space-xs
$lib-space-s: 8px; //$space-s:
$lib-space-m: 16px; //$space-m
$lib-space-l: 32px; //$space-l
$lib-space-xl: 48px; //$space-xl
$lib-space-xxl: 64px; //$space-xxl
