/* ------------------------------------*\
    #PAGE-WRAP
\*------------------------------------ */

/**
 * A main max-width page-wrapper.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-page-wrap-namespace: $inuit-namespace !default;

$page-wrap-padding: 4 * $lib-spacing-base-unit;

$inuit-enable-page-wrap--responsive: false !default;
$inuit-page-wrap-collapse-at: 720px !default;

.#{$inuit-page-wrap-namespace}o-page-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: $max-page-width-with-padding;
	padding-left: 1.142857142857143rem;
	padding-right: 1.142857142857143rem;
}
