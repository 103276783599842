/* ------------------------------------*\
    #HEADLINE
\*------------------------------------ */

$headline-product-font-size: $heading-secondary-font-size-delta;
$headline-product-font-size-small: $secondary-font-size-s;
$headline-font-size: $heading-secondary-font-size-delta;
$headline-font-weight: $font-weight-medium;
$headline-ultra-font-size: $heading-secondary-font-size-ultra;
$headline-large-font-size: $heading-secondary-font-size-gamma;
$headline-huge-font-size: $heading-secondary-font-size-beta;
$headline-large-classic-color: $color-classic-primary;

.o-headline {
	margin-bottom: 0.5em;
	overflow: hidden;

	@extend .ocm-headline2;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 0;
	}
}

.o-headline--checkout {
	font-weight: 600;
	line-height: 1.2em;
	font-size: 18px;
	margin: 0;

	@include media-query(l-up) {
		text-align: center;
	}
}

.o-headline--ultra {
	margin: 0 auto;

	@include font-size($headline-ultra-font-size);

	font-weight: lighter;
	line-height: 0.95;
	text-indent: 7%;

	@include media-query(xl-down) {
		font-size: $headline-ultra-font-size * 0.75;
	}

	@include media-query(m-down) {
		font-size: $headline-ultra-font-size * 0.5;
	}

	@include media-query(s-down) {
		font-size: $headline-ultra-font-size * 0.25;
	}

	& > span {
		@include font-size($headline-ultra-follow-font-size);

		font-weight: 700;
		line-height: 0.75;

		@include media-query(xl-down) {
			font-size: $headline-ultra-follow-font-size * 0.75;
		}

		@include media-query(m-down) {
			font-size: $headline-ultra-follow-font-size * 0.5;
		}

		@include media-query(s-down) {
			font-size: $headline-ultra-follow-font-size * 0.25;
		}
	}
}

.o-headline--large {
	//@include font-size($headline-large-font-size);
}

.o-headline--huge {
	@include font-size($headline-huge-font-size);
}

.o-headline--caps {
	@include font-size($headline-large-font-size);

	text-transform: uppercase;
}

.o-headline--luxus {
	color: $headline-large-classic-color;
}

// headline for products items, product detail page
.o-headline-product {
	@include font-size($headline-product-font-size, none);

	@include media-query(m-down) {
		@include font-size($inuit-heading-size-milli, none);

		margin-bottom: 0;
		vertical-align: top;
	}
}

.o-headline-product__top {
	display: block;
	font-weight: $font-weight-bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.o-headline-product__bottom {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// headline small for products items, product detail page
.o-headline-product--small {
	@include font-size($headline-product-font-size-small, none);

	@include media-query(m-down) {
		@include font-size($inuit-heading-size-milli, none);
	}
}

.o-headline-service {
	margin-bottom: $spacing-unit--small;
	font-weight: $font-weight-bold;
	line-height: 1;
}

.o-headline-mobile {
	padding-top: $spacing-unit + $spacing-unit--small;
	padding-bottom: $spacing-unit + $spacing-unit--small;
	padding-left: $spacing-unit--small;
	padding-right: $spacing-unit--small;
	margin-bottom: 0;
	background-color: $color-gray-lighter;
	text-align: center;

	@include font-size($inuit-heading-size-3);

	font-weight: $font-weight-normal;
}

// todo: LATER eigentlich sollte o-headline-mobile sich doch immer so verhalten ???
.o-headline-mobile--no-margin {
	margin-left: -$spacing-unit--small;
	margin-right: -$spacing-unit--small;
}

// Classic Subheadlines
.o-headline-classic-sub {
	font-weight: $font-weight-medium;
	margin-top: $spacing-unit;
	margin-bottom: $spacing-unit--small;

	&:first-child {
		margin-top: 0;
	}
}
