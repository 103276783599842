/* ------------------------------------*\
    #SOCIAL-ICONS
\*------------------------------------ */

$video-link-size: 60px;

// container with an image inside, showing video-start-button in the center
.c-video-image-link {
	cursor: pointer;
	position: relative;
	max-width: 100%;

	&::after {
		@include icon-font($video-link-size);

		content: $icon-font--play;
		color: $color-white-transparent-dark;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -($video-link-size / 2);
		margin-top: -($video-link-size / 2);
		width: $video-link-size;
		height: $video-link-size;
	}

	&:hover::after {
		color: $color-white;
	}

	&.is-black {
		&::after {
			color: $color-black-transparent;
		}

		&:hover::after {
			color: $color-black;
		}
	}

	.c-image-teaser & {
		min-height: 20px;
	}
}

.c-video-teaser {
	cursor: pointer;
}

.c-video-teaser__img {
	@include aspect-ratio(673, 400);

	max-height: 400px;
	width: 100%;
}

//close button
.c-video-close {
	position: absolute;
	z-index: 100;
	top: -$spacing-unit;
	right: -$spacing-unit;
	padding: $spacing-unit--small !important;
	font-size: $inuit-base-font-size !important;
	border-radius: 50%;
	color: $color-white;
	background-color: $color-black;
	border: none;

	@include media-query(m-down) {
		top: $spacing-unit--small;
		right: $spacing-unit--small;
	}
}
