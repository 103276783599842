/* ------------------------------------*\
    #PACK addition
\*------------------------------------ */

/**
 *  add the missing vertical-align: top to the .o-pack__item
 */

.o-pack--top .o-pack__item {
	vertical-align: top;
}
