.adyen-mock-c {
	margin: 50px;
	font-size: 1.2em;
}

.adyen-mock-c ul {
	margin-top: 10px;
}

.adyen-mock-c a {
	color: blue;
}

.adyen-mock-n {
	margin: 50px;
	font-size: 1.2em;
	font-weight: bold;
}

.adyen-mock-s {
	margin: 50px;
	font-size: 1.4em;
	font-weight: bold;
	width: 200px;
	text-align: center;
	background-color: var(--color-red-primary);
}

.adyen-mock-s a {
	color: white;
}

#adyen-mock-dialog {
	visibility: hidden;
}

/* TUB-3101: Bitte die folgenden Sachen im style.css vom Patternlab aktualisieren */
.c-account-item {
	position: relative;
	text-align: center !important;
	padding-top: 18px;
	padding-bottom: 18px;
	margin-bottom: 36px;
}

.c-account-item:hover .c-account-item__image {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	transition: all 0.2s ease-in;
}

.c-account-item__link {
	position: relative; /* Das relativ kann nach der Portierung entfernt werden */
	width: 100%;
	left: 0;
	bottom: 0;
	font-weight: 700;
}

/* TUB-3130 */
.c-form-row .c-form-title + .c-dropdown,
.c-form-row .c-form-professionalTitle + .c-dropdown {
	width: 40%;
}

/* TUB-7177 */
.c-form-row .c-form-title.c-dropdown--fullwidth + .c-dropdown {
	width: 100%;
}

/* TUB-3238 */
.c-checkout__address-row,
.c-checkout-address__row {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.c-category-bar__content {
	display: none;
}

/* TUB-3223 */
.c-checkout-creditcard__number.u-error {
	opacity: 0.7;
}

.c-product-detail__headline-caps {
	display: inline-block;
}
