.c-department-reco {
	position: relative;
	margin-bottom: $lib-space-xl;

	@include media-query(m-up) {
		margin-bottom: $lib-space-xxl;
	}

	.c-department-reco-header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: $lib-space-m;
	}

	.c-department-reco-headline {
		font-size: 22px;
		line-height: 32px;
		font-weight: 600;
	}

	.c-department-reco-link {
		@extend .ocm-button__outline-black;
		@extend .ocm-button--smallOnMobile;
	}

	// block space before widget is loaded
	// blocked hight is just estimated

	.c-department-reco-space {
		display: block;
		box-sizing: content-box;

		#kidsTopProducts &,
		#kidsMyNovelities & {
			////Slider with images
			height: 80px; // height: Subtext, margin-bottom: 20px
			padding-top: 67%; // Breite / Höhe aller Slider-Bilder
			@include media-query(m-up) {
				padding-top: 24%; // Breite / Höhe aller Slider-Bilder
			}
		}

		#kidsMyBrands & {
			height: 135px; // height: Logo 75px, margin-bottom: 60px
			padding-top: 157%; // Breite / Höhe der Kacheln
			@include media-query(m-up) {
				height: 145px; // height: Logo 115px, margin-bottom: 30px
				padding-top: 42%; // Breite / Höhe der Kacheln
			}
		}
	}

	.epoq-widget-initialized .c-department-reco-space {
		display: none;
	}
}
