/* ------------------------------------*\
    #DROPDOWN ( dropdown desk-list , mobile-select)
\*------------------------------------ */
$dropdown-height: $inuit-text-input-height;
$dropdown-border-color: $inuit-text-input-border-color;
$dropdown-label-icon-font-size: 10px;
$dropdown-list-pos-t: 20px;
$dropdown-item-p: 4px;

.c-dropdown {
	position: relative;
}

.c-dropdown__label {
	position: relative;
	display: block;
	height: $dropdown-height;
	line-height: $dropdown-height - 2;
	font-weight: normal;
	border: 1px solid $dropdown-border-color;

	> span {
		padding-left: $spacing-unit--small;
	}

	&::after {
		position: absolute;
		right: $spacing-unit--small;
		top: 50%;
		margin-top: -$dropdown-label-icon-font-size/2;
		content: $icon-font--arrow-down-big;
		color: $color-black;

		@include icon-font($dropdown-label-icon-font-size);
		@include ui-transition(0.5s);

		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	&.is-open::after {
		@include ui-transition(0.5s);

		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

.c-dropdown__list {
	position: absolute;
	top: $dropdown-height - 1; // minus border-width
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: $color-white;
	border: 1px solid $dropdown-border-color;
	z-index: 1;

	> li {
		padding-left: $spacing-unit--small;
		padding-top: $spacing-unit--tiny;
		padding-bottom: $spacing-unit--tiny;
		min-height: $spacing-unit;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;

		&.is-selected,
		&:hover {
			background-color: $color-gray-light;
		}
	}
}

// dropdown image
.c-dropdown-image {
	position: relative;

	a {
		font-weight: normal;
	}

	img {
		max-width: 25.8px;
		margin-bottom: $spacing-unit--tiny;
		height: 15px;
	}

	.c-dropdown__label {
		display: block;
		height: $dropdown-height;
		line-height: $dropdown-height;
		border: 1px solid $color-gray-dark;
		cursor: pointer;

		> span {
			padding-left: $spacing-unit--tiny;
		}

		img {
			height: auto;
		}
	}

	.c-dropdown__label::after {
		position: absolute;
		top: $spacing-unit;
		right: $spacing-unit;
	}
}

.c-dropdown__list-image {
	position: absolute;
	width: 100%;
	z-index: z("dropdown-list");
	padding: 1px;
	background-color: $color-white;
	border: 1px solid $color-black;
	border-top: none;

	li.is-selected,
	li:hover {
		background-color: $color-gray-light;
	}

	a {
		display: block;
		padding: $spacing-unit--tiny;
	}
}

// no-touch, touch select
.no-touchevents select.c-formElement {
	display: none !important;
}

.no-touchevents .c-dropdown {
	display: block;
}

.touchevents .c-dropdown {
	@include media-query(m-down) {
		display: none !important;
	}
}

.touchevents select.c-formElement {
	display: none;

	@include media-query(m-down) {
		display: inline-block;
		width: 100%;
		border: 1px solid $color-black-light;
		background-color: $color-white;
		background-image: url(/_ui/responsive/common/images/icons/arrow_down_big.svg);
		background-size: $spacing-unit $spacing-unit;
		background-position: right 8px center;
		background-repeat: no-repeat;
		padding-left: 8px;
	}
}
