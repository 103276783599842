/* ------------------------------------*\
    #BANDEROLE
\*------------------------------------ */

.c-banderole {
	display: none;
	transition: transform 0.3s ease-in;
	transform: translateY(-100%);
	border-bottom: 1px solid transparent;

	&.is-top {
		border-bottom-width: 0;
	}

	&.slided-in {
		transform: unset;
	}
}

.c-banderole-grid-content {
	display: grid;
	grid-template-columns: 1fr min-content;
	align-items: start;
	column-gap: 0.5714286rem;
	cursor: pointer;
}

.c-banderole-copy-button {
	display: inline-grid;
	grid-template-columns: 1fr min-content;
	align-items: center;
	column-gap: 0.2857143rem;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			text-decoration: underline;
			text-underline-offset: 2px;
		}
	}
}

.c-banderole-modal-button {
	display: grid;
	place-content: center;
	width: 1.7142857rem;
	height: 1.7142857rem;
	margin: -0.2857143rem !important;
	translate: 0 2px;
}

:is(.c-banderole-copy-button, .c-banderole-modal-button):focus-visible {
	outline-color: currentColor;
}
