/* ------------------------------------*\
    #ALIGN
\*------------------------------------ */

/**
 * Alignment helper-class.
 */

.u-align-top {
	vertical-align: top !important;
}

.u-align-middle {
	vertical-align: middle !important;
}

// todo: Wo wird diese verwendet? Generell überarbeiten?
.u-align-middle-m-down {
	@include media-query(m-down) {
		vertical-align: middle !important;
	}
}

.u-align-bottom {
	vertical-align: bottom !important;
}

.u-align-right {
	text-align: right !important;
}

.u-align-left {
	text-align: left !important;
}

.u-align-center {
	text-align: center !important;
}

.u-align-center-l-up {
	@include media-query(l-up) {
		text-align: center !important;
	}
}
