/* ------------------------------------*\
    #BUTTONS
\*------------------------------------ */

// Spacing defined in library-guild
$spacing-base-unit: 4px;

.o-btn {
	@include font-size($u-fontsize-s);

	font-weight: $font-weight-normal;
	display: inline-flex;
	vertical-align: unset;
	align-items: center;
	justify-content: center;
	padding-top: unset;
	padding-bottom: unset;
	height: $spacing-base-unit * 12;

	&--block-centered {
		display: block;
		margin: auto;
	}
}

.o-btn--small {
	@include font-size($u-fontsize);

	height: $spacing-base-unit * 8;
}

.o-btn--medium {
	@include font-size($u-fontsize);

	padding-left: $spacing-base-unit * 4;
	padding-right: $spacing-base-unit * 4;
	height: $spacing-base-unit * 10;
}
