/* ------------------------------------*\
    #RESTAURANT ITEM
\*------------------------------------ */

// Variables Start
$restaurant-saving-font-size: $heading-secondary-font-size-alpha;

$description-height: 250px;

$tablet-image: 900px;

$card-animation-time: 0.25s;

$restaurant-container-height: 456px;

// Variables End

.c-restaurant-list {
	@include media-query(m-down) {
		width: 100%;
	}

	@include media-query(m) {
		width: 49%;
	}

	@include media-query(l-up) {
		width: 33.333%;
	}

	@include media-query(xl-up) {
		width: 33.333%;
	}
}

.c-restaurant-item {
	position: relative;
	display: block;
	text-align: left;
	border: 1px solid $color-gray-darker;
	margin-right: 4px;

	// Telling the user what happens on click by highlighting the Call to Action
	&:hover {
		.c-restaurant-item__link {
			color: $color-classic-primary;
		}
	}
}

.c-restaurant-item__title {
	display: inline-block;
	width: 100%;
	height: $classic-headline-line-height-default * 2;
	overflow: hidden;
	text-overflow: ellipsis;

	&--small {
		height: $classic-headline-line-height-default * 1.2;
	}
}

.c-restaurant-item__rating {
	color: $color-classic-primary;

	.c-icon {
		font-size: 18px;
	}
}

.c-restaurant-item__image {
	display: block;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
	text-align: center;

	img {
		text-align: center;
		margin: 0 auto;
	}
}

.c-restaurant-item__body {
	display: block;
	cursor: pointer;
}

.c-restaurant-item__description {
	height: 3 * $inuit-base-line-height;
	overflow: hidden;

	@include media-query(m-down) {
		display: none;
	}
}

.c-restaurant-item__link {
	display: inline-block;
	font-weight: $font-weight-bold;
}
