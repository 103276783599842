// add an underline animation on hover to an element
// @param {Number} $duration - duration of the animation
// @param {String} $line-color - color of the underline
// @param {Number} $line-height - height of the underline
// @param {String} $element - element to apply the underline to, if hover is on a parent element

@mixin animate_underline_on_hover($duration: 400ms, $line-color: currentColor, $line-height: 1px, $element: "") {
	$hover-selector: "&:hover #{$element}";
	@if $element == "" {
		$hover-selector: "&:hover";
	}

	& #{$element} {
		position: relative;

		&::after {
			background-color: $line-color;
			bottom: -$line-height;
			content: "";
			height: $line-height;
			left: 0;
			opacity: 0;
			position: absolute;
			transition: width $duration ease-in-out, opacity $duration ease-in-out;
			width: 0;
		}
	}

	#{$hover-selector}::after {
		@extend %animate_underline_hover;
	}
}

%animate_underline_hover {
	opacity: 1;
	width: 100%;
}
