/* ------------------------------------*\
    #OPENING HOURS
\*------------------------------------ */

$opening-hours-max-width: 360px;
$opening-hours-max-width--wide: ($opening-hours-max-width * 2);

.o-opening-hours {
	width: 100%;
	max-width: $opening-hours-max-width;
	margin: 0 auto;

	@include media-query(m-down) {
		max-width: none;
	}

	&--wide {
		max-width: $opening-hours-max-width--wide;

		@include media-query(m-down) {
			max-width: $opening-hours-max-width;
		}
	}
}

.c-opening-hours__container {
	.language-ar & {
		direction: rtl;

		.c-classic-headline {
			text-align: right !important;
		}
	}
}

.c-opening-hours__table {
	width: 100%;

	td {
		width: 33.333%;
	}
}
