/* ------------------------------------*\
    #CL-LIST
\*------------------------------------ */

$cl-list-img-height: 40px;

.c-cl-list__item {
	display: inline-block;
	background: $color-gray-light;
	width: 100%;
	cursor: pointer;

	&--odd {
		background: $color-white;
	}
}

.c-cl-list__col {
	display: inline-block;
	vertical-align: middle;
}

.c-cl-list__col--last {
	text-align: right;
}

.c-cl-list__col--brand {
	text-align: center;
}

.c-cl-list__header {
	padding: $spacing-unit 0;
	font-weight: $font-weight-bold;

	.c-icon--arrow-down {
		transition: all 0.25s;
		transform: rotate(0deg);
	}

	&.is-open {
		.c-icon--arrow-down {
			display: inline-block;
			transform: rotate(-180deg);
		}
	}
}

.c-cl-list__body {
	background: $color-white;
	padding: $spacing-unit--large 0;
	cursor: auto;
	position: relative;
	display: none;

	// style the HTML from prescreen

	h2 {
		font-size: $inuit-heading-size-5;
		font-weight: $font-weight-bold;
	}
}

.c-cl-list__left,
.c-cl-list__right,
.c-cl-list__spacer {
	display: inline-block;
}

.c-cl-list__left {
	ul {
		margin-left: $spacing-unit;
	}
}

.c-cl-list__aside {
	background: $color-gray-lighter;
	padding: $spacing-unit--large;
}

.c-cl-list__img {
	height: $cl-list-img-height;
}
