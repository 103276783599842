// Colors defined in library-guild
$color-body: var(--color-black-primary);
$color-button: var(--color-black-primary);
$color-button-hover: var(--color-black);
$color-error: var(--color-red-primary);
$color-success: var(--color-success);
$color-gray-light: var(--color-gray-40);
$color-gray-lighter: var(--color-gray-20);
$color-gray-dark: var(--color-gray-60);
$color-gray-darker: var(--color-gray-80);
$color-button-red: var(--color-red-primary);
$color-button-red-hover: var(--color-red-hover);
$color-white: var(--color-white);
$color-white-shaded: var(--color-gray-20);
$color-white-transparent: rgba(255, 255, 255, 0.2);
$color-black: var(--color-black);
$color-black-light: var(--color-black-primary);
$color-black-lighter: var(--color-black-primary);

// Fontsizes defined in library-guild
$fontsize-2xs: 10px;
$fontsize-xs: 12px;
$fontsize-s: 16px;
$fontsize-m: 20px;
$fontsize-l: 24px;
$fontsize-xl: 32px;
$fontsize-2xl: 40px;
$fontsize-3xl: 64px;

// spacing base unit defined in style guide 2022
$spacing-base-unit: 4px;

// Spacing defined in library-guild
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 48px;
$space-xxl: 64px;