/* ------------------------------------*\
    #SMALLPRINT
\*------------------------------------ */

/**
 * Classes for tiny type.
 *
 * Force normal paragraphs or other text elements to take a smaller
 * font size than '$inuit-base-font-size'.
 *
   <p class="u-milli">Lorem ipsum dolor sit amet.</p>
 *
 */

// Predefine the variables below in order to alter and enable specific features.
$milli-size: 12px !default;
$micro-size: 10px !default;

.u-milli {
	@include font-size($milli-size);
}

.u-micro {
	@include font-size($micro-size);
}
