/* ------------------------------------*\
    #TEASER
\*------------------------------------ */

$teaser-pos-t: 10px;
$teaser-pos-b: 10px;
$teaser-info-icon-font-size: 20px;

$teaser-headline-border-pos-b: -4px;
$teaser-headline-border-width: 80%;
$teaser-headline-border-width-m: 60%;
$teaser-headline-mb: $spacing-unit;
$teaser-headline-mb-m: $spacing-unit--small;

$teaser-topic-mb: $spacing-unit--small;
$teaser-topic-mb-m: $spacing-unit--tiny;

//Variables for category teaser
$category-teaser-mb: $spacing-unit;

$teaser-font-size: $heading-secondary-font-size-beta;
$teaser-font-size-m: $heading-secondary-font-size-gamma;

$category-teaser-small-font-size: $heading-secondary-font-size-gamma;
$category-teaser-small-font-size-m: $heading-secondary-font-size-delta;

$category-teaser-headline-pos-top: 20px;
$category-teaser-headline-pos-top-m: 10px;

$teaser-small-headline-border-width: 60%;
$teaser-small-headline-border-width-m: 40%;

//Variables for highlight teaser
$highlight-teaser-mb: $spacing-unit;
$highlight-teaser-mb-m: $spacing-unit--small;

$highlight-teaser-headline-font-size: $inuit-heading-size-3;
$highlight-teaser-headline-font-size-m: $inuit-heading-size-4;
$highlight-teaser-headline-font-size-m-down: $inuit-heading-size-5;

$highlight-teaser-topic-font-size: $heading-secondary-font-size-beta;
$highlight-teaser-topic-font-size-m: $heading-secondary-font-size-gamma;
$highlight-teaser-topic-font-size-m-down: $heading-secondary-font-size-delta;

$highlight-teaser-topic-border-width: 60%;
$highlight-teaser-topic-border-width-m: 50%;

$highlight-teaser-headline-pos-r: 20px;

//Top Offer Teaser
$teaser-offer-background: $color-white;
$teaser-offer-background-luxus: $color-classic-primary;
$teaser-offer-color: $color-white;
$teaser-offer-border-color: $color-black;

$teaser-offer-discount-font-size: ($heading-secondary-font-size-alpha) * 2;
$teaser-offer-discount-font-size--small: 58px;
$teaser-offer-discount-font-size--mobile: ($heading-secondary-font-size-beta - 2);

$teaser-offer-period-mt: ($inuit-base-spacing-unit) / 2;

$teaser-offer-text-padding: 0 ($inuit-base-spacing-unit) * 2;
$teaser-offer-text-padding--tablet: 0 $inuit-base-spacing-unit;
$teaser-offer-text-font-weight: $font-weight-bold;

$teaser-offer-link-color: $color-classic-primary;
$teaser-offer-link-pt: $inuit-base-spacing-unit;
$teaser-offer-link-font-weight: 700;
$teaser-offer-link-pos-b: $inuit-base-spacing-unit;

$teaser-offer-link-icon-pl: ($inuit-base-spacing-unit) / 6;
$teaser-offer-link-icon-pl: ($inuit-base-spacing-unit) / 6;
$teaser-offer-link-icon-line-height: 17px;

.c-teaser {
	position: relative;
	overflow: hidden;
}

.c-teaser__img {
	position: absolute;
	width: 100%;
	height: auto;
}

.c-teaser__saleitem-img {
	height: 100%;
}

.c-teaser__headline {
	display: inline-block;
	position: relative;
	margin-bottom: $teaser-headline-mb;

	@include font-size($heading-secondary-font-size-alpha);

	@include media-query(l-down) {
		@include font-size($heading-secondary-font-size-beta);
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		height: 1px;
		bottom: $teaser-headline-border-pos-b;
		border-bottom: 1px solid $color-gray-darker;

		@include media-query(m-up) {
			margin-left: -($teaser-headline-border-width/2);
			width: $teaser-headline-border-width;
		}

		@include media-query(m-down) {
			margin-left: -($teaser-headline-border-width-m/2);
			width: $teaser-headline-border-width-m;
		}
	}

	.c-teaser--small & {
		&::after {
			@include media-query(m-up) {
				margin-left: -($teaser-small-headline-border-width/2);
				width: $teaser-small-headline-border-width;
			}

			@include media-query(m-down) {
				margin-left: -($teaser-small-headline-border-width-m/2);
				width: $teaser-small-headline-border-width-m;
			}
		}
	}
}

.c-teaser__topic {
	margin-bottom: $teaser-topic-mb;

	@include font-size($inuit-heading-size-5);

	font-weight: $font-weight-medium;
}

.c-teaser__subline {
	margin-bottom: $teaser-topic-mb;
}

.c-teaser__content {
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.c-teaser__info-icon {
	position: absolute;
	left: $teaser-pos-t;
	bottom: $teaser-pos-b;

	@include font-size($teaser-info-icon-font-size);

	color: $color-white;
	text-shadow: 0 0 5px rgba($color-black, 0.9);
}

.c-teaser__brand-img {
	height: 100px;
	max-width: 100%;

	@include media-query(l) {
		height: 70px;
	}

	@include media-query(l-down) {
		height: 50px;
	}
}

.c-teaser__discount {
	font-size: $teaser-offer-discount-font-size;
	color: $teaser-offer-color;
}

.c-teaser__text {
	font-weight: $teaser-offer-text-font-weight;
	color: $teaser-offer-color;
	padding: $teaser-offer-text-padding;

	@include media-query(m-down) {
		display: none;
	}

	@include media-query(m) {
		display: none;
	}

	@include media-query(l-down) {
		padding: $teaser-offer-text-padding--tablet;
	}

	@include media-query(l) {
		padding: $teaser-offer-text-padding--tablet;
	}
}

.c-teaser__period {
	color: $teaser-offer-color;
	margin-top: -$teaser-offer-period-mt;

	@include media-query(m-down) {
		display: none;
	}

	@include media-query(m) {
		display: none;
	}
}

.c-teaser__link-text {
	color: $teaser-offer-link-color;
	font-weight: $teaser-offer-link-font-weight;
	padding-top: $teaser-offer-link-pt;

	@include media-query(m-down) {
		display: none;
	}

	@include media-query(l) {
		display: none;
	}

	@include media-query(l-down) {
		display: none;
	}

	&:hover {
		color: $teaser-offer-link-color;
	}

	&::after {
		content: $icon-font--arrow-right;

		@include icon-font($contact-nav-icon-arrow-fonts-size-small);

		color: $teaser-offer-link-color;
		font-weight: $teaser-offer-link-font-weight;
		padding-left: $teaser-offer-link-icon-pl;
		line-height: $teaser-offer-link-icon-line-height;

		.language-ar & {
			content: "";
			padding: 0;
		}
	}
}

//Thank you Teaser
.c-teaser--thankyou {
	//@include aspect-ratio(1375,280);

	@include media-query(l-up) {
		.c-teaser__content {
			position: absolute;
			top: 0;
			width: 100%;
		}

		.c-teaser__subline,
		.c-teaser__headline {
			color: $color-white;
		}

		.c-teaser__headline::after {
			border-bottom: 1px solid $color-white;
		}
	}

	.c-teaser__img {
		position: relative;
	}

	.c-teaser__subline,
	.c-teaser__headline {
		@include media-query(l-down) {
			color: $color-black-light;
		}
	}
}

.c-teaser__link {
	color: $color-gray-darker;
}

.c-teaser--ty {
	@include aspect-ratio(442, 350);

	&:hover {
		.c-teaser__link {
			color: $color-black;
		}
	}
}

.c-teaser__body {
	position: absolute;
	left: 0;
	bottom: $spacing-unit--small;
	width: 100%;
}

.c-teaser__ty-content {
	background: $color-white;

	.c-teaser__headline {
		.c-teaser--category & {
			top: 0;
		}
	}
}

//Category Teaser

.c-teaser--category {
	@include aspect-ratio(325, 280);

	margin-bottom: $category-teaser-mb;

	&.c-teaser--small {
		margin-bottom: 17px;
	}

	.c-teaser__content {
		justify-content: flex-start;
	}

	.c-teaser__headline {
		top: $category-teaser-headline-pos-top;

		@include font-size($teaser-font-size);

		color: $color-gray-darker;

		@include media-query(m-down) {
			top: $category-teaser-headline-pos-top-m;

			@include font-size($teaser-font-size-m);
		}
	}

	&.c-teaser--small .c-teaser__headline {
		@include font-size($category-teaser-small-font-size);

		@include media-query(m-down) {
			@include font-size($category-teaser-small-font-size-m, false);
		}
	}
}

//Highlight Teaser
.c-teaser--highlight {
	@include aspect-ratio(535, 820);

	&.c-teaser--wide {
		@include aspect-ratio(811, 400);

		margin-bottom: $highlight-teaser-mb + 1;
	}

	&.c-teaser--small {
		@include aspect-ratio(535, 400);
	}
}

.c-teaser--highlight-advanced {
	@include aspect-ratio(673, 570);

	&.c-teaser--wide {
		//@include aspect-ratio(811,400);
		@include aspect-ratio(673, 290);
	}
}

.c-teaser--highlight,
.c-teaser--highlight-advanced {
	margin-bottom: $highlight-teaser-mb + 2;

	@include media-query(m) {
		margin-bottom: $highlight-teaser-mb-m;
	}

	.c-teaser__content {
		float: right;
		width: 50%;
		padding: 0 10%;
		color: $color-white;
	}

	.c-teaser__text {
		width: 50%;
		color: $color-white;
	}

	&.c-teaser--wide .c-teaser__text-wrapper {
		width: 40%;
	}

	.c-teaser__headline {
		@include font-size($highlight-teaser-headline-font-size);

		font-weight: $font-weight-bold;

		@include media-query(l-down) {
			margin-bottom: $teaser-headline-mb-m;
		}

		@include media-query(m) {
			@include font-size($highlight-teaser-headline-font-size-m);
		}

		@include media-query(m-down) {
			@include font-size($highlight-teaser-headline-font-size-m-down);
		}

		&::after {
			border-bottom-color: $color-white;
		}
	}

	.c-teaser__topic {
		display: block;
		position: relative;

		@include font-size($highlight-teaser-topic-font-size);

		font-weight: normal;

		@include media-query(l-down) {
			margin-bottom: $teaser-topic-mb-m;
		}

		@include media-query(m) {
			@include font-size($highlight-teaser-topic-font-size-m);
		}

		@include media-query(m-down) {
			@include font-size($highlight-teaser-topic-font-size-m-down, false);
		}
	}

	.c-teaser__subline {
		display: block;

		@include media-query(m-down) {
			@include font-size(11px, 1);
		}
	}
}

//Top Offer Teaser

.c-offer-item-container {
	//TODO: Check subclasses c-teaser-small
	@include aspect-ratio(320, 289);

	margin-bottom: $category-teaser-mb;
	background: $teaser-offer-background;
	border: 1px solid $teaser-offer-border-color;
	color: $teaser-offer-border-color;

	&.c-teaser--small {
		.c-teaser__brand-img {
			height: 80px;

			@include media-query(l) {
				height: 70px;
			}

			@include media-query(l-down) {
				height: 50px;
			}
		}

		@include media-query(m-down) {
			@include font-size($category-teaser-small-font-size-m);
		}

		.c-teaser__content {
			justify-content: flex-start;
			height: 100%;
			position: absolute;
			text-align: center;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			padding: 0;

			@include media-query(xl-down) {
				padding: 0 ($spacing-unit--small);
			}

			@include media-query(m-down) {
				justify-content: center;
			}

			@include media-query(m) {
				justify-content: center;
			}
		}

		.c-teaser__discount {
			@include font-size($teaser-offer-discount-font-size--small, 1.5);

			color: $teaser-offer-color;
			white-space: nowrap;

			@include media-query(l-down) {
				@include font-size($teaser-offer-discount-font-size--mobile, 1);
			}
		}

		.c-teaser__text {
			padding-top: ($inuit-base-spacing-unit) / 2;
			padding-right: 0;
			padding-left: 0;
			width: 100%;
		}

		.c-teaser__link-text {
			position: absolute;
			bottom: $teaser-offer-link-pos-b;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.c-teaser--large {
		.c-teaser__brand-img {
			height: 200px;
		}
	}

	&.c-teaser--luxus {
		background: $teaser-offer-background-luxus;

		.c-teaser__discount,
		.c-teaser__period,
		.c-teaser__text,
		.c-teaser__link-text {
			color: $color-black !important;

			&::after {
				color: $color-black !important;
			}
		}
	}
}
