/* ------------------------------------*\
    #BRANDLIST-TEASER
\*------------------------------------ */

$branlist-name-teaser-height: 100px;
$brandlist-name-teaser-pt: 24px;
$brandlist-name-teaser-font-size: $heading-secondary-font-size-alpha;

.c-brandlist-name-teaser {
	width: 100%;
	background-color: $color-gray-light;
	height: $branlist-name-teaser-height;
	font-size: $brandlist-name-teaser-font-size;
	text-align: center;
	padding-top: $brandlist-name-teaser-pt;
}
