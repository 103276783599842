/* PowerTip Plugin */

$powertip-arrow-height: 10px !default;
$powertip-arrow-width: 10px !default;
$powertip-border-color: #000 !default;
$powertip-background-color: #fff !default;

#powerTip {
	position: absolute;
	z-index: z("tooltip");
	display: none;
	margin-right: 0;
	min-width: 200px;
	max-width: 90%;
	cursor: default;

	@include font-size($inuit-heading-size-milli, 1.7);

	color: #000;
	background-color: $color-gray-light;
	text-indent: $spacing-unit--tiny;

	&.mobile-fullsize {
		background-color: $color-white;
	}
}

#powerTip::before,
#powerTip::after {
	content: "";
	position: absolute;
}

// fullsize Version
@include media-query("m-down") {
	#powerTip.mobile-fullsize::before,
	#powerTip.mobile-fullsize::after {
		display: none;
	}

	#powerTip.mobile-fullsize {
		position: fixed !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		width: 100%;
		max-width: 100%;
		z-index: 20000000;
	}
}

#powerTip.n::before,
#powerTip.s::before {
	border-right: $powertip-arrow-width solid transparent;
	border-left: $powertip-arrow-width solid transparent;
	left: 50%;
	margin-left: -$powertip-arrow-width;
}

#powerTip.e::before,
#powerTip.w::before {
	border-bottom: $powertip-arrow-width solid transparent;
	border-top: $powertip-arrow-width solid transparent;
	margin-top: -$powertip-arrow-width;
	top: 50%;
}

#powerTip.n::after,
#powerTip.s::after {
	border-right: $powertip-arrow-width solid transparent;
	border-left: $powertip-arrow-width solid transparent;
	left: 50%;
	margin-left: -$powertip-arrow-width;
}

#powerTip.e::after,
#powerTip.w::after {
	border-bottom: $powertip-arrow-width solid transparent;
	border-top: $powertip-arrow-width solid transparent;
	margin-top: -$powertip-arrow-width;
	top: 50%;
}

#powerTip.n::before {
	border-top: $powertip-arrow-height solid $powertip-border-color;
	bottom: -($powertip-arrow-height);
}

#powerTip.n::after {
	border-top: $powertip-arrow-height solid $powertip-background-color;
	bottom: -($powertip-arrow-height - 1px);
}

#powerTip.e::before {
	border-right: $powertip-arrow-height solid $powertip-border-color;
	left: -($powertip-arrow-height);
}

#powerTip.e::after {
	border-right: $powertip-arrow-height solid $powertip-background-color;
	left: -($powertip-arrow-height - 1px);
}

#powerTip.s::before {
	border-bottom: $powertip-arrow-height solid $powertip-border-color;
	top: -($powertip-arrow-height);
}

#powerTip.s::after {
	border-bottom: $powertip-arrow-height solid $powertip-background-color;
	top: -($powertip-arrow-height - 1px);
}

#powerTip.w::before {
	border-left: $powertip-arrow-height solid $powertip-border-color;
	right: -($powertip-arrow-height);
}

#powerTip.w::after {
	border-left: $powertip-arrow-height solid $powertip-background-color;
	right: -($powertip-arrow-height - 1px);
}

/////////////////
#powerTip.ne::before,
#powerTip.se::before {
	border-right: $powertip-arrow-width solid transparent;
	border-left: 0;
	left: 10px;
}

#powerTip.ne::after,
#powerTip.se::after {
	border-right: $powertip-arrow-width solid transparent;
	border-left: 0;
	left: 10px;
}

#powerTip.nw::before,
#powerTip.sw::before {
	border-left: $powertip-arrow-width solid transparent;
	border-right: 0;
	right: 10px;
}

#powerTip.ne::before,
#powerTip.nw::before {
	border-top: $powertip-arrow-height solid $powertip-border-color;
	bottom: -10px;
}

#powerTip.se::before,
#powerTip.sw::before {
	border-bottom: $powertip-arrow-height solid $powertip-border-color;
	top: -10px;
}

/////////////////
#powerTip.nw-alt::before,
#powerTip.ne-alt::before,
#powerTip.sw-alt::before,
#powerTip.se-alt::before {
	border-top: $powertip-arrow-height solid $powertip-border-color;
	bottom: -($powertip-arrow-height);
	border-left: $powertip-arrow-width solid transparent;
	border-right: $powertip-arrow-width solid transparent;
	left: 10px;
}

#powerTip.nw-alt::after,
#powerTip.ne-alt::after,
#powerTip.sw-alt::after,
#powerTip.se-alt::after {
	border-top: $powertip-arrow-height solid $powertip-background-color;
	bottom: -($powertip-arrow-height - 1px);
	border-left: $powertip-arrow-width solid transparent;
	border-right: $powertip-arrow-width solid transparent;
	left: 10px;
}

#powerTip.ne-alt::before,
#powerTip.ne-alt::after {
	left: auto;
	right: 10px;
}

#powerTip.sw-alt::before,
#powerTip.se-alt::before {
	border-top: none;
	border-bottom: $powertip-arrow-height solid $powertip-border-color;
	bottom: auto;
	top: -($powertip-arrow-height);
}

#powerTip.sw-alt::after,
#powerTip.se-alt::after {
	border-top: none;
	border-bottom: $powertip-arrow-height solid $powertip-background-color;
	bottom: auto;
	top: -($powertip-arrow-height - 1px);
}

#powerTip.se-alt::before,
#powerTip.se-alt::after {
	left: auto;
	right: 10px;
}
