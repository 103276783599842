/* ------------------------------------*\
    #FLOAT
\*------------------------------------ */

/**
 * Float left.
 */
.u-float-left {
	float: left !important;
}

/**
 * Float right.
 */
.u-float-right {
	float: right !important;
}

/**
 * Float none.
 */
.u-float-none {
	float: none !important;
}
