/* ------------------------------------*\
    #TEASER AREA
\*------------------------------------ */
$teaser-area-ts: -30px;
$teaser-area-item-text-background: rgba($color-black, 0.8);
$teaser-area-item-text-background-hover: rgba($color-black, 1);

.c-teaser-area {
	.c-teaser-area__item {
		display: block;
		position: relative;
		overflow: hidden;

		@include media-query(l-up) {
			&:hover {
				img {
					transform: scale(1.03);

					@include ui-transition(0.5s, ease-in);
				}

				.c-teaser-area__item--text {
					background-color: $teaser-area-item-text-background-hover;

					@include ui-transition(0.5s, ease-in);
				}
			}
		}
	}

	.c-teaser-area__item img {
		min-width: 100%;

		@include ui-transition(0.5s, ease-out);

		height: 100%;
	}

	.c-teaser-area__item--text {
		position: absolute;
		bottom: 0;
		left: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		padding: $spacing-unit;
		text-align: center;

		@include font-size($inuit-heading-size-6, 1);

		font-weight: $font-weight-medium;
		background-color: $teaser-area-item-text-background;
		color: $color-white;

		@include ui-transition(0.5s, ease-out);

		&::after {
			@extend .c-icon;

			content: $icon-font--arrow-right;
			font-size: $inuit-heading-size-micro;
			color: $color-white;
		}

		@include media-query(m) {
			@include font-size($inuit-heading-size-micro, 1);

			padding: $spacing-unit--small;
		}
	}

	.c-teaser-area__item--only-text {
		padding-top: $spacing-unit;
		padding-bottom: $spacing-unit;
		text-align: center;

		@include font-size($inuit-heading-size-6, 1);

		font-weight: $font-weight-medium;
		border: 1px solid $color-black;

		&:hover {
			color: $color-gray-dark;
			border-color: $color-gray-dark;
		}

		@include media-query(m) {
			padding-top: $spacing-unit--small;
			padding-bottom: $spacing-unit--small;

			@include font-size($inuit-heading-size-micro, 1);
		}
	}

	// todo: o-pack__item sollte nicht überschrieben werden
	.o-pack__item {
		padding-left: $spacing-unit;

		&:first-child {
			padding-left: 0;
		}
	}

	@include media-query(m-down) {
		.o-pack__item {
			display: none;
		}
	}
}

.c-teaser-area-border {
	border: 1px solid $color-gray-darker;
	border-top-width: 0;
	padding: $spacing-unit $spacing-unit 0 0;
	margin-bottom: $lib-spacing-base-unit * 9;
	margin-top: -$spacing-unit;

	@include media-query(m-down) {
		border-left-width: 0;
		border-right-width: 0;
		padding: $spacing-unit 0;
		margin-left: -$spacing-unit;
	}

	.slick-slide img {
		display: inline-block;
	}
}

.c-teaser-area-mobile {
	display: none;
	position: relative;

	.c-teaser-area__list {
		@extend .o-list-bare;

		position: absolute;
		width: 100%;
		z-index: 1;
	}

	.c-teaser-area__dropdown-wrap {
		position: absolute;
		width: 100%;
		background-color: $color-white;
		z-index: 51;
	}

	.c-teaser-area__label span {
		&::after {
			@extend .c-icon;
		}
	}

	@include media-query(m-down) {
		display: block;

		.c-teaser-area__label {
			display: block;
		}

		.c-teaser-area__label span {
			position: relative;
			z-index: -1;
			border: 1px solid $color-gray-dark;
			display: block;

			&::after {
				position: absolute;
				top: 50%;
				right: $spacing-unit;
				margin-top: -($inuit-heading-size-6/2);
				content: $icon-font--arrow-down;
				font-size: $inuit-heading-size-6;
				color: $color-black;
				transform: rotate(0deg);

				@include ui-transition(0.5s);
			}
		}

		.c-teaser-area__label span,
		li a {
			width: 100%;
			display: block;
			padding-top: $spacing-unit;
			padding-bottom: $spacing-unit;
			padding-left: $spacing-unit;
			text-align: left;

			@include font-size($inuit-heading-size-6, 1);

			color: $color-black;
			cursor: pointer;
		}

		li {
			background-color: $color-white;
			border: 1px solid $color-gray-dark;
			border-top-width: 0;

			&:hover {
				background-color: $color-gray-lighter;
			}
		}

		.c-teaser-area__label.is-open span {
			&::after {
				@include ui-transition();

				transform: rotate(180deg);

				@include ui-transition(0.5s);

				display: inline-block;
			}
		}
	}
}
