/* ------------------------------------*\
    Meta-Navigation Blocks - also nur die umgebenden Hüllen !!!!
\*------------------------------------ */
@import "../../settings/header-settings";

// Header Top Bar
$header-meta-nav-fixed-height: $gl_header-fixed-height;

// Header Meta Bar Navigation
$header-meta-site-toggle-min-width: 300px;
$header-meta-user-min-width: 90px;

//-----------------------------------
// Top-Bar Navigation

.c-header-meta-nav-wrapper {
	margin-top: -4.6rem;

	&--no-border {
		border-bottom-width: 0;
	}

	.is-header-landingpage &,
	.is-header-checkout & {
		margin-top: 0;
	}

	@media (min-width: 64em) {
		border-bottom: 0;
		margin-top: 0;

		.is-header-sticky & {
			margin-top: unset;
		}
	}
}

//todo: mobil fixed nicht vorhanden, z-index ...:
.c-header-meta-nav {
	align-items: flex-end;
	display: flex;
	height: 7.142857142857143rem;
	max-width: 100.571428571428571rem;
	margin: 0 auto;

	@media (min-width: 64em) {
		flex-direction: row;
		height: 6.142857142857143rem;
		justify-content: space-between;
		padding: 0 1.142857142857143rem;
		position: relative;
		width: 100%;
	}

	.is-header-sticky & {
		z-index: 502;
	}
}

//-----------------------------------
// OCM-Site-Toggle

.c-header-meta-nav__site-toggle {
	font-size: 0.857142857142857rem;
	transition: opacity 0.3s ease;
	width: 100%;

	@media (min-width: 23.4375em) {
		font-size: 1rem;
	}

	.is-site-links-fading-out & {
		opacity: 0;
	}

	.is-logo-collapsed & {
		display: none;
	}

	@media (min-width: 64em) {
		width: auto;
	}

	section {
		align-items: flex-end;
		display: flex;
	}

	.is-header-sticky & {
		display: none;
	}
}

//-----------------------------------
// User Navigation: account, watch-list, cart, adresse, kontakt, öffnungszeiten und !!!! Jetzt geöffnet

.c-header-meta-nav__user {
	position: relative;
	text-align: right;

	.template-pages-SignInPageTemplate & {
		display: none;
	}

	.is-header-sticky & {
		align-items: flex-end;
		display: flex;
		flex-direction: row;
		height: 4rem;
		justify-content: center;
		padding-bottom: 0;
		position: absolute;
		right: 1.142857142857143rem;
		top: 6.285714285714286rem;

		@media not all and (min-width: 64em) {
			display: none;
		}
	}
	.is-site-classic.is-header-sticky & {
		right: 0.357142857142857rem;
	}

	@media not all and (min-width: 64em) {
		display: none;
	}
}

.c-header-meta-nav__user-top {
	align-items: center;
	display: flex;
	height: 100%;
}

.c-header-meta-nav__user-bottom {
	bottom: -4.142857142857143rem;
	display: flex;
	flex-direction: row;
	height: 3.142857142857143rem;
	margin: 0;
	position: absolute;
	right: -0.785714285714286rem;
	z-index: 501;

	@media (min-width: 64em) {
		height: 4rem;
	}
	.is-header-sticky & {
		position: static;
		top: auto;
		right: auto;
	}
}
