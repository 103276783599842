/* Slider */

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	user-select: none;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
	direction: ltr;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&::before,
	&::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}

	// c-category-tile__item usses standard focus-visible styles (_categoryTileComponent.scss)
	:not(.c-category-tile__item) a {
		position: relative;
		&:focus-visible {
			outline: transparent;
			&::after {
				content: "";
				position: absolute;
				inset: 0;
				border: 2px solid var(--color-black-primary);
			}
		}
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}
