/* ------------------------------------*\
	#ORDER-HISTORY
\*------------------------------------ */
$order-history-item-status-left: $spacing-unit;
$order-history-item-status-bottom: $spacing-unit;

.c-order-history__infobox-wrapper {
	padding: 0 $spacing-unit;

	@include media-query(m-down) {
		padding: 0 $spacing-unit--small;
	}
}

.c-order-history__infobox {
	background: $color-gray-light;
	border: 1px solid $color-gray-dark;
	width: 100%;
	text-align: center;
	padding: $spacing-unit--tiny;
}
