/* ------------------------------------*\
    #OFFCANVAS LEFT , RIGHT
\*------------------------------------ */

$offcanvas-max-width: 35.714285714285714rem;
$offcanvas-nav-transition-speed: 0.3s;
// Home icon
$offcanvas-top-size: 20px;
$offcanvas-top-padding: 16px;

.c-offcanvas-wrapper {
	@media not all and (min-width: 64em) {
		position: relative;
		width: 100%;
	}

	.c-offcanvas-left {
		visibility: hidden;

		&:focus-visible {
			outline: transparent;
		}
	}

	&.is-left-offcanvas {
		.c-offcanvas-left {
			left: 0;
			transition: left $offcanvas-nav-transition-speed;
			visibility: visible;

			@include media-query(m) {
				box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.14);
			}
		}

		.c-filter.is-scrollup .c-filter__top,
		.c-brandlist__button-wrapper.is-fixed .c-brandlist__button-content {
			@include media-query(m-down) {
				left: 100%;
				transition: left $offcanvas-nav-transition-speed;
			}
		}
	}

	&.is-right-offcanvas {
		.c-offcanvas-right {
			right: 0;
			transition: right $offcanvas-nav-transition-speed;
			visibility: visible;
		}
	}
}

.c-offcanvas-wrapper.is-right-offcanvas,
.c-offcanvas-wrapper.is-left-offcanvas {
	@media not all and (min-width: 64em) {
		min-height: 100%;
		-webkit-overflow-scrolling: touch;
	}
}

//left offCanvas
.c-offcanvas-left {
	-webkit-backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	backface-visibility: hidden;
	background-color: white;
	box-sizing: border-box;
	left: -100%;
	height: 100%;
	max-width: 35.714285714285714rem;
	pointer-events: visible;
	overflow: hidden;
	position: fixed;
	top: 0;
	transition: left $offcanvas-nav-transition-speed;
	width: 100%;
	z-index: 903;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: 64em) {
		display: none;
	}
}

.c-offcanvas-left-content {
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
}

//left-off-canvas header
.c-offCanvas-left-top-content {
	height: $header-fixed-height;
	display: flex;
	justify-content: space-between;

	@include media-query(m-up) {
		display: none;
	}
}

//right offCanvas
.c-offcanvas-right {
	@media (min-width: 64em) {
		display: none;
	}

	&:focus-visible {
		outline: transparent;
	}

	position: fixed;
	z-index: 903;
	overflow-x: scroll;
	right: -100%;
	top: 0;
	width: 100%;
	max-width: 35.714285714285714rem;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	box-sizing: border-box;
	transition: right $offcanvas-nav-transition-speed;
	background-color: $color-white;

	&::-webkit-scrollbar {
		display: none;
	}
}

//right offCanvas content
.c-offcanvas-right-content {
	position: absolute;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	padding-bottom: $header-fixed-height;
	padding-left: $spacing-unit--small;
	padding-right: $spacing-unit--small;

	@media (min-width: 64em) {
		display: none;
	}
}

//layer offcanvas
.c-offcanvas-close {
	background-color: rgba(0, 0, 0, 0.7);
	bottom: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity $offcanvas-nav-transition-speed;
	z-index: 902;
}

.is-left-offcanvas .c-offcanvas-close,
.is-right-offcanvas .c-offcanvas-close {
	@media (min-width: 31.25em) {
		opacity: 1;
		pointer-events: initial;
	}
}

.c-header__offcanvas-top {
	width: 1.428571428571429rem;
	padding: 1.142857142857143rem;
	box-sizing: content-box;
}

.c-offcanvas-close-button {
	@extend .ocm-button__filled-black;
	@extend .ocm-button--small;
}

.c-offcanvas-brands-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: $spacing-unit--small;
	list-style: none;
	margin: 0;

	&__brand {
		background: $color-gray-lighter;
		position: relative;
		padding-top: percentage(
			71/107
		); // creates a box with fix aspect-ratio and replaces aspect-ratio: #{75/40}, which is no supported on safari prior V15

		display: block;
	}

	&__img {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		padding: 16px;

		@include media-query(m) {
			padding: 32px;
		}
	}

	&__link {
		grid-column: 1 / -1;
		display: flex;
		align-items: center;
		margin: $spacing-unit--small 0;
		@include font-size($u-fontsize, 1.2);
	}
}

.js-offcanvas-nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateX(-100%);
	transition: transform var(--navTransitionDuration) ease;
	display: none;
}

.js-nav-next {
	transform: translateX(100%);
}

.js-nav-active {
	display: block;
	position: relative;
	transform: translateX(0);
}

.c-offcanvas-nav__header {
	display: flex;
	align-items: center;
	padding: 20px 16px;
	border-bottom: 2px solid $color-gray-light;
}

.c-offcanvas-left .c-offcanvas-nav__main {
	overflow-y: auto;
	height: calc(100vh - 69px);
	width: 100%;

	&:last-child {
		padding-bottom: 25vh;
	}
}

.c-offcanvas-right .c-offcanvas-nav__main {
	margin-top: -1px;
}

.c-offcanvas-nav__back {
	@extend .ocm-button__text;
	@extend .ocm-button--small;

	display: flex;
	flex-grow: 0;
	padding: 0;
	align-items: center;

	svg {
		font-size: 20px;
		margin-left: 0;
	}
}

.c-offcanvas-nav__title {
	flex: 1 0 auto;
	font-size: 16px;
	font-weight: bold;
	margin: 0 10px;
}
