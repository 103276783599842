/* ------------------------------------*\
    #NEWSLETTER
\*------------------------------------ */

$newsletter-border-width: 1px;
$newsletter-border-style: solid;
$newsletter-border-color: rgba($color-classic-primary, 0.4);
$newsletter-padding: $inuit-base-spacing-unit 0;

$newsletter-button-font-size: $inuit-base-font-size;

$newsletter-message-font-weight: $font-weight-medium;
$newsletter-message-font-size: $inuit-heading-size-4;

$newsletter-message-item-font-size: $inuit-heading-size-1;
$newsletter-message-item-font-size-mobile: 26px;
$newsletter-message-item-color: $color-classic-primary;
$newsletter-message-item-margin: ($inuit-base-spacing-unit) / 2 0;

.c-newsletter {
	text-align: center;
	width: 100%;
	padding: $newsletter-padding;
	border-top: $newsletter-border-width $newsletter-border-style $newsletter-border-color;
	border-bottom: $newsletter-border-width $newsletter-border-style $newsletter-border-color;
}

.c-newsletter__message {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-size: $newsletter-message-font-size;
	font-weight: $newsletter-message-font-weight;
}

.c-newsletter__message-item {
	font-size: $newsletter-message-item-font-size;
	color: $newsletter-message-item-color;
	margin: -$newsletter-message-item-margin;

	@include media-query(l-down) {
		font-size: $newsletter-message-item-font-size-mobile;
		margin: 0;
	}
}

.c-newsletter__registration {
	display: inline-block;
	vertical-align: middle;
	margin-left: 36px;

	@include media-query(m-down) {
		padding: 0 9px;
		margin: 0;
		width: 100%;
	}
}

.c-newsletter__input {
	height: 40px;
	vertical-align: middle;

	@include media-query(l-up) {
		width: 450px;
	}

	@include media-query(l) {
		width: 320px;
	}

	@include media-query(m) {
		width: 230px;
	}

	@include media-query(m-down) {
		margin: 18px 0;
		width: 100%;
	}

	&--popup {
		width: 100%;
	}
}

.c-newsletter__button {
	height: 40px;
	margin-left: 9px;
	line-height: 1;
	font-size: $newsletter-button-font-size;
	white-space: nowrap;
}

.c-newsletter-form {
	display: table;

	@include media-query(m-down) {
		display: block;
	}
}

.c-newsletter-form__item {
	display: table-cell;
	width: 100%;

	@include media-query(m-down) {
		display: block;
		text-align: center;
	}
}
