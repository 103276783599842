/* ------------------------------------*\
    #EDITOR
\*------------------------------------ */

.c-editor {
	padding: $spacing-unit--large 0;
	background-image: url("/_ui/responsive/common/images/landingpage/hexagon-bg.jpg");
	background-repeat: no-repeat;
	background-position: center;
}
