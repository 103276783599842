/* stylelint-disable indentation */

/* ------------------------------------*\
    #FONTS
\*------------------------------------ */

@font-face {
	font-family: "Draft B";
	font-style: normal;
	font-weight: 400;
	src: url("/_ui/responsive/common/fonts/draft-b/draft_b_regular.woff2") format("woff2"),
		url("/_ui/responsive/common/fonts/draft-b/draft_b_regular.woff") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Draft B";
	font-style: normal;
	font-weight: 500;
	src: url("/_ui/responsive/common/fonts/draft-b/draft_b_medium.woff2") format("woff2"),
		url("/_ui/responsive/common/fonts/draft-b/draft_b_medium.woff") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Draft B";
	font-style: normal;
	font-weight: 600;
	src: url("/_ui/responsive/common/fonts/draft-b/draft_b_semibold.woff2") format("woff2"),
		url("/_ui/responsive/common/fonts/draft-b/draft_b_semibold.woff") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Draft B";
	font-style: normal;
	font-weight: 700;
	src: url("/_ui/responsive/common/fonts/draft-b/draft_b_bold.woff2") format("woff2"),
		url("/_ui/responsive/common/fonts/draft-b/draft_b_bold.woff") format("woff");
	font-display: swap;
}
