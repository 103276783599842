@charset "UTF-8";

/*!
 *
 * css-scaffold
 * https://github.com/csshugs/css-scaffold
 *
 */

// /*------------------------------------*\
//     #SETTINGS
// \*------------------------------------*/
@import "settings/global";
@import "settings/header-settings";
@import "settings/colors";
@import "settings/typography";
@import "settings/icon-font";
@import "settings/svg-icons";
@import "~inuit-defaults/settings.defaults";
@import "~inuit-responsive-settings/settings.responsive";

// /*------------------------------------*\
//     #TOOLS
// \*------------------------------------*/
@import "~inuit-functions/tools.functions";
@import "~inuit-mixins/tools.mixins";
@import "~inuit-responsive-tools/tools.responsive";
@import "~inuit-tools-widths/tools.widths";
//@import "inuit-tools-push/tools.push";
//@import "inuit-tools-pull/tools.pull";
@import "_IMPORTS/tools.imports";

// /*------------------------------------*\
//     #GENERIC
// \*------------------------------------*/
$inuit-global-border-box: true;

@import "~inuit-box-sizing/generic.box-sizing";
@import "~inuit-normalize/generic.normalize";
@import "~inuit-reset/generic.reset";
@import "~inuit-shared/generic.shared";

// /*------------------------------------*\
//     #BASE
// \*------------------------------------*/
// @import "~inuit-page/base.page";
@import "~inuit-headings/base.headings";
@import "~inuit-paragraphs/base.paragraphs";
@import "~inuit-lists/base.lists";
@import "_IMPORTS/base.imports";

// @import "../inuit-files/inuit";

// /*------------------------------------*\
//     #OBJECTS
// \*------------------------------------*/
// @import "inuit-block/objects.block";
// @import "inuit-box/objects.box";

$inuit-btn-namespace: o-;
$inuit-enable-btn--small: true;
$inuit-enable-btn--full: true;
$inuit-btn-background: transparent;
$inuit-btn-color: $inuit-base-text-color;
$inuit-btn-radius: 9999px;
$inuit-btn-padding: 12px !default;
$inuit-btn-padding--small: halve($inuit-btn-padding) !default;
$inuit-btn-border-width: 0 !default;

@import "~inuit-buttons/objects.buttons";

$inuit-flag-namespace: "o-";
$inuit-enable-flag--top: true;

@import "~inuit-flag/objects.flag";

$inuit-layout-namespace: "o-";
$inuit-enable-layout--auto: true;
$inuit-enable-layout--center: true;
$inuit-enable-layout--right: true;
$inuit-enable-layout--middle: true;
$inuit-enable-layout--bottom: true;
$inuit-enable-layout--tiny: true;
$inuit-enable-layout--small: true;
$inuit-enable-layout--large: true;
$inuit-enable-layout--huge: true;
$inuit-enable-layout--flush: true;
$inuit-enable-layout--rev: true;

@import "~inuit-layout/objects.layout";

$inuit-list-bare-namespace: o-;

@import "~inuit-list-bare/objects.list-bare";

$inuit-list-block-namespace: o-;

@import "~inuit-list-block/objects.list-block";

$inuit-list-inline-namespace: o-;

@import "~inuit-list-inline/objects.list-inline";

// @import "inuit-list-ui/objects.list-ui";

$inuit-media-namespace: "o-";
$inuit-enable-media--responsive: true;

@import "~inuit-media/objects.media";

$inuit-pack-namespace: "o-";
$inuit-enable-pack--auto: true;
$inuit-enable-pack--middle: true;
$inuit-enable-pack--bottom: true;
$inuit-enable-pack--tiny: true;
$inuit-enable-pack--small: true;
$inuit-enable-pack--large: true;

@import "~inuit-pack/objects.pack";

$inuit-enable-page-wrap--responsive: true;
$inuit-page-wrap-width: $max-page-width-with-padding - (8 * $lib-spacing-base-unit);

@import "../inuit-files/trumps/page-wrap";
@import "../inuit-files/objects/layout";
// @import "inuit-split/objects.split";

$inuit-table-namespace: "o-";
$inuit-enable-table--compact: true;
$inuit-enable-table--cosy: true;
$inuit-enable-table--fixed: true;

@import "~inuit-tables/objects.tables";

$inuit-tabs-namespace: "o-";

@import "~inuit-tabs/objects.tabs";

$inuit-text-input-border-width: 1px;
$inuit-enable-text-input--small: true;
$inuit-enable-textarea: true;
$inuit-text-input-border-color: var(--color-black-primary);

@import "../inuit-files/objects/text-input";
@import "_IMPORTS/objects.imports";

// /*------------------------------------*\
//     #myAccount
// \*------------------------------------*/
@import "../myAccount/badge";
@import "../myAccount/bg-image";
@import "../myAccount/headline";
@import "../myAccount/infobox";
@import "../myAccount/my-account";
@import "../myAccount/points";

// /*------------------------------------*\
//     #COMPONENTS
// \*------------------------------------*/
@import "_IMPORTS/components.imports";
// modal have to be more powerful than .c-icon
@import "../myAccount/modal";

// /*------------------------------------*\
//     #PLUGINS
// \*------------------------------------*/
@import "_IMPORTS/plugins.imports";

// /*------------------------------------*\
//     #TRUMPS
// \*------------------------------------*/
@import "~inuit-clearfix/trumps.clearfix";
@import "~inuit-headings-trumps/trumps.headings";
$inuit-widths-columns: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@import "~inuit-widths/trumps.widths";
$inuit-widths-columns-responsive: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@import "~inuit-responsive-widths/trumps.widths-responsive";
// @import "inuit-pull/trumps.pull";
// @import "inuit-responsive-pull/trumps.pull-responsive";
// @import "inuit-push/trumps.push";
// @import "inuit-responsive-push/trumps.push-responsive";
//$inuit-spacing-namespace:				u-;
//$inuit-enable-margins:					true;
//@import "inuit-spacing/trumps.spacing";
//@import "inuit-spacing-responsive/trumps.spacing-responsive";

@import "../inuit-files/trumps/float";

$inuit-enable-float--responsive--left: true;
$inuit-enable-float--responsive--right: true;
$inuit-enable-float--responsive--none: true;

@import "../inuit-files/trumps/float-responsive";

// @import "inuit-font-weight/trumps.helper.font-weight";
// @import "inuit-responsive-font-weight/trumps.helper.font-weight-responsive";
@import "../inuit-files/trumps/text-align";
$inuit-enable-text-align--responsive--left: true;
$inuit-enable-text-align--responsive--right: true;
$inuit-enable-text-align--responsive--center: true;

@import "../inuit-files/trumps/text-align-responsive";
@import "../inuit-files/trumps/hide";
@import "../inuit-files/trumps/font-size";
@import "~inuit-print/trumps.print";
@import "_IMPORTS/trumps.imports";

// /*------------------------------------*\
//     #Shame
// \*------------------------------------*/
@import "shame";

// /*------------------------------------*\
//     #Legacy
// \*------------------------------------*/
@import "legacy";
