/* ------------------------------------*\
    #ICONS
\*------------------------------------ */

//todo: sollte besser o-icon sein und muss im CSS immer vor allen c- kommen, dass es überschrieben werden kann

/// icon size
$icon-size--x-small: 8px !default;
$icon-size--small: 13px !default;
$icon-size--regular: 18px !default;
$icon-size--big: 25px !default;
$icon-size--large: 35px !default;
$icon-size--larger: 50px !default;
$icon-size--huge: 90px !default;

.c-icon {
	@include icon-font();
}

.c-icon--text-input {
	position: absolute;
	right: ($inuit-base-spacing-unit) / 2;
	top: ($inuit-base-spacing-unit) / 2;
	color: $color-gray-dark;
}

/**
* icon sizes
*/

.c-icon--x-small {
	@include font-size($icon-size--x-small);

	line-height: $icon-size--small;
}

.c-icon--small {
	@include font-size($icon-size--small);

	line-height: $icon-size--small;
}

.c-icon--regular {
	@include font-size($icon-size--regular);

	line-height: $icon-size--regular;
}

.c-icon--big {
	@include font-size($icon-size--big);

	line-height: $icon-size--big;
}

.c-icon--large {
	@include font-size($icon-size--large);

	line-height: $icon-size--large;
}

.c-icon--larger {
	@include font-size($icon-size--larger);

	line-height: $icon-size--larger;
}

.c-icon--huge {
	@include font-size($icon-size--huge);

	line-height: $icon-size--huge;
}

/**
* Icon round.
*/

.c-icon--round {
	border-radius: 50%;
}

.c-icon--circle-classic {
	position: relative;
	border: 2px solid $color-white;
	border-radius: 50%;
	padding: ($inuit-base-spacing-unit) / 2;
}

.c-icon--circle-canceled::after {
	position: absolute;
	top: 0;
	left: 50%;
	content: " ";
	height: 100%;
	width: 2px;
	background: $color-classic-primary;
	transform: rotate(45deg);
}

/**
* Icons
*/

.c-icon--instagram::before {
	content: $icon-font--instagram;
}

.c-icon--magnifier::before {
	content: $icon-font--magnifier;
}

.c-icon--minus::before {
	content: $icon-font--minus;
}

.c-icon--ocm-logo::before {
	content: $icon-font--ocm-logo;
}

.c-icon--pencil::before {
	content: $icon-font--pencil;
}

.c-icon--pinterest::before {
	content: $icon-font--pinterest;
}

.c-icon--plus::before {
	content: $icon-font--plus;
}

.c-icon--plus-circle::before {
	content: $icon-font--plus-circle;
}

.c-icon--reload::before {
	content: $icon-font--reload;
}

.c-icon--ruler::before {
	content: $icon-font--ruler;
}

.c-icon--shoppingbag::before {
	content: $icon-font--shoppingbag;
}

.c-icon--star-active::before {
	content: $icon-font--star-active;
}

.c-icon--star-inactive::before {
	content: $icon-font--star-inactive;
}

.c-icon--trust-correct::before {
	content: $icon-font--trust-correct;
}

.c-icon--trust-delivery::before {
	content: $icon-font--trust-delivery;
}

.c-icon--trust-faq::before {
	content: $icon-font--trust-faq;
}

.c-icon--trust-hotline::before {
	content: $icon-font--trust-hotline;
}

.c-icon--trust-payment::before {
	content: $icon-font--trust-payment;
}

.c-icon--trust-return::before {
	content: $icon-font--trust-return;
}

.c-icon--trust-return-2::before {
	content: $icon-font--trust-return-2;
}

.c-icon--trust-secure::before {
	content: $icon-font--trust-secure;
}

.c-icon--twitter::before {
	content: $icon-font--twitter;
}

.c-icon--youtube::before {
	content: $icon-font--youtube;
}

.c-icon--play::before {
	content: $icon-font--play;
}

.c-icon--arrow-down::before {
	content: $icon-font--arrow-down;
}

.c-icon--arrow-left::before {
	content: $icon-font--arrow-left;
}

.c-icon--arrow-right::before {
	content: $icon-font--arrow-right;
}

.c-icon--arrow-up::before {
	content: $icon-font--arrow-up;
}

.c-icon--avatar::before {
	content: $icon-font--avatar;
}

.c-icon--checkbox-active::before {
	content: $icon-font--checkbox-active;
}

.c-icon--checkbox-inactive::before {
	content: $icon-font--checkbox-inactive;
}

.c-icon--close::before {
	content: $icon-font--close;
}

.c-icon--dot::before {
	content: $icon-font--dot;
}

.c-icon--error::before {
	content: $icon-font--error;
}

.c-icon--facebook::before {
	content: $icon-font--facebook;
}

.c-icon--google_plus::before {
	content: $icon-font--google_plus;
}

.c-icon--heart::before {
	content: $icon-font--heart;
}

.c-icon--heart-outline::before {
	content: $icon-font--heart-outline;
}

.c-icon--hook::before {
	content: $icon-font--hook;
}

.c-icon--hook-2::before {
	content: $icon-font--hook-2;
}

.c-icon--info::before {
	content: $icon-font--info;
}

.c-icon--free-return::before {
	content: $icon-font--free-return;
}

.c-icon--mobile-button::before {
	content: $icon-font--mobile-button;
}

.c-icon--arrow-down-big::before {
	content: $icon-font--arrow-down-big;
}

.c-icon--arrow-left-big::before {
	content: $icon-font--arrow-left-big;
}

.c-icon--arrow-right-big::before {
	content: $icon-font--arrow-right-big;
}

.c-icon--arrow-up-big::before {
	content: $icon-font--arrow-up-big;
}

.c-icon--slider-arrow-price-left::before {
	content: $icon-font--slider-arrow-price-left;
}

.c-icon--slider-arrow-price-right::before {
	content: $icon-font--slider-arrow-price-right;
}

.c-icon--badge-circle::before {
	content: $icon-font--badge-circle;
}

.c-icon--badge-circle-filled::before {
	content: $icon-font--badge-circle-filled;
}

.c-icon--business::before {
	content: $icon-font--business;
}

.c-icon--clock::before {
	content: $icon-font--clock;
}

.c-icon--dot-navigation::before {
	content: $icon-font--dot-navigation;
}

.c-icon--map::before {
	content: $icon-font--map;
}

.c-icon--phone::before {
	content: $icon-font--phone;
}

/* spezial Icons */
.c-icon--in-stock::before {
	content: $icon-font--dot;
	color: $color-ok;
}

.c-icon--atm::before {
	content: $icon-font--atm;
}

.c-icon--parkplatz::before {
	content: $icon-font--parkplatz;
}

.c-icon--parkhaus::before {
	content: $icon-font--parkhaus;
}

.c-icon--kids-camp::before {
	content: $icon-font--kids-camp;
}

.c-icon--hundeakzeptanz::before {
	content: $icon-font--hundeakzeptanz;
}

.c-icon--handy-ladestation::before {
	content: $icon-font--handy-ladestation;
}

.c-icon--geschenkkarte::before {
	content: $icon-font--geschenkkarte;
}

.c-icon--gepaeckabgabe::before {
	content: $icon-font--gepaeckabgabe;
}

.c-icon--fussweg::before {
	content: $icon-font--fussweg;
}

.c-icon--fundbuero::before {
	content: $icon-font--fundbuero;
}

.c-icon--free-wifi::before {
	content: $icon-font--free-wifi;
}

.c-icon--events::before {
	content: $icon-font--events;
}

.c-icon--e-tankstelle::before {
	content: $icon-font--e-tankstelle;
}

.c-icon--buggyverleih::before {
	content: $icon-font--buggyverleih;
}

.c-icon--auto::before {
	content: $icon-font--auto;
}

.c-icon--angebote::before {
	content: $icon-font--angebote;
}

.c-icon--wickelraum::before {
	content: $icon-font--wickelraum;
}

.c-icon--weyboo::before {
	content: $icon-font--weyboo;
}

.c-icon--tripadvisor::before {
	content: $icon-font--tripadvisor;
}

.c-icon--tourist-information::before {
	content: $icon-font--tourist-information;
}

.c-icon--toiletten::before {
	content: $icon-font--toiletten;
}

.c-icon--tax-free::before {
	content: $icon-font--tax-free;
}

.c-icon--spielplatz::before {
	content: $icon-font--spielplatz;
}

.c-icon--specials-gifts::before {
	content: $icon-font--specials-gifts;
}

.c-icon--snapchat::before {
	content: $icon-font--snapchat;
}

.c-icon--shopping-shuttle::before {
	content: $icon-font--shopping-shuttle;
}

.c-icon--rollstuhlverleih::before {
	content: $icon-font--rollstuhlverleih;
}

.c-icon--restaurant::before {
	content: $icon-font--restaurant;
}

.c-icon--logout::before {
	content: $icon-font--logout;
}

.c-icon--chocolate::before {
	content: $icon-font--chocolate;
}

.c-icon--coffee::before {
	content: $icon-font--coffee;
}

.c-icon--mail_open::before {
	content: $icon-font--mail_open;
}

.c-icon--mail::before {
	content: $icon-font--mail;
}

.c-icon--pdf::before {
	content: $icon-font--pdf;
}

.c-icon--atm2::before {
	content: $icon-font--atm2;
}

.c-icon--star-half::before {
	content: $icon-font--star-half;
}

.c-icon--star-half-outline::before {
	content: $icon-font--star-half-outline;
}

.icon-font--filter-arrow::before {
	content: $icon-font--filter-arrow;
}

.c-icon--gebetsraum::before {
	content: $icon-font--gebetsraum;
}

.c-icon--standort::before {
	content: $icon-font--standort;
}

.c-icon--lageplan::before {
	content: $icon-font--lageplan;
}

.c-icon--isp::before {
	content: $icon-font--isp;
}

.c-icon--share::before {
	content: $icon-font--share;
}

.c-icon--card::before {
	content: $icon-font--card;
}
