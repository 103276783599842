/* hide Google Captcha Badge: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed  */
.grecaptcha-badge {
	visibility: hidden;
	height: 0 !important;
	opacity: 0;
}

#captchaLogin,
#captchaRegister {
	visibility: hidden;
	height: 0 !important;
	width: 0;
}
// Leider werden dadurch auch Fehlermeldungen von Captcha ausgeblendet
