/* ------------------------------------*\
    #MULTI-IMAGE-TEXT
\*------------------------------------ */

.c-multi-image-text {
	padding-top: $spacing-unit--large;
	padding-bottom: $spacing-unit--huge;
}

.c-multi-image-text--gray {
	background: $color-gray-lighter;
}

.c-multi-image-text__images {
	a {
		display: inline-block;
	}

	@include media-query(m-down) {
		> div {
			display: none;

			&:first-child() {
				display: block;
			}
		}
	}
}
