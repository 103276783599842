.c-category-tile {
	margin-bottom: $lib-space-xl;

	&.isDesigner {
		background-color: var(--color-gray-10);
		padding: 2.8571rem 0;
	}

	@include media-query(m-up) {
		margin-bottom: $lib-space-xxl;
	}

	// Header
	&__header {
		//desktop page-wrap
		max-width: 1408px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $lib-space-m;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: $lib-space-m;
	}

	&__headline {
		@extend .ocm-headline2;

		margin: 0;
	}

	&__spacer {
		flex: 1;
	}

	&__text {
		margin: 0;
		font-size: 1rem;
		font-weight: $font-weight-medium;
		line-height: 20px;
		padding-left: 0;

		.isDesigner & {
			text-align: center;
		}

		@include media-query(m-up) {
			padding-left: $lib-space-m;
		}

		@include media-query(l-up) {
			padding-left: 0;
		}
	}

	&__button {
		@extend .ocm-button__outline-black;
		@extend .ocm-button--smallOnMobile;

		span {
			display: none;

			@include media-query(m-up) {
				display: inline;
			}
		}
	}

	// Teaser
	&__items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -$lib-space-s;
		margin-top: -$lib-space-l;
		padding: 0 $lib-space-m;

		@include media-query(m-up) {
			overflow: hidden;
			display: grid;
			grid-template-columns: repeat(10, 1fr);
			margin: 0;
			padding: 0;
		}

		@include media-query(l-up) {
			//desktop page-wrap
			max-width: 1408px;
			margin: 0 auto;
			padding: 0 $lib-space-m;
		}

		// Center align arrows to the image
		.c-slider-control.slick-arrow {
			@extend .ocm-button__filled-black;
			@extend .ocm-button--small;

			&.slick-arrow {
				position: absolute;
				top: calc((50% - ((16px + 20px) / 2)) - (46px / 2));
				margin-left: 0;
				margin-top: 0;
				width: 46px;
				height: 46px;
				background-color: $color-black-light;

				&:not(.slick-disabled) {
					// leave button properly hidden to avoid activating button via tabbing
					display: flex !important; // override element style inline-block from slick slider on back button.
				}

				svg {
					width: 20px;
					height: 20px;
				}
			}

			&.c-slider-control--left {
				left: $lib-space-m;
			}

			&.c-slider-control--right {
				right: $lib-space-m;
			}
		}
	}

	&__items.slick-slider {
		// provide space for focus-visible styles
		margin-bottom: -2px 0;

		@include media-query(m-up) {
			padding: 0 16px 0 12px;
		}

		@include media-query(l-up) {
			padding: 0 12px;
			margin: -2px auto;
		}

		.slick-list {
			// space for focus-visible styles
			padding: 4px;
		}
	}

	&__item {
		width: calc(50% - 8px);
		display: block;
		margin-left: $lib-space-s;
		margin-top: $lib-space-l;

		@include media-query(m-up) {
			width: 338px;
			margin-top: 0;
		}

		&:hover {
			text-decoration: underline;
		}

		a {
			display: block;
		}
	}

	&__teaserImage {
		margin: 0 0 $lib-space-s 0;

		// blocking space for image
		position: relative;
		overflow: hidden;
		background: lightgray;
		width: 100%;
		padding-top: 100%;

		.isDesigner & {
			border-radius: 50%;
		}

		@include media-query(m-up) {
			overflow: hidden;
			padding-top: 100%;
			margin-bottom: $lib-space-m;

			&:hover {
				img {
					transform: scale(1.075);
				}
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			@include media-query(m-up) {
				transition: transform 250ms ease-in-out;
				transform-origin: center;
			}
		}
	}

	.slick-slider {
		margin-bottom: 0;
	}
}
