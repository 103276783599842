/* ------------------------------------*\
    #SLOT
\*------------------------------------ */

$headline-font-size: $heading-secondary-font-size-delta;
$slot-font-weight: $font-weight-medium;

.c-slot {
	margin-bottom: $spacing-unit--large;
}

.c-slot p {
	@include font-size(16px, 1.5);
}

.c-slot__title {
	margin-bottom: 0;
	font-weight: $slot-font-weight;

	@include font-size($headline-font-size);

	text-align: center;
}

.c-slot__title-inner {
	display: inline-block;
	position: relative;
	margin-bottom: 10px;
	padding-bottom: 10px;

	&::after {
		content: $icon-font--arrow-down-big;

		@include icon-font(10px);

		position: absolute;
		bottom: 0;
		left: 45%;

		@include ui-transition();

		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	&.is-open::after {
		@include ui-transition();

		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

.c-slot__content {
	text-align: center;
}

.c-slot_video {
	padding: $spacing-unit--large 0;
}
