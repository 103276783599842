/* ------------------------------------*\
    #COUNTER
\*------------------------------------ */

$counter-headline-font-size: 60px;
$counter-headline-font-size-m: $heading-secondary-font-size-beta;
$counter-subline-font-size: 18px;
$counter-subline-font-size-m: 12px;

$counter-number-height: 40px;
$counter-number-height-m: 28px;
$counter-number-width: 28px;
$counter-number-width-m: 24px;
$counter-number-ph: 8px;
$counter-number-font-size: 20px;

$counter-text-font-size: 10px;

$counter-coupon-width: 240px;
$counter-coupon-p: 4px;
$counter-coupon-code-font-size: 24px;
$counter-coupon-code-font-size-m: 20px;
$counter-coupon-label-font-size: 10px;

.c-counter {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	padding: 0 $spacing-unit--large;
	height: 100%;
	background-color: $color-white;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@include media-query(l-up) {
		right: 50px;
	}

	@include media-query(m) {
		width: 50%;
	}

	@include media-query(m-down) {
		position: relative;
		width: 100%;
		margin-top: $spacing-unit--small;
	}
}

.c-counter__headline {
	font-weight: $font-weight-medium;

	@include font-size($counter-headline-font-size);

	text-align: center;

	@include media-query(l-down) {
		@include font-size($counter-headline-font-size-m);
	}
}

.c-counter__subline {
	margin-bottom: $spacing-unit;
	font-size: $counter-subline-font-size;
	font-weight: $font-weight-bold;

	@include media-query(l-down) {
		margin-bottom: $spacing-unit--tiny;
		font-size: $counter-subline-font-size-m;
	}
}

.c-counter__number-wrapper {
	display: inline-block;
	vertical-align: middle;
}

.c-counter__spacer {
	display: inline-block;
	vertical-align: top;
	width: ($counter-number-width - 2);
}

.c-counter__spacer,
.c-counter__number {
	height: $counter-number-height;
	line-height: $counter-number-height;
	font-size: $counter-number-font-size;
}

.c-counter__number {
	display: inline-block;
	width: $counter-number-width;
	padding: 0 $counter-number-ph;
	text-align: center;
	background-color: $color-gray-light;

	&:nth-child(odd) {
		border-right: 2px solid $color-white;
	}

	@include media-query(m) {
		width: $counter-number-width-m;
		height: $counter-number-height-m;
		line-height: $counter-number-height-m;
		font-size: $u-fontsize;
		padding: 0 ($counter-number-ph - 2);
	}
}

.c-counter__text {
	font-size: $counter-text-font-size;
	font-weight: $font-weight-medium;
}

.c-counter__coupon {
	width: $counter-coupon-width;
	padding: $counter-coupon-p;
	border: 1px solid $color-black;

	@include media-query(l-down) {
		width: 100%;
		padding: ($counter-coupon-p - 2);
	}
}

.c-counter__coupon-label {
	font-size: $counter-coupon-label-font-size;
	font-weight: $font-weight-medium;
}

.c-counter__coupon-code {
	font-size: $counter-coupon-code-font-size;
	font-weight: $font-weight-bold;

	@include media-query(m) {
		font-size: $counter-coupon-code-font-size-m;
	}
}
