/* ------------------------------------*\
    #BUTTONS
\*------------------------------------ */

.c-gotop__button {
	visibility: hidden;
	opacity: 0;
	transition: background-color 150ms ease, opacity 300ms ease-in-out, visibility 0ms ease 300ms;

	&.is-visible {
		visibility: visible;
		opacity: 1;
		transition: background-color 150ms ease, opacity 300ms ease-in-out;
	}
}
