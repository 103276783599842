///*------------------------------------*\
//    #ICON-FONT
//\*------------------------------------*/

// Import the icon-font files.
@font-face {
	font-family: "ocm-icons";
	src: url("/_ui/responsive/common/fonts/ocm-icons.woff2?20180925") format("woff2"),
		url("/_ui/responsive/common/fonts/ocm-icons.woff?20180925") format("woff"),
		url("/_ui/responsive/common/fonts/ocm-icons.svg?20180925#ocm-icons") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

$icon-font--instagram: "\e900";
$icon-font--magnifier: "\e901";
$icon-font--minus: "\e902";
$icon-font--ocm-logo: "\e903";
$icon-font--pencil: "\e904";
$icon-font--pinterest: "\e905";
$icon-font--plus: "\e906";
$icon-font--plus-circle: "\e907";
$icon-font--reload: "\e909";
$icon-font--ruler: "\e90a";
$icon-font--shoppingbag: "\e90b";
$icon-font--star-active: "\e942";
$icon-font--star-inactive: "\e941";
$icon-font--trust-correct: "\e90e";
$icon-font--trust-delivery: "\e90f";
$icon-font--trust-faq: "\e910";
$icon-font--trust-hotline: "\e911";
$icon-font--trust-payment: "\e912";
$icon-font--trust-return: "\e913";
$icon-font--trust-return-2: "\e914";
$icon-font--trust-secure: "\e915";
$icon-font--twitter: "\e916";
$icon-font--youtube: "\e917";
$icon-font--play: "\e92a";
$icon-font--arrow-down: "\e918";
$icon-font--arrow-left: "\e919";
$icon-font--arrow-right: "\e91a";
$icon-font--arrow-up: "\e91b";
$icon-font--avatar: "\e91c";
$icon-font--checkbox-active: "\e91d";
$icon-font--checkbox-inactive: "\e91e";
$icon-font--close: "\e91f";
$icon-font--dot: "\e920";
$icon-font--error: "\e921";
$icon-font--facebook: "\e922";
$icon-font--google_plus: "\e923";
$icon-font--heart: "\e924";
$icon-font--heart-outline: "\e92b";
$icon-font--hook: "\e925";
$icon-font--hook-2: "\e926";
$icon-font--info: "\e927";
$icon-font--free-return: "\e928";
$icon-font--mobile-button: "\e929";
$icon-font--arrow-down-big: "\e92c";
$icon-font--arrow-left-big: "\e92d";
$icon-font--arrow-right-big: "\e92e";
$icon-font--arrow-up-big: "\e92f";
$icon-font--slider-arrow-price-left: "\e931";
$icon-font--slider-arrow-price-right: "\e932";
$icon-font--badge-circle: "\e933";
$icon-font--badge-circle-filled: "\e930";
$icon-font--video_pause: "\e934";
$icon-font--video_play: "\e935";
$icon-font--video_volume: "\e936";
$icon-font--video_volume-off: "\e938";
$icon-font--video_zoom: "\e937";
$icon-font--video_zoom-out: "\e939";
$icon-font--radiobutton_active: "\ea54";
$icon-font--radiobutton_inactive: "\ea56";
$icon-font--dot-navigation: "\e93c";
$icon-font--map: "\e93d";
$icon-font--phone: "\e93e";
$icon-font--clock: "\e93b";
$icon-font--business: "\e93a";
$icon-font--atm: "\e93f";
$icon-font--parkplatz: "\e96b";
$icon-font--parkhaus: "\e96a";
$icon-font--kids-camp: "\e969";
$icon-font--hundeakzeptanz: "\e968";
$icon-font--handy-ladestation: "\e966";
$icon-font--geschenkkarte: "\e965";
$icon-font--gepaeckabgabe: "\e960";
$icon-font--fussweg: "\e95e";
$icon-font--fundbuero: "\e95d";
$icon-font--free-wifi: "\e95c";
$icon-font--events: "\e95b";
$icon-font--e-tankstelle: "\e959";
$icon-font--buggyverleih: "\e958";
$icon-font--auto: "\e955";
$icon-font--angebote: "\e954";
$icon-font--wickelraum: "\e953";
$icon-font--weyboo: "\e952";
$icon-font--tripadvisor: "\e951";
$icon-font--tourist-information: "\e950";
$icon-font--toiletten: "\e94f";
$icon-font--tax-free: "\e94e";
$icon-font--spielplatz: "\e94d";
$icon-font--specials-gifts: "\e94c";
$icon-font--snapchat: "\e94b";
$icon-font--shopping-shuttle: "\e947";
$icon-font--rollstuhlverleih: "\e946";
$icon-font--restaurant: "\e944";
$icon-font--logout: "\e940";
$icon-font--chocolate: "\e943";
$icon-font--coffee: "\e945";
$icon-font--mail_open: "\e948";
$icon-font--mail: "\e90c";
$icon-font--pdf: "\e90d";
$icon-font--atm2: "\e949";
$icon-font--star-half: "\e94a";
$icon-font--star-half-outline: "\e956";
$icon-font--filter-arrow: "\e963";
$icon-font--gebetsraum: "\e95f";
$icon-font--standort: "\e964";
$icon-font--lageplan: "\e967";
$icon-font--isp: "\e96c";
$icon-font--share: "\e96d";
$icon-font--card: "\e96e";
