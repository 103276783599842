/* ------------------------------------*\
    #FONT-WEIGHT
\*------------------------------------ */

$font-weights: (
	"font-weight-bold": 700,
	"font-weight-semibold": 600,
	"font-weight-medium": 500,
	"font-weight-normal": 400,
);

@each $font-weight in $font-weights {
	$font-weight-name: nth($font-weight, 1);
	$font-weight-value: nth($font-weight, 2);

	.u-#{$font-weight-name} {
		font-weight: #{$font-weight-value} !important;
	}
}
