/* ------------------------------------*\
    #ARRIVAL
\*------------------------------------ */

$arrival-container-height: 450px;
$arrival-container-height-mobile: auto;

$arrival-container-width: $max-page-width-with-padding;

$arrival-background-image: url("../../images/dummy/mapdummy.jpg");
$arrival-background-repeat: no-repeat;
$arrival-background-size: cover;
$arrival-background-position: center center;

$arrival-inner-wrapper-pos-t: $spacing-unit;
$arrival-inner-wrapper-ph: 0 $spacing-unit--large;

$arrival-text-color: $color-white;

$arrival-item-max-width: 385px;
$arrival-item-p: $spacing-unit;

$arrival-item-background: rgba($color-black, 0.8);
$arrival-item-headline-pt: ($spacing-unit / 2);
$arrival-item-headline-color: $color-classic-primary;
$arrival-item-headline-font-weight: $font-weight-medium;
$arrival-item-headline-font-size: $inuit-heading-size-2;

$arrival-item-list-mb: $spacing-unit !default;
$arrival-item-list-line-height: 20px;

$arrival-item-address-height: 48px;

$arrival-item-button-font-size: $inuit-base-font-size;
$arrival-item-button-font-size-m: 26px;
$arrival-item-button-ml: ($spacing-unit / 2);
$arrival-item-button-mt-mobile: $spacing-unit;

.c-arrival {
	width: 100%;
	height: $arrival-container-height;
	display: block;
	position: relative;
	background-repeat: $arrival-background-repeat;
	background-size: $arrival-background-size;
	background-position: $arrival-background-position;
	overflow: hidden;
	padding: $spacing-unit--large 0;

	@include media-query(m-down) {
		text-align: center;
		height: $arrival-container-height-mobile;
	}
}

.c-arrival__inner-wrapper {
	width: 100%;
	max-width: $arrival-container-width;
	margin: 0 auto;
	padding: $arrival-inner-wrapper-ph;
	z-index: 5;
	position: relative;

	@include media-query(m-down) {
		padding: 0;
		top: 0;
	}
}

.c-arrival__item {
	color: $arrival-text-color;

	@include media-query(l-down) {
		position: static;
		max-width: 100%;
		text-align: center;
	}
}

.c-arrival__item-headline {
	color: $arrival-item-headline-color;
	font-weight: $arrival-item-headline-font-weight;
	font-size: $arrival-item-headline-font-size;
	margin: 0;

	@include media-query(l-down) {
		font-size: 26px;
	}
}

.c-arrival__item-list {
	margin-bottom: $arrival-item-list-mb;

	li {
		line-height: $arrival-item-list-line-height;

		&:first-of-type {
			font-size: $u-fontsize;
		}
	}
}

.c-arrival__item-button {
	@include media-query(m-down) {
		margin-top: $arrival-item-button-mt-mobile;
	}
}

.c-arrival__form {
	&--nowrap {
		white-space: nowrap;

		@include media-query(m-down) {
			white-space: normal;
		}
	}
}

.c-arrival__input {
	width: 215px;
	height: $arrival-item-address-height;
	vertical-align: middle;

	@include media-query(m) {
		margin: 0 auto;
		width: 65%;
	}

	@include media-query(m-down) {
		width: 100%;
	}

	&--full {
		width: 100%;
	}
}

#arrival-map {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
}
