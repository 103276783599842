/* ------------------------------------*\
    #FORM-ELEMENTS DETAILS
\*------------------------------------ */

$text-input-with-icon-p: ($inuit-base-spacing-unit) * 2;
$ms-reveal-icon-offset: $spacing-unit--large - $inuit-base-spacing-unit--small;

.o-text-input[disabled] {
	color: $color-gray-dark;

	&.o-text-input--read-only {
		color: $color-black-light;
	}
}

.o-text-input {
	padding-top: 0;
	padding-bottom: 0;
}

.o-text-input--lighter {
	border-color: $color-gray-dark;
}

.o-text-input--black {
	border-color: $color-black !important;
}

.o-text-input--with-icon {
	padding-right: $text-input-with-icon-p;
}

.o-text-input--read-only {
	border: none;
	padding: 0 1px;
}

.o-text-input[type="password"]::-ms-reveal {
	margin-right: $ms-reveal-icon-offset;
}

.o-textarea {
	padding: $spacing-unit--small;
}
