/* ------------------------------------*\
    #SALE ITEM
\*------------------------------------ */

$upto-font-size: $heading-classic-secondary-font-size-epsilon;

$saving-font-size-default: $heading-classic-secondary-font-size-beta;
$saving-font-size-highight: $heading-classic-secondary-font-size-alpha;

.c-sale-item__wrapper {
	display: table;
	width: 100%;
}

.c-sale-item__cell {
	display: table-cell;
	vertical-align: top;

	@include media-query(m-down) {
		display: block;
		border-bottom: 1px solid $color-gray-light;
	}
}

.c-sale-item__item {
	display: table;
}

.c-sale-item__col {
	display: table-cell;

	@include media-query(m-down) {
		display: inline-block;
	}

	&:first-child {
		@include media-query(m-down) {
			display: inline-block;
			width: 100%;
			text-align: center;
		}
	}

	&:last-child {
		border-right: 1px solid $color-gray-light;

		@include media-query(m-down) {
			border-right: none;
		}

		.c-sale-item__cell:last-child & {
			border-right: none;
		}
	}

	&--highlight {
		color: $color-classic-primary;

		.c-sale-item__saving {
			font-size: $saving-font-size-highight;
		}
	}
}

.c-sale-item__upto {
	font-weight: 500;
	font-size: $upto-font-size;
	display: block;

	@include media-query(m-down) {
		display: inline-block;
	}
}

.c-sale-item__saving {
	line-height: 0.8em;
	font-weight: 500;
	font-size: $saving-font-size-default;
}

.c-sale-item__starlet {
	display: inline-block;
	padding-top: $spacing-unit--small;
}

.c-sale-item__list {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		line-height: $spacing-unit * 1.8;
	}
}

.c-sale-item__divider {
	display: block;
	margin: 0 auto;
	border-bottom: 1px solid $color-gray-light;

	@include media-query(m-down) {
		display: none;
	}
}
