/* ------------------------------------*\
    #RADIO
\*------------------------------------ */

$radio-size: 16px;

/**
 * Styling for checkboxes.
 */
.c-radio__label {
	display: inline-flex;
	line-height: $radio-size;
}

.c-radio {
	padding-left: $spacing-unit;
	position: relative;
	padding-right: $spacing-unit--tiny;
}

.c-radio__origin {
	z-index: z("base-checkbox-origin");
	height: $radio-size;
	width: $radio-size;
	left: 0;
	position: absolute;
	opacity: 0;

	&.u-error + .c-radio__selection::before {
		color: $color-error;
	}
}

.c-radio__origin[type="radio"] {
	+ .c-radio__selection {
		position: relative;

		&::before {
			@include icon-font();

			position: absolute;
			left: -$spacing-unit;
			content: $icon-font--radiobutton_inactive;
			font-size: $radio-size;
			line-height: $radio-size;
		}
	}

	&:checked + .c-radio__selection {
		&::before {
			content: $icon-font--radiobutton_active;
		}
	}
}

.c-radio__selection {
	height: $radio-size;
	width: $radio-size;
}
