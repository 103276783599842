/* ------------------------------------*\
    #CHECKBOX
\*------------------------------------ */

$checkbox-size: 18px;
$checkbox-size-m: 20px;
$checkbox-m: $spacing-unit--small;
$checkbox-text-margin: 10px 0 20px 0;

/**
 * Styling for checkboxes.
 */
.c-checkbox__label {
	display: inline-flex;
	line-height: $checkbox-size;
	cursor: pointer;
}

.c-checkbox__label--ml {
	& .c-checkbox {
		min-width: $spacing-unit--large;
	}
}

.c-checkbox__text {
	margin: $checkbox-text-margin;
	font-weight: $font-weight-normal;
	font-size: $inuit-heading-size-milli;
	line-height: $inuit-heading-size-milli;
}

.c-checkbox__link {
	font-weight: $font-weight-normal;
	font-size: $inuit-heading-size-milli;
	line-height: $inuit-heading-size-milli;
	text-decoration: underline;
}

.c-checkbox {
	position: relative;
	padding-right: $spacing-unit--tiny;
	display: inline;
	vertical-align: middle;
}

.c-checkbox__origin {
	cursor: pointer;
	z-index: z("base-checkbox-origin");
	height: $checkbox-size;
	width: $checkbox-size;
	left: 0;
	position: absolute;
	opacity: 0;

	&.u-error + .c-checkbox__selection::before {
		color: $color-error;
	}
}

.c-checkbox__origin[type="checkbox"] {
	+ .c-checkbox__selection {
		&::before {
			@include icon-font();

			content: $icon-font--checkbox-inactive;
			font-size: $checkbox-size;
			line-height: $checkbox-size;
			background-color: $color-white;
		}
	}

	&:checked + .c-checkbox__selection {
		&::before {
			content: $icon-font--checkbox-active;
		}
	}
}

.c-checkbox__selection {
	height: $checkbox-size;
	width: $checkbox-size;
}

//filter checkbox

.c-checkbox--filter,
.c-checkbox--brand,
.c-checkbox--dynamic {
	display: inline-block;
	margin-right: $checkbox-m;
	cursor: pointer;
	width: 18px;
	height: 18px;
	border: 1px solid $color-black-light;
	border-radius: 2px;
	appearance: none;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		background-color: $color-white;
		border-radius: 1px;
		width: 10px;
		height: 10px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.is-selected & {
		&::before {
			background-color: $color-black-light;
		}
	}
}

.c-checkbox--size {
	display: inline-block;
	height: 30px;
	width: auto;
	padding: 0;
	padding-left: $spacing-unit--tiny;
	padding-right: $spacing-unit--tiny;
	line-height: 30px;
	text-align: center;
	border: 1px solid $color-gray-dark;

	&.is-selected {
		background-color: $color-black;
		color: $color-white;
	}
}

.c-checkbox--color {
	display: inline-flex;
	margin-right: $checkbox-m;
	margin-left: 2px;
	text-align: center;
	padding-right: 0;

	.is-selected & {
		padding: 1px;
		border: 1px solid $color-black;
		width: $checkbox-size;
		height: $checkbox-size;

		@include media-query(m-down) {
			height: $checkbox-size-m;
			width: $checkbox-size-m;
		}
	}

	.color {
		display: block;
		width: $checkbox-size;
		height: $checkbox-size;

		@include media-query(m-down) {
			height: $checkbox-size-m;
			width: $checkbox-size-m;
		}

		.is-selected & {
			width: $checkbox-size - 4px;
			height: $checkbox-size - 4px;

			@include media-query(m-down) {
				height: $checkbox-size-m - 4px;
				width: $checkbox-size-m - 4px;
			}
		}
	}
}
