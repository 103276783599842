/* ------------------------------------*\
    #OFFER SWITCH
\*------------------------------------ */

// $brand-slider-item-height:              60px;

$switch-headline-font-size-big: $heading-classic-secondary-font-size-delta;
$switch-headline-font-size-small: $heading-classic-secondary-font-size-zeta;

$switch-headline-font-size-big-responsive: $heading-classic-secondary-font-size-zeta;
$switch-headline-font-size-small-responsive: 11px;

$switch-icon-arrow-fonts-size: 16px;
$switch-icon-arrow-ml: $switch-icon-arrow-fonts-size / 2;
$switch-icon-arrow-pos-b: -30px;
$switch-icon-arrow-pos-l: 50%;

.c-offer-switch__link {
	position: relative;
	flex-direction: column;
	text-align: center;
	display: flex;

	&::after,
	&::before {
		content: "";
		position: absolute;
		border-bottom: 1px solid $color-gray-darker;
		top: 50%;
		width: $inuit-page-wrap-width;

		.t-classic & {
			border-bottom: 1px solid rgba($color-classic-primary, 0.4);
		}
	}

	&::before {
		right: 100%;
		margin-right: $spacing-unit;
	}

	&::after {
		left: 100%;
		margin-left: $spacing-unit;
	}
}

.c-offer-switch__item {
	width: auto;
}

.c-offer-switch__headline {
	&--big {
		font-size: $switch-headline-font-size-big;

		@include media-query(m-down) {
			font-size: $switch-headline-font-size-big-responsive;
		}
	}

	&--small {
		font-size: $switch-headline-font-size-small;

		@include media-query(m-down) {
			font-size: $switch-headline-font-size-small-responsive;
		}
	}
}
