/* logoAnimation.js */

.logo-animation {
	&__group-fade-out {
		opacity: 1;
		transition: opacity 0.12s ease;

		@include media-query(l-up) {
			opacity: unset !important;
			transition: unset;
		}
	}

	&__o,
	&__c {
		transform: translateX(0);
		transition: transform 0.12s ease;

		@include media-query(l-up) {
			transform: unset !important;
			transition: unset;
		}
	}
}
