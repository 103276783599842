/* ------------------------------------*\
    #IMAGE CUTOUT
\*------------------------------------ */
.o-image-cutout {
	height: 10%;
	background-color: white;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
