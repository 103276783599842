@charset "UTF-8";

// Default Variables

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: " " !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff url("/_ui/responsive/common/images/loader/loading.gif") center center no-repeat;
		background-size: 60px 60px;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"),
			slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0px;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;

	&:hover,
	&:focus {
		outline: none;
		background: transparent;
		color: transparent;

		&::before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled::before {
		opacity: $slick-opacity-not-active;
	}
}

.slick-prev::before,
.slick-next::before {
	font-family: $slick-font-family;
	font-size: 20px;
	line-height: 1;
	color: $slick-arrow-color;
	opacity: $slick-opacity-default;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -25px;

	[dir="rtl"] & {
		left: auto;
		right: -25px;
	}

	&::before {
		content: $slick-prev-character;

		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: -25px;

	[dir="rtl"] & {
		left: -25px;
		right: auto;
	}

	&::before {
		content: $slick-next-character;

		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	.c-product-slide__popup & {
		display: none !important;
	}

	position: absolute;
	left: 0;
	bottom: -30px;
	list-style: none;
	display: block;
	text-align: center;
	margin: 0;
	padding: 0;
	width: 100%;

	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0;
		padding: 0;
		cursor: pointer;

		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			//line-height: 0px;
			//font-size: 0px;
			color: transparent;
			padding: 6px;
			cursor: pointer;

			&:hover,
			&:focus {
				outline: none;
				//&:before {
				//    opacity: $slick-opacity-on-hover;
				//}
			}

			&::before {
				position: absolute;
				//top: 0;
				//left: 0;
				content: $slick-dot-character;
				width: 8px;
				height: 8px;
				border-radius: 8px;
				border: 1px solid $color-gray-darker;
				//font-family: $slick-font-family;
				//font-size: $slick-dot-size;
				line-height: 10px;
				text-align: center;
				color: $color-gray-darker;
				background-color: $color-white;
				//opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}

		&.slick-active button::before {
			color: $color-gray-darker;
			background-color: $color-gray-darker;
			opacity: $slick-opacity-default;
		}
	}
}
