/* ------------------------------------*\
    #CL-FILTER
    - Filterbox / Flyout
\*------------------------------------ */

// todo: Breite automatisch anpassen
$filter-width-category: 350px;
$filter-width-stars: 172px;
$filter-width-distance: 350px;
$filter-height-distance: 80px;

.c-cl-filter {
	position: absolute;
	left: 0;
	top: 100%;

	// Set star colors to primary
	.c-icon--star-inactive,
	.c-icon--star-active {
		color: $color-classic-primary;
	}

	.c-cl-filter-bar__item.is-open & {
		display: block;
	}

	@include media-query(m-down) {
		position: static;
		width: 100%;
		display: block;
	}
}

.c-cl-filter__box {
	display: none;
	border: 1px solid $color-gray-light;
	padding: $spacing-unit;
	background: $color-white;

	.c-cl-filter-bar__item.is-open & {
		// wird nie gesetzt ?
		display: inline-block;
	}

	&--categories {
		width: $filter-width-category;

		@include media-query(m-down) {
			width: 100%;
		}
	}

	&--stars {
		width: $filter-width-stars;

		@include media-query(m-down) {
			width: 100%;
		}
	}

	&--sort {
		position: absolute;
		left: auto;
		right: 0;
		white-space: nowrap;

		@include media-query(m-down) {
			background-color: $color-white !important;
			border: 1px solid $color-gray-darker !important;
			margin-top: -1px;
		}

		&.is-open {
			display: block;
		}
	}

	&--distances {
		width: $filter-width-distance;
		height: $filter-height-distance;

		@include media-query(m-down) {
			width: 100%;
		}
	}

	@include media-query(m-down) {
		border: none;
		background: $color-gray-lighter;

		&.is-open {
			display: block;
		}
	}
}

.c-cl-filter__list-item-container {
	line-height: 0;
	margin-bottom: $spacing-unit--small;

	&:hover {
		.c-cl-filter__list-item {
			&::after {
				content: $icon-font--star-active;

				@include icon-font();

				color: $color-classic-primary;
			}
		}
	}
}

// Bezieht sich nur auf Stars ????
.c-cl-filter__list-item {
	padding-right: $spacing-unit--tiny;
	height: 20px;
	font-size: 20px;

	&::after {
		content: $icon-font--star-inactive;

		@include icon-font();

		color: $color-classic-primary;
	}

	&:hover {
		& ~ .c-cl-filter__list-item::after {
			content: $icon-font--star-inactive;

			@include icon-font();
		}
	}
}

.c-cl-filter__list-link {
	width: 100%;
	display: inline-block;
	line-height: 1.2;
	padding: 6px 0;
}

.c-cl-filter__box-wrapper {
	@include media-query(m-down) {
		max-height: none !important;
	}
}

.c-cl-filter__append-container {
	label {
		&:hover {
			cursor: pointer;
		}
	}
}
